import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Button from '../ui/Buttons/Button'
import styles from './LoadMore.module.sass'
import { SessionContext } from '../../modules/Session/context'
import { licenseTypes } from '../../services/FW5ML/parsers/UserParser'
import { paywallRedirectFlow } from '../../modules/Benefits/reducer'

const LoadMore = ({
  label = 'Load More',
  hasMore = true,
  isLoading = false,
  loadMore,
  pageStart = 0,
  ...props
}) => {
  const [page, setPage] = useState(pageStart)
  const { checked: sessionReady, user } = useContext(SessionContext)
  const dispatch = useDispatch()
  const hndClick = e => {
    if (!sessionReady) return
    if (
      user.license === licenseTypes.LICENSE_PLUS ||
      user.license === licenseTypes.LICENSE_FREE
    ) {
      loadMore(page + 1)
      setPage(page + 1)
    } else {
      dispatch(paywallRedirectFlow('push'))
    }
  }
  if (!hasMore && !isLoading) return null
  const onClick = !isLoading && hndClick ? hndClick : undefined
  return (
    <Button
      type="button"
      textCase="none"
      onClick={onClick}
      hasSpinner
      submitting={isLoading}
      iconLeft="svg/material-design-icons/navigation/more_vert"
      tier="default"
      className={styles.button}
      data-piwik-class="load-more"
      data-testid="load-more"
      {...props}
    >
      {label}
    </Button>
  )
}

LoadMore.propTypes = {
  label: PropTypes.string,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  pageStart: PropTypes.number
}

export default LoadMore
