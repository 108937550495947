import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

const isExternal = url => /^(\w*:)\/\//.test(url)

const Link = ({ to, ...props }) =>
  isExternal(to) ? (
    <a href={to} {...props}>
      {props.children}
    </a>
  ) : (
    <RouterLink to={to} {...props} />
  )

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
}

export default Link
