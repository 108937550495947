import { takeEvery, put, call, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { reauthAndRetryCall } from 'src/utils/sagaHelpers.js'
import { actions } from './reducer.js'
// import * as selectors from './selectors'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import { paywallRedirectFlow } from 'src/modules/Benefits/reducer.js'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import { logout } from 'src/modules/Session/actions'
import {
  NOT_ALLOWED,
  SERVER_ERROR,
  ACCESS_DENIED
} from 'src/services/FW5ML/errorCodes.js'

function* fetchRiverData(services, payload) {
  const { searchConfig = {}, searchQueryConfig = {} } = payload
  const StoriesRepository = services('StoriesRepository')
  const Piwik = services('Piwik')

  try {
    const { data } = yield reauthAndRetryCall(
      services,
      [StoriesRepository, 'search'],
      searchConfig,
      searchQueryConfig
    )

    return data
  } catch (e) {
    yield put(actions.errorDynamicRiverContent())
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === NOT_ALLOWED) {
        yield put(paywallRedirectFlow('push'))
      }
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: false }))
        return
      }
      if ([SERVER_ERROR].indexOf(code) > -1) {
        yield put(logPush('Search', e.message))
        yield put(
          notificationsEnqueue({
            message:
              'Unable to conduct search. Please refresh the page and try again.'
          })
        )
        yield call(
          [Piwik, 'track'],
          'notification',
          'error',
          'search-server-error'
        )
        yield put(push('/contact-us'))
      }
    } else {
      console.log(e)
      yield put(
        notificationsEnqueue({
          message:
            'Unable to conduct search. Please refresh the page and try again.'
        })
      )
      yield call(
        [Piwik, 'track'],
        'notification',
        'error',
        'search-general-error'
      )
    }
  }
}

function* requestDynamicRiverContent(services, { payload }) {
  try {
    const { riverId, config = {} } = payload
    const searchConfig = { filters: config }
    const data = yield fetchRiverData(services, { searchConfig })
    yield put(actions.successDynamicRiverContent({ riverId, data }))
  } catch (e) {
    console.error(e)
    yield put(actions.errorDynamicRiverContent())
  }
}

function* loadMore(services, { payload }) {
  try {
    const { riverId, config = {} } = payload

    const dynamicContentRiver = yield select(state => state.dynamicContentRiver)
    const prevConfig = dynamicContentRiver[riverId]
      ? dynamicContentRiver[riverId]
      : {}

    const searchConfig = {
      filters: {
        ...config,
        from: prevConfig.filter ? prevConfig.filter.from : 0
      }
    }

    const data = yield fetchRiverData(services, { searchConfig })
    yield put(actions.successLoadMore({ riverId, data }))
  } catch (e) {
    console.error(e)
    yield put(actions.errorDynamicRiverContent())
  }
}

export default function* watchConfig(services) {
  yield all([
    takeEvery(
      actions.requestDynamicRiverContent,
      requestDynamicRiverContent,
      services
    ),
    takeEvery(actions.requestLoadMore, loadMore, services)
  ])
}
