import { createAction, handleActions } from 'redux-actions'
import asyncMeta from '../AsyncFlags/metaCreator'

export const registerAds = createAction('ADS__REGISTER')
export const requestAds = createAction('ADS__REQUEST', null, asyncMeta)
export const successAds = createAction('ADS__SUCCESS', null, asyncMeta)
export const errorAds = createAction('ADS__ERROR', null, asyncMeta)
export const cleanAds = createAction('ADS__CLEAN')
export const prestitialAdRequest = createAction('PRESTITIAL_AD__REQUEST')
export const prestitialAdFinished = createAction('PRESTITIAL_AD__FINISHED')
export const prestitialAdReset = createAction('PRESTITIAL_AD__RESET')

export const actions = {
  registerAds,
  requestAds,
  successAds,
  errorAds,
  cleanAds,
  prestitialAdRequest,
  prestitialAdFinished,
  prestitialAdReset
}

const defaultState = {}

export default handleActions(
  {
    ADS__REGISTER: (state, { payload: ads }) => {
      const newAds = Object.keys(ads).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...ads[key],
            ready: false
          }
        }),
        {}
      )
      return {
        ...state,
        ...newAds
      }
    },
    ADS__SUCCESS: (state, { payload: { key, ad } }) => ({
      ...state,
      [key]: {
        ...state[key],
        ...ad,
        ready: true
      }
    }),
    ADS__ERROR: (state, { payload: { key, error } }) => ({
      ...state,
      [key]: {
        ...state[key],
        error,
        ready: true
      }
    }),
    ADS__CLEAN: () => {
      return defaultState
    },
    PRESTITIAL_AD__REQUEST: state => ({
      ...state,
      prestitialAd: {
        ...state?.prestitialAd,
        ready: false
      }
    }),
    PRESTITIAL_AD__FINISHED: (state, { payload }) => ({
      ...state,
      prestitialAd: {
        ...payload,
        ready: true
      }
    }),
    PRESTITIAL_AD__RESET: state => ({
      ...state,
      prestitialAd: {
        ...state?.prestitialAd,
        emptyContent: true
      }
    })
  },
  defaultState
)
