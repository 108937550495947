import React from 'react'
import ComponentLoopItemMapping from 'src/layouts/components/children/ComponentLoopItemMapping'
import * as singleStoryComponents from './components'
import { mappedComponents } from 'src/layouts/components/children/utils'
import useSingleStory from 'src/hooks/useSingleStory'

const mapping = {
  ...mappedComponents(singleStoryComponents)
}

const SingleStoryLoopItem = props => {
  const { story } = useSingleStory()
  return <ComponentLoopItemMapping {...props} mapping={mapping} story={story} />
}

export default SingleStoryLoopItem
