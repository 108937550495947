export default [
  'fw_category',
  'fw_clinical_trial',
  'fw_company',
  'fw_conference',
  'fw_market_segment',
  'fw_product',
  'fw_regulatory_market',
  'fw_regulatory_qualifier',
  'fw_story_watch',
  'fw_job_position',
  'conditions'
]
