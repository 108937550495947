import React from 'react'
import PropTypes from 'prop-types'
import ActionIcon from '../ActionIcon'

const LearnMoreActionButton = props => {
  const { action, ...rest } = props
  return (
    <ActionIcon
      {...rest}
      iconText={''}
      iconPath="svg/material-design-icons/content/info"
    />
  )
}

LearnMoreActionButton.propTypes = {
  action: PropTypes.any
}

export default LearnMoreActionButton
