import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Ripple from '../Ripple'
import modernStyles from './SwitchBaseModern.module.sass'
import traditionalStyles from './SwitchBaseTraditional.module.sass'
import Divider from '../Divider'
import classNames from 'classnames/bind'
import FieldError from '../FieldError'
// import requiredIf from 'react-required-if'

export const SwitchIndication = ({
  themeStyles,
  type,
  checked,
  hasChildren,
  hasError,
  labelPlacement = 'right'
}) => {
  const cx = classNames.bind(themeStyles)
  const valignCheckboxStyles = {
    top: '50%',
    transform: 'translateY(-50%)'
  }
  const Indication = (
    <div
      style={{
        ...(hasChildren && valignCheckboxStyles)
      }}
      className={cx({
        checkboxOn: type === 'checkbox' && checked,
        checkboxOff: type === 'checkbox' && !checked,
        radioIndication: type === 'radio',
        checkboxError: hasError,
        indicationRight: labelPlacement === 'left' && type !== 'radio'
      })}
    />
  )
  if (type === 'radio') {
    return (
      <div
        className={cx({
          radioOn: checked,
          radioOff: !checked,
          radioParent: true,
          indicationRight: labelPlacement === 'left'
        })}
      >
        {Indication}
      </div>
    )
  } else {
    return Indication
  }
}
SwitchIndication.propTypes = {
  /*
   * The imported styles merged with any altTheme that was provided to the parent component
   */
  themeStyles: PropTypes.object,
  /*
   * If present, the controlled `checked` state
   */
  checked: PropTypes.bool,
  /*
   * The input type
   */
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  /*
   * Set to `true` if the parent component has children
   */
  hasChildren: PropTypes.bool,
  /*
   *  Set to `true` if the parent component has errors
   */
  hasError: PropTypes.bool,
  /*
   * The label text placement
   */
  labelPlacement: PropTypes.oneOf(['right', 'left'])
}

const SwitchBase = ({
  input: {
    checked: inputChecked,
    value,
    name: inputName,
    onChange: inputOnChange,
    ...input
  },
  meta: { touched, error: metaError },
  onChange: onChange,
  name,
  meta,
  label,
  children,
  hasDivider,
  inline,
  error,
  errorAlign,
  altTheme,
  checked: checkedProp,
  value: valueProp,
  type,
  traditional,
  className,
  disabled,
  textAlign,
  defaultChecked,
  labelPlacement = 'right',
  ...other
}) => {
  const [checkedState, setChecked] = useState(defaultChecked)
  const isControlled = checkedProp != null
  const styles = traditional ? traditionalStyles : modernStyles
  const themeStyles = { ...styles, ...altTheme }
  const checked = isControlled ? checkedProp : checkedState
  const hasError = Boolean((touched && metaError) || error)
  const id = `${inputName || name}${valueProp ? `-${valueProp}` : ''}`
  const ParentTag = disabled ? 'div' : Ripple
  const cx = classNames.bind(themeStyles)
  const handleChange = event => {
    const checked = event.target.checked
    if (!isControlled) {
      setChecked(checked)
    }
    if (inputOnChange !== undefined) {
      inputOnChange(event)
    }
    if (onChange !== undefined) {
      onChange(event)
    }
  }
  return (
    <Fragment>
      <div
        style={{
          ...(!label && !children && { width: '44px' }),
          ...(inline && { display: 'inline-block', marginRight: '5px' })
        }}
        className={cx({
          checked,
          checkboxParent: true,
          disabled,
          className
        })}
      >
        <input
          {...input}
          {...other}
          id={id}
          value={valueProp}
          name={inputName || name}
          type={type}
          checked={checked}
          className={themeStyles.checkboxInput}
          onChange={handleChange}
          disabled={disabled}
        />
        {!traditional && (
          <span
            className={cx({
              checkboxMaterial: true,
              indicationRight: labelPlacement === 'left'
            })}
          >
            <span className={themeStyles.checkboxChecker} />
          </span>
        )}
        <ParentTag className={themeStyles.ripple}>
          <label
            htmlFor={id}
            className={cx({
              checkboxLabel: true,
              checkboxLeft: labelPlacement === 'right',
              checkboxRight: labelPlacement === 'left'
            })}
            style={{ ...(textAlign && { textAlign: textAlign }) }}
          >
            {!children ? label : children}
          </label>
        </ParentTag>
        <SwitchIndication
          themeStyles={themeStyles}
          type={type}
          checked={checked}
          hasChildren={Boolean(children)}
          hasError={hasError}
          labelPlacement={labelPlacement}
        />
      </div>
      {hasDivider && !inline && (label || children) && (
        <Divider
          marginLeft={labelPlacement === 'right' ? 42 : 0}
          marginRight={labelPlacement === 'left' ? 42 : 0}
          error={hasError}
        />
      )}
      {hasError ? (
        <FieldError align={errorAlign}>{metaError || error}</FieldError>
      ) : null}
    </Fragment>
  )
}

SwitchBase.displayName = 'SwitchBase'
SwitchBase.defaultProps = {
  input: {},
  meta: {},
  hasDivider: true,
  inline: false,
  error: '',
  traditional: false,
  disabled: false
}
SwitchBase.propTypes = {
  /**
   * The field label
   */
  label: PropTypes.string,
  /**
   * If `true`, the field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Does this item have a bottom divider? (Has no effect if there is no "label" prop or if "inline" is `true`)
   */
  hasDivider: PropTypes.bool,
  /**
   * The connected input props (if using redux-form)
   */
  input: PropTypes.object,
  /**
   * Metadata about the state of this field (if using redux-form)
   */
  meta: PropTypes.object,
  /**
   * If present, the controlled `checked` state
   */
  checked: PropTypes.bool,
  /**
   * Used for the input "id", "name", and label "for" attributes
   */
  name: (props, propName) => {
    if (!props.name && !props.input.name) {
      return new Error(
        'Please specify at least one of props "name" or "input.name"'
      )
    }
  },
  /**
   * Should the checkbox display inline?
   */
  inline: PropTypes.bool,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * If `true`, the input uses the traditional style
   */
  traditional: PropTypes.bool,
  /**
   * A custom onChange event
   */
  onChange: PropTypes.func,
  /**
   * The error shown below the checkbox field (not used if there is a redux-form style 'meta.error' value)
   */
  error: PropTypes.node,
  /**
   * The error alignment
   */
  errorAlign: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  /**
   * The lengthy version of the label, html supported. Will replace label if provided
   */
  children: PropTypes.node,
  /**
   * If `true` and the input does not have a controlled `checked` prop, it is checked by default
   */
  defaultChecked: PropTypes.bool,
  /**
   * The input value
   */
  // value: requiredIf(PropTypes.string, props => props.type === 'radio'),
  /**
   * The input type
   */
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  /**
   * The label text alignment
   */
  textAlign: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  /**
   * An additional custom className for the root element
   */
  className: PropTypes.string,
  /*
   * The label text placement
   */
  labelPlacement: PropTypes.oneOf(['right', 'left'])
}
export default SwitchBase
