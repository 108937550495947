import { Fragment } from 'react'
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { Link } from 'react-router-dom'
import { actions } from './reducer.js'

function* requestArticleShare(services, action) {
  const StoriesRepository = services('StoriesRepository')
  const Piwik = services('Piwik')
  const { payload } = action
  const { data = {} } = payload
  try {
    const Session = services('Session')
    const user = yield call([Session, 'userLoad'])
    const body = { ...data, user }
    yield call([StoriesRepository, 'shareArticle'], body)

    yield call(
      [Piwik, 'track'],
      'pop-up_share-email',
      'button-click',
      'success'
    )

    yield put(actions.successArticleShare())
  } catch (e) {
    yield put(actions.errorArticleShare(e.message))
    yield call([Piwik, 'track'], 'pop-up_share-email', 'button-click', 'failed')

    yield put(
      notificationsEnqueue({
        message: <Fragment>Unable to share the selected article.</Fragment>
      })
    )
  }
}
function* requestSiteShare(services, action) {
  const StoriesRepository = services('StoriesRepository')
  const Piwik = services('Piwik')
  const { payload } = action
  const { data } = payload
  try {
    const Session = services('Session')
    const user = yield call([Session, 'userLoad'])
    const body = { ...data, user }
    yield call([StoriesRepository, 'shareSite'], body)

    yield call(
      [Piwik, 'track'],
      'pop-up_share-invite-colleague',
      'button-click',
      'success'
    )
    yield put(actions.successSiteShare())
  } catch (e) {
    yield put(actions.errorSiteShare(e.message))
    yield call(
      [Piwik, 'track'],
      'pop-up_share-invite-colleague',
      'button-click',
      'failed'
    )

    yield put(
      notificationsEnqueue({
        message: <Fragment>Unable to share site.</Fragment>
      })
    )
  }
}

function* reportDownloadRequest(services, { payload: productCode }) {
  const StoriesRepository = services('StoriesRepository')
  const Piwik = services('Piwik')

  try {
    const data = yield call([StoriesRepository, 'downloadReport'], productCode)
    const response = yield call(fetch, data.url)

    if (!response.ok) {
      yield put(actions.reportDownloadError({ error: e.message }))
      yield call([Piwik, 'track'], 'action-bar_article', 'download', 'failed')
      yield put(
        notificationsEnqueue({
          message: (
            <Fragment>
              Unable to download report. Please{' '}
              <Link to={'/contact-us'}>contact Us </Link> for assistance.
            </Fragment>
          )
        })
      )
      return
    }

    const disposition = response.headers.get('Content-Disposition')
    let filename = ''

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches?.[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }

    if (!filename) {
      filename = response.url.split('/').pop().split('?').shift()
    }

    const blob = yield response.blob()
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)

    yield put(actions.reportDownloadSuccess())
    yield call([Piwik, 'track'], 'action-bar_article', 'download', 'success')
  } catch (e) {
    yield put(actions.reportDownloadError({ error: e.message }))
    yield call([Piwik, 'track'], 'action-bar_article', 'download', 'failed')
    yield put(
      notificationsEnqueue({
        message: (
          <Fragment>
            Unable to download report. Please{' '}
            <Link to={'/contact-us'}>contact Us </Link> for assistance.
          </Fragment>
        )
      })
    )
  }
}

function* updateLikeRequest(services, { payload: articleId }) {
  const StoriesRepository = services('StoriesRepository')
  try {
    yield call([StoriesRepository, 'updateLike'], articleId)
    yield put(actions.updateLikeSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.updateLikeError({ error: e.message }))
    yield put(
      notificationsEnqueue({
        message: <Fragment>Unable to like the selected article.</Fragment>
      })
    )
  }
}

export default function* watch(services) {
  yield takeLatest('ARTICLE_SHARE__REQUEST', requestArticleShare, services)
  yield takeLatest('SITE_SHARE__REQUEST', requestSiteShare, services)
  yield takeEvery('UPDATE_LIKE_REQUEST', updateLikeRequest, services)
  yield takeEvery('REPORT_DOWNLOAD__REQUEST', reportDownloadRequest, services)
}
