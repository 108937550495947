import React from 'react'
import Page from '../../../../components/Page/index.js'
import LoginEmailSent from './components/LoginEmailSent'
import styles from '../../../../modules/Static/Static.module.sass'
import cx from 'classnames'

const EmailSentPage = () => {
  return (
    <Page className={cx(styles.static, styles.centered)}>
      <LoginEmailSent />
    </Page>
  )
}

export default EmailSentPage
