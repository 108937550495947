import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { getConfig } from '../Config/selectors'

const useLaunchDarkly = () => {
  const flags = useFlags()

  const featureFlagConfig = useSelector(getConfig('feature_flag_ldk')) || null

  const validateMenuItem = item => {
    const { featureFlag = null, featureFlagFallback = null } = item
    if (!featureFlag || flags[featureFlag]) return item

    if (featureFlagFallback) {
      return featureFlagFallback
    }

    return null
  }

  const getFeatureFlagMenuItem = item => {
    if (item?.group) {
      let content = []
      for (const groupItem of item.content) {
        const validatedGroupItem = validateMenuItem(groupItem)
        if (validatedGroupItem) {
          content.push({ ...validatedGroupItem })
        }
      }
      item.content = content
      return item
    } else {
      return validateMenuItem(item)
    }
  }

  return {
    flags,
    featureFlagConfig,
    getFeatureFlagMenuItem,
    validateMenuItem
  }
}

export default useLaunchDarkly
