export default [
  {
    body: '**Discover our new scientific area weekly newsletters**  \nStay on top of critical developments in top fields of interest. _Learn more._',
    cta: '/my-account/newsletters'
  },
  {
    body: 'Drive our efforts by sending us _feedback_ on our new site',
    cta: '/contact-us'
  },
  {
    body: 'Is the new FirstWord HealthTech service meeting your needs? _Tell us_ how we can improve',
    cta: '/contact-us'
  },
  {
    body: 'Have you encountered any bugs or unexpected results in testing our new site? _Let us know_ so we can fix them',
    cta: '/contact-us'
  }
]

export const singleStorySponsoredHeadline =
  '# Get your daily HealthTech news!\n\n[Register](/register) to receive the FW HealthTech newsletter providing you with global news and intelligence on the latest developments in the medical device, digital health and diagnostics industries.'
