export default function validate(values) {
  const errors = {}

  if (!values.email_address) {
    errors.email_address = 'Please enter at least one email.'
  }
  if (values.email_address) {
    const emails = values.email_address.split(',')
    for (const email of emails) {
      if (!/^[^@]+@.+\.[a-z]{2,}$/i.test(email.trim()))
        errors.email_address = 'Invalid email address'
    }
  }

  if (!values.email_message) {
    errors.email_message = 'Message is required'
  }

  return errors
}
