import _set from 'lodash/set'
import _get from 'lodash/get'
import { getSiteName } from '../../../utils/oneCodeBase'

const getIsReportsSite = () => getSiteName() === 'Reports'

const SERVICE_OR_BRAND_NAME = {
  Pharma: 'FirstWord Pharma+',
  HealthTech: 'FirstWord HealthTech+'
}
const getServiceOrBrandName = site => SERVICE_OR_BRAND_NAME[site]

const SERVICE_CODES = {
  Pharma: '596100009',
  HealthTech: '596100073'
}
const getServiceCode = site => SERVICE_CODES[site]

const PAGES = {
  '/river/all-reports': 'river-page_all-reports',
  '/river/your-reports': 'river-page_your-reports',
  '/river/upcoming': 'river-page_upcoming-reports',
  'landing-page': 'report-landing-page',
  '/': 'home-page',
  default: 'home-page'
}
const getReportsCampaign = pathname => {
  const regex = /^\/story\/\d+$/
  if (pathname in PAGES) return PAGES[pathname]
  if (regex.test(pathname)) return PAGES['landing-page']
  return PAGES.default
}

const PAYWALL_SUB_TYPES = {
  plus_article: 'PLUS Article - ',
  plus_newsletter: 'PLUS Newsletter - ',
  article_limit: 'Anonymous User - Max Articles exceeded',
  add_interests: 'Add Interest - Story Page - ',
  remove_interests: 'Add Interest - Story Page',
  search: 'Search',
  ai_paywall: 'FirstWord AI - '
}
const ANONYMOUS_PAYALLS = ['plus_article', 'add_interests', 'remove_interests']
// Gets the utm_campaign (for PH and HT) based on the paywall subtype
const getUTMCampaign = (subType, isAuthenticated, extra) => {
  let UTMCampaign = PAYWALL_SUB_TYPES[subType]
  if (!UTMCampaign) return ''
  // Since Paywall is just for Free and Anonymous
  if (ANONYMOUS_PAYALLS.includes(subType)) {
    const prefix = !isAuthenticated ? 'Anonymous' : 'Free'
    UTMCampaign += prefix
  }

  if (subType === 'ai_paywall') {
    const prefix = !isAuthenticated ? 'Anonymous' : 'Logged In'
    UTMCampaign += prefix
  }

  if (subType === 'plus_newsletter' && extra) {
    const { newsletterName = '' } = extra
    UTMCampaign += newsletterName
  }

  if (subType === 'search') {
    UTMCampaign += extra
  }
  return UTMCampaign
}

const ContactUsParser = () => ({
  parseZohoDataRequest: data => {
    let newParsedData = {}
    const isReportSite = getIsReportsSite()
    const siteName = getSiteName()
    const fullUrl = _get(data, 'fullPageUrl', '')
    const extra = _get(data, 'extraInfo', {})
    const isAuthenticated = _get(data, 'isAuthenticated')
    const pathname = _get(data, 'originUrl', '')

    // Shared Data
    _set(newParsedData, 'page_url', fullUrl)
    _set(newParsedData, 'first_name', _get(data, 'first_name', ''))
    _set(newParsedData, 'country', _get(data, 'country', ''))
    _set(newParsedData, 'email', _get(data, 'email', ''))
    _set(newParsedData, 'company', _get(data, 'company', ''))
    _set(newParsedData, 'job_function', _get(data, 'job_function', ''))
    _set(newParsedData, 'job_title', _get(data, 'job_title', ''))
    _set(newParsedData, 'last_name', _get(data, 'last_name', ''))
    _set(newParsedData, 'license', _get(data, 'license', ''))
    _set(newParsedData, 'phone_number', _get(data, 'phone_number', ''))
    _set(newParsedData, 'therapy_area', _get(data, 'therapy_area', ''))
    _set(newParsedData, 'comments', _get(data, 'comments', false))
    _set(newParsedData, 'register_pharma', _get(data, 'register_pharma', false))
    _set(newParsedData, 'register_edge', _get(data, 'register_edge', false))
    _set(
      newParsedData,
      'register_healthtech',
      _get(data, 'register_healthtech', false)
    )
    _set(
      newParsedData,
      'register_reports',
      _get(data, 'register_reports', false)
    )

    // Reports Data
    if (isReportSite) {
      const reportTitle = _get(extra, 'reportTitle', '')
      const productCode = _get(extra, 'productCode', '')
      const reportBrand = _get(extra, 'reportBrand', '')
      _set(newParsedData, 'fw_service_name', reportTitle)
      _set(newParsedData, 'fw_service_code', productCode)
      _set(newParsedData, 'brand_name', reportBrand)
      _set(newParsedData, 'fw_service', 'FirstWord Reports')
      _set(newParsedData, 'utm_medium', 'pop up')
      _set(newParsedData, 'utm_source', 'website')
      _set(newParsedData, 'utm_campaign', getReportsCampaign(pathname))

      // PH or HT Data
    } else {
      const serviceOrBrandName = getServiceOrBrandName(siteName)
      const subType = _get(data, 'subType', '')
      _set(newParsedData, 'fw_service_name', serviceOrBrandName)
      _set(newParsedData, 'fw_service_code', getServiceCode(siteName))
      _set(newParsedData, 'brand_name', serviceOrBrandName)
      _set(newParsedData, 'fw_service', serviceOrBrandName)
      _set(newParsedData, 'utm_medium', 'paywall')
      _set(newParsedData, 'utm_source', 'website')
      _set(
        newParsedData,
        'utm_campaign',
        getUTMCampaign(subType, isAuthenticated, extra)
      )
    }

    return newParsedData
  }
})

export default ContactUsParser
