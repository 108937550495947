import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import styles from './CustomizableModal.module.sass'

export const CustomizableModal = ({
  children,
  open = false,
  onClose = () => {},
  modalStyles = {},
  modalContentStyles = {},
  width = '',
  height = '',
  isLoading = false
}) => {
  const modalSize = useMemo(
    () => ({
      maxWidth: width,
      height
      // left: `calc(50% - (${width} / 2))`,
      // bottom: `calc(50% - (${height} / 2))`
    }),
    [width, height]
  )
  if (!open) {
    return null
  }

  return (
    <div id="modal" className={styles.modal} style={modalStyles}>
      <div
        className={styles.content}
        style={{ ...modalContentStyles, ...modalSize }}
      >
        <div className={styles.closeSection}>
          <button
            onClick={!isLoading ? onClose : () => {}}
            className={styles.modalButton}
          >
            <span id="closeModal" className={styles.close}>
              &times;
            </span>
          </button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}

CustomizableModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalContentStyles: PropTypes.object,
  modalStyles: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  isLoading: PropTypes.bool
}
