import { getConfigModules } from './utils'

const contentRiversPath = `river/contentRivers`
const filterLabelsPath = `river/filterLabels`
const labelReplacementsPath = `river/labelReplacements`
const riverConfigPath = `river/riverConfig`
const externalSitesConfigPath = `river/externalSitesConfig`

export const contentRiversConfigModule = getConfigModules(contentRiversPath)
export const filterLabelsConfigModule = getConfigModules(filterLabelsPath)
export const labelReplacementsConfigModule = getConfigModules(
  labelReplacementsPath
)
export const riverConfigModule = getConfigModules(riverConfigPath)
export const externalSitesConfigModule = getConfigModules(
  externalSitesConfigPath
)
