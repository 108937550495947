import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import i18next from 'i18next'
import { actions as chatActions } from './ChatReducer'
import { notificationsEnqueue } from '../Notifications/reducer'
import moment from 'moment'
import { getMessages, getLinkedPromptId } from './ChatSelector'
import { actions as loadingActions } from '../loading/LoadingReducer'
import { piwikSiteId } from '../../constants'
import { reauthAndRetryCall } from '../../utils/sagaHelpers'

function* promptRequest(services, { payload }) {
  const DougallService = services('DougallService')
  const SessionService = services('SessionService')
  const Piwik = services('Piwik')
  const promptsAllowed = 10000
  const messages = yield select(getMessages())
  const date = moment().format('DD/MM/YYYY')
  const haystack = localStorage.getItem('knowledge_setting')
  const haystackTrackingKey =
    haystack === 'fwHaystackPlusBase' ? 'fw-news-and-dougall' : 'fw-news-only'

  try {
    // Add date chunk divider
    if (date && !messages.find(m => m.date === date && m.isDivider)) {
      yield put(
        chatActions.addMessage({
          date,
          isDivider: true
        })
      )
    }
    // Update Chat reducer
    yield put(
      chatActions.addMessage({
        message: payload.hcpPrompt,
        isAnswer: false,
        date: moment().format()
      })
    )
    yield put(
      chatActions.setFormState({
        prompt: ''
      })
    )

    // Exit if user is anon & deviceId is not ready
    yield put(chatActions.promptRequestStart())
    const promptsRequested = yield call(
      [SessionService, 'getFromCache'],
      'promptsRequested',
      0
    )

    const type = localStorage.getItem('knowledge_setting')
    if (promptsAllowed && promptsRequested >= promptsAllowed) {
      // User no longer has prompts available, redirect to login page
      // yield call([history, history.replace], routes.login.path)
    } else {
      // TODO: Figure out where this come from
      const dspId = ''
      const [languageCode, countryCode] = navigator.language.split('-')
      const source = parseInt(piwikSiteId)
      const linkedPromptId = yield select(getLinkedPromptId()) || null

      if (linkedPromptId) {
        payload.applicationLinkedPromptId = linkedPromptId
      }

      const response = yield reauthAndRetryCall(
        services,
        [DougallService, 'promptStreamRequest'],
        {
          ...payload,
          countryCode,
          languageCode,
          dspId,
          type,
          source
        }
      )

      if (response) {
        if (promptsAllowed) {
          // Increase cache count if role contains promptsAllowed key
          yield call(
            [SessionService, 'saveToCache'],
            'promptsRequested',
            promptsRequested + 1
          )
        }
      }
    }
  } catch (e) {
    yield call(
      [Piwik, 'track'],
      'firstword-ai',
      'failed',
      `prompt-answer_${haystackTrackingKey}`
    )

    console.log(e)
    // Handle Timeout error & add error answer
    if ([408, 504].includes(e.getCode())) {
      yield put(
        chatActions.addMessage({
          answer: i18next.t('dougallGptTimeoutAnswer'),
          isAnswer: true,
          showSocial: false,
          isError: true,
          showWarning: false,
          date: moment().format()
        })
      )
      yield put(chatActions.promptFail())
    } else {
      yield put(
        notificationsEnqueue({
          message: 'There was a problem with the request.',
          type: 'error'
        })
      )
      yield put(chatActions.promptFail())
    }
  } finally {
    // Reset stored path to avoid multiple url prompts requests
    yield put(loadingActions.pathReset())
    // Set URL flag request to true
    yield put(chatActions.setUrlRequested(true))
  }
}

function* actionSubmit(services, { payload }) {
  const DougallService = services('DougallService')
  const SessionService = services('SessionService')

  try {
    const { action, promptId, answerId, feedback, undo, parentPromptId } =
      payload

    const deviceId = yield call(
      [SessionService, 'getFromCache'],
      'id_key',
      !isAuth ? '' : 'DeviceID not available'
    )
    // Exit if user is anon & deviceId is not ready

    const { actionId } = yield call([DougallService, 'answerActionRequest'], {
      action,
      applicationPromptId: promptId,
      answerId,
      applicationLinkedPromptId: parentPromptId,
      deviceId: isAuth && !isLimited ? deviceId : `${deviceId}+${Date.now()}`,
      undo
    })

    if (actionId) {
      if (feedback) {
        const dspId = yield select(getDspId())
        const campaignId = yield call(
          [SessionService, 'getFromCache'],
          'CampaignID',
          ''
        )
        const dspUserData = dspId
          ? { dspId, campaignId: parseInt(campaignId) }
          : {}
        const response = yield call([DougallService, 'answerFeedbackSubmit'], {
          feedback,
          actionId,
          ...dspUserData
        })

        if (response) {
          yield put(
            notificationsEnqueue({
              message: i18next.t('feedbackThankYou'),
              type: 'success'
            })
          )
        } else {
          yield put(
            notificationsEnqueue({
              message: response,
              type: 'error'
            })
          )
        }
      }
    } else {
      yield put(
        notificationsEnqueue({
          message: 'There was a problem submitting your action',
          type: 'error'
        })
      )
    }
  } catch (e) {
    yield put(
      notificationsEnqueue({
        message: 'There was a problem submitting your action',
        type: 'error'
      })
    )
  }
}

function* shareEmail(services, { payload }) {
  const DougallService = services('DougallService')
  const UserTransactionService = services('UserTransactionService')

  try {
    const {
      pId = undefined // If pId then is Email Share
    } = payload

    const service = pId
      ? [DougallService, 'promptShareRequest'] // Share prompt
      : [UserTransactionService, 'inviteSubmit'] // Invite your colleague
    const response = yield call(service, payload)

    if (response) {
      yield put(
        notificationsEnqueue({
          message: i18next.t('emailShareFormSnackbarMessage'),
          type: 'success'
        })
      )
    }
  } catch (e) {
    console.error(e)
    yield put(
      notificationsEnqueue({
        message: i18next.t('commentsSagaErrorMessage'),
        type: 'error'
      })
    )
  }
}

export default function* watchChat(services) {
  yield all([
    takeEvery('CHAT_PROMPT_REQUEST', promptRequest, services),
    takeEvery('CHAT_ACTION_SUBMIT', actionSubmit, services),
    takeEvery('CHAT_SHARE_EMAIL', shareEmail, services)

    // If prompt validation should occur on events add the events here like:
    // or dispatch the action validatePromptAccess from the component
    //takeEvery('CHAT_SET_FORM_STATE', validatePromptAccess, services),
    //takeEvery('OTHER_ACTION', validatePromptAccess, services),
  ])
}
