import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { includes, lowerCase } from 'lodash'

import Button from '@mui/material/Button'
import { Box, Typography, styled } from '@mui/material'
import usePiwik from 'src/hooks/usePiwik'

/**
 * MD Message Container
 * theme path: DougallMDMessage.styleOverrides.container
 */
const MDMessageContainer = styled(Box, {
  name: 'DougallMDMessage',
  label: 'dougallMDMessageContainer',
  overridesResolver: (props, styles) => [styles.container]
})(({ theme }) => ({
  overflowY: 'auto',
  position: 'relative',
  paddingTop: '0.875rem',
  paddingBottom: '1.125rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  borderRadius: '1rem',
  letterSpacing: '-0.015em',
  background: `linear-gradient(to bottom, hsla(var(--bg-000) / 0.75) 0%, hsla(var(--bg-000) / 0) 90%)`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: `radial-gradient(ellipse at left top, hsla(var(--bg-000) / 0.5) 0%, hsla(var(--bg-000) / 0.3) 60%)`,
    borderRadius: '1rem',
    border: '0.5px solid hsla(var(--border-100) / 0.15)',
    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.015)',
    transition:
      'opacity 150ms ease-out, transform 250ms cubic-bezier(0.695, 0.555, 0.655, 1.650)',
    zIndex: 0
  },
  '&[data-is-streaming="true"]::before': {
    opacity: 0,
    transform: 'scale(0.995)'
  },
  '& .mdText': {
    lineHeight: 1.5,
    fontSize: '12px',
    color: theme.palette.fwMessage?.text,
    fontWeight: 500,
    width: '100%',
    margin: '10px 0px'
  }
}))

/**
 * Tags Container
 * theme path: DougallMDMessage.styleOverrides.tagsContainer
 */
const TagsContainer = styled(Box, {
  name: 'DougallMDMessage',
  label: 'dougallMDMessageTagsContainer',
  overridesResolver: (props, styles) => [styles.tagsContainer]
})({
  display: 'flex',
  flexDirection: 'column',
  margin: '3px 0px 3px 2px',
  '> div': {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0px 30px',
    '@media all and (max-width: 860px)': {
      margin: '0px 0px'
    }
  }
})

/**
 * MD Button
 * theme path: DougallMDMessage.styleOverrides.tagButton
 */
const MDButton = styled(Button, {
  name: 'DougallMDMessage',
  label: 'dougallMDMessageButton',
  overridesResolver: (props, styles) => [styles.tagButton]
})(({ theme }) => ({
  borderRadius: '4px',
  border: `solid 1px ${theme.palette.fwMessage?.buttonBorder}`,
  margin: '6px 3px',
  minWidth: 'auto',
  fontSize: '12px',
  color: theme.palette.fwMessage?.buttonText,
  padding: '4px 7px 3px',
  lineHeight: '1.5',
  fontWeight: '500',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
  '&:hover': {
    border: `solid 1px ${theme.palette.fwMessage?.buttonHoverBorder}`,
    backgroundColor: theme.palette.fwMessage?.buttonHoverBackground,
    color: theme.palette.fwMessage?.buttonHoverText
  }
}))

const DougallMDSection = ({ url, handleButtonClick }) => {
  const { handleTracking } = usePiwik()
  const { section, elements } = url
  const { t } = useTranslation()
  let errorMsg = includes(['conditions', 'maladies'], lowerCase(section))
    ? t('chatNoConditionFound')
    : t('chatNoMedicationsFound')
  return (
    <TagsContainer key={section}>
      <div>
        {elements.length > 0 ? (
          elements.map((element, idx) => {
            const refUrl = process.env.REACT_APP_URL
            const { name } = element

            const encodedName = encodeURIComponent(name)

            const link = (
              refUrl + '/search/${tagName}?rst=1&sort=relevancy'
            ).replace('${tagName}', encodedName)

            return (
              <div key={name}>
                <MDButton
                  data-url={link}
                  type="button"
                  onClick={() => {
                    handleButtonClick(link, { name }, idx + 1)
                    handleTracking('firstword-ai', 'trusted-link-click', name)
                  }}
                >
                  {name}
                </MDButton>
              </div>
            )
          })
        ) : (
          <Typography align={'center'} className="mdText">
            {errorMsg}
          </Typography>
        )}
      </div>
    </TagsContainer>
  )
}

DougallMDSection.propTypes = {
  url: PropTypes.object,
  handleButtonClick: PropTypes.func
}

const DougallMDMessage = ({ urls = [], interactionId = '' }) => {
  // tracking on view of answer
  const { t } = useTranslation()

  const handleUrlClick = url => {
    // track click on btn click.
    window.open(url, '_blank')
  }

  return (
    <MDMessageContainer>
      {urls.length > 0 ? (
        urls.map(url => (
          <div key={url.section}>
            <DougallMDSection
              url={url}
              handleButtonClick={handleUrlClick}
              interactionId={interactionId}
            />
          </div>
        ))
      ) : (
        <Typography align={'center'} className="mdText">
          {t('chatNoTagsFound')}
        </Typography>
      )}
    </MDMessageContainer>
  )
}

DougallMDMessage.propTypes = {
  urls: PropTypes.array,
  interactionId: PropTypes.string
}

export default DougallMDMessage
