import React from 'react'
import { Provider } from 'react-redux'
import createContainer from 'services'
import createStore from './store'
import Router, { history } from './Router'
import { createServiceProvider } from 'src/services/ServiceContext.js'
import Session from 'src/modules/Session/SessionContainer'

import Translation from 'src/modules/translation/Translation'
import Title from 'src/modules/Title/TitleContainer'
import Bootstrapping from 'src/modules/Bootstrapping/components/BootstrappingPage'
import LaunchDarklyContainer from 'src/modules/LaunchDarkly/LaunchDarklyContainer'
import Theme from 'src/modules/Theme/ThemeContainer'
import { ThemeProvider } from 'src/modules/themeProvider'

const container = createContainer()
const store = createStore(history, container)
container.registerStore(store)

if (process.env.NODE_ENV === 'development') {
  if (window.Cypress) {
    window.store = store
  }
}

const ServiceProvider = createServiceProvider(container)

const App = () => {
  return (
    <Provider store={store}>
      <ServiceProvider>
        <Bootstrapping>
          <Session>
            <Title history={history}>
              <Theme>
                <ThemeProvider>
                  <Translation>
                    <LaunchDarklyContainer>
                      <Router />
                    </LaunchDarklyContainer>
                  </Translation>
                </ThemeProvider>
              </Theme>
            </Title>
          </Session>
        </Bootstrapping>
      </ServiceProvider>
    </Provider>
  )
}

export default App
