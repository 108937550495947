import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import Icon from 'src/components/ui/Icon/index.js'
import Modal from 'src/components/Modal/index.js'
import LoginPage from 'src/modules/Login/LoginContainer'
import RegisterPage from 'src/modules/Register/containers/RegisterEdgeContainer'
import styles from './LoginRegisterModal.module.sass'
import commonStyles from 'src/styles/common.module.sass'
import { SessionContext } from 'src/modules/Session/context'
import cx from 'classnames'

const LoginRegisterModal = ({
  loginConfig = {},
  registerConfig = {},
  modalClassName = '',
  showCloseBtn = false,
  setModalVisible = () => {},
  tracking = () => {},
  ...props
}) => {
  const { checked: sessionReady } = useContext(SessionContext)
  const [loginVisible, setLoginVisible] = useState(false)
  const [registerVisible, setRegisterVisible] = useState(true)

  const handleRegisterLink = () => {
    setLoginVisible(false)
    setRegisterVisible(true)
  }

  const handleLoginLink = () => {
    setRegisterVisible(false)
    setLoginVisible(true)
  }

  useEffect(() => {
    // Track when modal shows up (if applicable)
    tracking()
  }, [])

  return (
    <>
      {sessionReady && (
        <Modal className={cx(styles.modal, modalClassName)}>
          {showCloseBtn && (
            <div className={styles.closeContainer}>
              <button
                className={styles.closeButton}
                onClick={() => setModalVisible(false)}
              >
                <Icon
                  className={styles.closeButton}
                  colorTier="icon"
                  icon="svg/material-design-icons/navigation/close"
                  iconSize={24}
                />
              </button>
            </div>
          )}
          <div
            className={cx(
              commonStyles.content,
              commonStyles.centered,
              styles.container
            )}
          >
            {loginVisible && (
              <LoginPage
                redirectEmailSent={false}
                parentClassName={styles.login}
                handleRegisterLinkFlow={handleRegisterLink}
                config={loginConfig}
              />
            )}
            {registerVisible && (
              <RegisterPage
                redirectEmailSent={false}
                parentClassName={styles.register}
                handleLoginLinkFlow={handleLoginLink}
                config={registerConfig}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

LoginRegisterModal.propTypes = {
  loginConfig: PropTypes.object,
  registerConfig: PropTypes.object,
  modalClassName: PropTypes.string,
  showCloseBtn: PropTypes.bool,
  setModalVisible: PropTypes.func,
  tracking: PropTypes.func
}

export default LoginRegisterModal
