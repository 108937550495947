import LogRocket from 'logrocket'
import { calculateSHA256 } from 'src/utils/index.js'

const LOG_ROCKET_APP_ID = process.env.REACT_APP_LOG_ROCKET_SITE || null

const requestSanitizer = request => {
  const loweredURL = request.url.toLowerCase()

  // if the url contains certain words
  const urlsToCheck = ['delete', 'auth', 'profile', 'registration']
  const containsUrl = new RegExp(urlsToCheck.join('|')).test(loweredURL)
  if (containsUrl) {
    // scrub out the body
    request.body = null
  }

  // if the url contains 'ignore'
  if (loweredURL.indexOf('ignore') !== -1) {
    // ignore the request response pair
    return null
  }

  // scrub header value from request
  if (request.headers['x-auth-token']) {
    request.headers['x-auth-token'] = ''
  }

  // scrub header value from request
  if (request.headers['Authorization']) {
    request.headers['Authorization'] = ''
  }

  // scrub header pair from request
  request.headers['x-secret'] = null

  // make sure you return the modified request
  return request
}

const responseSanitizer = response => {
  if (response.headers['x-secret']) {
    // removes all response data
    return null
  }

  // scrubs response body
  response.body = null
  return response
}

class LogRocketService {
  constructor() {
    this.initialized = false
  }

  initialize() {
    if (LOG_ROCKET_APP_ID) {
      LogRocket.init(LOG_ROCKET_APP_ID, {
        network: {
          requestSanitizer,
          responseSanitizer
        }
      })
      this.initialized = true
    }
  }

  identify(user) {
    if (!this.initialized) return

    const id = user?.profile_id
    if (id) {
      const {
        company,
        language,
        job_title,
        subscription_type,
        subscription_status,
        address = {}
      } = user

      const { country } = address

      const customData = {
        company,
        language,
        job_title,
        subscription_type,
        subscription_status,
        country
      }

      const cryptedId = calculateSHA256(id)
      LogRocket.identify(cryptedId, customData)
    }
  }

  reset() {
    if (!this.initialized) return
    LogRocket.identify()
  }
}

export default LogRocketService
