import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { tagWithCategory } from 'src/utils/propTypes.js'
import Typography from 'src/components/ui/Typography/index.js'
import styles from './AddInterests.module.sass'
import Interest from 'src/components/Interest/SingleStoryInterest.js'
import { getConfigFilteredTags } from 'src/modules/Config/selectors'
import { SessionContext } from 'src/modules/Session/context'
import {
  requestInsertInterest,
  requestDeleteInterest
} from 'src/modules/MyInterests/reducer.js'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { paywallRedirectFlow } from 'src/modules/Benefits/reducer.js'

const AddInterests = ({
  label = 'Add Interests to MyFW+',
  interests = [],
  disabledTags = [],
  showHeader = true,
  showIcons = true,
  clickable = true
}) => {
  const filteredTags =
    useSelector(
      getConfigFilteredTags({
        interests
      })
    ) || interests

  const sessionContext = useContext(SessionContext)
  const dispatch = useDispatch()
  const onAddInterest = (interest, e) => {
    const { checked: sessionReady, user } = sessionContext
    if (!sessionReady) return
    if (user.license !== licenseTypes.LICENSE_PLUS) {
      return dispatch(
        paywallRedirectFlow({
          type: 'plus_feature',
          subtype: 'add_interests'
        })
      )
    }
    return dispatch(requestInsertInterest(interest))
  }
  const onDeleteInterest = (...args) => {
    const { checked: sessionReady, user } = sessionContext
    if (!sessionReady) return
    if (user.license !== licenseTypes.LICENSE_PLUS) {
      return dispatch(
        paywallRedirectFlow({
          type: 'plus_feature',
          subtype: 'remove_interests'
        })
      )
    }
    return dispatch(requestDeleteInterest(...args))
  }

  return (
    <section className={styles.section}>
      {showHeader && (
        <Typography type="subheading" className={styles.label}>
          {label}
        </Typography>
      )}
      <div>
        {filteredTags
          .sort((a, b) =>
            a.tag.toLowerCase().localeCompare(b.tag.toLowerCase())
          )
          .map(interest => {
            const active = disabledTags.some(
              disabledInterest => disabledInterest.tag === interest.tag
            )
            return (
              <Interest
                active={active}
                interest={interest}
                key={`${interest.category}-${interest.tag}`}
                onDeleteInterest={onDeleteInterest}
                onAddInterest={onAddInterest}
                iconPosition="left"
                deleteEnabled
                inline
                showIcons={showIcons}
                clickable={clickable}
              />
            )
          })}
      </div>
    </section>
  )
}

AddInterests.propTypes = {
  /**
   * The section label
   */
  label: PropTypes.string,
  /**
   * Array of tags
   */
  interests: PropTypes.arrayOf(tagWithCategory),
  /**
   * Array of disabled tags
   */
  disabledTags: PropTypes.arrayOf(tagWithCategory),
  /**
   * Whether to show the header
   */
  showHeader: PropTypes.bool,
  /**
   * Whether to show icons
   */
  showIcons: PropTypes.bool,
  /**
   * Whether the interests can be clicked
   */
  clickable: PropTypes.bool
}

export default AddInterests
