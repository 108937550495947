import { createAction, handleActions } from 'redux-actions'

export const requestNotices = createAction('NOTICES__REQUEST')
export const successNotices = createAction('NOTICES__SUCCESS')
export const errorNotices = createAction('NOTICES__ERROR')
export const dismissNotice = createAction('NOTICE__DISMISS')

export const actions = {
  requestNotices,
  successNotices,
  errorNotices,
  dismissNotice
}

const defaultState = {
  notices: [],
  loading: false,
  error: null
}

export default handleActions(
  {
    [requestNotices]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [successNotices]: (state, action) => {
      return {
        ...state,
        notices: [...state.notices, ...action.payload],
        loading: false,
        error: null
      }
    },
    [errorNotices]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload
      }
    },
    [dismissNotice]: (state, { payload: { id } }) => {
      const { notices } = state
      const newNotices = notices.filter(n => n.notification_id !== id)
      return {
        ...state,
        notices: newNotices,
        loading: false,
        error: null
      }
    }
  },
  defaultState
)
