import PropTypes from 'prop-types'
import { Box, Divider, Typography, styled } from '@mui/material'

/**
 * Text Divider Container
 * theme path: TextDivider.styleOverrides.container
 */
const Container = styled(Box, {
  name: 'TextDivider',
  label: 'textDividerContainer',
  overridesResolver: (props, styles) => [styles.container]
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  '& p': { color: theme.palette.textDivider?.text, margin: '0px 8px' },
  '& hr': { flex: 1, color: theme.palette.textDivider?.dividers }
}))

const TextDivider = ({ text, customStyles = {} }) => {
  return (
    <Container sx={{ ...customStyles }}>
      <Divider />
      <Typography>{text}</Typography>
      <Divider />
    </Container>
  )
}

TextDivider.propTypes = {
  text: PropTypes.string,
  customStyles: PropTypes.object
}

export default TextDivider
