import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Metadata from '../../../../components/Metadata'
import Page from '../../../../components/Page'
import StorySpinner from '../../../../components/StorySpinner'
import AboutUs from '../../../../components/AboutUs'
import { notificationsEnqueue } from '../../../Notifications/reducer'
import ArticleItem from '../../components/ArticleItem'
import MultipleAsset from '../../templates/MultipleAsset'
import SingleAsset from '../../templates/SingleAsset'
import copyToClipboard from '../../../../utils/copyToClipboard'
import commonStyles from '../../../../styles/common.module.sass'
import styles from './Landing.module.sass'
import classNames from 'classnames/bind'
import { getConfig } from '../../../Config/selectors'
import { socialMediaInfo } from '../../../../utils/oneCodeBase'

const cx = classNames.bind(styles)
const getUrl = () => window.location

export const ArticlesList = ({
  accesible = true,
  articles = [],
  handleTracking = () => {},
  articlesHtml = {}
}) => {
  const articlesContainerStyles = (articlesHtml.css &&
    articlesHtml.css.length > 0 &&
    articlesHtml.css) || ['columnLayout']

  const articlesStyles = articlesHtml?.article?.css || []

  const articleDescriptionStyles =
    articlesHtml?.article?.articleDescription?.css || []

  const imageContainerStyles = articlesHtml?.imageContainer?.css || []

  const normalImageStyles = articlesHtml?.normalImage?.css || []

  const properties = {
    accesible,
    handleTracking,
    articlesStyles,
    articleDescriptionStyles,
    imageContainerStyles,
    normalImageStyles
  }

  return (
    <div
      className={cx(
        articlesContainerStyles.map(style => styles[style]),
        styles.articlesContainer
      )}
    >
      {articles.map((articleItem, index) => {
        const key = `Article_${index + 1}_${articleItem.title}`
        return (
          <ArticleItem
            key={key}
            properties={{ ...properties }}
            index={index}
            articleItem={articleItem}
          />
        )
      })}
    </div>
  )
}

ArticlesList.propTypes = {
  accesible: PropTypes.bool,
  articles: PropTypes.array,
  handleTracking: PropTypes.func,
  articlesHtml: PropTypes.object
}

const Landing = ({
  loadCompanyContent,
  content = null,
  consent = false,
  busy = false,
  setStoryTitle = () => {},
  ...props
}) => {
  const history = useHistory()
  const pathname = history.location.pathname
  const route = pathname.split('/')[1]
  const url = window.location.href
  const dispatch = useDispatch()
  const pagesConfig = useSelector(getConfig('pages'))

  const handleTracking = (category, action, name, custom = null) => {
    const { Piwik } = props.services
    const finalName = `${name}_${pathname}`
    if (custom) Piwik.track(category, action, finalName, custom)
    else Piwik.track(category, action, finalName)
  }

  const handleSocialIconClick = socialNetwork => e => {
    e.preventDefault()
    let title = content?.general?.title ?? ''
    let body = content?.general?.subtitle ?? ''
    const genericText = 'Check out this article I read'

    if (socialNetwork === 'link') {
      copyToClipboard(url)
      dispatch(
        notificationsEnqueue({
          message: 'URL was copied to your clipboard'
        })
      )
      handleTracking('page', 'click', 'copy-url')
      return
    }

    const linkBuilders = {
      twitter: () => {
        let message = ''

        if (!title || title == '') {
          message = encodeURIComponent(`${genericText}: ${getUrl()}`)
        } else {
          message = encodeURIComponent(
            `${title} ${getUrl()} via ${socialMediaInfo?.twitter} ${
              content?.general?.twitter ? `and @${content.general.twitter}` : ''
            }`
          )
        }
        return `https://twitter.com/intent/tweet?text=${message}`
      },
      linkedin: () => {
        if (body && body.length > 50) {
          body = body.substr(0, 50) + '...'
        }
        if (!body) {
          body = genericText
        }
        if (!title) {
          title = 'Article'
        }

        return `http://www.linkedin.com/shareArticle?mini=true&url=${getUrl()}&title=${encodeURIComponent(
          title
        )}&summary=${encodeURIComponent(body)}&source=${
          socialMediaInfo?.linkedin
        }`
      }
    }
    if (!linkBuilders[socialNetwork]) return
    const link = linkBuilders[socialNetwork]()
    handleTracking('page', 'click', `${socialNetwork}-icon`)
    window.open(link, 'pop', 'width=600, height=400, scrollbars=no')
  }

  useEffect(() => {
    // Validation for dynamic pages, to avoid redirecting to 404 while content loads
    if (
      pagesConfig &&
      !pagesConfig.find(
        page =>
          !page.exclude_from_routes &&
          (page.uri_path.includes(route) ||
            page?.matches?.find(route =>
              window.location.pathname.includes(route)
            ))
      )
    ) {
      loadCompanyContent({
        type: 'landing',
        route
      })
    }
  }, [])

  const html = content?.html || []

  const pageStyles = (html.length > 0 && html[0]?.main?.css) || ''
  const articlesHtml = html.map(html => html?.articlesContainer || {})

  setStoryTitle(content?.general?.title ?? '')
  const newProps = {
    content,
    handleTracking,
    articlesHtml,
    handleSocialIconClick
  }
  return (
    <Page>
      {busy ? (
        <div className={commonStyles.content}>
          <StorySpinner />
        </div>
      ) : (
        content &&
        consent && (
          <>
            {content?.general && (
              <Metadata
                title={content?.general?.title ?? ''}
                description={content?.general?.description ?? ''}
              />
            )}

            <div className={cx(commonStyles.content, styles[pageStyles])}>
              {content.template === 'singleAsset' ? (
                <SingleAsset {...newProps} />
              ) : (
                <MultipleAsset {...newProps} />
              )}
            </div>

            {/* Sponsor Footer */}
            {content.sponsor && <AboutUs sponsor={content.sponsor} />}
          </>
        )
      )}
    </Page>
  )
}

Landing.propTypes = {
  loadCompanyContent: PropTypes.func,
  content: PropTypes.object,
  consent: PropTypes.bool,
  busy: PropTypes.bool,
  setStoryTitle: PropTypes.func,
  services: PropTypes.object
}

export default Landing
