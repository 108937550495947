import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

const HtmlAd = props => {
  const { className, style, id, ready, data = '' } = props
  return (
    ready && (
      <div className={className} style={style} id={id}>
        {ReactHtmlParser(data)}
      </div>
    )
  )
}

HtmlAd.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  ready: PropTypes.bool,
  data: PropTypes.string
}

export default HtmlAd
