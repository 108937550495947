import React from 'react'
import Page from '../../../../components/Page'
import Typography from '../../../../components/ui/Typography'
import styles from '../../../../modules/Static/Static.module.sass'
import SectionHeading from '../../../../components/SectionHeading'
import Metadata from '../../../../components/Metadata'

const CookiePolicyPage = () => {
  return (
    <Page className={styles.static}>
      <Metadata title="FirstWord Cookie Policy" />
      <SectionHeading title="FirstWord Cookie Policy" gutterBottom paragraph />
      <Typography type="body1" className={styles.paragraph} paragraph>
        We may place cookies on users’ computers. Cookies are alphanumeric
        identifiers that we transfer to your computer’s hard drive through your
        web browser when you use our Services. Cookies can save data about
        individual users, such as the user's name, password, username, screen
        preferences, and the pages of a site viewed by the user. When the user
        revisits the website, we may recognize the user based on any cookies
        placed on their computer and customize the user's experience
        accordingly.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        You do not need to have cookies turned on to visit or use our Services.
        You can refuse cookies by turning them off on your web browser. The Help
        portion of the toolbar on most browsers will tell you how to prevent
        your web browser from accepting new cookies, how to have your web
        browser notify you when you receive a new cookie, or how to disable
        cookies altogether. For specific information on how to do so, please
        visit{' '}
        <a className={styles.link} href="http://www.allaboutcookies.org/">
          http://www.allaboutcookies.org/
        </a>
        . If you want to access webpages on our Services that require you to
        sign in to your account, you need to accept a mandatory cookie, which
        you can delete after you leave our Services.
      </Typography>
    </Page>
  )
}

export default CookiePolicyPage
