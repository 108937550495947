export const INSERT_AD_AFTER_X_WORDS = 200
const NODE_TRAVERSAL_MAX_DEPTH = 200

const WORD_BOUNDARY = /\b/
const WORD = /\w+/

const VALID_PARENTS = ['BODY', 'DIV']

class AdInjector {
  injectAd(html) {
    try {
      const domParser = new DOMParser()
      const doc = domParser.parseFromString(html, 'text/html')
      const context = {
        wordcount: 0,
        depth: 0,
        curNode: null
      }
      this.traverseNode(doc.body, context)
      if (context.curNode === null) {
        context.curNode = doc.body
      }
      const [adParent, sibling] = this.findClosestValidParentAndSibling(
        context.curNode
      )
      adParent.insertBefore(document.createElement('ad-placeholder'), sibling)
      return doc.body.innerHTML
    } catch (e) {
      console.warn('Unable to parse body: %o', e)
      return html + '<ad-placeholder />'
    }
  }

  traverseNode(node, context) {
    for (node of node.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.nodeValue
        const words = text.split(WORD_BOUNDARY).filter(t => WORD.test(t))
        context.wordcount += words.length
        if (context.wordcount >= INSERT_AD_AFTER_X_WORDS) {
          context.curNode = node.parentNode
          return context.curNode
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        context.depth++
        this.traverseNode(node, context)
        context.depth--
      }
      if (context.curNode) {
        return context.curNode
      }
      if (context.depth > NODE_TRAVERSAL_MAX_DEPTH) {
        throw new Error('recursion limit reached')
      }
    }
  }

  findClosestValidParentAndSibling(node) {
    // Handle root element
    if (node.nodeName === 'BODY') {
      return [node, null]
    }
    let validParent = node.parentNode
    let sibling = node.nextSibling
    let iterations = 0
    while (!VALID_PARENTS.includes(validParent.nodeName)) {
      sibling = validParent.nextSibling
      validParent = validParent.parentNode
      if (iterations++ > NODE_TRAVERSAL_MAX_DEPTH) break
    }
    return [validParent, sibling]
  }
}

export default AdInjector
