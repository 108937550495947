import React from 'react'
import PropTypes from 'prop-types'
import commonStyles from 'src/styles/common.module.sass'
import ComponentLoop from 'src/layouts/components/ComponentLoop.js'

function MainLayout({ content = {}, ...props }) {
  const mainSection = content?.sidebar ? 'main' : 'mainWithoutSidebar'

  return (
    <>
      <ComponentLoop section="top" items={content?.top} {...props} />
      <div className={commonStyles.content}>
        <ComponentLoop section={mainSection} items={content?.main} {...props} />
        <ComponentLoop section="sidebar" items={content?.sidebar} {...props} />
      </div>
    </>
  )
}

MainLayout.propTypes = {
  content: PropTypes.object
}

export default MainLayout
