import { call } from 'redux-saga/effects'

export function* reauthAndRetryCall({ UserRepository, Session }, ...args) {
  try {
    const res = yield call(...args)
    return res
  } catch (e) {
    if (e.response && e.response.status === 401) {
      const { access_token: token } = yield call([UserRepository, 'auth'])
      yield call([Session, 'tokenSave'], token)
      const res = yield call(...args)
      return res
    }
    throw e
  }
}
