import React from 'react'
import PropTypes from 'prop-types'
import FullImage from '../../../../components/FullImage'
import styles from '../../pages/Landing/Landing.module.sass'
import cx from 'classnames'

const LogoSponsor = ({ sponsor = {}, handleTracking = () => {} }) => {
  return (
    <section className={styles.sponsor}>
      <div className={cx(styles.sponsorLogoSmall, sponsor.name.toLowerCase())}>
        <a
          href={sponsor.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleTracking('page', 'click', 'client-logo_top')}
        >
          <FullImage src={sponsor.smallLogoUrl} alt={`${sponsor.name} Logo`} />
        </a>
      </div>
    </section>
  )
}

LogoSponsor.propTypes = {
  sponsor: PropTypes.object,
  handleTracking: PropTypes.func
}

export default LogoSponsor
