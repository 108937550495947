import SvgIcon from '@mui/material/SvgIcon'
const MessageTextSquareIcon = props => (
  <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
    <path
      d="M7 8.566h5m-5 3.5h8m-5.316 6H16.2c1.68 0 2.52 0 3.162-.326a3 3 0 0 0 1.311-1.312c.327-.641.327-1.481.327-3.162v-5.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.31c-.642-.328-1.482-.328-3.162-.328H7.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.346 3 6.186 3 7.866v12.536c0 .533 0 .8.11.936a.5.5 0 0 0 .39.188c.176 0 .384-.166.8-.5l2.385-1.908c.488-.39.731-.584 1.002-.723.241-.123.497-.213.762-.267.299-.062.61-.062 1.235-.062z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
export default MessageTextSquareIcon
