import { createAction, handleActions } from 'redux-actions'
import asyncMeta from '../AsyncFlags/metaCreator'

export const successAccountUpdate = createAction(
  'ACCOUNT_UPDATE__SUCCESS',
  null,
  asyncMeta
)
export const errorAccountUpdate = createAction(
  'ACCOUNT_UPDATE__ERROR',
  null,
  asyncMeta
)
export const requestAccountUpdate = createAction(
  'ACCOUNT_UPDATE__REQUEST',
  null,
  asyncMeta
)

export const actions = {
  requestAccountUpdate,
  successAccountUpdate,
  errorAccountUpdate
}

const defaultState = {}

export default handleActions({}, defaultState)
