import React from 'react'
import PropTypes from 'prop-types'
import DropDown from '../../../../components/ui/DropDown/DropDown'
import Button from '../../../../components/ui/Buttons/Button'
import classNames from 'classnames/bind'
import styles from './DeliveryTime.module.sass'

// Form imports
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import validate from './validate'

const cx = classNames.bind(styles)

const DeliveryTimeForm = ({
  handleSubmit,
  onDismiss = () => {},
  updateDeliveryTime,
  busy,
  initialValues,
  newsletter,
  ...props
}) => {
  const options = newsletter?.deliveryTimes.reduce(
    (a, v) => ({ ...a, [v]: v }),
    {}
  )

  return (
    <form onSubmit={handleSubmit}>
      <Field
        className={styles.deliveryDropDown}
        label="Delivery Time"
        component={DropDown}
        name="deliveryTime"
        options={options}
        errorAlign="left"
      />
      <div className={styles.buttonsContainer}>
        <Button
          className={cx(styles.button, { cancel: true })}
          tier="default"
          onClick={onDismiss}
          textCase="none"
          text="Cancel"
        />
        <Button
          type="submit"
          className={cx(styles.button, { changing: busy })}
          hasSpinner={busy} // Toggle hasSpinner in addition to submitting to prevent button width animation
          submitting={busy}
          tier="secondary"
          textCase="none"
          text="Save"
        />
      </div>
    </form>
  )
}

const mapStateToProps = (state, { selectedDeliveryTime, newsletter }) => {
  return {
    initialValues: {
      deliveryTime: selectedDeliveryTime,
      newsletterUuid: newsletter?.id
    },
    busy: state.myNewsletters.newsletterSettings.busy
  }
}

DeliveryTimeForm.propTypes = {
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  updateDeliveryTime: PropTypes.func,
  busy: PropTypes.bool,
  initialValues: PropTypes.object,
  newsletter: PropTypes.object
}

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'delivery-time-update',
    validate,
    touchOnBlur: false,
    enableReinitialize: true
  })
)(DeliveryTimeForm)
