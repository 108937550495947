import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import FullImage from '../../../../components/FullImage'
import styles from '../../pages/Landing/Landing.module.sass'
import ReactHtmlParser from 'react-html-parser'
import cx from 'classnames'

const ArticleItem = props => {
  const { index, articleItem, properties } = props
  const {
    articlesStyles,
    articleDescriptionStyles,
    imageContainerStyles,
    normalImageStyles,
    handleTracking,
    accesible
  } = properties

  const mediaId = `Article_${index + 1}_${articleItem.title}`
  const videoRef = useRef(`Video_${index + 1}_${articleItem.title}`)
  const formatedTitle = articleItem.title.replace(/ /g, '-').toLowerCase()

  const onVideoAction = (action, custom = null) => {
    handleTracking('video', action, `video-${formatedTitle}`, custom)
  }

  useEffect(() => {
    if (typeof videoRef.current === 'string') return
    videoRef.current.onplay = function () {
      onVideoAction('play', { duration: videoRef.current.currentTime })
    }
    videoRef.current.onpause = function () {
      onVideoAction('pause')
    }
    videoRef.current.onvolumechange = function () {
      onVideoAction('volume-change', { volume: videoRef.current.volume })
    }
    videoRef.current.onratechange = function () {
      onVideoAction('click_menu_playback_speed')
    }
    videoRef.current.onenterpictureinpicture = function () {
      onVideoAction('click_menu_enter-picture-in-picture')
    }
    videoRef.current.onleavepictureinpicture = function () {
      onVideoAction('click_menu_leave-picture-in-picture')
    }
  }, [])

  return (
    <div
      key={mediaId}
      className={cx(
        styles.article,
        articlesStyles.map(style => styles[style])
      )}
    >
      {/* Multumedia Container */}
      <div className={styles.mediaContainer}>
        {!['video', 'download'].includes(articleItem.multimediaType) &&
          articleItem.imageUrl && (
            <div
              className={cx(
                imageContainerStyles.map(style => styles[style]),
                styles.imageContainer
              )}
            >
              <FullImage
                className={cx(
                  normalImageStyles.map(style => styles[style]),
                  styles.normalImage
                )}
                src={articleItem.imageUrl}
                alt={mediaId}
              />
            </div>
          )}

        {articleItem.multimediaType === 'download' &&
        articleItem.multimediaUrl &&
        accesible ? (
          <div
            className={cx(
              imageContainerStyles.map(style => styles[style]),
              styles.imageContainer
            )}
          >
            <a
              href={articleItem.multimediaUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleTracking('whitepaper', 'download', formatedTitle)
              }
            >
              <FullImage
                className={cx(
                  normalImageStyles.map(style => styles[style]),
                  styles.normalImage
                )}
                src={articleItem.imageUrl}
                alt={mediaId}
              />
            </a>
          </div>
        ) : (
          !accesible && (
            <FullImage
              className={cx(
                normalImageStyles.map(style => styles[style]),
                styles.normalImage
              )}
              src={articleItem.imageUrl}
              alt={mediaId}
            />
          )
        )}

        {articleItem.multimediaType === 'video' &&
          (articleItem.external && articleItem.iframe
            ? ReactHtmlParser(articleItem.iframe)
            : articleItem.multimediaFiles && (
                <video
                  ref={videoRef}
                  className={styles.multimediaFile}
                  id={mediaId}
                  preload="auto"
                  controls
                  playsInline
                  controlsList="nodownload"
                  poster={articleItem.imageUrl}
                >
                  {articleItem.multimediaFiles.map(file => (
                    <source
                      key={`${file.type} - ${file.url}`}
                      src={file.url}
                      type={file.type}
                    ></source>
                  ))}
                </video>
              ))}

        {articleItem.multimediaFiles &&
          articleItem.multimediaType === 'audio' && (
            <audio
              id={mediaId}
              preload="auto"
              controls
              className={cx(styles.multimediaFile, styles.multimediaFileAudio)}
            >
              {articleItem.multimediaFiles.map(file => (
                <source
                  key={`${file.type} - ${file.url}`}
                  src={file.url}
                  type={file.type}
                ></source>
              ))}
            </audio>
          )}
      </div>

      {/* Article Description  */}
      {articleItem.title && (
        <div
          className={cx(
            styles.articleDescription,
            articleDescriptionStyles.includes('bgGray') ||
              articleDescriptionStyles.includes('bgWhite')
              ? null
              : styles.bgGray,
            articleDescriptionStyles.map(style => styles[style])
          )}
        >
          {articleItem.title && (
            <h4>
              {articleItem.multimediaType === 'download' && accesible ? (
                <a
                  href={articleItem.multimediaUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    handleTracking('whitepaper', 'download', articleItem.title)
                  }
                >
                  {ReactHtmlParser(articleItem.title)}
                </a>
              ) : (
                ReactHtmlParser(articleItem.title)
              )}
            </h4>
          )}
          {articleItem.description && (
            <>
              <p>{ReactHtmlParser(articleItem.description)}</p>
              {articleItem.multimediaType === 'download' &&
                articleItem.multimediaUrl &&
                accesible && (
                  <a
                    href={articleItem.multimediaUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      handleTracking(
                        'whitepaper',
                        'download',
                        articleItem.title
                      )
                    }
                    className={styles.downloadLink}
                  >
                    Download now
                  </a>
                )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

ArticleItem.propTypes = {
  index: PropTypes.number,
  articleItem: PropTypes.object,
  properties: PropTypes.object
}

export default ArticleItem
