import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestLiveFeed = createAction('LIVE_FEED_REQUEST')
export const requestLiveFeedSuccess = createAction('LIVE_FEED_SUCCESS')
export const requestLiveFeedFailed = createAction('LIVE_FEED_FAIL')

export const actions = {
  requestLiveFeed,
  requestLiveFeedSuccess,
  requestLiveFeedFailed
}

const initialState = {
  fetching: true,
  liveFeed: [],
  error: null,
  liveFeedTags: []
}
const LiveFeedReducer = createReducer(initialState, {
  [requestLiveFeed]: (state, { payload }) => {
    state.fetching = true
    state.liveFeedTags = payload
  },
  [requestLiveFeedSuccess]: (state, { payload }) => {
    state.fetching = false
    state.liveFeed = payload
  },
  [requestLiveFeedFailed]: state => {
    state.fetching = false
    state.liveFeed = []
    state.error = 'failed to get livefeed'
  }
})

export default LiveFeedReducer
