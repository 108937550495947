import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ProtectedArea, ShowFor } from '../LicenseProtected'
import HeaderItem from '../HeaderItem'
import useMediaSize from '../../hooks/useMediaSize'
import Icon from '../ui/Icon'
import styles from './Menu.module.sass'
import HeaderButton from '../HeaderButton'

const Menu = ({ handleSlideMenu, hideMenu }) => {
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)'
    }
  })

  return (
    <nav className={styles.menu}>
      <ul>
        {!hideMenu && (
          <>
            {mediaSize === 'large' && (
              <ProtectedArea>
                <ShowFor anonymous>
                  <Fragment>
                    <HeaderItem register>
                      <HeaderButton
                        color="secondary"
                        label="Register"
                        to="/register"
                      />
                    </HeaderItem>
                    <HeaderItem register>
                      <HeaderButton
                        color="secondary"
                        label="Sign In"
                        to="/sign-in"
                      />
                    </HeaderItem>
                  </Fragment>
                </ShowFor>
              </ProtectedArea>
            )}
          </>
        )}
        <ProtectedArea>
          <ShowFor authenticated>
            <HeaderItem icon>
              <Icon
                icon="svg/material-design-icons/action/account_circle"
                iconSize={32}
                onClick={handleSlideMenu}
                button
              />
            </HeaderItem>
          </ShowFor>
        </ProtectedArea>
      </ul>
    </nav>
  )
}

Menu.propTypes = {
  handleSlideMenu: PropTypes.func,
  hideMenu: PropTypes.bool
}

export default Menu
