import _isEqual from 'lodash/isEqual'
import { riverConfigModule } from '../../../siteConfig/river'

const defaultRiverConfig = riverConfigModule?.default || {}

const getDefaultRiverConfig = () => {
  return defaultRiverConfig
}

export const getRiverConfig = (riverId = '') => {
  const defaultConfig = getDefaultRiverConfig()
  const splitId = riverId.split(';;')
  const riverKey = splitId[0]
  const config = {}
  if (defaultConfig[riverKey] !== undefined) {
    Object.assign(
      config,
      defaultConfig[riverKey]({
        args: splitId[1]
      })
    )
  }
  return config
}

export const forceHideUsedFilter = (riverId, filterKey, filter) => {
  const {
    hideFilterSections = [],
    applyDefaultFilters = [],
    defaultFilters
  } = getRiverConfig(riverId)
  return (
    hideFilterSections.includes(filterKey) ||
    (applyDefaultFilters.includes(filterKey) &&
      _isEqual(defaultFilters.filters[filterKey], filter))
  )
}

export const forceDisplayFilterSection = (riverId, filterKey) => {
  const { applyDefaultFilters = [] } = getRiverConfig(riverId)
  return applyDefaultFilters.includes(filterKey)
}

export const getDefaultFilters = (riverId, filterKey) => {
  let override = {}
  const { applyDefaultFilters = [], defaultFilters = [] } =
    getRiverConfig(riverId)
  if (applyDefaultFilters.includes(filterKey) && defaultFilters) {
    override = defaultFilters
  }
  return override
}

export default getDefaultRiverConfig()
