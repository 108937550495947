import React from 'react'
import Page from '../../../../components/Page'
import SectionHeading from '../../../../components/SectionHeading'
import PromotionalVideo from '../../../../components/PromotionalVideo'
import styles from '../../../../modules/Static/Static.module.sass'
import Typography from '../../../../components/ui/Typography'
import Metadata from '../../../../components/Metadata'

const AboutPage = () => {
  return (
    <Page className={styles.static}>
      <Metadata
        title="About FirstWord Pharma"
        description="FirstWord Pharma is the trusted global news and analysis service used by
        thousands of pharmaceutical professionals worldwide."
      />
      <SectionHeading title="About FirstWord Pharma" gutterBottom paragraph />
      <Typography type="body1" className={styles.paragraph} paragraph>
        FirstWord Pharma is the trusted global news and analysis service used by
        thousands of pharmaceutical professionals worldwide. Stay on top of
        critical developments with comprehensive industry coverage anywhere,
        anytime, on your preferred device at the office or on the go.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        FirstWord Pharma PLUS allows readers to customize their news service to
        their needs by tracking specific products, companies, conditions,
        medical meetings, regulatory areas, and markets. It also provides
        exclusive access to timely, event-driven physician intelligence produced
        from physician snap-polls and first-hand interviews with KOLs and
        industry experts, ensuring you stay up-to-date in a dynamic market.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        Discover why FirstWord Pharma and FirstWord Pharma PLUS readers are
        better prepared to make decisions with the speed and confidence to stay
        ahead of the competition.
      </Typography>
      <PromotionalVideo className={styles.aboutPromoVideo}></PromotionalVideo>
      <Metadata
        title="About FirstWord Group"
        description="FirstWord is an innovative industry intelligence leader serving over
        260,000 HealthTech and pharma professionals worldwide."
      />
      <SectionHeading title="About FirstWord Group" gutterBottom paragraph />
      <Typography type="body1" className={styles.paragraph} paragraph>
        FirstWord is an innovative industry intelligence leader serving over
        260,000 HealthTech and pharma professionals worldwide. FirstWord’s
        products and services are designed to help you gain a competitive edge
        by making key decisions with speed and confidence.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        FirstWord HealthTech PLUS and Pharma PLUS deliver up-to-the-minute
        personalised and comprehensive news, insight, analysis, and physician
        views of importance to your products and company. FirstWord Dossier
        reports provide expert views from industry on important trends and
        challenging issues. Covering over 40 disease areas, FirstView and
        Therapy Trends reports offer unique insights into current and future
        market dynamics and treatment landscapes through quantitative surveys
        with physicians and in-depth interviews with the world’s leading KOLs.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        Learn more:{' '}
        <a className={styles.link} href="http://www.firstwordgroup.com/">
          www.firstwordgroup.com
        </a>
      </Typography>
    </Page>
  )
}

export default AboutPage
