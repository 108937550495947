import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  requestUpdateDeliveryTime,
  openUpdateDeliveryTime,
  closeUpdateDeliveryTime
} from '../reducer'
import Newsletter from '../components/Newsletter'

const mapStateToProps = state => ({
  deliveryTime: state.myNewsletters.newsletterSettings.delivery_time,
  region: state.myNewsletters.region.userRegion.data
})

const mapDispatchToProps = dispatch => ({
  updateDeliveryTime: values => {
    const { newsletterUuid, deliveryTime } = values
    dispatch(
      requestUpdateDeliveryTime({
        newsletterUuid,
        deliveryTime
      })
    )
  },
  openUpdateDeliveryTime: newsletterUuid => {
    dispatch(openUpdateDeliveryTime(newsletterUuid))
  },
  closeUpdateDeliveryTime: newsletterUuid => {
    dispatch(closeUpdateDeliveryTime(newsletterUuid))
  }
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Newsletter)
