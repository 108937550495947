import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import ConsentForm from '../components/ConsentForm'
import validate from '../components/ConsentForm/validate'

const mapStateToProps = state => {
  return {
    initialValues: {
      first_name: state.session.user.firstName,
      last_name: state.session.user.lastName,
      job_title: state.session.user.job_title,
      company: state.session.user.company,
      email: state.session.user.email
    },
    busy: state.landingPage.loading
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'consent',
    validate,
    touchOnBlur: false
  })
)(ConsentForm)
