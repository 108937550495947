import _get from 'lodash/get'

const ONE_HOUR = 60 * 60 * 1000

class Cache {
  constructor(config = {}) {
    this.config = config
    this.cache = {}
  }

  hasExpired(key) {
    if (!this.cache[key]) return undefined
    const ttl = _get(this.config, 'ttl', ONE_HOUR)
    const now = Date.now()
    const age = now - _get(this.cache, `${key}.created`, 0)
    return age >= ttl
  }

  has(key) {
    if (!this.cache[key]) return false
    return !this.hasExpired(key)
  }

  get(key, defaultValue) {
    if (!this.cache[key]) return defaultValue
    if (this.hasExpired(key)) return defaultValue
    return _get(this.cache, `${key}.value`, defaultValue)
  }

  set(key, value) {
    this.cache[key] = {
      value,
      created: Date.now()
    }
  }
}

export default Cache
