export const addInternalToken = (url, token, user) => {
  if (!url.includes('{it}')) return url

  const separator = url.includes('?') ? '&' : '?'
  return token && user
    ? url.replace('{it}', `${separator}it=${token}`)
    : url.replace('{it}', '')
}

export const recursiveAddTokenToMenus = (dataSet, token, user) => {
  const keys = Object.keys(dataSet)

  for (let key of keys) {
    const data = dataSet[key]

    if (typeof data === 'object') {
      if (data?.to || data?.featureFlagFallback?.to) {
        // Add internal token to the URL
        if (data?.to) {
          data.to = addInternalToken(data?.to, token, user)
        }
        // Add internal token to the URL for featureFlagFallback to the URL
        if (data?.featureFlagFallback?.to) {
          data.featureFlagFallback.to = addInternalToken(
            data?.featureFlagFallback?.to,
            token,
            user
          )
        }
      } else {
        recursiveAddTokenToMenus(data, token, user)
      }
    }
  }
  return dataSet
}
