import React from 'react'
import PropTypes from 'prop-types'
import commonStyles from '../../../../styles/common.module.sass'
import GeneralContent from '../../components/GeneralContent'
import AdditionalResources from '../../components/AdditionalResources'
import LogoSponsor from '../../components/LogoSponsor'
import PromoButton from '../../components/PromoButton'
import Contributors from '../../components/Contributors'
import { ArticlesList } from '../../pages/Landing'
import styles from '../../pages/Landing/Landing.module.sass'
import SocialButtons from '../../../../components/SocialButtons'

const MultipleAsset = ({
  content,
  handleTracking = () => {},
  articlesHtml = [],
  handleSocialIconClick
}) => {
  return (
    <>
      <section className={commonStyles.main}>
        {/* Article Header */}
        {content.general && <GeneralContent general={content.general} />}

        {/* Article items */}
        {content.articles && content.articles.length > 0 && (
          <ArticlesList
            articles={content.articles}
            handleTracking={handleTracking}
            articlesHtml={articlesHtml[0] ? articlesHtml[0] : {}}
          />
        )}

        {/* Additional Resources items */}
        {content.additionalResources && (
          <AdditionalResources
            handleTracking={handleTracking}
            articlesHtml={articlesHtml[1] ? articlesHtml[1] : {}}
            additionalResources={content.additionalResources}
          />
        )}
      </section>
      {/* Sidebar Information */}
      <aside className={commonStyles.sidebar}>
        {/* Sponsor */}
        {content.sponsor && (
          <LogoSponsor
            handleTracking={handleTracking}
            sponsor={content.sponsor}
          />
        )}

        {/* Share Buttons */}
        {content.general &&
          (content?.general?.twitter || content?.general?.linkedin) && (
            <section className={styles.shareButtons}>
              <SocialButtons
                addTwitter={Boolean(content?.general?.twitter)}
                addLinkedin={Boolean(content?.general?.linkedin)}
                onClick={handleSocialIconClick}
              />
            </section>
          )}

        {/* Contributors */}
        {content.contributors && content.contributors.length > 0 && (
          <Contributors contributors={content.contributors} />
        )}

        {/* Button Promotional Button */}
        {content?.sponsor?.promoButtonUrl && (
          <PromoButton sponsor={content.sponsor} />
        )}
      </aside>
    </>
  )
}

MultipleAsset.propTypes = {
  content: PropTypes.object,
  handleTracking: PropTypes.func,
  articlesHtml: PropTypes.array,
  handleSocialIconClick: PropTypes.func
}

export default MultipleAsset
