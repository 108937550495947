import React from 'react'
import PropTypes from 'prop-types'
import ComponentLoop from 'src/layouts/components/ComponentLoop.js'
import styles from './SingleColumnLayout.module.sass'

function SingleColumnLayout({ content = {}, ...props }) {
  return (
    <div>
      <ComponentLoop section="top" items={content?.top} {...props} />
      <ComponentLoop
        section="main"
        className={styles.main}
        items={content?.main}
        {...props}
      />
    </div>
  )
}

SingleColumnLayout.propTypes = {
  content: PropTypes.object
}

export default SingleColumnLayout
