import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import LoginForm from '../../LoginFormContainer'
import styles from './LoginPage.module.sass'
import Typography from '../../../../components/ui/Typography'
import { Link, useHistory } from 'react-router-dom'
import { historyHandler } from '../../../../utils'
import LoginEmailSent from '../../../Static/components/LoginEmailSentPage/components/LoginEmailSent'
import { getConfig } from '../../../Config/selectors'

const LoginPage = ({
  handleRegisterLinkFlow = null,
  parentClassName = '',
  landingPageCompany = null,
  redirectEmailSent = true,
  config = {},
  ...props
}) => {
  const history = useHistory()
  const [showEmailSent, setShowEmailSent] = useState(false)

  const webpageContent = useSelector(getConfig('webpage_content')) || {}
  const loginConfig = webpageContent['sign-in'] || {}
  const finalConfig = { ...loginConfig, ...config }

  const {
    register = {
      text: 'Already have a FirstWord account?',
      linkText: 'Sign in'
    },
    form = {},
    header = null
  } = finalConfig

  const submit = values => {
    handleTracking('button', 'click', 'sign-in')

    const { requestPasswordlessLogin } = props
    requestPasswordlessLogin(values.email)

    if (redirectEmailSent !== undefined && redirectEmailSent === false) {
      return setShowEmailSent(true)
    }
    return historyHandler('/sign-in-email-sent', '', history, 'push')
  }

  const handleTracking = (category, action, name) => {
    // Only track if it has a landing page
    if (landingPageCompany) {
      const { Piwik } = props.services
      Piwik.track(category, action, name)
    }
  }

  const isDeviceLogIn = () => {
    const { location: { pathname = '' } = {} } = props
    return /\/device/.test(pathname)
  }

  const handleRegisterLink = e => {
    e.preventDefault()
    handleTracking('hyperlink', 'click', 'register-for-a-free-account')
    if (handleRegisterLinkFlow) {
      return handleRegisterLinkFlow()
    }
    return historyHandler('/register', '', history, 'push')
  }

  const message =
    isDeviceLogIn() &&
    'This device is not registered to your FirstWord account. Please sign in to confirm it is you and to allow FirstWord to add this device to your account.'

  return (
    <>
      {showEmailSent && (
        <LoginEmailSent
          parentClassName={styles.loginEmailSent}
          handleRegisterLink={handleRegisterLink}
        />
      )}
      {!showEmailSent && (
        <div className={parentClassName} data-private>
          <Typography type="headline" gutterBottom>
            {header}
          </Typography>
          {message && (
            <Typography type="body1" gutterBottom>
              {message}
            </Typography>
          )}
          <Typography type="body1" align="center" className={styles.info}>
            {register.text}{' '}
            <Link to="" className={styles.link} onClick={handleRegisterLink}>
              {register.linkText}
            </Link>
          </Typography>
          <LoginForm onSubmit={submit} busy={props.busy} {...form} />
        </div>
      )}
    </>
  )
}

LoginPage.propTypes = {
  handleRegisterLinkFlow: PropTypes.func,
  parentClassName: PropTypes.string,
  landingPageCompany: PropTypes.string,
  redirectEmailSent: PropTypes.bool,
  config: PropTypes.object,
  requestPasswordlessLogin: PropTypes.func,
  busy: PropTypes.bool,
  services: PropTypes.object,
  location: PropTypes.object
  // Add other props here
}

export default LoginPage
