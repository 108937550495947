import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { sessionReducer } from 'redux-react-session'

import { connectRouter, routerMiddleware } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import * as reducers from 'src/modules/reducers'
import rootSaga from 'src/modules/rootSaga'

export default (history, container) => {
  const { resolve } = container // Get Services resolver

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
    form: formReducer,
    session: sessionReducer
  })
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    rootReducer,
    compose(
      applyMiddleware(sagaMiddleware, routerMiddleware(history)),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  )

  sagaMiddleware.run(rootSaga, resolve)

  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test'
  ) {
    module.hot.accept('src/modules/reducers', () => {
      store.replaceReducer(rootReducer)
    })
  }
  window.store = store

  return store
}
