import SvgIcon from '@mui/material/SvgIcon'

const Star = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <defs>
      <path id="ay84ypyfha" d="M0 0h24v24H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M13.528 6.416c.406 1.054.714 1.729.924 2.025.28.393.624.737 1.017 1.017.444.316.972.519 2.028.925l4.413 1.697-4.408 1.696-.008.002c-1.054.406-1.729.714-2.025.924a4.32 4.32 0 0 0-1.017 1.017c-.316.444-.519.972-.925 2.028L11.83 22.16l-1.696-4.408-.002-.008c-.406-1.054-.714-1.729-.924-2.025a4.32 4.32 0 0 0-1.017-1.017c-.444-.316-.972-.519-2.028-.925L1.75 12.08l4.408-1.696.008-.002c1.054-.406 1.729-.714 2.025-.924A4.32 4.32 0 0 0 9.208 8.44c.316-.444.519-.972.925-2.028L11.83 2l1.696 4.408.002.008z"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </SvgIcon>
)
export default Star
