import { createReducer, createAction } from '@reduxjs/toolkit'

export const processExternalURL = createAction('PROCESS_EXTERNAL')
export const setPathURL = createAction('SET_PATH')
export const pathReset = createAction('PATH_RESET')

export const actions = {
  processExternalURL,
  setPathURL,
  pathReset
}

const initialState = {
  path: ''
}

const loadingReducer = createReducer(initialState, {
  [setPathURL]: (state, { payload: { search } }) => {
    state.path = search
  },
  [pathReset]: state => {
    state.path = ''
  }
})
export default loadingReducer
