import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../components/ui/Icon'
import { tagWithCategory } from '../../utils/propTypes'
import { Link } from 'react-router-dom'
import styles from './Interest.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const Interest = ({
  interest,
  onDeleteInterest = () => {
    /* NOOP */
  },
  onAddInterest = () => {
    /* NOOP */
  },
  iconPosition = 'right',
  deleteEnabled = true,
  active,
  inline,
  showIcons = true,
  clickable = true
}) => {
  const category =
    interest.category === 'fw_therapeutic_category'
      ? 'drug_classes'
      : interest.category
  const LinkedTag = (
    <>
      {!clickable ? (
        <span>{interest.tag}</span>
      ) : (
        <Link
          className={styles.tag}
          to={`/river/tag/${encodeURIComponent(category)}/${encodeURIComponent(
            interest.tag
          )}`}
          role="router-link"
        >
          {interest.tag}
        </Link>
      )}
    </>
  )

  const handleClick = e => {
    if (active) {
      if (deleteEnabled) {
        onDeleteInterest(interest, e)
      }
    } else {
      onAddInterest(interest, e)
    }
  }

  return (
    <div className={cx(styles.interest, { inline })}>
      {iconPosition === 'right' && LinkedTag}
      {showIcons && (
        <Icon
          className={cx(styles.icon, { disabled: active && !deleteEnabled })}
          icon={
            active
              ? 'svg/material-design-icons/navigation/check'
              : 'svg/material-design-icons/content/add_circle'
          }
          colorTier={active ? 'default' : 'secondary'}
          onClick={handleClick}
          button
        />
      )}
      {iconPosition === 'left' && LinkedTag}
    </div>
  )
}

Interest.propTypes = {
  /**
   * The interest
   */
  interest: tagWithCategory,
  /**
   * Handler. Called when "delete interest" button is clicked
   */
  onDeleteInterest: PropTypes.func,
  /**
   * Handler. Called when "add interest" button is clicked
   */
  onAddInterest: PropTypes.func,
  /**
   * The interest icon position
   */
  iconPosition: PropTypes.oneOf(['right', 'left']),
  /**
   * If `true`, the interest is displayed with the 'active' style and its icon calls `onDeleteInterest` when clicked
   */
  active: PropTypes.bool,
  /**
   * If `true`, the interest is displayed inline with its siblings
   */
  inline: PropTypes.bool,
  /**
   * If `true`, the interest can be deleted in this view
   */
  deleteEnabled: PropTypes.bool,
  /**
   * If `true`, the icons are displayed
   */
  showIcons: PropTypes.bool,
  /**
   * If `true`, the interest is clickable and navigates to a new page
   */
  clickable: PropTypes.bool
}

export default Interest
