import { createAction, handleActions } from 'redux-actions'
import _mergeWith from 'lodash/mergeWith'
import _uniq from 'lodash/uniq'

import asyncMeta from '../AsyncFlags/metaCreator'

export const requestSearch = createAction(
  'CONTENT_RIVER__REQUEST',
  null,
  asyncMeta
)
export const successSearch = createAction(
  'CONTENT_RIVER__SUCCESS',
  null,
  asyncMeta
)
export const errorSearch = createAction('CONTENT_RIVER__ERROR', null, asyncMeta)

export const requestLoadMore = createAction(
  'CONTENT_RIVER_LOAD_MORE__REQUEST',
  null,
  asyncMeta
)
export const successLoadMore = createAction(
  'CONTENT_RIVER_LOAD_MORE__SUCCESS',
  null,
  asyncMeta
)
export const errorLoadMore = createAction(
  'CONTENT_RIVER_LOAD_MORE__ERROR',
  null,
  asyncMeta
)

// @tood rename all actions to "RIVER_"
export const setLoading = createAction('RIVER_LOADING')
export const setTagsLoading = createAction('TAGS_LOADING')
export const setSearch = createAction('CONTENT_RIVER_SET')
export const postErrorSearch = createAction('CONTENT_RIVER_POST_ERROR')
export const mergeSearch = createAction('CONTENT_RIVER_MERGE')
export const removeSearch = createAction('CONTENT_RIVER_REMOVE')
export const clearSearch = createAction('CONTENT_RIVER_CLEAR')
export const loadContentRiver = createAction('CONTENT_RIVER_LOAD')
export const removeFilter = createAction('CONTENT_RIVER_REMOVE_FILTER')
// this below is currently a quick hack around the removeFilter with it being
// watched which doesn't allow me to do bulk removes
export const removeFilterWithoutSearch = createAction(
  'RIVER_REMOVE_FILTER_NO_SEARCH'
)
export const addFilter = createAction('CONTENT_RIVER_ADD_FILTER')
export const selectFilter = createAction('CONTENT_RIVER_SELECT_FILTER')
export const setSorting = createAction('CONTENT_RIVER_SORT_SET')
export const setMedicalAbstracts = createAction(
  'CONTENT_RIVER_SET_MEDICAL_ABSTRACTS'
)
export const setIgnoreOnMount = createAction(
  'CONTENT_RIVER_SET_IGNORE_ON_MOUNT'
)
export const replaySearch = createAction('CONTENT_RIVER_REPLAY')
export const redirectSearch = createAction('CONTENT_RIVER_SEARCH_REDIRECT')

export const appendSponsoredHeadlines = createAction(
  'CONTENT_RIVER_APPEND_SPONSORED_HEADLINES'
)

export const setMyfwTab = createAction('CONTENT_RIVER_MYFW_TAB_SET')

// Featured Content Actions
export const requestFeaturedContent = createAction(
  'FEATURED_CONTENT_RIVER__REQUEST',
  null,
  asyncMeta
)
export const successFeaturedContent = createAction(
  'FEATURED_CONTENT_RIVER__SUCCESS',
  null,
  asyncMeta
)
export const errorFeaturedContent = createAction(
  'FEATURED_CONTENT_RIVER__ERROR',
  null,
  asyncMeta
)

export const requestTagsSearch = createAction(
  'CONTENT_TAGS_SEARCH__REQUEST',
  null,
  asyncMeta
)
export const successTagsSearch = createAction(
  'CONTENT_TAGS_SEARCH__SUCCESS',
  null,
  asyncMeta
)
export const errorTagsSearch = createAction(
  'CONTENT_TAGS_SEARCH__ERROR',
  null,
  asyncMeta
)
export const cleanTagsSearch = createAction('CONTENT_TAGS_SEARCH__CLEAN')

export const addSelectedTag = createAction('ADD_SELECTED_TAG')
export const removeSelectedTag = createAction('REMOVE_SELECTED_TAG')

export const actions = {
  setLoading,
  setTagsLoading,
  setSearch,
  mergeSearch,
  removeSearch,
  requestSearch,
  successSearch,
  errorSearch,
  clearSearch,
  loadContentRiver,
  removeFilter,
  setSorting,
  requestLoadMore,
  successLoadMore,
  appendSponsoredHeadlines,
  setIgnoreOnMount,
  replaySearch,
  redirectSearch,
  addFilter,
  selectFilter,
  postErrorSearch,
  setMyfwTab,
  setMedicalAbstracts,
  requestFeaturedContent,
  successFeaturedContent,
  errorFeaturedContent,
  requestTagsSearch,
  successTagsSearch,
  errorTagsSearch,
  cleanTagsSearch,
  removeFilterWithoutSearch,
  addSelectedTag,
  removeSelectedTag
}

const defaultState = {
  myfwTabValue: 'all'
}

const buildRiverObject = ({
  results = [],
  count = 0,
  page = 0,
  aggs = {},
  filter = {},
  sponsoredHeadlines = [],
  ignoreOnMount = false,
  loading = false,
  loadingMore = false,
  showMedicalAbstracts,
  featuredResults = [],
  loadingFeaturedContent = false,
  excludeIds = [],
  tagFound = false,
  tagForCategory = {}
} = {}) => ({
  loading,
  loadingMore,
  results,
  count,
  page,
  aggs,
  filter,
  sponsoredHeadlines,
  showMedicalAbstracts,
  ignoreOnMount, // Skip componentDidMount triggers
  featuredResults,
  loadingFeaturedContent,
  excludeIds,
  tagFound,
  tagOptions: [],
  loadingTags: false,
  tagForCategory
})

export default handleActions(
  {
    [setLoading]: (state, { payload }) => {
      const { loading, riverId } = payload
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loading
        }
      }
    },
    [setSearch]: (state, { payload }) => {
      const { riverId, searchBody, isSavedSearch = false } = payload
      return {
        ...state,
        isSavedSearch,
        [riverId]: {
          ...buildRiverObject({ filter: searchBody, loading: true })
        }
      }
    },
    [mergeSearch]: (state, { payload }) => {
      const { riverId, searchBody, replaceFiltersCategory = '' } = payload
      let filter = _mergeWith(
        {},
        state[riverId].filter,
        searchBody,
        (obj, src, key) => {
          if (key === 'publication_date') return src
          if (Array.isArray(obj)) return _uniq(obj.concat(src))
        }
      )
      // Sometimes a filter can reset the entire selected filters
      if (filter.filters[replaceFiltersCategory]) {
        filter.filters[replaceFiltersCategory] =
          searchBody.filters[replaceFiltersCategory]
      }

      delete filter.from

      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loading: true,
          filter
        }
      }
    },
    [removeSearch]: (state, { payload }) => {
      const { riverId, searchBody } = payload
      const currentFilter = state[riverId].filter || {}

      let filter = {}
      const removedKeys = Object.keys(searchBody)

      for (let key in currentFilter) {
        if (!removedKeys.includes(key)) {
          filter[key] = currentFilter[key]
        }
      }

      delete filter.from
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loading: true,
          filter
        }
      }
    },
    [successSearch]: (state, { payload }) => {
      const { riverId, data, skipAggs = false } = payload
      const { results = [], count = 0, aggs = {}, tagFound = false } = data

      const finalAggs = skipAggs ? state[riverId].aggs : aggs

      return {
        ...state,
        isSavedSearch: false,
        [riverId]: {
          ...state[riverId],
          loading: false,
          loadingMore: false,
          results,
          count: count,
          ignoreOnMount: false,
          tagFound,
          aggs: finalAggs
        }
      }
    },
    [errorSearch]: (state, { payload }) => {
      return state // leave content as-is
    },
    [clearSearch]: () => defaultState,
    [requestLoadMore]: (state, { payload }) => {
      const { riverId } = payload
      const from = (state[riverId]?.filter?.from || 0) + 10
      const filter = { ...state[riverId].filter, from }

      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loadingMore: true,
          filter
        }
      }
    },
    [successLoadMore]: (state, { payload }) => {
      const { riverId, data } = payload
      const from = state[riverId]?.filter?.from || 10

      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loadingMore: false,
          results: [...state[riverId].results, ...data.results],
          page: from / 10
        }
      }
    },
    [removeFilterWithoutSearch]: (state, { payload }) => {
      const {
        riverId,
        filter: { field: fieldToRemove, value: valueToRemove },
        defaultFilters = { filters: {} }
      } = payload
      const { filters: currentFilters } = state[riverId].filter
      const updatedFilters = Object.keys(currentFilters).reduce((acc, key) => {
        const value = currentFilters[key]
        if (fieldToRemove !== key) return { ...acc, [key]: value }
        if (Array.isArray(value) && value.indexOf(valueToRemove) > -1) {
          return {
            ...acc,
            [key]: value.filter(val => val !== valueToRemove)
          }
        } else if (typeof value === 'string' && value === valueToRemove) {
          return { ...acc }
        }
        return { ...acc, [key]: value }
      }, {})

      delete updatedFilters.from
      let newFilters = {
        ...state[riverId].filter,
        filters: {
          ...updatedFilters,
          ...defaultFilters.filters
        }
      }

      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          filter: newFilters
        }
      }
    },

    [removeFilter]: (state, { payload }) => {
      const {
        riverId,
        filter: { field: fieldToRemove, value: valueToRemove },
        defaultFilters = { filters: {} }
      } = payload
      const { filters: currentFilters } = state[riverId].filter
      const updatedFilters = Object.keys(currentFilters).reduce((acc, key) => {
        const value = currentFilters[key]
        if (fieldToRemove !== key) return { ...acc, [key]: value }
        if (Array.isArray(value) && value.indexOf(valueToRemove) > -1) {
          return {
            ...acc,
            [key]: value.filter(val => val !== valueToRemove)
          }
        } else if (typeof value === 'string' && value === valueToRemove) {
          return { ...acc }
        }
        return { ...acc, [key]: value }
      }, {})

      delete updatedFilters.from
      let newFilters = {
        ...state[riverId].filter,
        filters: {
          ...updatedFilters,
          ...defaultFilters.filters
        },
        from: 0
      }

      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          filter: newFilters
        }
      }
    },
    [setSorting]: (state, { payload }) => {
      const { riverId, sort } = payload
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          page: 0,
          filter: {
            ...(state[riverId]?.filter ? state[riverId].filter : {}),
            sort,
            from: 0
          }
        }
      }
    },
    [appendSponsoredHeadlines]: (state, { payload }) => {
      const { riverId, sponsoredHeadlines } = payload
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          sponsoredHeadlines: [
            ...(state[riverId]?.sponsoredHeadlines
              ? state[riverId].sponsoredHeadlines
              : []),
            ...sponsoredHeadlines
          ]
        }
      }
    },
    [setMedicalAbstracts]: (state, { payload: { riverId, value } }) => ({
      ...state,
      [riverId]: {
        ...state[riverId],
        showMedicalAbstracts: value
      }
    }),
    [setIgnoreOnMount]: (state, { payload: ignoreOnMount }) => ({
      ...state,
      ignoreOnMount
    }),
    [setMyfwTab]: (state, { payload: myfwTabValue }) => ({
      ...state,
      myfwTabValue
    }),
    [requestFeaturedContent]: (state, { payload }) => {
      const { riverId, loading = true } = payload
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loadingFeaturedContent: loading
        }
      }
    },
    [successFeaturedContent]: (state, { payload }) => {
      const { riverId, data, excludeIds } = payload
      const { results = [] } = data
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loadingFeaturedContent: false,
          featuredResults: results,
          excludeIds
        }
      }
    },
    [errorFeaturedContent]: (state, { payload }) => {
      return state // leave content as-is
    },
    [setTagsLoading]: (state, { payload }) => {
      const { loading, riverId, category } = payload
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          tagForCategory: {
            ...state[riverId]?.tagForCategory,
            [category]: {
              ...state[riverId]?.tagForCategory[category],
              loadingTags: loading
            }
          }
        }
      }
    },
    [successTagsSearch]: (state, { payload }) => {
      const { riverId, data = [], category = '' } = payload
      return {
        ...state,
        isSavedSearch: false,
        [riverId]: {
          ...state[riverId],
          tagForCategory: {
            ...state[riverId]?.tagForCategory,
            [category]: {
              ...state[riverId]?.tagForCategory[category],
              loadingTags: false,
              tagOptions: data
            }
          }
        }
      }
    },
    [errorTagsSearch]: (state, { payload }) => {
      const { category } = payload
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          tagForCategory: {
            ...state[riverId]?.tagForCategory,
            [category]: {
              ...state[riverId]?.tagForCategory[category],
              loadingTags: false
            }
          }
        }
      }
    },
    [cleanTagsSearch]: (state, { payload }) => {
      const { category, riverId } = payload

      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          tagForCategory: {
            ...state[riverId]?.tagForCategory,
            [category]: {
              ...state[riverId]?.tagForCategory[category],
              tagOptions: []
            }
          }
        }
      }
    },
    [addSelectedTag]: (state, { payload }) => {
      const { option, riverId, category, singleSelect } = payload

      const newTag = { ...option, selected: true }
      let newSelectedTagsState = []

      if (singleSelect) {
        newSelectedTagsState = [newTag]
      } else {
        const alreadySelectedTags =
          state[riverId]?.tagForCategory[category]?.selectedTags ?? []

        const isOptionAlreadySelected = alreadySelectedTags?.findIndex(
          opt => opt.value === newTag.value
        )

        if (isOptionAlreadySelected !== -1) {
          newSelectedTagsState = [...alreadySelectedTags]
        } else {
          newSelectedTagsState = [...alreadySelectedTags, newTag]
        }
      }

      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          tagForCategory: {
            ...state[riverId]?.tagForCategory,
            [category]: {
              ...state[riverId]?.tagForCategory[category],
              selectedTags: newSelectedTagsState
            }
          }
        }
      }
    },
    [removeSelectedTag]: (state, { payload }) => {
      const { option: newTag, riverId, category } = payload

      const alreadySelectedTags =
        state[riverId]?.tagForCategory[category]?.selectedTags ?? []

      const filteredTags = alreadySelectedTags.filter(
        tag => tag.value !== newTag.value
      )

      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          tagForCategory: {
            ...state[riverId]?.tagForCategory,
            [category]: {
              ...state[riverId]?.tagForCategory[category],
              selectedTags: filteredTags
            }
          }
        }
      }
    }
  },
  defaultState
)
