import React, { Fragment } from 'react'
import { call, put, takeEvery } from 'redux-saga/effects'
import RoutedLink from 'src/components/RoutedLink/index.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import { USER_ALREADY_EXISTS } from 'src/services/FW5ML/errorCodes.js'
import { actions } from './reducer.js'

function* requestUserRegistration(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const { payload: account } = action
  try {
    yield call([UserRepository, 'registration'], account)
    yield put(actions.successUserRegistration())
    yield call([Piwik, 'track'], 'register', 'register', 'register')
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError && e.getCode() === USER_ALREADY_EXISTS) {
      yield put(
        notificationsEnqueue({
          message: (
            <Fragment>
              If you are registered, you will receive a verification email in
              your inbox. If you do not receive it,{' '}
              <RoutedLink label="Register Now" to="/register" />
            </Fragment>
          )
        })
      )

      yield call(
        [Piwik, 'track'],
        'notification',
        'error',
        'user-was-already-registered'
      )
    } else {
      yield put(logPush('Register', `Error in user registration: ${e.message}`))

      yield put(
        notificationsEnqueue({
          message:
            'Something went wrong!  Please refresh the page and try again.'
        })
      )

      yield call(
        [Piwik, 'track'],
        'notification',
        'error',
        'could-not-register-user'
      )
    }
    yield put(actions.errorUserRegistration(e.message))
  }
}

export default function* watchRegistration(services) {
  yield takeEvery(
    'USER_REGISTRATION__REQUEST',
    requestUserRegistration,
    services
  )
}
