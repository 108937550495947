import React from 'react'
import PropTypes from 'prop-types'
import { getInitials } from '../utils'
import styles from './Avatar.module.sass'
import cx from 'classnames'

const Avatar = ({ image, name, size, border, className, altTheme, color }) => {
  const themeStyles = { ...styles, ...altTheme }
  const initials = name && getInitials(name)
  return (
    <div
      className={cx(
        themeStyles.container,
        !image && themeStyles.containerBlank,
        className
      )}
      style={{
        fontSize: size,
        width: size,
        height: size,
        ...(border && { border: '1px solid #cccccc' })
      }}
    >
      {image ? (
        <img title={name} className={themeStyles.image} src={image} />
      ) : (
        <span className={cx(themeStyles.initials, themeStyles[color])}>
          {initials}
        </span>
      )}
    </div>
  )
}
Avatar.displayName = 'Avatar'
Avatar.defaultProps = {
  size: 40,
  name: '',
  border: false
}
Avatar.propTypes = {
  /**
   * The initials to show in place of an image
   */
  name: PropTypes.string,
  /**
   * The avatar image
   */
  image: PropTypes.node,
  /**
   * An additional custom className
   */
  className: PropTypes.string,
  /**
   * The size of the avatar
   */
  size: PropTypes.number,
  /**
   * Does the avatar have a border?
   */
  border: PropTypes.bool,
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * Select a color from the theme
   */
  color: PropTypes.oneOf([
    '',
    'inherit',
    'primary',
    'secondary',
    'tertiary',
    'error',
    'text-primary',
    'text-secondary',
    'knockout'
  ])
}
export default Avatar
