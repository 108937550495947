import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from 'src/components/DougallModal'
import PromptList from 'src/components/PromptList/PromptList'
import HistoryItem from './components/HistoryItem'

import { actions as historyActions } from './HistoryReducer'
import {
  getHistory,
  getIsDeleting,
  getIsFetching,
  isClearAllOpen,
  getSelectedThread
} from './HistorySelector'
import { HistoryOutlined } from '@mui/icons-material'
import usePiwik from 'src/hooks/usePiwik'

const History = ({ nightMode = false, openCallback, isOpen }) => {
  const { handleTracking } = usePiwik()
  const dispatch = useDispatch()
  const clearAllOpen = useSelector(isClearAllOpen())
  const history = useSelector(getHistory())
  const isFetching = useSelector(getIsFetching())
  const isDeleting = useSelector(getIsDeleting())
  const selectedThread = useSelector(getSelectedThread())
  const { t } = useTranslation()

  const handleCloseModal = () => {
    dispatch(historyActions.setClearAllOpen({ open: false }))
  }

  const handleOpenModal = () => {
    dispatch(historyActions.setClearAllOpen({ open: true }))
    handleTracking('firstword-ai', 'click', 'history_clear-all')
  }

  const handleDeleteAll = () => {
    dispatch(historyActions.deleteHistory({ t }))
    handleTracking('firstword-ai', 'button-click', 'history_pop-up_clear-all')
  }

  const renderHistoryItem = item => {
    return (
      <HistoryItem
        nightMode={nightMode}
        item={item}
        selected={selectedThread === item.id}
      />
    )
  }

  return (
    <div data-testid="historyContainer">
      <PromptList
        id="history"
        title={t('history')}
        icon={HistoryOutlined}
        items={history}
        customChildrenRender={renderHistoryItem}
        isFetching={isFetching}
        listClassName={{
          maxHeight: '613px',
          overflowY: 'scroll',
          msOverflowStyle: 'none', // Scrollbar Hidden for IE and Edge
          scrollbarWidth: 'none', // Scrollbar Hidden for Firefox
          '&::-webkit-scrollbar': { display: 'none' } // Scrollbar Hidden for Chrome, Safari and Opera
        }}
        collapsibleContent
        nightMode={nightMode}
        button={history.length > 0}
        buttonLabel={t('historyClearAll')}
        onButtonClick={handleOpenModal}
        manualOpenCallback={openCallback}
        isOpen={isOpen}
        dividers={false}
        autoIndent={false}
      />
      <Modal
        open={clearAllOpen}
        actionCaption={t('historyClearAllSubmitButton')}
        onCancel={handleCloseModal}
        onConfirm={handleDeleteAll}
        submitting={isDeleting}
      >
        <Box data-testid="clearAllModalContainer">
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.5em' }}>
            {t('historyClearAllModalTitle')}
          </Typography>
          <Typography sx={{ marginTop: '13px', fontSize: '1em' }}>
            {t('historyClearAllModalSubtitle')}
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}

History.propTypes = {
  /**
   * Styles for dark or light background
   */
  nightMode: PropTypes.bool,
  /**
   * Callback when manually controlled opening
   */
  openCallback: PropTypes.func,
  /**
   * Variable for manual opening
   */
  isOpen: PropTypes.bool
}

export default History
