import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/Icon'
import { Link } from 'react-router-dom'
import styles from './ViewMoreLink.module.sass'

const ViewMoreLink = ({ children, to, className, ...props }) => (
  <Link to={to} className={styles.link + ` ${className || ''}`} {...props}>
    <span className={styles.text}>{children}</span>
    <Icon
      icon="svg/material-design-icons/hardware/keyboard_arrow_right"
      colorTier="secondary"
      className={styles.icon}
    />
  </Link>
)

ViewMoreLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  className: PropTypes.string
}

export default ViewMoreLink
