import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../../../../components/ui/Typography'
import { Link } from 'react-router-dom'
import Logo from '../../../../components/Logo'
import styles from './PayWallPage.module.sass'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const PayWallSection = ({
  plus,
  subheading,
  children,
  cta,
  to,
  className,
  isFree
}) => (
  <section className={cx(styles.section, className, { isFree })}>
    <div className={styles.head}>
      <div className={styles.logoContainer}>
        <Logo className={cx(styles.logo, { plus })} />
      </div>
      {subheading && (
        <Typography type="subheading" className={styles.subheading} uppercase>
          {subheading}
        </Typography>
      )}
      {cta && (
        <Link to={to} className={cx(styles.button, 'paywall-cta-button')}>
          {cta}
        </Link>
      )}
    </div>
    <div className={styles.children}>{children}</div>
  </section>
)
PayWallSection.propTypes = {
  /**
   * If `true`, the section has "plus" attributes, like logo plus icon
   */
  plus: PropTypes.bool,
  /**
   * Subheading text
   */
  subheading: PropTypes.string,
  /**
   * The elements to display underneath the section heading
   */
  children: PropTypes.node,
  /**
   * The call to action button text
   */
  cta: PropTypes.string,
  /**
   * The button link
   */
  to: PropTypes.string,
  /**
   * An additional className to use for the root element
   */
  className: PropTypes.string,
  /**
   * If 'true' the Link button will not be displayed
   */
  isFree: PropTypes.bool
}
export default PayWallSection
