import { takeLatest, all, call, put, select } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { notificationDuration as duration } from 'src/constants/index.js'

function* listReports(services, { payload = {} }) {
  const { size = 3 } = payload
  const StoriesRepository = services('StoriesRepository')
  try {
    const payload = {
      sort_by: 'publication_date',
      from: 0,
      size,
      include_likes: true
    }
    const response = yield call([StoriesRepository, 'getProfileReports'], {
      customPayload: payload,
      config: null
    })

    yield put(actions.successListReports(response.data))
  } catch (e) {
    yield put(
      notificationsEnqueue({
        message: `Unable to load your reports. Please refresh the page and try again.`,
        duration
      })
    )
    console.log(e)
    yield put(actions.errorListReports(e.message))
  }
}

function* listReportsCount(services, action) {
  const FWReports = services('FWReportsRepository')
  const session = yield select(store => store.session)
  let reportsCount = 0
  try {
    if (session?.authenticated) {
      reportsCount = yield call([FWReports, 'getUserReportsCount'])
    }
    yield put(actions.successReportsCount(reportsCount))
  } catch (e) {
    yield put(actions.errorReportsCount(e.message))
  }
}

export default function* watchUpdate(services) {
  yield all([
    takeLatest('ACCOUNT_LIST_REPORTS__REQUEST', listReports, services)
  ])
  yield all([
    takeLatest('ACCOUNT_LIST_REPORTS_COUNT_REQUEST', listReportsCount, services)
  ])
}
