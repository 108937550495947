import asyncMeta from '../AsyncFlags/metaCreator'
import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestListReports = createAction(
  'ACCOUNT_LIST_REPORTS__REQUEST',
  null,
  asyncMeta
)
export const successListReports = createAction(
  'ACCOUNT_LIST_REPORTS__SUCCESS',
  null,
  asyncMeta
)
export const errorListReports = createAction(
  'ACCOUNT_LIST_REPORTS__ERROR',
  null,
  asyncMeta
)

export const requestReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT_REQUEST',
  null,
  asyncMeta
)
export const successReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT__SUCCESS',
  null,
  asyncMeta
)
export const errorReportsCount = createAction(
  'ACCOUNT_LIST_REPORTS_COUNT__ERROR',
  null,
  asyncMeta
)

export const actions = {
  requestListReports,
  successListReports,
  errorListReports,
  requestReportsCount,
  successReportsCount,
  errorReportsCount
}

const initialState = {
  reports: {
    loading: null,
    error: null,
    data: null
  },
  count: {
    loading: true,
    error: null,
    data: null
  }
}

const myReportsReducer = createReducer(initialState, {
  [requestListReports]: state => {
    state.reports.loading = true
    state.reports.error = null
    state.reports.data = null
  },
  [successListReports]: (state, { payload: reports }) => {
    state.reports.loading = false
    state.reports.error = null
    state.reports.data = reports
  },
  [errorListReports]: (state, { payload: error }) => {
    state.reports.loading = false
    state.reports.error = error
    state.reports.data = null
  },
  [requestReportsCount]: state => {
    state.count.loading = true
    state.count.error = null
    state.count.data = null
  },
  [successReportsCount]: (state, { payload: count }) => {
    state.count.loading = false
    state.count.error = null
    state.count.data = count
  },
  [errorReportsCount]: (state, { payload: error }) => {
    state.count.loading = false
    state.count.error = error
    state.count.data = null
  }
})

export default myReportsReducer
