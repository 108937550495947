import { connect } from 'react-redux'
import {
  requestInsertInterest,
  requestDeleteInterest
} from '../../MyInterests/reducer'
import { paywallRedirectFlow } from '../../Benefits/reducer'
import { treeToArray } from '../../../utils'
import _get from 'lodash/get'
import RiverHeader from '../../River/components/RiverHeader'

const mapStateToProps = state => ({
  userInterests: treeToArray(
    _get(state.session.user, 'interests.stated', {}),
    (category, tag) => ({
      category,
      tag
    })
  )
})

const mapDispatchToProps = dispatch => ({
  requestAddInterestToUser: interest =>
    dispatch(requestInsertInterest(interest)),
  requestDeleteInterestToUser: interest =>
    dispatch(requestDeleteInterest(interest)),
  paywallRedirection: data => dispatch(paywallRedirectFlow(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RiverHeader)
