import { sessionService } from 'redux-react-session'
import Cookies from 'js-cookie'
import _set from 'lodash/set'
import _unset from 'lodash/unset'
import _get from 'lodash/get'

/**
 * This constant stablishes the supported session structures
 * using the semver spec.
 * If the user structure changes in a non compatible way, change
 * accordingly.
 * @type {String}
 */
const COOKIE_VERSION = 'V1_'

export const options = {
  refreshOnCheckAuth: true,
  redirectPath: '/',
  driver: 'INDEXEDDB',
  validateSession: session => {
    return true
  }
}
class SessionService {
  constructor() {
    this.token = null
    this.tokenType = null
    this.phoenixToken = null
  }

  async sessionLoad() {
    let session = false
    try {
      session = await sessionService.loadSession()
    } catch (e) {
      // If user is anon, ignore, else throw
      if (e !== 'Session not found') throw e
    }
    return session
  }

  async userLoad() {
    let user = false
    try {
      user = await sessionService.loadUser()
    } catch (e) {
      // If user is anon, ignore, else throw
      if (e !== 'User not found') throw e
    }
    return user
  }

  async getUserField(path, defaultValue) {
    const user = await this.userLoad()
    if (!user) return defaultValue
    return _get(user, path, defaultValue)
  }

  async tokenLoad() {
    if (this.token) return this.token
    const session = await this.sessionLoad()
    return (session && session.token) || null
  }

  async tokenTypeLoad() {
    if (this.tokenType) return this.tokenType
    const session = await this.sessionLoad()
    return (session && session.tokenType) || null
  }

  async phoenixTokenLoad() {
    if (this.phoenixToken) return this.phoenixToken
    const session = await this.sessionLoad()
    return (session && session.phoenixToken) || null
  }
  async sessionSave(data, user = null) {
    try {
      if (data.token) this.token = data.token
      if (data.tokenType) this.tokenType = data.tokenType
      if (data.phoenixToken) this.phoenixToken = data.phoenixToken
      await sessionService.saveSession({
        ...data
      })
      if (user) {
        await sessionService.saveUser(user)
      }
      return true
    } catch (error) {
      return false
    }
  }

  async sessionUpdate(newSession) {
    try {
      const session = await this.sessionLoad()
      if (!session) return this.sessionSave(newSession)
      const updatedSession = {
        ...session,
        ...newSession
      }
      await sessionService.sessionSave(updatedSession)
      return true
    } catch (error) {
      return false
    }
  }

  async userSave(user) {
    try {
      await sessionService.saveUser(user)
      return true
    } catch (error) {
      return false
    }
  }

  async userUpdate(newUser) {
    try {
      const user = await sessionService.loadUser()
      const updatedUser = {
        ...user,
        ...newUser
      }
      if (newUser.country) {
        updatedUser.address.country = newUser.country
        delete updatedUser.country
      }

      await sessionService.saveUser(updatedUser)
      return true
    } catch (error) {
      return false
    }
  }

  async userRemoveInterest(interestRemoved) {
    try {
      const user = await sessionService.loadUser()
      const updatedUser = { ...user }
      const { category, tag } = interestRemoved
      const categoryInterests = _get(
        updatedUser,
        `interests.stated.${category}`,
        []
      )
      const filteredInterests = categoryInterests.filter(
        interest => interest !== tag
      )
      if (filteredInterests.length > 0) {
        _set(updatedUser, `interests.stated.${category}`, filteredInterests)
      } else {
        _unset(updatedUser, `interests.stated.${category}`)
      }
      await sessionService.saveUser(updatedUser)
      return true
    } catch (error) {
      return false
    }
  }

  async userInsertInterest(interest) {
    try {
      const user = await sessionService.loadUser()
      const updatedUser = { ...user }
      const { category, tag } = interest
      const categoryInterests = _get(
        updatedUser,
        `interests.stated.${category}`,
        []
      )
      categoryInterests.push(tag)
      _set(updatedUser, `interests.stated.${category}`, categoryInterests)
      await sessionService.saveUser(updatedUser)
      return true
    } catch (error) {
      return false
    }
  }

  async tokenSave(token, tokenType, phoenixToken) {
    this.token = token
    this.tokenType = tokenType
    this.phoenixToken = phoenixToken
    return await this.sessionSave({ token, tokenType, phoenixToken })
  }

  async destroySession() {
    await sessionService.deleteSession()
    await sessionService.deleteUser()
    this.token = null
    this.tokenType = null
  }

  async invalidateSession() {
    sessionService.invalidateSession()
  }

  setCookie(key, value, options = {}) {
    const { expires } = options
    const newKey = `${COOKIE_VERSION}${key}`
    if (!expires) options.expires = 30
    return Cookies.set(newKey, value, options)
  }

  getCookie(key, json = true) {
    const newKey = `${COOKIE_VERSION}${key}`
    if (json) return Cookies.getJSON(newKey)
    return Cookies.get(newKey)
  }

  removeCookie(key) {
    const newKey = `${COOKIE_VERSION}${key}`
    return Cookies.remove(newKey)
  }
}

export default SessionService
