export const orderedFilters = [
  'fw_report_disease_areas',
  'fw_report_therapy_areas',
  'fw_company',
  'fw_product',
  'fw_report_functions_issues',
  'fw_report_stakeholders'
]

export const filterLabels = {
  fw_product: 'Products',
  fw_company: 'Companies',
  fw_companies: 'Companies',
  fw_report_disease_areas: 'Conditions',
  fw_report_functions_issues: 'Function',
  fw_report_brand: 'Report Brand',
  fw_report_stakeholders: 'Stakeholder',
  fw_report_therapy_areas: 'Therapy Area'
}

export const titleLabels = {
  fw_report_functions_issues: 'Business Area',
  fw_report_brand: 'Report Brand',
  fw_report_stakeholders: 'Stakeholder',
  fw_report_therapy_areas: 'Therapy Area'
}

export const excludedCategories = [
  'type',
  'publication_date',
  'fw_category',
  'fw_companies',
  'fw_job_title',
  'fw_regulatory_agency',
  'fw_story_watch',
  'fw_mdCompanies',
  'fw_mdProducts',
  'fw_mdMarketSegments'
]
const overrides = {
  '1_day': '1 Day',
  '1_week': '1 Week',
  '1_month': '1 Month',
  '2_month': '2 Months',
  '3_month': '3 Months',
  '4_month': '4 Months',
  '5_month': '5 Months',
  '6_month': '6 Months'
}
const filterTags = {
  products: {
    filters: {},
    fieldsExist: ['tags.fw_product']
  },
  fw_report_disease_areas: {
    filters: {},
    fieldsExist: ['tags.fw_report_disease_areas']
  },
  business: {
    filters: {},
    fieldsExist: ['tags.fw_job_position']
  }
}

// Get exclusions list for other_by river
const otherByExclusions = {
  'tags.fw_report_therapy_areas': [
    'Cardiac, Metabolic & Endocrine',
    'Dermatology',
    'Gastroenterology',
    'Haemato-Oncology',
    'Neurology',
    'Ophthalmology',
    'Respiratory',
    'Rheumatology',
    'Solid Tumours'
  ],
  'tags.fw_report_functions_issues': [
    'Digital Health',
    'Market Access',
    'Medical Affairs',
    'Sales & Marketing'
  ],
  'tags.fw_report_stakeholders': [
    'Industry Experts',
    'KOLs',
    'Treating Physicians',
    'Payers'
  ]
}

export const getFilterLabel = filter => overrides[filter] || filter
export const getKey = key => filterLabels[key] || key
export const getFilterTags = key => filterTags[key] || key
export const getOtherByExclusions = key => otherByExclusions[key] || key
