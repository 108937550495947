const iconJson = require('./index.json')

const flatten = (obj, prefix = [], current = []) => {
  const isArray = Array.isArray(obj)
  if (isArray && typeof obj[0] === 'string') {
    current.push(...obj.map(icon => [...prefix, icon].join('/')))
  } else {
    Object.keys(obj).forEach(key => {
      flatten(obj[key], isArray ? prefix : [...prefix, key], current)
    })
  }
  return current
}

const iconList = flatten(iconJson)

export default iconList
