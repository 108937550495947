import { ThemeProvider as Provider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import useTheme from './useTheme'
/**
 * Overrides default theme with a selected JS file containing a new theme
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const ThemeProvider = ({ children }) => {
  const { active, create } = useTheme()
  return <Provider theme={create(active)}>{children}</Provider>
}
ThemeProvider.propTypes = {
  // Application content
  children: PropTypes.element
}
ThemeProvider.displayName = 'ThemeProvider'

export default ThemeProvider
