import { getFilterTags, getOtherByExclusions } from './filterLabels'

export const myReports = async services => {
  return {
    filters: {}
  }
}

export const relatedAnalysis = ids => {
  const filters = {
    filters: {
      type: [
        'Friday Five',
        'IAV Other',
        'In Focus',
        'KOL Views',
        'Kol Views Results',
        'Physician Views',
        'Physician Views Poll Results',
        'Physician Views In depth',
        'Physician Views In depth results',
        'Spotlight On',
        'The First Take',
        'ViewPoints',
        'Vital Signs'
      ]
    },
    related_to_article_ids: ids
  }

  return filters
}

export const relatedNews = id => {
  const filters = {
    filters: {
      type: [
        'NewsPoints',
        'Conference Dispatch',
        'DGDispatch',
        'Daily News Video',
        'List',
        'People News',
        'Press Release',
        'Top Story'
      ]
    },
    related_to_article_ids: [id]
  }

  return filters
}

export const allReports = {
  filters: {}
}

export const upcomingReports = {
  filters: {},
  base_query: {
    must: [
      {
        term: {
          status: '1'
        }
      },
      {
        terms: {
          'tags.fw_report_brand': ['Dossier', 'FirstView', 'Therapy Trends']
        }
      },
      {
        term: {
          'extra_fields.preview': '1'
        }
      },
      {
        term: {
          'tags.fw_report_destination': 'FWReports'
        }
      }
    ],
    must_not: [
      {
        term: {
          'extra_fields.private.keyword': '1'
        }
      }
    ]
  }
}

export const recentRiver = key => {
  return getFilterTags(key)
}

export const byType = type => {
  const typesArray = [type]
  return {
    filters: {
      type: typesArray
    }
  }
}

export const allBy = path => {
  const matches = path.match(/(\w+)\/(.*)/)
  if (!matches) return {}
  const [, by, value] = matches
  if (!by || !value) return {}

  return {
    filters: {},
    fieldsExist: [`${by}.${value}`]
  }
}

export const byTag = path => {
  const matches = path.match(/(\w+)\/(.*)/)
  if (!matches) return {}
  const [, category, tag] = matches
  if (!category || !tag) return {}
  return {
    filters: {
      [category]: [tag]
    },
    type: 'tags'
  }
}

export const otherBy = path => {
  const matches = path.match(/(\w+)\/(.*)/)
  if (!matches) return {}
  const [, by, value] = matches
  if (!by || !value) return {}

  const key = `${by}.${value}`
  const exclusionsList = getOtherByExclusions(key)

  return {
    filters: {},
    base_query: {
      add_to_criteria: true,
      must_not: [
        {
          terms: {
            [key]: exclusionsList
          }
        }
      ]
    },
    fieldsExist: [key]
  }
}

export const searchRiver = (services, pathArgs) => {
  return {
    filters: {},
    query: pathArgs,
    sort: '_score'
  }
}

export const rivers = {
  MY_REPORTS: 'your-reports',
  RELATED_ANALYSIS: 'related-analysis',
  RELATED_NEWS: 'related-news',
  ALL_REPORTS: 'all-reports',
  RECENT_RIVER: 'recent',
  TYPE: 'type',
  TAG: 'tag',
  SEARCH: 'search',
  DEFAULT_RIVER: '',
  ALL_BY: 'all_by',
  UPCOMING_REPORTS: 'upcoming',
  OTHER_BY: 'other_by'
}

export const mediaEventTypes = [rivers.EVENTS, rivers.RESOURCES]

export default {
  [rivers.MY_REPORTS]: services => myReports(services),
  [rivers.RELATED_ANALYSIS]: (services, pathArgs) => relatedAnalysis(pathArgs),
  [rivers.RELATED_NEWS]: (services, pathArgs) => relatedNews(pathArgs),
  [rivers.ALL_REPORTS]: () => allReports,
  [rivers.RECENT_NEWS]: (services, pathArgs) => recentRiver(pathArgs),
  [rivers.TYPE]: (services, type) => byType(type),
  [rivers.TAG]: (services, pathArgs) => byTag(pathArgs),
  [rivers.ALL_BY]: (services, pathArgs) => allBy(pathArgs),
  [rivers.OTHER_BY]: (services, pathArgs) => otherBy(pathArgs),
  [rivers.SEARCH]: (services, pathArgs) => searchRiver(services, pathArgs),
  [rivers.UPCOMING_REPORTS]: () => upcomingReports,
  [rivers.DEFAULT_RIVER]: (services, pathArgs) =>
    searchRiver(services, pathArgs)
}
