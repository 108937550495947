import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

import TextareaAutosize from 'react-textarea-autosize'
import styles from './PromptBar.module.sass'
import classNames from 'classnames/bind'

import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { SendIcon } from 'src/static/icons'

import { useTranslation } from 'react-i18next'
import Typography from 'src/components/ui/Typography/Typography'

const cx = classNames.bind(styles)

/**
 * Send Button
 * theme path: PromptBar.styleOverrides.sendButton
 */
const SendButton = styled(IconButton, {
  name: 'PromptBar',
  label: 'sendButton',
  overridesResolver: (props, styles) => [styles.sendButton]
})(({ theme }) => ({
  backgroundColor: `${theme.palette.promptBar?.sendButtonBackground} !important`,
  border: '0px',
  borderRadius: '33px',
  height: '32px',
  width: '32px',
  padding: '0',
  marginRight: '13px'
}))

/**
 * New Topic Button
 * theme path: PromptBar.styleOverrides.newTopicButtonContainer
 */
const NewTopicButton = styled(IconButton, {
  name: 'PromptBar',
  label: 'newTopicButton',
  overridesResolver: (props, styles) => [styles.newTopicButtonContainer]
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: `${theme.palette.promptBar?.newTopicBackground}!important`,
  border: '0px',
  borderRadius: '33px',
  height: '62px',
  width: '62px',
  marginRight: '5px',
  maxWidth: '62px',
  maxHeight: '62px',
  p: {
    lineHeight: '1',
    whiteSpace: 'normal',
    pointerEvents: 'none',
    margin: '0',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '12px',
    color: theme.palette.promptBar?.newTopicText
  }
}))

const PromptBar = ({
  control,
  name = 'prompt-bar',
  placeholder = '',
  newTopic = true,
  redShadow = false,
  onNewTopicClick = () => {},
  onSubmit = () => {}
}) => {
  const { t } = useTranslation()
  const { setValue, handleSubmit } = useFormContext()
  const inputRef = useRef(null)
  const handleChange = e => {
    // Limit number of words to 500, see #DOUGGPT-283
    if (e.target.value.trim().split(' ').length <= 500) {
      setValue(name, e.target.value)
    }
  }
  // Detect Enter Key to submit
  const onEnterPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }
  return (
    <div className={styles.container}>
      {newTopic && (
        <NewTopicButton
          data-testid="handleNewTopicButton"
          onClick={() => {
            onNewTopicClick()
          }}
        >
          <Typography>{t('newTopic')}</Typography>
        </NewTopicButton>
      )}
      <div className={cx(styles.promptContainer, { redShadow })}>
        <div className={styles.prompt}>
          <Controller
            name={name}
            control={control}
            render={({ field: { onBlur, value } }) => (
              <TextareaAutosize
                id={name}
                name={name}
                ref={inputRef}
                label=""
                value={value}
                placeholder={placeholder}
                className={styles.input}
                onChange={handleChange}
                onBlur={onBlur}
                onKeyDown={onEnterPress}
                maxRows={6}
              />
            )}
          />
          <SendButton data-testid="promptSubmitButton" type="submit">
            <SendIcon
              sx={{
                height: '20px',
                width: '20px',
                margin: '0'
              }}
            />
          </SendButton>
        </div>
      </div>
    </div>
  )
}

PromptBar.propTypes = {
  /**
   * react-hook-form useForm control
   */
  control: PropTypes.any,
  /**
   * Name identifier for a form input
   */
  name: PropTypes.string,
  /**
   * Prompt Bar input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * New Topic Button show bool
   */
  newTopic: PropTypes.bool,
  /**
   * Shadow style color red
   */
  redShadow: PropTypes.bool,
  /**
   * Function to execute on New Topic button click
   */
  onNewTopicClick: PropTypes.func,
  /**
   * onSubmit handler
   */
  onSubmit: PropTypes.func
}

export default PromptBar
