import React from 'react'
import Notice from '../../components/Notice'
import useNotices from './hooks/useNotices'

function Notices() {
  const { unreadNotices = [], dismissNotice } = useNotices()
  if (!unreadNotices.length) return null
  const firstNotice = unreadNotices[0]
  return (
    <Notice
      visible={!!firstNotice}
      content={firstNotice?.content}
      onDismiss={() => dismissNotice(firstNotice.notification_id)}
    />
  )
}

export default Notices
