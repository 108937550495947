import React from 'react'
import PropTypes from 'prop-types'
import styles from './DisplayTabsBox.module.sass'
import Tab from '../../../../../components/ui/Tabs/Tab'
import Tabs from '../../../../../components/ui/Tabs/Tabs'

const options = {
  all: 'All',
  byInterest: 'By Interest'
}

export const DisplayTabs = ({ value, onClick = () => {} }) =>
  Object.keys(options).map(key => (
    <Tab
      className={styles.tab}
      key={key}
      selected={key === value}
      onClick={onClick(key)}
      data-piwik-class="myfw-display-button"
      showsActiveBar
    >
      {options[key]}
    </Tab>
  ))

const DisplayTabsBox = ({ show, ...props }) =>
  show && (
    <div className={styles.bar}>
      <Tabs className={styles.container}>
        <DisplayTabs {...props} />
      </Tabs>
    </div>
  )

DisplayTabsBox.propTypes = {
  show: PropTypes.bool
}

export default DisplayTabsBox
