import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import RenderFullPage from '../RenderFullPage'
import styles from './Page.module.sass'

const RenderSimplePage = props => (
  <div className={styles.page}>
    <main className={cx(styles.content, props.className)}>
      {props.children}
    </main>
  </div>
)

RenderSimplePage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

const Page = props => {
  if (props?.simplePage) {
    return RenderSimplePage(props)
  }

  return <RenderFullPage {...props} />
}

Page.propTypes = {
  simplePage: PropTypes.bool
}

export default Page
