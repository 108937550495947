import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../../components/ui/Icon'
import Spinner from '../../../../components/ui/Spinner'
import styles from './SavedSearch.module.sass'
import { savedSearch as savedSearchPropType } from '../../../../utils/propTypes'
import FilterList from '../FilterList'

const SavedSearch = ({
  search,
  deleting,
  onReplay,
  onDelete,
  onUpdate,
  isEditable,
  isRemovable,
  hasEmailNotification
}) => {
  const handleReplay = !deleting ? onReplay : undefined
  const handleDelete = !deleting ? onDelete : undefined
  const handleUpdate = !deleting ? onUpdate : undefined
  const frequenciesName = {
    hourly: 'Immediately',
    daily: 'Daily Digest'
  }
  return (
    <tr>
      <td>
        <div className={styles.label} onClick={handleReplay}>
          {search.name}
          <FilterList {...search.search_request} />
        </div>
      </td>
      {hasEmailNotification && (
        <td>
          <div className={styles.frequency}>
            {frequenciesName[search.alert_frequency] || 'none'}
          </div>
        </td>
      )}
      {isEditable && (
        <td>
          <Icon
            className={styles.icon}
            icon="svg/custom/edit"
            colorTier="secondary"
            onClick={handleUpdate}
            button
          />
        </td>
      )}
      {isRemovable && (
        <td>
          {deleting ? (
            <div className={styles.spinner}>
              <Spinner
                fillColor="transparent"
                strokeColor="rgba(213,213,213,1)"
                size={24}
              />
            </div>
          ) : (
            <Icon
              className={styles.icon}
              icon="svg/fw/delete"
              colorTier="secondary"
              onClick={handleDelete}
              button
            />
          )}
        </td>
      )}
    </tr>
  )
}

SavedSearch.propTypes = {
  /**
   * Saved search body
   */
  search: savedSearchPropType,
  /**
   * True if this saved search is being deleted
   */
  deleting: PropTypes.bool,
  /**
   * Called to request the current saved search to be replayed
   */
  onReplay: PropTypes.func,
  /**
   * Called to request the current saved search to be deleted
   */
  onDelete: PropTypes.func,
  /**
   * Other props
   */
  onUpdate: PropTypes.func,
  isEditable: PropTypes.bool,
  isRemovable: PropTypes.bool,
  hasEmailNotification: PropTypes.any
}

export default SavedSearch
