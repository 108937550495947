import React, { Fragment } from 'react'
import RoutedLink from 'src/components/RoutedLink'
import {
  notificationDuration as duration,
  noticeContent,
  endpointVersions
} from 'src/constants'
import { takeEvery, put, call, select, all, take } from 'redux-saga/effects'
import {
  replace,
  push,
  getLocation,
  onLocationChanged
} from 'connected-react-router'
import { sessionService } from 'redux-react-session'
import { actions } from './reducer'
import { actions as configActions } from 'src/modules/Config/reducer'
import { actions as piwikActions } from 'src/modules/Piwik/reducer'
import { actions as adActions } from 'src/modules/Ads/reducer'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer'
import { logPush } from 'src/modules/ContactUs/reducer'
import { requestAccountSettingsLoad } from 'src/modules/MySettings/reducer'
import { requestAccountSettingsLoadFromCache } from 'src/modules/MySettings/saga'
import {
  ACCESS_DENIED,
  TOKEN_EXPIRED,
  DEVICE_LIMIT_REACHED,
  DEVICE_MISMATCH,
  DEVICE_UNKNOWN,
  DEVICE_UNKNOWN_CONFIRMATION_REQUIRED,
  CAPTCHA_VERIFICATION_REQUIRED,
  PROFILE_ALREADY_SUBSCRIBED,
  REGISTRATION_ALREADY_CONFIRMED
} from 'src/services/FW5ML/errorCodes'
import FW5MLError from 'src/services/FW5ML/FW5MLError'
import tokenTypes from 'src/services/session/tokenTypes'
import autoTrack from 'src/modules/Tracking/autoTrack'
import { stringHash } from 'src/utils'
import { siteName } from 'src/utils/oneCodeBase'
import { setCaptchaLoginMeta } from 'src/modules/Captcha/reducer'
import { LOGIN_TOKEN, NL_TOKEN } from 'src/modules/Captcha/constants'
import { options } from 'src/services/session'
import {
  SUCCEED_SSO_URL,
  FAILED_SSO_URL
} from 'src/services/FW5ML/constants/sso'
import { setNewsletterSettings } from 'src/modules/MyNewsletters/reducer'
import newsletterTracking from 'src/modules/Bootstrapping/newsletterTracking'

/**
 * Main initialization flow.
 * - Inits Piwik tracking
 * Handles this flows:
 *   - NL Autologin
 *   - Email Passwordless Login
 *   - Anon Client auth
 */

const { tokenGetEndpoint } = endpointVersions.V2
function* initialize(services, action) {
  const queryString = services('queryString')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const Device = services('Device')
  const LogRocketService = services('LogRocketService')

  try {
    // Initialize logRocket
    yield call([LogRocketService, 'initialize'])

    const location = yield select(state => state.router.location) // @TODO - Change function to getLocation when test supports browser history
    const { pathname } = location
    // Initialize redux persist session
    yield call(initializeSessionService, services)

    // Initialize iOvation
    const deviceInitialized = yield call([Device, 'initialize'])
    if (!deviceInitialized) {
      console.warn('Id-key was not initialized')
    }

    // Initialize Piwik
    yield call([Piwik, 'initialize'])

    // Autologin token
    const queryToken = queryString.get('t')
    // Passwordless Login Token
    const loginToken = queryString.get('at') || queryString.get('access_token')
    // Registration Token
    const registrationToken = queryString.get('registration_token')
    // Internal token
    const internalToken = queryString.get('it')
    // Logout key
    const logoutKey = queryString.get('logout')

    // Remove tokens from URL
    if (
      queryToken ||
      loginToken ||
      registrationToken ||
      internalToken ||
      logoutKey
    ) {
      const redirection = queryString.get('redir')
      if (redirection) {
        const newUrl = decodeURIComponent(redirection)
        yield put(
          onLocationChanged(
            { pathname: newUrl, search: '', hash: '' },
            'POP',
            false
          )
        )
        yield put(push(newUrl))
      } else {
        let newQS = queryString.removeKey('t')
        newQS = queryString.removeKey('at', newQS)
        newQS = queryString.removeKey('access_token', newQS)
        newQS = queryString.removeKey('registration_token', newQS)
        newQS = queryString.removeKey('it', newQS)
        newQS = queryString.removeKey('logout', newQS)
        yield put(replace({ search: newQS }))
      }
    }
    // Previous session token
    const sessionToken = yield call([Session, 'tokenLoad'])
    if (logoutKey) {
      // No previous or new session.
      yield call(logoutRedirect, services)
    }
    // Auth flows
    else if ([SUCCEED_SSO_URL, FAILED_SSO_URL].includes(pathname)) {
      // SSO flow
      yield call(ssoFlow, services)
    } else if (registrationToken) {
      // Registration Confirmation Flow
      yield call(registrationConfirmationFlow, services, registrationToken)
      // Initialize settings
      yield call(initializeSettings, services)
    } else if (queryToken) {
      const location = yield select(state => state.router.location) // @TODO - Change function to getLocation when test supports browser history
      // NL Autologin Flow

      yield call(
        nlLoginFlow,
        services,
        queryToken,
        undefined,
        undefined,
        location
      )
      // Initialize settings
      yield call(initializeSettings, services)
    } else if (loginToken) {
      // Passwordless Login Flow
      yield call(emailLoginFlow, services, loginToken)
      // Initialize settings
      yield call(initializeSettings, services)
    } else if (internalToken) {
      // Passwordless Login Flow
      yield call(internalTokenFlow, services, internalToken)
      // Initialize settings
      yield call(initializeSettings, services)
    } else if (sessionToken) {
      // Previous session. Open session

      yield call(previousSession, services, sessionToken)
      // Update settings
      yield call(initializeSettings, services)
    } else {
      // No previous or new session.
      yield call(anonFlow, services)
    }

    // AutoTrack
    // Manage display of the breaking news notice
    yield all([
      call([Piwik, 'autoTrack'], autoTrack()),
      call(noticeHandler, services)
    ])
    // Initialize Datalist Dynamic Config
    yield put(configActions.initialConfigRequest())
    yield take('INITIAL_CONFIG_SUCCESS')

    // Initialize Prestitial Ad Request
    yield put(adActions.prestitialAdRequest())
    yield take('PRESTITIAL_AD__FINISHED')

    yield put(actions.successBootstrap())
  } catch (e) {
    console.error(e)
    yield put(configActions.initialConfigRequest())
    yield put(logPush('Bootstrap', `Error while initializing: ${e.message}`))
    if (
      e instanceof FW5MLError &&
      e.getCode() === REGISTRATION_ALREADY_CONFIRMED
    ) {
      yield put(
        notificationsEnqueue({
          message: 'Your account has already been verified, please sign in'
        })
      )
      yield put(replace('/sign-in'))
    } else {
      yield put(push('/contact-us'))
      yield put(
        notificationsEnqueue({
          message: (
            <Fragment>
              Something went wrong! <RoutedLink label="Contact us" />
            </Fragment>
          ),
          duration
        })
      )
    }
    yield call([Piwik, 'track'], 'notification', 'error', e.message)
    yield put(actions.errorBootstrap(e.message))
  }
}

function* noticeHandler(services) {
  const Session = services('Session')
  let savedNoticeVisibility = yield call(
    [Session, 'getCookie'],
    'USER_NOTICE_DISPLAY'
  )
  let savedNoticeHash = yield call([Session, 'getCookie'], 'USER_NOTICE_HASH')
  let noticeVisibility =
    savedNoticeVisibility || savedNoticeVisibility === undefined
  const currentNoticeHash = stringHash(noticeContent)
  if (savedNoticeHash !== currentNoticeHash) {
    yield call([Session, 'setCookie'], 'USER_NOTICE_HASH', currentNoticeHash)
    noticeVisibility = true
  }
  yield put(actions.setNoticeVisibility(noticeVisibility))
}

export function* initializeSettings(services) {
  yield call(requestAccountSettingsLoadFromCache, services)
  yield put(requestAccountSettingsLoad())
}

/**
 * Logs in using a token sent via query string in "access_token"
 * from a link in a login email.
 */
export function* emailLoginFlow(services, token, captchaToken) {
  const UserRepository = services('UserRepository')
  const UserParser = services('UserParser')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const Device = services('Device')
  const LogRocketService = services('LogRocketService')
  const PhoenixAuthService = services('PhoenixAuthService')
  const ClarityService = services('ClarityService')

  try {
    const {
      access_token,
      data: { profile }
    } = yield call(
      [UserRepository, 'passwordlessLoginAuth'],
      token,
      captchaToken
    )

    yield call([Session, 'tokenSave'], access_token, tokenTypes.TOKEN_TYPE_FULL)
    if (profile) {
      const user = yield call([UserParser, 'parseUser'], profile)
      yield call([LogRocketService, 'identify'], user)
      yield call([UserRepository, 'initializeUserflow'], user)

      let did = yield call([Session, 'getCookie'], 'PSL_DEVICE_ID')
      if (!did) {
        did = yield call([Device, 'trackInfo'], user)
      }

      if (did) {
        yield call([Piwik, 'updateCustomVariable'], 'psl_device_id', did)
        yield call([Session, 'setCookie'], 'PSL_DEVICE_ID', did)
      }
      if (user?.premiumAccountId) {
        // Get the premium account
        const consultantResponse = yield call([
          UserRepository,
          'getPremiumAccountConsultant'
        ])

        if (consultantResponse) {
          const consultant = consultantResponse?.data
          if (consultant?.active) {
            user.premiumAccountConsultant = consultant
          }
        }
      }
      // Save profile newsletter settings
      if (profile.newsletter_settings) {
        yield put(
          setNewsletterSettings({
            newsletterSettings: profile.newsletter_settings
          })
        )
      }

      if (user?.party_id) {
        const phoenixTokenRes = yield call(
          [PhoenixAuthService, 'getPhoenixToken'],
          tokenGetEndpoint,
          {
            partyId: user.party_id
          }
        )
        yield call(
          [Session, 'tokenSave'],
          access_token,
          tokenTypes.TOKEN_TYPE_FULL,
          phoenixTokenRes?.access
        )
      }
      yield call([Session, 'userSave'], user)
      yield call(
        [ClarityService, 'setCustomIds'],
        user?.party_id || user?.profile_id,
        user?.subscription_type
      )
    }
    // Always set member_id to 1. User actual id will be stored in site_uid
    yield call([Piwik, 'updateCustomVariable'], 'member_id', 1)
    yield call([Piwik, 'updateCustomVariable'], 'site_uid', profile.profile_id)
    yield call([Piwik, 'track'], 'login', 'login', 'sign-in')
    // current_flow cookie validation
    yield call(currentFlowRedirect, services, null)
  } catch (e) {
    // Previous session token
    const sessionToken = yield call([Session, 'tokenLoad'])
    if (sessionToken) {
      // Previous session. Open session
      yield call(previousSession, services, sessionToken)
      // Update settings
      yield call(initializeSettings, services)
    } else {
      yield call([Piwik, 'track'], 'login', 'login', 'failed')
      // Use an anon token
      if (e instanceof FW5MLError) {
        const code = e.getCode()
        if ([TOKEN_EXPIRED, ACCESS_DENIED].includes(code)) {
          yield call([Session, 'destroySession'])
          yield put(
            notificationsEnqueue({
              message: (
                <Fragment>
                  Your link to sign in to the site expired. Please request a new
                  one by <RoutedLink label="clicking here" to="/sign-in" />.
                </Fragment>
              )
            })
          )
          yield call(anonFlow, services)
          return
        } else if ([DEVICE_MISMATCH, DEVICE_UNKNOWN].includes(code)) {
          yield call(anonFlow, services)
          const location = yield select(getLocation)
          const { pathname } = location
          yield put(
            replace(`/device-sign-in?r=${encodeURIComponent(pathname)}`)
          )
          return
        } else if (code === DEVICE_LIMIT_REACHED) {
          yield call([Session, 'destroySession'])
          yield call(anonFlow, services)
          yield put(push('/device-limit'))
          yield put(
            notificationsEnqueue({
              message: (
                <Fragment>
                  You have reached the maximum number of devices on your
                  account. Please <RoutedLink label="contact us" /> for further
                  information.
                </Fragment>
              ),
              duration
            })
          )
          yield call([Piwik, 'track'], 'notification', 'error', 'device-limit')
          return
        } else if (code === CAPTCHA_VERIFICATION_REQUIRED) {
          yield call([Session, 'destroySession'])
          yield call(anonFlow, services)
          // Set captcha search state
          yield put(setCaptchaLoginMeta({ token, type: LOGIN_TOKEN }))
          // Redirect to captcha form
          yield put(replace(`/captcha`))
          return
        } else if ([DEVICE_UNKNOWN_CONFIRMATION_REQUIRED].includes(code)) {
          yield call(anonFlow, services)
          yield put(replace('/confirmation-email-sent'))
          return
        }
      }
      throw e
    }
  }
}

/**
 * Logs in using a token sent via query string in "register_token"
 * from a link in a registration confirmation email.
 */
function* registrationConfirmationFlow(services, token) {
  const UserRepository = services('UserRepository')
  const UserParser = services('UserParser')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const Device = services('Device')
  const LogRocketService = services('LogRocketService')
  const PhoenixAuthService = services('PhoenixAuthService')
  const ClarityService = services('ClarityService')
  try {
    const {
      access_token,
      data: { profile, did }
    } = yield call([UserRepository, 'confirmRegistration'], token)
    yield call([Session, 'tokenSave'], access_token, tokenTypes.TOKEN_TYPE_FULL)
    if (profile) {
      const user = yield call([UserParser, 'parseUser'], profile)
      yield call([LogRocketService, 'identify'], user)
      yield call([UserRepository, 'initializeUserflow'], user)

      let did = yield call([Session, 'getCookie'], 'PSL_DEVICE_ID')
      if (!did) {
        did = yield call([Device, 'trackInfo'], user)
      }

      if (did) {
        yield call([Piwik, 'updateCustomVariable'], 'psl_device_id', did)
        yield call([Session, 'setCookie'], 'PSL_DEVICE_ID', did)
      }
      if (user?.premiumAccountId) {
        // Get the premium account
        const consultantResponse = yield call([
          UserRepository,
          'getPremiumAccountConsultant'
        ])

        if (consultantResponse) {
          const consultant = consultantResponse?.data
          if (consultant?.active) {
            user.premiumAccountConsultant = consultant
          }
        }
      }
      // Save profile newsletter settings
      if (profile.newsletter_settings) {
        yield put(
          setNewsletterSettings({
            newsletterSettings: profile.newsletter_settings
          })
        )
      }

      if (user?.party_id) {
        //Authenticate to phoenix
        const phoenixTokenRes = yield call(
          [PhoenixAuthService, 'getPhoenixToken'],
          tokenGetEndpoint,
          {
            partyId: user.party_id
          }
        )
        yield call(
          [Session, 'tokenSave'],
          access_token,
          tokenTypes.TOKEN_TYPE_FULL,
          phoenixTokenRes?.access
        )
      }
      yield call([Session, 'userSave'], user)
      yield call(
        [ClarityService, 'setCustomIds'],
        user?.party_id || user?.profile_id,
        user?.subscription_type
      )

      yield put(
        configActions.addConfigRequest({
          key: 'newsletter_promo_visibility',
          data: { visible: true }
        })
      )

      // current_flow cookie validation
      const registerRedirect = '/register-newsletter-subscribe'
      yield call(currentFlowRedirect, services, registerRedirect)
    }
    // Always set member_id to 1. User actual id will be stored in site_uid
    yield call([Piwik, 'updateCustomVariable'], 'member_id', 1)
    yield call([Piwik, 'updateCustomVariable'], 'site_uid', profile.profile_id)
    if (did) {
      yield call([Piwik, 'updateCustomVariable'], 'psl_device_id', did)
      yield call([Session, 'setCookie'], 'PSL_DEVICE_ID', did)
    }
    yield call([Piwik, 'track'], 'register', 'confirm', 'confirmed')
    let registerMessage = 'Your registration has been confirmed.'
    if (siteName === 'Edge') {
      if (profile.subscription.type === 'FirstWord Edge Oncology') {
        registerMessage =
          'Thank you for verifying your email. You are now registered to FirstWord Edge Oncology.'
      } else {
        registerMessage =
          'Thank you for verifying your email. A member of our team will reach out to you shortly.'
      }
    }
    yield put(notificationsEnqueue({ message: registerMessage }))
    yield call([Piwik, 'track'], 'notification', 'message', registerMessage)
  } catch (e) {
    // Previous session token
    const sessionToken = yield call([Session, 'tokenLoad'])
    if (sessionToken) {
      // Previous session. Open session
      yield call(previousSession, services, sessionToken)
      // Update settings
      yield call(initializeSettings, services)
    } else {
      yield call([Piwik, 'track'], 'register', 'confirm', 'failed')
      // Use an anon token
      if (
        e instanceof FW5MLError &&
        (e.getCode() === TOKEN_EXPIRED ||
          e.getCode() === REGISTRATION_ALREADY_CONFIRMED)
      ) {
        yield call([Session, 'destroySession'])
        yield call(anonFlow, services)
      }
      throw e
    }
  }
}

/**
 * Logs in using a token sent via query string in "t" from a link
 * in a Newsletter piece
 */
export function* nlLoginFlow(
  services,
  token,
  captchaToken,
  { nlEventId: storedNlEventId, nlCampaignId: storedNlCampaignId } = {},
  redirect
) {
  const UserRepository = services('UserRepository')
  const UserParser = services('UserParser')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const queryString = services('queryString')
  const Device = services('Device')
  const LogRocketService = services('LogRocketService')
  const PhoenixAuthService = services('PhoenixAuthService')
  const ClarityService = services('ClarityService')

  const nlEventId = storedNlEventId || queryString.get('nle', null, true)
  const nlCampaignId = storedNlCampaignId || queryString.get('nlc', null, true)

  // New Login flow could redirect user to new device confimation page
  // we need to send the pathname to the auth endpoint.
  const metadata = { redirection: redirect }
  // Auth endpoint now can receive session token
  // Will return same session if it matches with sent token
  // Otherwise it will use the new token session to authenticate
  const sessionToken = yield call([Session, 'tokenLoad'])
  let access_token = null

  try {
    const cachedDeviceId = yield call(
      [Session, 'getCookie'],
      'PSL_DEVICE_ID',
      false
    )

    let profile

    if (sessionToken) {
      access_token = sessionToken
      const { data } = yield call([UserRepository, 'getProfile'])
      profile = data
    } else {
      const response = yield call(
        [UserRepository, 'auth'],
        token,
        cachedDeviceId,
        captchaToken,
        metadata,
        sessionToken
      )
      const data = response.data
      access_token = response.access_token
      profile = data.profile
      yield call(
        [Session, 'tokenSave'],
        access_token,
        tokenTypes.TOKEN_TYPE_FULL
      )
    }

    if (profile) {
      const user = yield call([UserParser, 'parseUser'], profile)
      yield call([LogRocketService, 'identify'], user)
      yield call([UserRepository, 'initializeUserflow'], user)
      let did = yield call([Session, 'getCookie'], 'PSL_DEVICE_ID')
      if (!did) {
        did = yield call([Device, 'trackInfo'], user)
      }

      if (did) {
        yield call([Piwik, 'updateCustomVariable'], 'psl_device_id', did)
        yield call([Session, 'setCookie'], 'PSL_DEVICE_ID', did)
      }
      if (user?.premiumAccountId) {
        // Get the premium account
        const consultantResponse = yield call([
          UserRepository,
          'getPremiumAccountConsultant'
        ])

        if (consultantResponse) {
          const consultant = consultantResponse?.data
          if (consultant?.active) {
            user.premiumAccountConsultant = consultant
          }
        }
      }
      // Save profile newsletter settings
      if (profile.newsletter_settings) {
        yield put(
          setNewsletterSettings({
            newsletterSettings: profile.newsletter_settings
          })
        )
      }

      if (user?.party_id) {
        //Authenticate to phoenix
        const phoenixTokenRes = yield call(
          [PhoenixAuthService, 'getPhoenixToken'],
          tokenGetEndpoint,
          {
            partyId: user.party_id
          }
        )
        yield call(
          [Session, 'tokenSave'],
          access_token,
          tokenTypes.TOKEN_TYPE_FULL,
          phoenixTokenRes?.access
        )
      }
      yield call([Session, 'userSave'], user)
      yield call(
        [ClarityService, 'setCustomIds'],
        user?.party_id || user?.profile_id,
        user?.subscription_type
      )
    }
    // Always set member_id to 1. User actual id will be stored in site_uid
    yield call([Piwik, 'updateCustomVariable'], 'member_id', 1)
    yield call([Piwik, 'updateCustomVariable'], 'site_uid', profile.profile_id)

    if (nlEventId) {
      yield call([Piwik, 'updateCustomVariable'], 'event_id', nlEventId)
    }
    if (nlCampaignId) {
      yield call([Piwik, 'updateCustomVariable'], 'project_id', nlCampaignId)
    }

    // OneClickSubscribe Campaign Flow Validation
    const campaign_id = queryString.get('sub_to_id', null, true)
    const from_campaign_id = queryString.get('sub_from_id', null, true)
    const campaign_uuid = queryString.get('sub_to_uuid', null, true)
    const event_id = queryString.get('event_id', null, true)

    if (campaign_id && from_campaign_id && campaign_uuid && event_id) {
      yield call(
        oneClickSubscribe,
        services,
        campaign_id,
        from_campaign_id,
        campaign_uuid,
        event_id
      )
    } else if (redirect) {
      if (typeof redirect === 'object') {
        // Redirection comes from ML response
        const { pathname, search } = redirect
        yield put(
          replace({ pathname, search: queryString.removeKey('t', search) })
        )
      } else {
        // Redirection comes from NL url
        yield put(replace(redirect))
      }
    }
    yield call([Piwik, 'track'], 'login', 'nl-autologin', 'sign-in')
    yield put(piwikActions.newsletterPiwikRequest(newsletterTracking))
  } catch (e) {
    yield call([Piwik, 'track'], 'login', 'nl-autologin', 'failed')
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if ([TOKEN_EXPIRED, ACCESS_DENIED].includes(code)) {
        yield call([Session, 'destroySession'])
        yield put(
          notificationsEnqueue({
            message: (
              <Fragment>
                Your link to sign in to the site expired. Please request a new
                one by <RoutedLink label="clicking here" to="/sign-in" />.
              </Fragment>
            )
          })
        )
        yield call(anonFlow, services)
        return
      } else if ([DEVICE_MISMATCH, DEVICE_UNKNOWN].includes(code)) {
        yield call(anonFlow, services)
        const location = yield select(getLocation)
        const { pathname } = location
        yield put(replace(`/device-sign-in?r=${encodeURIComponent(pathname)}`))
        return
      } else if (code === CAPTCHA_VERIFICATION_REQUIRED) {
        yield call([Session, 'destroySession'])
        yield call(anonFlow, services)
        // Get redirect pathname
        const location = yield select(state => state.router.location)
        // Set captcha search state
        yield put(
          setCaptchaLoginMeta({
            token,
            type: NL_TOKEN,
            redirect: location,
            nlEventId,
            nlCampaignId
          })
        )
        // Redirect to captcha form
        yield put(replace('/captcha'))
        return
      } else if ([DEVICE_UNKNOWN_CONFIRMATION_REQUIRED].includes(code)) {
        yield call(anonFlow, services)
        yield put(replace('/confirmation-email-sent'))
        return
      }
    }
    throw e
  }
}

/**
 * SSO login flow
 */
export function* ssoFlow(services) {
  const Session = services('Session')
  const queryString = services('queryString')
  const UserParser = services('UserParser')
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const Device = services('Device')
  const LogRocketService = services('LogRocketService')
  const location = yield select(state => state.router.location) // @TODO - Change function to getLocation when test supports browser history
  const { pathname } = location
  const redirect = queryString.get('relay_state') || '/'
  const PhoenixAuthService = services('PhoenixAuthService')
  const ClarityService = services('ClarityService')

  if (pathname === SUCCEED_SSO_URL) {
    // SSO succeded, save access token

    const access_token = queryString.get('atk')
    yield call([Session, 'tokenSave'], access_token, tokenTypes.TOKEN_TYPE_FULL)
    const { data: profile } = yield call([UserRepository, 'getProfile'])
    if (profile) {
      const user = yield call([UserParser, 'parseUser'], profile)
      yield call([LogRocketService, 'identify'], user)
      yield call([UserRepository, 'initializeUserflow'], user)
      yield call([Session, 'userSave'], user)
      if (user?.party_id) {
        const phoenixTokenRes = yield call(
          [PhoenixAuthService, 'getPhoenixToken'],
          tokenGetEndpoint,
          {
            partyId: user.party_id
          }
        )
        yield call(
          [Session, 'tokenSave'],
          access_token,
          tokenTypes.TOKEN_TYPE_FULL,
          phoenixTokenRes?.access
        )
      }
      let did = yield call([Session, 'getCookie'], 'PSL_DEVICE_ID')

      if (!did) {
        did = yield call([Device, 'trackInfo'], user)
      }

      if (did) {
        yield call([Piwik, 'updateCustomVariable'], 'psl_device_id', did)
        yield call([Session, 'setCookie'], 'PSL_DEVICE_ID', did)
      }
      yield call(
        [ClarityService, 'setCustomIds'],
        user?.party_id || user?.profile_id,
        user?.subscription_type
      )
    }
    yield call([Piwik, 'track'], 'login', 'sso-login', 'succeded')
    let searchApiCookie = yield call(
      [Session, 'getCookie'],
      'search_api_cookie'
    )
    /* The above code is checking if the `searchApiCookie` variable exists and is not an empty string.
    If it does exist and is not empty, it uses the `put` function to dispatch an action to replace
    the value of `searchApiCookie`. */
    if (searchApiCookie && searchApiCookie !== '') {
      yield put(replace(searchApiCookie))
      return
    }

    yield call(currentFlowRedirect, services, redirect)
  } else if (pathname === FAILED_SSO_URL) {
    // SSO failed, display error and start anon flow
    const errorMessage = queryString.get('error_msg').replace('+', ' ')
    yield call([Piwik, 'track'], 'login', 'sso-login', 'failed')
    yield call(anonFlow, services)
    yield put(
      notificationsEnqueue({
        message: <Fragment>{errorMessage}</Fragment>,
        duration
      })
    )
    if (redirect !== '/') {
      yield put(replace(redirect))
    } else {
      yield put(replace('/sign-in'))
    }
  }
}

/**
 * Logs in using a token sent via query string in "it"
 * from a link in the other site
 */
export function* internalTokenFlow(services, token) {
  const Session = services('Session')
  const UserRepository = services('UserRepository')
  const UserParser = services('UserParser')
  const Piwik = services('Piwik')
  const Device = services('Device')
  const LogRocketService = services('LogRocketService')
  const PhoenixAuthService = services('PhoenixAuthService')
  const ClarityService = services('ClarityService')
  try {
    yield call([Session, 'tokenSave'], token, tokenTypes.TOKEN_TYPE_FULL)
    const { data: profile } = yield call([UserRepository, 'getProfile'])

    if (profile) {
      const user = yield call([UserParser, 'parseUser'], profile)
      yield call([LogRocketService, 'identify'], user)
      yield call([UserRepository, 'initializeUserflow'], user)
      let did = yield call([Session, 'getCookie'], 'PSL_DEVICE_ID')
      if (!did) {
        did = yield call([Device, 'trackInfo'], user)
      }

      if (did) {
        yield call([Piwik, 'updateCustomVariable'], 'psl_device_id', did)
        yield call([Session, 'setCookie'], 'PSL_DEVICE_ID', did)
      }
      if (user?.premiumAccountId) {
        // Get the premium account
        const consultantResponse = yield call([
          UserRepository,
          'getPremiumAccountConsultant'
        ])

        if (consultantResponse) {
          const consultant = consultantResponse?.data
          if (consultant?.active) {
            user.premiumAccountConsultant = consultant
          }
        }
      }

      // Save profile newsletter settings
      if (profile.newsletter_settings) {
        yield put(
          setNewsletterSettings({
            newsletterSettings: profile.newsletter_settings
          })
        )
      }

      if (user?.party_id) {
        //Authenticate to phoenix
        const phoenixTokenRes = yield call(
          [PhoenixAuthService, 'getPhoenixToken'],
          tokenGetEndpoint,
          {
            partyId: user.party_id
          }
        )
        yield call(
          [Session, 'tokenSave'],
          token,
          tokenTypes.TOKEN_TYPE_FULL,
          phoenixTokenRes?.access
        )
      }

      yield call([Session, 'userSave'], user)
      yield call(
        [ClarityService, 'setCustomIds'],
        user?.party_id || user?.profile_id,
        user?.subscription_type
      )

      // User actual id will be stored in site_uid
      yield call(
        [Piwik, 'updateCustomVariable'],
        'site_uid',
        profile?.profile_id
      )
    }
    // Always set member_id to 1.
    yield call([Piwik, 'updateCustomVariable'], 'member_id', 1)
    yield call([Piwik, 'track'], 'login', 'internal-login', 'sign-in')

    // current_flow cookie validation
    yield call(currentFlowRedirect, services, null)
  } catch (e) {
    // Previous session token
    const sessionToken = yield call([Session, 'tokenLoad'])
    if (sessionToken) {
      // Previous session. Open session
      yield call(previousSession, services, sessionToken)
      // Update settings
      yield call(initializeSettings, services)
    } else {
      yield call([Piwik, 'track'], 'login', 'internal-login', 'failed')
      if (e instanceof FW5MLError) {
        yield call([Session, 'destroySession'])
        yield call(anonFlow, services)
        return
      }
      throw e
    }
  }
}

/**
 * Authenticates the FE Client to call other endpoints
 */

export function* anonFlow(services) {
  const UserRepository = services('UserRepository')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const PhoenixAuthService = services('PhoenixAuthService')
  const { access_token: token } = yield call([UserRepository, 'auth'])

  const phoenixTokenRes = yield call(
    [PhoenixAuthService, 'getPhoenixToken'],
    tokenGetEndpoint,
    {}
  )

  yield call(
    [Session, 'tokenSave'],
    token,
    tokenTypes.TOKEN_TYPE_ANON,
    phoenixTokenRes?.access
  )
  yield call([Session, 'invalidateSession'])
  yield call([Piwik, 'updateCustomVariable'], 'member_id', 1)
  yield call([Piwik, 'updateCustomVariable'], 'site_uid', 0)
  yield call([UserRepository, 'initializeUserflow'], null)
}

/**
 * not you? register here! flow
 */
export function* logoutRedirect(services) {
  const Session = services('Session')
  const ClarityService = services('ClarityService')
  const queryString = services('queryString')
  const redirect = queryString.get('redirect') || 'register'
  yield call([Session, 'destroySession'])
  yield call(anonFlow, services)
  yield call([ClarityService, 'resetCustomIds'])
  yield put(replace(redirect))
}

/**
 * Old session refresh
 */
function* previousSession(services, token) {
  const UserRepository = services('UserRepository')
  const UserParser = services('UserParser')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const user = yield call([Session, 'userLoad'])
  const Device = services('Device')
  const LogRocketService = services('LogRocketService')
  const PhoenixAuthService = services('PhoenixAuthService')
  const ClarityService = services('ClarityService')
  if (user) {
    // Previous session was a fully auth-ed user.
    const { data: profile } = yield call([UserRepository, 'getProfile'])
    // Set piwik custom var to the user's profile_id
    yield call([Piwik, 'updateCustomVariable'], 'site_uid', user.profile_id)
    // Always set member_id to 1. User actual id will be stored in site_uid
    yield call([Piwik, 'updateCustomVariable'], 'member_id', 1)
    // Update user session
    if (profile) {
      yield call([Session, 'tokenSave'], token, tokenTypes.TOKEN_TYPE_FULL)
      const user = yield call([UserParser, 'parseUser'], profile)
      yield call([LogRocketService, 'identify'], user)
      yield call([UserRepository, 'initializeUserflow'], user)
      let did = yield call([Session, 'getCookie'], 'PSL_DEVICE_ID')
      if (!did) {
        did = yield call([Device, 'trackInfo'], user)
      }

      if (did) {
        yield call([Piwik, 'updateCustomVariable'], 'psl_device_id', did)
        yield call([Session, 'setCookie'], 'PSL_DEVICE_ID', did)
      }
      if (user?.premiumAccountId) {
        // Get the premium account
        const consultantResponse = yield call([
          UserRepository,
          'getPremiumAccountConsultant'
        ])

        if (consultantResponse) {
          const consultant = consultantResponse?.data
          if (consultant?.active) {
            user.premiumAccountConsultant = consultant
          }
        }
      }
      // Save profile newsletter settings
      if (profile.newsletter_settings) {
        yield put(
          setNewsletterSettings({
            newsletterSettings: profile.newsletter_settings
          })
        )
      }

      if (user?.party_id) {
        //Authenticate to phoenix
        const phoenixTokenRes = yield call(
          [PhoenixAuthService, 'getPhoenixToken'],
          tokenGetEndpoint,
          {
            partyId: user.party_id
          }
        )

        yield call(
          [Session, 'tokenSave'],
          token,
          tokenTypes.TOKEN_TYPE_ANON,
          phoenixTokenRes?.access
        )
      }
      yield call(
        [ClarityService, 'setCustomIds'],
        user?.party_id || user?.profile_id,
        user?.subscription_type
      )
      yield call([Session, 'userSave'], user)
    }
  } else {
    // Previous session was client auth only.
    // Refresh auth token by calling anonFlow
    yield call([Session, 'destroySession'])
    yield call([ClarityService, 'resetCustomIds'])
    yield call(anonFlow, services)
  }
}

/**
 * Maintain user choice for home page notice visibility
 */
function* setNoticeVisibility(services, action) {
  const Session = services('Session')
  const { payload: visibility } = action
  yield call([Session, 'setCookie'], 'USER_NOTICE_DISPLAY', visibility)
}

/**
 * Verify that the browser support the storage driver &
 * initialize redux persist session
 */
export function* initializeSessionService(services) {
  const DriverService = services('Driver')
  let sessionServiceOptions = {}

  const localStorage = yield call(DriverService.localStorageSupport)
  if (localStorage) {
    sessionServiceOptions = {
      ...options,
      driver: 'LOCALSTORAGE'
    }
  } else {
    sessionServiceOptions = {
      ...options,
      driver: 'COOKIES'
    }
  }

  sessionService.initSessionService(window.store, sessionServiceOptions)
}

/**
 * Nl One click subscribe flow
 */
function* oneClickSubscribe(
  services,
  campaign_id,
  from_campaign_id,
  campaign_uuid,
  event_id
) {
  const UserRepository = services('UserRepository')
  const NewslettersRepository = services('NewslettersRepository')
  const ocsroute = '/newsletter-subscribe'
  let nls_all = []
  let newsletter = null

  try {
    nls_all = yield call([NewslettersRepository, 'getNewsletters'])
    newsletter = yield call(
      [NewslettersRepository, 'getNewsletterById'],
      nls_all,
      campaign_uuid
    )
    // Add newsletter to user
    yield call(
      [UserRepository, 'insertNewsletterOneClick'],
      campaign_uuid,
      parseInt(event_id),
      parseInt(campaign_id),
      parseInt(from_campaign_id)
    )
    yield put(
      replace(ocsroute, {
        result: 'subscribed',
        nlName: newsletter.name
      })
    )
  } catch (e) {
    console.log(e)
    const code = e.getCode()

    // User is already subscribed
    if (code === PROFILE_ALREADY_SUBSCRIBED && newsletter) {
      yield put(
        replace(ocsroute, {
          result: 'already-subscribed',
          nlName: newsletter.name
        })
      )
      return
    }

    yield put(
      replace(ocsroute, {
        result: 'error',
        errorCode: code
      })
    )
  }
}

function* currentFlowRedirect(services, redirect = null) {
  try {
    const Session = services('Session')
    let currentFlowCookie = yield call([Session, 'getCookie'], 'current_flow')

    // current_flow cookie redirection
    if (currentFlowCookie && currentFlowCookie !== '') {
      const cookieSplit = currentFlowCookie.split('|')
      const route = cookieSplit[0]
      const additionalProps = cookieSplit[1]

      if (route === 'notify-me-subscribe') {
        yield put(replace(`/story/${additionalProps}`))
        return
      }
      yield put(replace(route))
      return
    }

    // Specific redirection in case the is no current_flow cookie defined
    if (redirect) {
      yield put(replace(redirect))
    }
  } catch (e) {
    console.log(e)
  }
}

export default function* watchInitialize(services) {
  yield takeEvery('BOOTSTRAP__REQUEST', initialize, services)
  yield takeEvery(
    'BOOTSTRAP_SET_NOTICE_VISIBILITY',
    setNoticeVisibility,
    services
  )
}
