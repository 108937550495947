import React from 'react'
import PropTypes from 'prop-types'
import StorySpinner from '../StorySpinner'
import commonStyles from '../../styles/common.module.sass'

const View = ({
  view = { stories: [] },
  error = '',
  loading = false,
  render,
  limit
}) => {
  if (loading) return <StorySpinner />
  if (error)
    return (
      <div className={commonStyles.storyError}>
        Error loading the articles: {view.error}
      </div>
    )

  if (view?.stories && view?.stories?.length > 0) {
    return (
      <div>
        {/* This wrapping element enables first-child styling for the excerpts */}
        {view.stories.slice(0, limit).map(render)}
      </div>
    )
  }

  return null
}

View.propTypes = {
  /**
   * The views rendered for this page
   */
  view: PropTypes.shape({
    stories: PropTypes.array
  }),
  error: PropTypes.string,
  loading: PropTypes.bool,
  render: PropTypes.func,
  limit: PropTypes.number
}
export default View
