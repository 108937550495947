import React from 'react'
import styles from '../../SingleStory.module.sass'
import Button from '@mui/material/Button'
import usePiwik from 'src/hooks/usePiwik'
import PropTypes from 'prop-types'

const UpcomingAndAnonymous = ({ openModal, articleId }) => {
  const { handleTrackingWithArticleIdProp } = usePiwik()

  const handleNotifyButtonClick = () => {
    openModal('notify-me', 'notify-me', true)
  }

  const handleContactButtonClick = () => {
    handleTrackingWithArticleIdProp(
      'article',
      'button-click',
      'contact-me-about-this-report',
      articleId
    )
    openModal('contact-me-about-this-report', false, 'contact-me')
  }

  return (
    <>
      <Button
        className={styles.notifyButtonAnon}
        onClick={handleNotifyButtonClick}
        variant="contained"
      >
        Notify me of this report
      </Button>
      <Button
        className={styles.contactButtonAnon}
        onClick={handleContactButtonClick}
        variant="contained"
      >
        Contact me about this report
      </Button>
    </>
  )
}

UpcomingAndAnonymous.propTypes = {
  openModal: PropTypes.func,
  articleId: PropTypes.string
}

export default UpcomingAndAnonymous
