import { takeEvery, call, all, put, select } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { push } from 'connected-react-router'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import upperFirst from 'lodash/upperFirst'
import { notificationDuration as duration } from 'src/constants/index.js'
import React, { Fragment } from 'react'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import RoutedLink from 'src/components/RoutedLink/index.js'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import { logout } from 'src/modules/Session/actions'
import {
  SAVED_SEARCHES_LIMIT_REACHED,
  ACCESS_DENIED
} from 'src/services/FW5ML/errorCodes.js'
import { getConfig } from 'src/modules/Config/selectors'

function* listSearch(services, action) {
  const UserRepository = services('UserRepository')

  try {
    const { data: searches } = yield call([UserRepository, 'listSavedSearches'])
    yield put(actions.successSavedSearchList(searches))
  } catch (e) {
    console.log(e)
    yield put(
      logPush(
        'Saved Searches List',
        `Error while listing saved searches: ${e.message}`
      )
    )
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(actions.errorSavedSearchList({ error: e.message }))
  }
}

function* saveSearch(services, action) {
  const Piwik = services('Piwik')
  try {
    const Session = services('Session')
    const user = yield call([Session, 'userLoad'])
    const { saved_searches = {} } = yield select(
      getConfig('my_account_settings')
    )

    if (
      user &&
      (user.license === licenseTypes.LICENSE_PLUS ||
        saved_searches?.for_all === true)
    ) {
      // Config Validation to know if redirect applies
      const emailNotificationValidation =
        saved_searches?.show_email_notification ?? true
      if (emailNotificationValidation === false) {
        yield call(saveSearchAlert, services, action)
      } else {
        yield put(push('/save-search'))
      }
    } else {
      yield put(
        notificationsEnqueue({
          message: (
            <Fragment>
              Your subscription does not allow for saved searches.{' '}
              <RoutedLink label="Contact us" /> to find out more.
            </Fragment>
          ),
          duration
        })
      )
      yield call(
        [Piwik, 'track'],
        'notification',
        'error',
        'user-attempted-save-search-when-not-registered'
      )
      yield put(
        actions.errorSavedSearchSave(
          'Your subscription does not allow for saved searches'
        )
      )
    }
  } catch (e) {
    console.log(e)
  }
}

function* deleteSearch(services, action) {
  const UserRepository = services('UserRepository')
  const Piwik = services('Piwik')
  const {
    payload: { search_id: id, name }
  } = action
  try {
    yield call([UserRepository, 'deleteSavedSearch'], id)
    yield put(actions.successSavedSearchDelete(id))
    yield call([Piwik, 'track'], 'account', 'update', 'remove-saved-search', {
      name
    })
    yield put(
      notificationsEnqueue({
        message: `${upperFirst(
          name
        )} has been removed from your saved searches.`,
        duration
      })
    )
  } catch (e) {
    yield put(
      logPush(
        'Saved Searches Delete',
        `Error while deleting a saved search: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message: `Unable to remove ${name}. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-remove-saved-search_${name}`
    )
    yield put(actions.errorSavedSearchDelete({ id, error: e.message }))
  }
}

function* saveSearchAlert(services, action) {
  const Piwik = services('Piwik')
  try {
    const UserRepository = services('UserRepository')
    const savedSearch = yield select(state => state.mySavedSearches.saving)
    const { name, search } = savedSearch[0]
    const { search_id } = search
    const { alert } = action.payload
    if (search_id) {
      // Call Update endpoint
      yield call(
        [UserRepository, 'updateSavedSearch'],
        search_id,
        name,
        search,
        alert
      )
      yield put(
        notificationsEnqueue({
          message: `${name} has been updated.`,
          duration
        })
      )
    } else {
      yield call([UserRepository, 'insertSavedSearch'], name, search, alert)
      yield put(
        notificationsEnqueue({
          message: `${name} has been saved to your saved searches.`,
          duration
        })
      )
    }
    yield put(push('/my-account/searches'))
    yield put(actions.successSavedSearchSave(name))
    yield put(actions.successSaveSearchAlert())
    yield call([Piwik, 'track'], 'account', 'update', 'add-saved-search', {
      name
    })
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === SAVED_SEARCHES_LIMIT_REACHED) {
        yield put(
          notificationsEnqueue({
            message: 'You have reached the maximum number of saved searches.'
          })
        )
        yield call(
          [Piwik, 'track'],
          'notification',
          'error',
          'user-has-reached-saved-search-maximum'
        )
        yield put(actions.errorSaveSearchAlert(e.message))
        return
      }
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        yield put(actions.errorSaveSearchAlert(e.message))
        yield put(actions.errorSaveSearchAlert(e.message))
        return
      }
    }
    const savedSearch = yield select(state => state.mySavedSearches.saving)
    const { name } = savedSearch[0]
    yield put(
      notificationsEnqueue({
        message: `Unable to save ${name}. Please refresh the page and try again.`,
        duration
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      `could-not-save-search_${name}`
    )
    yield put(actions.errorSaveSearchAlert(e.message))
  }
}

export default function* watchUpdate(services) {
  yield all([
    takeEvery('SAVED_SEARCH_LIST__REQUEST', listSearch, services),
    takeEvery('SAVED_SEARCH_SAVE__REQUEST', saveSearch, services),
    takeEvery('SAVED_SEARCH_DELETE__REQUEST', deleteSearch, services),
    takeEvery('SAVE_SEARCH_ALERT__REQUEST', saveSearchAlert, services)
  ])
}
