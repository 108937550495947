const paths = [
  {
    test: /^\/$/,
    track: ['page', 'view', 'home']
  },
  // Start Static pages
  {
    test: /^\/(sign-in-email-sent|confirmation-email-sent|register-email-sent|register-newsletter-subscribe-success|register-newsletter-subscribe|device-limit|device-sign-in|captcha|newsletter-subscribe|not-found|log-(out|in))/,
    track: params => ['page', 'view', params?.page]
  },
  {
    test: /^\/(resources|advertise|about|privacy|terms|cookie-policy|contact-us|FirstAnswers|SEEAUKPrivacy|CCPAPrivacyPolicy|fwdealflow|fwbiosimilarindex|marketing-specs|features-and-benefits|whats-new)/,
    track: params => ['page', 'view', params?.page]
  },
  // End Static pages
  {
    test: /^\/perspectives\/(.+)/,
    track: params => ['page', 'view', params?.match?.slice(1)]
  },
  {
    test: /^\/my-account\/(.+)/,
    track: params => ['page', 'view', `my-account_${params?.page}`]
  },
  {
    test: /^\/search\/(.+)/,
    track: params => ['page', 'view', `search_${params?.page}`]
  },
  {
    test: /^\/story\/\d+/, //This one is tracked in the single story saga
    track: null
  },
  {
    test: /^\/benefits/, //This one is tracked in the benefits saga
    track: null
  },
  {
    test: /^\/sign-in/, //This one is tracked in the gated landing page saga
    track: null
  },
  {
    test: /^\/register/, //This one is tracked in the gated landing page saga
    track: null
  },
  {
    test: /^\/consent/, //This one is tracked in the gated landing page saga
    track: null
  },
  {
    test: /^\/(river|stories)\/([^/]+)/, //This one is tracked in the river saga
    track: null
  },
  // Catch all
  {
    test: /.*/,
    track: params => ['page', 'view', params?.match?.slice(1)]
  }
]

export default path => {
  const match = paths.find(currentPath => currentPath.test.test(path))
  if (!match) throw new Error(`Piwik action for path ${path} not found.`)
  if (typeof match.track === 'function') {
    const matchedExpression = path.match(match.test)
    const params = {
      path,
      match: matchedExpression[0],
      page: matchedExpression[1]
    }
    return match.track(params)
  }
  return match.track
}
