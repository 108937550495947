class ConfigRepository {
  constructor(getState) {
    this.getState = getState
  }

  getStateConfig(key) {
    try {
      const state = this.getState()
      return state?.config[key]?.data
    } catch (e) {
      console.log(e)
      return null
    }
  }
}

export default ConfigRepository
