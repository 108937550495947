// React
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useViewProps from 'src/layouts/hooks/useViewProps'
import * as viewsSelectors from 'src/modules/Views/selector'
import ComponentLoopItemMapping from 'src/layouts/components/children/ComponentLoopItemMapping'
import * as viewComponents from './components'
import { mappedComponents } from 'src/layouts/components/children/utils'

const mapping = {
  ...mappedComponents(viewComponents)
}

const ContentViewLoopItem = props => {
  const item = props?.item || null
  const viewProps = useViewProps(props)
  const view = useSelector(viewsSelectors.getCurrentViews)[item?.name] || null
  const viewsError = useSelector(viewsSelectors.getViewsError)
  const viewsLoading = useSelector(viewsSelectors.getViewsLoading)

  return (
    <ComponentLoopItemMapping
      {...props}
      mapping={mapping}
      viewProps={viewProps}
      view={view}
      viewsError={viewsError}
      viewsLoading={viewsLoading}
    />
  )
}

ContentViewLoopItem.propTypes = {
  item: PropTypes.object
}

export default ContentViewLoopItem
