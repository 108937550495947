import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Field } from 'redux-form'
import Button from 'src/components/ui/Buttons/Button.js'
import { Input } from 'src/components/ui/Input/index.js'
import SelectMenu from 'src/components/ui/SelectMenu/index.js'
import Footnote from 'src/components/ui/Footnote/index.js'
import Typography from 'src/components/ui/Typography/index.js'
import styles from './PaymentForm.module.sass'
import Icon from 'src/components/ui/Icon/index.js'
import Spinner from 'src/components/ui/Spinner/index.js'
import StorySpinner from 'src/components/StorySpinner/index.js'
import ReactHtmlParser from 'react-html-parser'
import { getConfig } from 'src/modules/Config/selectors'
import {
  monetaryMap,
  allCountries,
  getMonths,
  getNextYears
} from 'src/utils/index.js'

const months = getMonths()
const years = getNextYears(10)

const PaymentForm = ({
  handleSubmit,
  loading,
  clearCoupon,
  applyCoupon,
  price,
  priceWithDiscount,
  coupon,
  getPrice,
  currency
}) => {
  const [internalCoupon, setInternalCoupon] = useState('')

  const webpageContentConfig = useSelector(getConfig('webpage_content')) ?? {}
  const formDescription =
    webpageContentConfig?.checkout?.paywall?.form_description ?? null

  useEffect(() => {
    getPrice()
  }, [])

  return (
    <div className={styles.formWrapper}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Field component={Input} name="email" label="E-mail (*)" />
        <Field component={Input} name="name" label="Name on card (*)" />
        <Field component={Input} name="number" label="Card number (*)" />
        <div className={styles.half}>
          <Field
            component={SelectMenu}
            label="Month (*)"
            name="exp_month"
            placeholder="Select a month"
            options={months}
          />
          <Field
            component={SelectMenu}
            label="Year (*)"
            name="exp_year"
            placeholder="Select a year"
            options={years}
          />
          <Field component={Input} name="cvc" label="CVC (*)" />
        </div>
        <div className={styles.formCountryOrRegion}>
          <Field
            component={Input}
            label="Full Address (*)"
            name="full_address"
          />
          <div className={styles.formAddress}>
            <Field component={Input} label="City (*)" name="city" />
            <Field
              component={SelectMenu}
              label="Country (*)"
              name="country"
              placeholder="Select a country"
              options={allCountries}
            />
            <Field
              component={Input}
              name="postal_code"
              label="Postal/Zip Code (*)"
            />
          </div>
          <div className={styles.formCouponWrapper}>
            <div className={styles.formCoupon}>
              <Field
                component={Input}
                name="coupon"
                label="Coupon Code"
                disabled={coupon.isApplied}
                className={styles.formCouponTextFiled}
                onChange={e => {
                  if (e?.target?.value) setInternalCoupon(e.target.value)
                }}
              />
              {coupon.isApplied ? (
                <Typography className={styles.formCouponApplied}>
                  Coupon Applied
                  <Icon
                    className={styles.icon}
                    icon="svg/fw/delete"
                    colorTier="secondary"
                    onClick={e => {
                      clearCoupon()
                      e.preventDefault()
                    }}
                    button
                  />
                </Typography>
              ) : (
                <button
                  className={styles.btnApplyCoupon}
                  disabled={coupon.isLoading}
                  onClick={e => {
                    applyCoupon(internalCoupon)
                    e.preventDefault()
                  }}
                >
                  Apply
                  {coupon.isLoading && (
                    <Spinner
                      style={{ position: 'absolute', top: 0, left: '40%' }}
                      size={20}
                      fillColor="transparent"
                      strokeColor="#E27746"
                    />
                  )}
                </button>
              )}
            </div>
            {coupon.error && (
              <Typography gutterBottom className={styles.formCouponError}>
                {coupon.error}
              </Typography>
            )}
          </div>
        </div>

        <Button
          type="submit"
          tier="secondary"
          className={styles.button}
          textCase="none"
          disabled={coupon.isLoading || loading}
        >
          {`Pay ${monetaryMap[currency]} ${priceWithDiscount || price}`}
        </Button>
        <Typography>(*) Required fields</Typography>

        <Footnote textClassName="Footnote-stories-module__footnote___3HH5M">
          {formDescription && ReactHtmlParser(formDescription)}
        </Footnote>
      </form>
      {loading && (
        <div className={styles.loading}>
          <StorySpinner />
        </div>
      )}
    </div>
  )
}

PaymentForm.propTypes = {
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  clearCoupon: PropTypes.func,
  applyCoupon: PropTypes.func,
  price: PropTypes.number,
  priceWithDiscount: PropTypes.number,
  coupon: PropTypes.object,
  getPrice: PropTypes.func,
  currency: PropTypes.string
}

export default PaymentForm
