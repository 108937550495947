import { isValidElement } from 'react'

const isParticularElement = (element, displayNames) => {
  return (
    isValidElement(element) &&
    displayNames.indexOf(element.type.displayName) !== -1
  )
}

export default isParticularElement
