import { createAction, handleActions } from 'redux-actions'

export const setLastArticleId = createAction('BENEFITS_SET_LAST_ARTICLE_ID')

export const paywallRedirectFlow = createAction('PAYWALL_REDIRECT_REQUEST')

export const actions = {
  setLastArticleId,
  paywallRedirectFlow
}

const defaultState = {
  lastArticleId: null
}

export default handleActions(
  {
    BENEFITS_SET_LAST_ARTICLE_ID: (state, { payload }) => ({
      ...state,
      lastArticleId: payload
    })
  },
  defaultState
)
