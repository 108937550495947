import React, { Fragment, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import _get from 'lodash/get'
import ReCAPTCHA from 'react-google-recaptcha'
import Button from '../../../../components/ui/Buttons/Button'
import { Input } from '../../../../components/ui/Input'
import Checkbox from '../../../../components/ui/Checkbox'
import DropDown from '../../../../components/ui/DropDown'
import { arrToObj } from '../../../../utils'
import { audienceTypes } from '../../../../services/FW5ML/parsers/UserParser'
import styles from './RegisterForm.module.sass'
import Typography from '../../../../components/ui/Typography'
import useMediaSize from '../../../../hooks/useMediaSize'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { siteName } from '../../../../utils/oneCodeBase'

const CustomCheckbox = ({ label, info, name }) => {
  return (
    <div className={styles.checkboxContainer}>
      <Field
        component={Checkbox}
        name={name}
        defaultChecked={false}
        children={
          <div className={styles.checkboxDetails}>
            <Typography type="body1" align="left">
              <span className={styles.traditionalLabel}>{label}</span>
              <span className={cx(styles.info, styles.checkboxInfo)}>
                {info}
              </span>
            </Typography>
          </div>
        }
      />
    </div>
  )
}

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string
}

const RegisterForm = ({
  handleLoginLink = () => {},
  newDisplay: display = 'single_column',
  handleSubmit = () => {},
  change,
  busy,
  catalogs = {},
  fromLanding = false,
  parentClassName = null,
  traditional = false,
  extraFields = ['heard_about', 'license'],
  buttonLabel = 'Register',
  emailInfo = 'Use your corporate/work email to make sure you are taking advantage of any benefits your company may have.',
  login = {
    text: 'Already have a FirstWord account?',
    linkText: 'Sign in'
  },
  onSuccess = () => {}
}) => {
  const countryOptions = _get(catalogs, 'country.options', {})
  // const sortedCountryOptions = countryOptions.sort()

  const jobFunctionOptions = _get(catalogs, 'jobFunctions.options', {})
  const heardAboutOptions = _get(catalogs, 'heardAbout.options', {})

  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 600px)',
      medium: '(max-width: 960px)'
    }
  })

  const [otherField, setOtherField] = useState('')
  const recaptchaRef = useRef()
  const handleRecaptcha = value => {
    change('cap', value)
  }
  const handleSubmitWithRecaptcha = async values => {
    if (siteName !== 'Reports') {
      const recaptchaValue = await recaptchaRef.current.executeAsync()
      handleRecaptcha(recaptchaValue)
      values.cap = recaptchaValue
      recaptchaRef.current.reset()
    }

    if (onSuccess) {
      onSuccess(values)
    }
  }
  return (
    <form
      onSubmit={handleSubmit(handleSubmitWithRecaptcha)}
      className={cx(
        parentClassName,
        fromLanding ? styles.formFromLanding : styles.form
      )}
    >
      <Field
        component={Input}
        label="First Name"
        name="first_name"
        errorAlign="center"
        traditional={traditional}
      />
      <Field
        component={Input}
        label="Last Name"
        name="last_name"
        errorAlign="center"
        traditional={traditional}
      />
      <div>
        <Field
          component={Input}
          label="Work Email"
          name="email"
          errorAlign="center"
          type="email"
          traditional={traditional}
        />
        {emailInfo && (
          <Typography type="body1" align="center" className={styles.info}>
            {emailInfo}
          </Typography>
        )}
      </div>
      <Field
        className={styles.select}
        component={DropDown}
        label="Country"
        options={countryOptions}
        name="country"
        errorAlign="center"
        autoComplete="disable" // Using unsupported autocomplete value to disable Chrome autocomplete
        traditional={traditional}
      />
      <Field
        component={Input}
        label="Company"
        name="company"
        errorAlign="center"
        traditional={traditional}
      />
      <Field
        component={Input}
        label="Job Title"
        name="job_title"
        errorAlign="center"
        traditional={traditional}
      />
      <Field
        className={styles.select}
        component={DropDown}
        label="Job Function"
        options={jobFunctionOptions}
        name="job_function"
        errorAlign="center"
        autoComplete="disable"
        traditional={traditional}
      />
      <Field
        component={Input}
        label="Phone Number"
        name="phone_number"
        errorAlign="center"
        traditional={traditional}
      />
      {extraFields.includes('license') && mediaSize !== 'small' && (
        <Field
          component={DropDown}
          label="License"
          options={arrToObj([audienceTypes.FREE])}
          name="license"
          errorAlign="center"
          readOnly
        />
      )}
      {extraFields.includes('heard_about') && (
        <>
          <Field
            className={styles.select}
            component={DropDown}
            label="Where did you hear about us?"
            onChange={value => setOtherField(value)}
            options={heardAboutOptions}
            name="heard_about"
            errorAlign="center"
            autoComplete="disable"
            traditional={traditional}
          />
          {otherField === 'other' ? (
            <Fragment>
              {fromLanding && mediaSize === 'large' ? null : (
                <div className={styles.fieldReplacer}></div>
              )}
              <Field
                component={Input}
                label="If Other, Please Specify"
                name="other"
                errorAlign="center"
                traditional={traditional}
              />
              {fromLanding && mediaSize === 'large' ? null : (
                <div className={styles.fieldReplacer}></div>
              )}
            </Fragment>
          ) : (
            <div className={styles.fieldReplacer}></div>
          )}
        </>
      )}
      {extraFields.includes('license') && mediaSize === 'small' && (
        <Field
          component={DropDown}
          label="License"
          options={arrToObj([audienceTypes.FREE])}
          name="license"
          errorAlign="center"
          readOnly
          traditional={traditional}
        />
      )}
      <div className={styles.registerCheckboxes}>
        {extraFields.includes('register_pharma') && (
          <CustomCheckbox
            name="register_pharma"
            label="Register to FirstWord Pharma"
            info="Free pharma news and analysis delivered every weekday"
          />
        )}
        {extraFields.includes('register_healthtech') && (
          <CustomCheckbox
            name="register_healthtech"
            label="Register to FirstWord HealthTech"
            info="Free digital health news and analysis delivered every weekday"
          />
        )}
        {extraFields.includes('register_reports') && (
          <CustomCheckbox
            name="register_reports"
            label="Register to FirstWord Reports"
            info="Reports delivered every weekday"
          />
        )}
        {/* EdgeFlag
        {extraFields.includes('register_edge') && (
          <CustomCheckbox
            name="register_edge"
            label="Enquire about FirstWord Edge"
            info="Agenda-setting daily oncology intelligence service for pharma professionals"
          />
        )} */}
      </div>
      {display === 'double_column' && (
        <Typography
          type="body1"
          align="center"
          className={styles.info}
          style={{ textAlign: 'center' }}
        >
          {login.text}{' '}
          <Link to="" className={styles.link} onClick={handleLoginLink}>
            {login.linkText}
          </Link>
        </Typography>
      )}
      <Field
        name="cap"
        component={() => null} // no need to render anything
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        onChange={handleRecaptcha}
      />
      <div className={styles.submitBtn}>
        <Button
          type="submit"
          tier="secondary"
          submitting={busy}
          className={styles.button}
          hasSpinner
        >
          {buttonLabel}
        </Button>
      </div>
    </form>
  )
}

RegisterForm.propTypes = {
  handleLoginLink: PropTypes.func,
  newDisplay: PropTypes.string,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  busy: PropTypes.bool,
  catalogs: PropTypes.object,
  fromLanding: PropTypes.bool,
  parentClassName: PropTypes.string,
  traditional: PropTypes.bool,
  extraFields: PropTypes.arrayOf(PropTypes.string),
  buttonLabel: PropTypes.string,
  emailInfo: PropTypes.string,
  login: PropTypes.shape({
    text: PropTypes.string,
    linkText: PropTypes.string
  }),
  onSuccess: PropTypes.func
}

export default RegisterForm
