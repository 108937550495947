import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import SectionHeading from '../SectionHeading'
import styles from './StoryRiver.module.sass'

const StoryRiver = ({ children, title, className }) => (
  <div className={cx(styles.container, className)}>
    {title && <SectionHeading title={title} />}
    {children}
  </div>
)

StoryRiver.propTypes = {
  children: PropTypes.node,
  title: PropTypes.any,
  className: PropTypes.string
}

export default StoryRiver
