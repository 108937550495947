import { createReducer, createAction } from '@reduxjs/toolkit'

const STACK_SIZE = 5
export const successSingleStory = createAction('SINGLE_STORY__SUCCESS')
// TODO: Add a error reducer for this guy
export const errorSingleStory = createAction('SINGLE_STORY__ERROR')
export const requestSingleStory = createAction('SINGLE_STORY__REQUEST')
export const cleanSingleStory = createAction('SINGLE_STORY_CLEAN')
export const disableSingleStoryTag = createAction('SINGLE_STORY_DISABLE_TAG')
export const enableSingleStoryTag = createAction('SINGLE_STORY_ENABLE_TAG')
export const requestFWReportTracking = createAction(
  'FW_REPORT_TRACKING__REQUEST'
)
export const limitedSingleStory = createAction('LIMIT_SINGLE_STORY')
export const requestNotifyMeResults = createAction('NOTIFY_ME_RESULTS__REQUEST')
export const successNotifyMeResults = createAction('NOTIFY_ME_RESULTS__SUCCESS')
export const errorNotifyMeResults = createAction('NOTIFY_ME_RESULTS__ERROR')
export const requestNotifyMeSubscribe = createAction(
  'NOTIFY_ME_RESULTS_SUBSCRIBE__REQUEST'
)
export const requestSimpleNotifyMeSubscribe = createAction(
  'SIMPLE_NOTIFY_ME_RESULTS_SUBSCRIBE__REQUEST'
)

export const requestRelatedUpdates = createAction('RELATED_UPDATES__REQUEST')
export const successRelatedUpdates = createAction('RELATED_UPDATES__SUCCESS')
export const errorRelatedUpdates = createAction('RELATED_UPDATES__ERROR')

export const actions = {
  limitedSingleStory,
  requestSingleStory,
  successSingleStory,
  errorSingleStory,
  cleanSingleStory,
  disableSingleStoryTag,
  enableSingleStoryTag,
  requestFWReportTracking,
  requestNotifyMeResults,
  successNotifyMeResults,
  errorNotifyMeResults,
  requestNotifyMeSubscribe,
  requestSimpleNotifyMeSubscribe,
  requestRelatedUpdates,
  successRelatedUpdates,
  errorRelatedUpdates
}

const initialState = {
  story: null,
  notifyMeResultArticle: null,
  disabledTags: [],
  articleStack: [],
  loading: false,
  relatedUpdates: {},
  combinedLoading: false // This tracks the combined loading state for Reports
}

const singleStoryReducer = createReducer(initialState, {
  [requestSingleStory]: (state, { payload: story }) => {
    state.loading = true
    state.combinedLoading = true
    state.notifyMeResultArticle = null
    state.story = {}
    state.notifyMeResultArticle = null
    state.disabledTags = []
    state.articleStack = [...state.articleStack, story].slice(-1 * STACK_SIZE)
  },
  [successSingleStory]: (state, { payload: story }) => {
    state.story = story
    state.loading = false
    state.notifyMeResultArticle = null
    state.disabledTags = []
  },
  [cleanSingleStory]: state => {
    state.story = {}
    state.notifyMeResultArticle = null
    state.disabledTags = []
  },
  [disableSingleStoryTag]: (state, { payload: tag }) => {
    state.notifyMeResultArticle = null
    state.disabledTags = [...state.disabledTags, tag]
  },

  [enableSingleStoryTag]: (state, { payload: tag }) => {
    const tags = state.disabledTags.filter(
      disabledTag => disabledTag.tag !== tag.tag
    )

    state.disabledTags = tags
    state.notifyMeResultArticle = null
  },
  [requestNotifyMeResults]: state => {
    state.notifyMeResultArticle = { loading: true }
  },
  [successNotifyMeResults]: (state, { payload: story }) => {
    // TODO: this should probably be better: no idea what notifyMeResultArticle state is
    state.notifyMeResultArticle = { ...story, loading: false }
  },
  [errorNotifyMeResults]: state => {
    state.notifyMeResultArticle = { loading: false }
  },
  [requestRelatedUpdates]: state => {
    state.relatedUpdates = { loading: true, error: null }
    state.combinedLoading = true
  },
  [successRelatedUpdates]: (state, { payload }) => {
    state.relatedUpdates = { data: payload, loading: false, error: null }
    state.combinedLoading = state.loading // Only set to false if single story is also done
  },
  [errorRelatedUpdates]: (state, { payload: error }) => {
    state.relatedUpdates = { loading: false, error }
    state.combinedLoading = false
  }
})

export default singleStoryReducer
