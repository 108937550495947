import i18n from 'i18next'
import { Suspense } from 'react'
import PropTypes from 'prop-types'
import { I18nextProvider } from 'react-i18next'

const Translation = ({ children }) => {
  return (
    <Suspense fallback={<div>{children} </div>}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </Suspense>
  )
}
Translation.propTypes = {
  children: PropTypes.node
}

export default Translation
