import FormHelperText from '@mui/material/FormHelperText'
import { styled } from '@mui/material/styles'

/**
 * Form Helper Input
 * theme path: FormInputHelper.styleOverrides.root
 */
const FormInputHelper = styled(FormHelperText, {
  name: 'FormInputHelper',
  overridesResolver: (props, styles) => [styles.root]
})(({ theme, error }) => {
  return {
    color: error
      ? theme.palette.error.main
      : theme.palette.formInputHelper.helperText
  }
})

export default FormInputHelper
