import { createAction, handleActions } from 'redux-actions'

export const piwikRequest = createAction('PIWIK_REQUEST')
export const piwikSuccess = createAction('PIWIK_SUCCESS')
export const piwikError = createAction('PIWIK_ERROR')

export const newsletterPiwikRequest = createAction('NEWSLETTER_PIWIK_REQUEST')

export const actions = {
  piwikRequest,
  piwikSuccess,
  piwikError,

  newsletterPiwikRequest
}

const defaultState = {
  busy: false,
  error: null
}

export default handleActions(
  {
    [piwikRequest]: () => {
      return { busy: true }
    },
    [piwikSuccess]: () => {
      return { busy: false }
    },
    [piwikError]: (state, { payload }) => {
      return { busy: false, error: payload.error }
    }
  },
  defaultState
)
