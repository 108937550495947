export default string => {
  try {
    const el = document.createElement('textarea')
    el.value = string
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-999px'
    document.body.appendChild(el)
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    if (selected) {
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(selected)
    }
    return
  } catch (e) {
    console.warn(e)
    return
  }
}
