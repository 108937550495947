import PropTypes from 'prop-types'

const listingPropType = PropTypes.shape({
  /**
   * Body text
   */
  body: PropTypes.array,
  /**
   * Date
   */
  date: PropTypes.string,
  /**
   * Human readable url
   */
  human_url: PropTypes.string,
  /**
   * Unique id
   */
  id: PropTypes.string,
  /**
   * Source text
   */
  source: PropTypes.string,
  /**
   * Title
   */
  title: PropTypes.string,
  /**
   * Description (truncated body text to be displayed where the full description is not appropriate)
   */
  description: PropTypes.string,
  /**
   * Reference text
   */
  reference: PropTypes.string
})

export { listingPropType }
