import { createContext, Component, useContext } from 'react'
import PropTypes from 'prop-types'

export const context = createContext(i => i)

const { Provider } = context

export const createServiceProvider = ({ resolve }) => {
  const Component = ({ children }) => (
    <Provider value={resolve}>{children}</Provider>
  )
  Component.propTypes = {
    children: PropTypes.node
  }
  return Component
}

export const withServices = mapServicesToProps => WrappedComponent => {
  class WithServices extends Component {
    // eslint-disable-next-line no-undef
    static contextType = context
    render() {
      const services = mapServicesToProps(this.context)
      return <WrappedComponent services={services} {...this.props} />
    }
  }
  const wrappedDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  WithServices.displayName = `WithServices(${wrappedDisplayName})`
  return WithServices
}

export const useServices = (servicesList = []) => {
  const serviceContext = useContext(context)
  const services = servicesList.reduce(
    (prev, curr) => ({ ...prev, [curr]: serviceContext(curr) }),
    {}
  )
  return services
}
