import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, styled } from '@mui/material'
import { Link } from 'react-router-dom'
import Modal from 'src/components/DougallModal'

const EmailConfirmation = ({
  open,
  onCancel,
  thankYouText = 'A member of our team will reach out to you shortly',
  inlineContact = false
}) => {
  return (
    <Modal customActions={<></>} open={open} onCancel={onCancel}>
      <Box style={{ padding: '30px' }}>
        <ModalTitle>Thank you, your request has been received!</ModalTitle>
        <Typography textAlign="center" maxWidth="400px">
          {thankYouText}
          {inlineContact && (
            <Link style={{ color: '#ec8756ff' }} to="/contact-us">
              contact us.
            </Link>
          )}
        </Typography>
        {!inlineContact && (
          <Typography textAlign="center" maxWidth="600px">
            please{' '}
            <Link style={{ color: '#ec8756ff' }} to="/contact-us">
              contact us
            </Link>
          </Typography>
        )}
      </Box>
    </Modal>
  )
}

EmailConfirmation.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  thankYouText: PropTypes.string,
  inlineContact: PropTypes.bool
}

export default EmailConfirmation

const ModalTitle = styled(Typography, {
  name: 'EmailModal',
  label: 'emailModalTitle',
  overridesResolver: (props, styles) => [styles.modalTitle]
})(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#494949',
  textAlign: 'center',
  lineHeight: '24px',
  marginBottom: '8px'
}))
