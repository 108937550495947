import { createSelector } from '@reduxjs/toolkit'

export const historySelector = state => state.historyReducer

export const getHistory = () =>
  createSelector(historySelector, history => history.history)
export const getRawHistory = () =>
  createSelector(historySelector, history => history.rawHistory)
export const getIsFetching = () =>
  createSelector(historySelector, history => history.fetching)
export const getIsDeleting = () =>
  createSelector(historySelector, history => history.deleting)
export const getDeletingId = () =>
  createSelector(historySelector, history => history.deletingId)
export const isClearAllOpen = () =>
  createSelector(historySelector, history => history.clearAllOpen)
export const getSelectedThread = () =>
  createSelector(historySelector, history => history.selectedThreadId)
