import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'

import { getPathToRedirect } from 'src/modules/loading/LoadingSelector'
import { actions as historyActions } from 'src/modules/history/HistoryReducer'
import useChat from './useChat'

function decodeHtml(html) {
  let txt = document.createElement('textarea')
  txt.innerHTML = html
  const textValue = txt.value
  txt.remove()
  return textValue
}

/**
 * Hook to handle all URL related prompts
 */
const useUrlPrompt = () => {
  // Hooks
  const dispatch = useDispatch()
  const { isFetching, promptRequest, setPromptNum } = useChat()
  const { t } = useTranslation()

  // Url Parameters
  const path = useSelector(getPathToRedirect()) || false
  const {
    source = undefined,
    prompt = undefined,
    promptNum = undefined,
    dspId = undefined,
    pId = undefined,
    articleId = undefined,
    type = undefined
  } = queryString.parse(path)
  /**
   * URL prompt params action
   */
  useEffect(() => {
    // Prompt from UBQ3 ads
    // expects url params: dspId, prompt, promptNum
    if (dspId && prompt && promptNum && !isFetching) {
      promptRequest({
        hcpPrompt: decodeHtml(prompt),
        articleId,
        type: 'ubq3'
      })
      setPromptNum(promptNum)
    }

    // DougallApps prompt handling
    // expects url params: articleId, type
    else if (articleId && type) {
      // Get translated prompt to show
      let dougallAppPrompt
      switch (type) {
        case 'implications':
          dougallAppPrompt = t('dougallAppsPromptImplications')
          break
        case 'explain':
          dougallAppPrompt = t('dougallAppsPromptExplain')
          break
        default:
          dougallAppPrompt = decodeHtml(prompt)
          break
      }
      // Submit prompt
      promptRequest({
        hcpPrompt: dougallAppPrompt,
        articleId,
        type
      })
    }

    // Prompt submitted in other site or NL
    // expects url params: prompt, source, articleId(optional)
    else if (prompt && source && !isFetching) {
      promptRequest({
        hcpPrompt: decodeHtml(prompt),
        articleId,
        type: source === 'DGPTLessons' ? 'lessons' : 'regular'
      })
    }
    // eslint-disable-next-line
  }, [prompt, promptNum])

  // Shared Prompts
  useEffect(() => {
    if (pId && source) {
      dispatch(historyActions.requestPromptHistory({ pId, source }))
    }
    // eslint-disable-next-line
  }, [pId, source])
}

export default useUrlPrompt
