import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import styles from './List.module.sass'
import classNames from 'classnames/bind'
import isEmpty from 'lodash/isEmpty'

class List extends Component {
  static displayName = 'List'
  static defaultProps = {
    component: 'ul',
    dense: false,
    ripple: false
  }
  static propTypes = {
    /**
     * An additional custom className for the root element
     */
    className: PropTypes.string,
    /**
     * The component used for the root node. Either a string to use a DOM element or a component.
     */
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * If `true`, compact vertical padding designed for keyboard and mouse input will be used.
     */
    dense: PropTypes.bool,
    /**
     * If `true`, a ripple click/touch indication will be shown for the child ListItems
     */
    ripple: PropTypes.bool,
    /**
     * Accepts a list-style CSS property
     */
    listStyle: PropTypes.string,
    /**
     * An object containing a custom list item marker image and its optional size attribute
     */
    listImage: PropTypes.shape({
      image: PropTypes.string,
      size: PropTypes.string
    }),
    /**
     * If `true`, a divider will be shown for the child ListItems
     */
    divider: PropTypes.bool,
    /** The List children (can contain an array of ListItems or <li>) */
    children: PropTypes.node,
    /** A CSS modules style object to override default theme */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }
  static childContextTypes = {
    dense: PropTypes.bool
  }
  getChildContext = () => {
    return {
      dense: this.props.dense
    }
  }
  allowEnhancement(child) {
    return ['SubMenu', 'MenuItem', 'ListItem'].includes(child.type.displayName)
  }
  render = () => {
    const {
      component,
      dense,
      ripple,
      divider,
      children,
      altTheme,
      className,
      listStyle,
      listImage,
      ...other
    } = this.props
    const Component = component
    const themeStyles = { ...styles, ...altTheme }
    const cx = classNames.bind(themeStyles)
    const enhancedChildren = Children.map(children, child => {
      if (child) {
        return this.allowEnhancement(child)
          ? React.cloneElement(child, {
              ripple,
              listStyle,
              divider,
              listImage,
              ...child.props
            })
          : child
      }
    })
    return (
      <Component
        className={cx(
          themeStyles.list,
          {
            dense,
            listStyle: listStyle || !isEmpty(listImage)
          },
          className
        )}
        style={{ ...(listStyle && { listStyle: listStyle }) }}
        {...other}
      >
        {enhancedChildren}
      </Component>
    )
  }
}
export default List
