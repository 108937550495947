// React
import React from 'react'
import ComponentLoopItemMapping from 'src/layouts/components/children/ComponentLoopItemMapping'
import * as generalComponents from './components'
import { mappedComponents } from 'src/layouts/components/children/utils'

const mapping = {
  ...mappedComponents(generalComponents)
}

const GeneralLoopItem = props => {
  return <ComponentLoopItemMapping mapping={mapping} {...props} />
}

export default GeneralLoopItem
