import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from '../Ad/Ad.module.sass'

const AdUnknown = ({ width = '300px', height = '250px', className }) => (
  <div className={cx(styles.ad, className)}>
    <div
      style={{
        width,
        height,
        background: '#c00',
        color: '#fff'
      }}
    >
      Unknown ad type
    </div>
  </div>
)

AdUnknown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
}

export default AdUnknown
