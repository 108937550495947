import React from 'react'
import PropTypes from 'prop-types'

const baseOffset = 24

const BaseSkeleton = ({ w, h, radius, color }) => {
  const skeletonFixed = `
    @keyframes skeletonFixed {
      0%   { background-position: -${w}px; }
      40%  { background-position: ${w}px; }
      100% { background-position: ${w + 100}px; }
    }
  `

  const skeleton = `
    @keyframes skeleton {
      0%   { background-position: -100px; }
      40%  { background-position: 200px; }
      100% { background-position: 1000px; }
    }
  `

  return (
    <>
      <style
        children={
          w && w.toString().indexOf('%') !== -1 ? skeleton : skeletonFixed
        }
      />
      <div
        style={{
          width: w
            ? `${w}${
                w.toString().indexOf('px') === -1 &&
                w.toString().indexOf('%') === -1
                  ? `px`
                  : ''
              }`
            : `${baseOffset}px`,
          height: h
            ? `${h}${
                h.toString().indexOf('px') === -1 && h.toString().indexOf('%')
                  ? `px`
                  : ''
              }`
            : `${baseOffset}px`,
          backgroundColor: '#ccc',
          borderRadius: radius || `0%`,
          backgroundImage: `linearGradient(
            90deg,
            ${color || '#e0e0e0'} 0px,
            ${color || '#e0e0e0'} 40px,
            ${color || '#e0e0e0'} 80px
          )`,
          animation: `${
            w && w.toString().indexOf('%') !== -1 ? 'skeleton' : 'skeletonFixed'
          } 12s infinite linear`
        }}
      />
    </>
  )
}

BaseSkeleton.propTypes = {
  color: PropTypes.string,
  radius: PropTypes.number,
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default BaseSkeleton
