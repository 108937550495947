import _get from 'lodash/get'
import { getExtraFilters } from './filterLabels'

// HEALTHTECH
export const topStoryRiver = {
  filters: {
    type: ['Top Story']
  },
  operator: 'OR',
  include_related_tags: true
}

export const typeKOLorPhysicianRiver = {
  'KOL Views': {
    filters: {
      type: ['KOL Views', 'KOL Views Results']
    },
    operator: 'OR',
    include_related_tags: true
  },
  'Physician Views': {
    filters: {
      type: ['Physician Views', 'Physician Views Poll Results']
    },
    operator: 'OR',
    include_related_tags: true
  }
}

export const iavRiver = {
  filters: {
    type: [
      'The Wider View',
      'One To Watch',
      'HealthTech Leaders',
      'Digital Ten',
      'Friday Five',
      'Spotlight On',
      'Physician Views',
      'Physician Views Poll Results',
      'ViewPoints',
      'KOL Views',
      'KOL Views Results',
      'FirstImpact',
      'FirstImpact Findings',
      'In The Know',
      'Conference Daily Digest',
      'FW in Conversation'
    ]
  },
  operator: 'AND',
  include_related_tags: true
}

export const conferenceNewsRiver = {
  filters: {
    type: [
      'NewsPoints',
      'Press Release',
      'Top Story',
      'Friday Five',
      'The First Take',
      'Spotlight On',
      'Physician Views',
      'Physician Views Poll Results',
      'KOL Views',
      'KOL Views Results',
      'In Focus',
      'IAV Other',
      'ViewPoints',
      'One To Watch',
      'Digital Ten',
      'Conference Dispatch',
      'Conference Daily Digest'
    ]
  },
  fieldsExist: ['tags.fw_conference'],
  operator: 'AND',
  include_related_tags: true
}

export const peopleNewsRiver = {
  filters: {
    type: ['People News']
  },
  operator: 'AND',
  include_related_tags: true
}

export const resourcesRiver = {
  filters: {
    type: ['Analyst Notes'],
    publication_date: '3_month'
  },
  operator: 'OR',
  include_related_tags: true
}

export const conditionRiver = {
  filters: {},
  fieldsExist: ['tags.conditions'],
  exclude_article_types: ['Conference Daily Digest'],
  operator: 'AND',
  include_related_tags: true
}

export const medicalMeetingsRiver = {
  filters: {},
  fieldsExist: ['tags.fw_conference'],
  exclude_article_types: ['Conference Daily Digest'],
  operator: 'AND',
  include_related_tags: true
}

export const marketSegmentRiver = {
  filters: {},
  fieldsExist: ['tags.fw_market_segment'],
  exclude_article_types: ['Conference Daily Digest'],
  operator: 'AND',
  include_related_tags: true
}

export const productRiver = {
  filters: {},
  fieldsExist: ['tags.fw_product'],
  exclude_article_types: ['Conference Daily Digest'],
  operator: 'AND',
  include_related_tags: true
}

export const companyRiver = {
  filters: {},
  fieldsExist: ['tags.fw_company'],
  exclude_article_types: ['Conference Daily Digest'],
  operator: 'AND',
  include_related_tags: true
}

export const regulatoryRiver = {
  filters: {},
  fieldsExist: [
    'tags.fw_regulatory_market',
    'tags.fw_regulatory_agency',
    'tags.fw_regulatory_qualifier'
  ],
  exclude_article_types: ['Conference Daily Digest'],
  operator: 'AND',
  include_related_tags: true
}

export const eventsRiver = {
  filters: {
    type: ['FW Perspectives Live', 'IAV Live', 'Sponsor Event'],
    publication_date: '1_year'
  },
  operator: 'OR',
  include_related_tags: true,
  size: 1000
}

export const myFwRiver = async services => {
  const Session = _get(services, 'Session', {})
  const user = await Session.userLoad()
  if (!user) return {}
  const tags = user.interests.stated
  return {
    filters: {
      ...tags
    },
    operator: 'OR',
    include_related_tags: true,
    exclude_article_types: ['Conference Daily Digest']
  }
}

export const relatedAnalysis = ids => {
  const filters = {
    filters: {
      type: [
        'Friday Five',
        'IAV Other',
        'In Focus',
        'KOL Views',
        'Kol Views Results',
        'Physician Views',
        'Physician Views Poll Results',
        'Spotlight On',
        'The First Take',
        'ViewPoints'
      ]
    },
    operator: 'OR',
    include_related_tags: true,
    related_to_article_ids: ids
  }

  return filters
}

export const relatedNews = id => {
  const filters = {
    filters: {
      type: [
        'NewsPoints',
        'Conference Dispatch',
        'DGDispatch',
        'Daily News Video',
        'List',
        'People News',
        'Press Release',
        'Top Story'
      ]
    },

    operator: 'AND',
    include_related_tags: true,
    related_to_article_ids: [id]
  }

  return filters
}

export const recentNews = {
  filters: {
    type: [
      'Analyst Notes',
      'Conference Dispatch',
      'Daily News Video',
      'Digital Ten',
      'Friday Five',
      'IAV Other',
      'In Focus',
      'Industry Views',
      'Industry Views Poll Results',
      'KOL Views',
      'KOL Views Results',
      'List',
      'HealthTech Leaders',
      'NewsPoints',
      'One To Watch',
      'People News',
      'Physician Views',
      'Physician Views Poll Results',
      'Press Release',
      'Spotlight On',
      'The First Take',
      'The Wider View',
      'Top Story',
      'ViewPoints',
      'FirstImpact',
      'FirstImpact Findings',
      'In The Know',
      'Conference Daily Digest',
      'Vital Signs',
      'FW in Conversation'
    ]
  },
  operator: 'AND',
  include_related_tags: true
}

export const recentReports = {
  filters: {
    type: ['FWReports']
  }
}

export const byType = type => {
  const typesArray = getExtraFilters(type) || [type]
  return {
    filters: {
      type: typesArray
    },
    operator: 'AND',
    include_related_tags: true
  }
}

export const byTag = path => {
  const matches = path.match(/(\w+)\/(.*)/)
  if (!matches) return {}
  const [, category, tag] = matches
  if (!category || !tag) return {}
  return {
    filters: {
      [category]: [tag]
    },
    type: 'tags',
    operator: 'AND',
    include_related_tags: true
  }
}

export const storyWatch = (services, storywatch) => {
  if (!storywatch)
    throw new Error('Story Watch content river requires an argument')
  return {
    filters: {
      fw_story_watch: [storywatch]
    },
    operator: 'AND',
    include_related_tags: true,
    exclude_article_types: ['Conference Daily Digest']
  }
}

export const searchRiver = (services, pathArgs) => {
  return {
    filters: {},
    operator: 'AND',
    include_related_tags: true,
    query: pathArgs,
    sort: '_score'
  }
}

export const rivers = {
  TOP_STORIES: 'top-stories',
  IAV: 'iav',
  CONFERENCE_NEWS: 'conference-news',
  PEOPLE_NEWS: 'people-news',
  RESOURCES: 'resources',
  EVENTS: 'events',
  CONDITION: 'condition',
  MEDICAL_MEETINGS: 'medical-meetings',
  MARKET_SEGMENT: 'market-segment',
  PRODUCT: 'product',
  COMPANY: 'company',
  REGULATORY: 'regulatory',
  MY_FW: 'my-fw',
  RELATED_ANALYSIS: 'related-analysis',
  RELATED_NEWS: 'related-news',
  RECENT_NEWS: 'recent-news',
  RECENT_REPORTS: 'recent-reports',
  TYPE: 'type',
  TAG: 'tag',
  STORY_WATCH: 'story-watch',
  SEARCH: 'search',
  DEFAULT_RIVER: ''
}

export const mediaEventTypes = [rivers.EVENTS, rivers.RESOURCES]

export default {
  [rivers.TOP_STORIES]: () => topStoryRiver,
  [rivers.IAV]: () => iavRiver,
  [rivers.CONFERENCE_NEWS]: () => conferenceNewsRiver,
  [rivers.PEOPLE_NEWS]: () => peopleNewsRiver,
  [rivers.RESOURCES]: () => resourcesRiver,
  [rivers.EVENTS]: () => eventsRiver,
  [rivers.CONDITION]: () => conditionRiver,
  [rivers.MEDICAL_MEETINGS]: () => medicalMeetingsRiver,
  [rivers.MARKET_SEGMENT]: () => marketSegmentRiver,
  [rivers.COMPANY]: () => companyRiver,
  [rivers.PRODUCT]: () => productRiver,
  [rivers.REGULATORY]: () => regulatoryRiver,
  [rivers.MY_FW]: services => myFwRiver(services),
  [rivers.RELATED_ANALYSIS]: (services, pathArgs) => relatedAnalysis(pathArgs),
  [rivers.RELATED_NEWS]: (services, pathArgs) => relatedNews(pathArgs),
  [rivers.RECENT_NEWS]: () => recentNews,
  [rivers.RECENT_REPORTS]: () => recentReports,
  [rivers.TYPE]: (services, type) => byType(type),
  [rivers.TAG]: (services, pathArgs) => byTag(pathArgs),
  [rivers.STORY_WATCH]: (services, pathArgs) => storyWatch(services, pathArgs),
  [rivers.SEARCH]: (services, pathArgs) => searchRiver(services, pathArgs),
  [rivers.DEFAULT_RIVER]: (services, pathArgs) =>
    searchRiver(services, pathArgs)
}
