import React from 'react'
import PropTypes from 'prop-types'
import styles from './DoubleColumnLayout.module.sass'
import ComponentLoop from 'src/layouts/components/ComponentLoop.js'
import cx from 'classnames'

function DoubleColumnLayout({ content = {}, ...props }) {
  return (
    <div className={cx(styles.static)}>
      <ComponentLoop section="top" items={content?.top} {...props} />
      <div className={styles.doubleColumnLayout}>
        <ComponentLoop section="main" items={content?.main} {...props} />
        <ComponentLoop section="sidebar" items={content?.sidebar} {...props} />
      </div>
    </div>
  )
}

DoubleColumnLayout.propTypes = {
  content: PropTypes.object
}

export default DoubleColumnLayout
