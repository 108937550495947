import { takeEvery, call, all, put } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { notificationDuration as duration } from 'src/constants/index.js'
import { push } from 'connected-react-router'
import { initialize } from 'redux-form'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import { logout } from 'src/modules/Session/actions'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import { ACCESS_DENIED } from 'src/services/FW5ML/errorCodes.js'

function* requestAccountUpdate(services, action) {
  const UserRepository = services('UserRepository')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const { payload: account } = action

  try {
    const user = yield call([Session, 'userLoad'])
    if (!user) throw new Error('Please sign in and try again.')
    const { profile_id } = user
    yield call([UserRepository, 'updateAccount'], profile_id, account)
    yield put(actions.successAccountUpdate())
    yield call([Session, 'userUpdate'], account)
    yield put(
      notificationsEnqueue({
        message: `Your profile has been updated.`,
        duration
      })
    )
    yield put(
      initialize('updateAccount', account, false, { keepSubmitSucceeded: true })
    )
    yield call([Piwik, 'track'], 'account', 'update', 'request')
  } catch (e) {
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      logPush(
        'Account Update',
        `Error with user profile update in: ${e.message}`
      )
    )
    yield put(
      notificationsEnqueue({
        message:
          'Unable to update your profile. Please refresh the page and try again.'
      })
    )
    yield call(
      [Piwik, 'track'],
      'notification',
      'error',
      'could-not-update-account'
    )
    yield put(actions.errorAccountUpdate(e.message))
    yield put(push('/contact-us'))
  }
}

export default function* watchUpdate(services) {
  yield all([
    takeEvery('ACCOUNT_UPDATE__REQUEST', requestAccountUpdate, services)
  ])
}
