import { createAction, handleActions } from 'redux-actions'

export const requestArticleShare = createAction('ARTICLE_SHARE__REQUEST')
export const successArticleShare = createAction('ARTICLE_SHARE__SUCCESS')
export const errorArticleShare = createAction('ARTICLE_SHARE__ERROR')

export const requestSiteShare = createAction('SITE_SHARE__REQUEST')
export const successSiteShare = createAction('SITE_SHARE__SUCCESS')
export const errorSiteShare = createAction('SITE_SHARE__ERROR')

export const updateLikeRequest = createAction('UPDATE_LIKE_REQUEST')
export const updateLikeSuccess = createAction('UPDATE_LIKE_SUCCESS')
export const updateLikeError = createAction('UPDATE_LIKE_ERROR')

export const reportDownloadRequest = createAction('REPORT_DOWNLOAD__REQUEST')
export const reportDownloadSuccess = createAction('REPORT_DOWNLOAD__SUCCESS')
export const reportDownloadError = createAction('REPORT_DOWNLOAD__ERROR')

export const closeConfirmationModal = createAction('CONFIRMATION_MODAL__CLOSE')

export const actions = {
  requestArticleShare,
  successArticleShare,
  errorArticleShare,

  reportDownloadRequest,
  reportDownloadSuccess,
  reportDownloadError,

  requestSiteShare,
  successSiteShare,
  errorSiteShare,

  updateLikeRequest,
  updateLikeSuccess,
  updateLikeError,

  closeConfirmationModal
}

const defaultState = {
  error: '',
  busy: false,
  values: {},
  like: {},
  confirmationModal: false
}
export default handleActions(
  {
    // Article Share
    ARTICLE_SHARE__REQUEST: state => {
      return { ...state, busy: true }
    },

    ARTICLE_SHARE__SUCCESS: state => {
      return {
        ...state,
        busy: false,
        confirmationModal: true
      }
    },
    ARTICLE_SHARE__ERROR: (state, { payload: { error } }) => {
      return {
        ...state,
        busy: false,
        error
      }
    },
    // Site Share
    SITE_SHARE__REQUEST: state => {
      return { ...state, busy: true }
    },
    SITE_SHARE__SUCCESS: state => {
      return {
        ...state,
        busy: false,
        confirmationModal: true
      }
    },
    SITE_SHARE__ERROR: (state, { payload: { error } }) => {
      return {
        ...state,
        busy: false,
        error
      }
    },
    CONFIRMATION_MODAL__CLOSE: state => {
      return {
        ...state,
        confirmationModal: false
      }
    },
    // Like
    UPDATE_LIKE_REQUEST: state => {
      return {
        ...state,
        like: {
          error: null,
          success: null,
          busy: true
        }
      }
    },
    UPDATE_LIKE_SUCCESS: state => {
      return {
        ...state,
        like: {
          error: null,
          success: true,
          busy: false
        }
      }
    },
    UPDATE_LIKE_ERROR: (state, { payload: { error } }) => {
      return {
        ...state,
        like: {
          error,
          success: false,
          busy: false
        }
      }
    },
    REPORT_DOWNLOAD__REQUEST: state => {
      return {
        ...state,
        busy: true,
        error: null
      }
    },
    REPORT_DOWNLOAD__SUCCESS: state => {
      return {
        ...state,
        busy: false,
        error: null
      }
    },
    REPORT_DOWNLOAD__ERROR: (state, { payload: error }) => {
      return {
        ...state,
        busy: false,
        error
      }
    }
  },
  defaultState
)
