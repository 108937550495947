import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Typography from '../../../../../components/ui/Typography'
import styles from '../../../Static.module.sass'
import { getConfig } from '../../../../Config/selectors'
import ReactHtmlParser from 'react-html-parser'

const RegisterEmailSent = ({ parentClassName = '', ...props }) => {
  const webpageContent = useSelector(getConfig('webpage_content'))
  const registerConfig = webpageContent?.register || {}
  const { emailSent = {} } = registerConfig

  const {
    header = 'Email sent',
    content = [
      "We've sent you an email. Please click the link within the email to activate your account. If you haven’t received the email, please check your spam and promotions folders, or <a href='/contact-us'>contact us</a>."
    ]
  } = emailSent

  return (
    <div className={parentClassName}>
      <Typography type="title" className={styles.title} paragraph>
        {header}
      </Typography>
      {content?.map((item, index) => (
        <Typography
          key={`register-email-sent-description-${index}`}
          type="body1"
          className={styles.paragraph}
          paragraph
        >
          {ReactHtmlParser(item)}
        </Typography>
      ))}
    </div>
  )
}

RegisterEmailSent.propTypes = {
  parentClassName: PropTypes.string
}

export default RegisterEmailSent
