import { connect } from 'react-redux'
import { actions } from './reducer'
import CaptchaPage from './components/CaptchaPage'
import * as flags from '../../modules/AsyncFlags/selectors'

const mapStateToProps = state => ({
  token: state.captcha.meta.token,
  busy: flags.isBusy('CAPTCHA_LOGIN')(state)
})

const mapDispatchToProps = dispatch => ({
  requestCaptchaLogin: captchaToken =>
    dispatch(actions.requestCaptchaLogin({ captchaToken }))
})

export default connect(mapStateToProps, mapDispatchToProps)(CaptchaPage)
