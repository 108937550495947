export default []
export const singleStorySponsoredHeadline = ''

// export default [
//   {
//     body: '**Discover our new scientific area weekly newsletters**  \nStay on top of critical developments in top fields of interest. _Learn more._',
//     cta: '/my-account/newsletters'
//   },
//   {
//     body: 'Drive our efforts by sending us _feedback_ on our new site',
//     cta: '/contact-us'
//   },
//   {
//     body: `Is the new FirstWord Pharma service meeting your needs? _Tell us_ how we can improve`,
//     cta: '/contact-us'
//   },
//   {
//     body: 'Have you encountered any bugs or unexpected results in testing our new site? _Let us know_ so we can fix them',
//     cta: '/contact-us'
//   }
// ]

// export const singleStorySponsoredHeadline =
//   '# [Discover our new scientific area weekly newsletters](/my-account/newsletters)\n\nStay on top of critical developments in top fields of interest. [Learn more.](/my-account/newsletters)'
