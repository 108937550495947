import { compose } from 'redux'
import { connect } from 'react-redux'
import * as flags from '../../AsyncFlags/selectors'
import {
  getDeletingSearches,
  getSavedSearches
} from '../../MySavedSearches/selectors'
import { actions } from '../reducer'
import { actions as searchActions } from '../../River/reducer'
import SavedSearches from '../components/SavedSearches'

const mapStateToProps = state => ({
  searches: getSavedSearches(state),
  deleting: getDeletingSearches(state),
  loadingSavedSearches: flags.isBusy('SAVED_SEARCH_LIST')(state),
  savedSearchesConfig: state?.config?.my_account_settings?.data?.saved_searches
})

const mapDispatchToProps = dispatch => ({
  requestSavedSearches: () => dispatch(actions.requestSavedSearchList()),
  onReplay: ({ search_request: { query_string } }) => {
    dispatch(searchActions.redirectSearch(query_string))
  },
  onDelete: search => dispatch(actions.requestSavedSearchDelete(search)),
  onUpdate: search =>
    dispatch(
      actions.requestSavedSearchSave({
        name: search.name,
        search: search
      })
    )
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SavedSearches
)
