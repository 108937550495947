import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from '../../modules/MobileMenu/reducer'
import { actions as searchActions } from '../../modules/River/reducer'
import Icon from '../ui/Icon'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styles from './SearchBarHeader.module.sass'

const HeaderSearchBar = ({
  handleOpenSearch,
  openSearch,
  doSearchCall,
  leftMenuOpen,
  rightMenuOpen,
  handleLeftSlideMenu,
  handleSlideMenu
}) => {
  const [term, setTerm] = useState('')
  const search = () => {
    const TrimTerm = term.trim()
    if (!term || TrimTerm.length === 0) return
    doSearchCall(TrimTerm)
    setTerm('')
  }
  useEffect(() => {
    if (openSearch && rightMenuOpen) handleSlideMenu()
    if (openSearch && leftMenuOpen) handleLeftSlideMenu()
  }, [openSearch])
  const handleInputChange = event => setTerm(event.target.value)
  const handleEnterKey = event => event.key === 'Enter' && search()
  return (
    <ClickAwayListener onClickAway={handleOpenSearch}>
      <div className={styles.searchBar}>
        <TextField
          placeholder="Search"
          onChange={handleInputChange}
          onKeyDown={handleEnterKey}
          value={term}
          variant="standard"
          autoFocus
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  button
                  icon="svg/material-design-icons/action/search"
                  iconSize={24}
                  onClick={search}
                  colorTier="action"
                />
              </InputAdornment>
            )
          }}
        />
      </div>
    </ClickAwayListener>
  )
}

const mapStateToProps = ({ mobileMenu }) => ({
  leftMenuOpen: mobileMenu.leftMenuOpen,
  rightMenuOpen: mobileMenu.rightMenuOpen
})

const mapDispatchToProps = dispatch => ({
  doSearchCall: query => dispatch(searchActions.redirectSearch(query)),
  handleSlideMenu: () => dispatch(actions.toggleRightMenu()),
  handleLeftSlideMenu: () => dispatch(actions.toggleLeftMenu())
})

HeaderSearchBar.propTypes = {
  handleOpenSearch: PropTypes.func,
  openSearch: PropTypes.bool,
  doSearchCall: PropTypes.func,
  leftMenuOpen: PropTypes.bool,
  rightMenuOpen: PropTypes.bool,
  handleLeftSlideMenu: PropTypes.func,
  handleSlideMenu: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearchBar)
