import React from 'react'
import PropTypes from 'prop-types'
import Button from '../ui/Buttons/Button'
import styles from './SaveButton.module.sass'

const SaveButton = ({ saved }) => (
  <Button
    tier={saved ? 'tertiary' : 'default'}
    raised={false}
    iconRight="svg/material-design-icons/action/bookmark"
    minWidth="0"
    className={styles.button}
    type="button"
    text={`Save${saved ? 'd' : ''}`}
  />
)

SaveButton.propTypes = {
  saved: PropTypes.bool
}

export default SaveButton
