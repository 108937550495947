import React from 'react'
import PropTypes from 'prop-types'
import FullImage from '../../../../components/FullImage'
import ReactHtmlParser from 'react-html-parser'
import SectionHeading from '../../../../components/SectionHeading'
import styles from '../../pages/Landing/Landing.module.sass'
import cx from 'classnames'

const Contributors = ({ contributors = [] }) => {
  return (
    <section className={cx(styles.contributors, styles.singleStory)}>
      <SectionHeading title="Contributors" />
      <div className={styles.contributorsList}>
        {contributors.map((contributor, index) => {
          return (
            contributor?.name && (
              <div key={`${contributor?.name} - ${index + 1}`}>
                <div className={styles.contributor}>
                  {contributor?.imageUrl && (
                    <div className={styles.contributorImage}>
                      <FullImage
                        src={contributor?.imageUrl}
                        alt={`${contributor?.name} Photo`}
                      />
                    </div>
                  )}
                  <div className={styles.contributorDetails}>
                    {contributor?.name && (
                      <div className={styles.title}>
                        <h3>{ReactHtmlParser(contributor?.name)}</h3>
                      </div>
                    )}
                    {contributor?.credentials?.map((credential, index) => (
                      <p key={`${credential?.name} - ${index + 1}`}>
                        {ReactHtmlParser(credential)}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            )
          )
        })}
      </div>
    </section>
  )
}

Contributors.propTypes = {
  contributors: PropTypes.array
}

export default Contributors
