import master from './master'

/**
 * Color palette
 */
const palette = {
  aeroblue: '#d1fadf',
  americanblue: '#363f72',
  americanred: '#be1e2d',
  aurometalsaurus: '#667085',
  black: '#000000',
  black10: '#0000001a',
  black20: '#00000033',
  black42: '#0000006b',
  bostonuniversityred: '#007e6f',
  brilliantazure: '#38a5f9',
  browngrey2: '#9b9b9b',
  browngrey3: '#a0a0a0',
  brownishgrey2: '#666',
  cadetgrey: '#98a2b3',
  celadonblue: '#0778ad',
  chineseblack: '#101323',
  darkbluegrey: '#656c94',
  darkjunglegreen: '#101828',
  darkjunglegreen10: '#1018281a',
  darkjunglegreen6: '#1018280f',
  darkpowderblue: '#003399',
  darkslateblue2: '#183564',
  eerieblack: '#111323',
  davysgrey: '#5a5a5a',
  gainsboro: '#d5d9eb',
  ghostwhite: '#f8f9fc',
  grey: '#cececd',
  greyishbrown: '#595959',
  greyishpink: '#efefef',
  honeydew: '#ecfdf3',
  honolulublue: '#0164b0',
  lava: '#ca1c1a',
  lavanderblush: '#fef3f2',
  liberty: '#4e5ba6',
  lightblack: '#333333',
  lightgrey: '#ebebeb',
  lightsilver: '#d6d6d6',
  lightsteelblue: '#b3b8db',
  mediumpersianblue: '#015ea6',
  mediumvermilion: '#cd5532',
  mistyrose: '#fee4e2',
  paleblue: '#e5e6e8',
  philippinesilver: '#b1b1b1',
  silverlakeblue: '#597fcb',
  skyblue: '#95bfe9',
  spacecadet: '#293056',
  soft_black: '#494949',
  firstword_orange: '#e27746',
  firstword_green: '#007e6f',
  firstword_green_contrast: '#108e7f',
  firstword_celadon_orange: '#F4A37E',
  firstword_celadon_green: '#aedbd6',
  firstword_blue: '#363e4e',
  toolbox: '#717bbc',
  white: '#f8f9fc',
  whitegrey: '#999999',
  whiteishgrey: '#d8d8d8',
  yankeesblue: '#222848',
  chat_blue: '#b6b9c3'
}
/**
 * Font family overrides for DGPT
 */
const fontFamily = {
  fontPrimary: ['"Inter"', 'Arial', 'sans-serif'].join(','),
  fontSecondary: ['Imperial', 'Georgia', 'serif'].join(','),
  fontTertiary: 'arial'
}
/**
 * MUI Default theme overrides for DGPT brand
 */
const dougallgpt = {
  active: 'dougallgpt',
  palette: {
    primary: {
      main: palette.celadonblue,
      contrastText: palette.white
    },
    error: { main: palette.americanred },
    header: {
      main: palette.white,
      menuIcon: palette.soft_black
    },
    menu: {
      main: palette.soft_black,
      topText: palette.skyblue,
      divider: palette.lightgrey,
      selected: palette.lightblue,
      searchInput: palette.whitegrey,
      searchIcon: palette.brownishgrey2,
      searchText: palette.brownishgrey2,
      background: palette.white,
      textHover: palette.honolulublue
    },
    divider: { main: palette.grey },
    sharerModal: { divider: palette.whiteishgrey },
    search: { divider: palette.white },
    comments: {
      divider: palette.black,
      button: palette.white,
      inputBorder: palette.paleblue,
      inputBackground: palette.greyishpink
    },
    modal: {
      content: palette.greyishbrown,
      icons: palette.browngrey2,
      submitBackground: palette.firstword_orange,
      submitBackgroundHover: palette.yankeesblue
    },
    traditionalInput: {
      inputPlaceholder: palette.cadetgrey,
      inputBorder: palette.gainsboro,
      inputBorderHover: palette.black42,
      inputBorderFocused: palette.silverlakeblue,
      inputText: palette.soft_black,
      placeholderSecondary: palette.verylightgrey,
      disabled: palette.black20,
      inputBoxShadow1: palette.darkjunglegreen10,
      inputBoxShadow2: palette.darkjunglegreen6
    },
    formInputHelper: {
      helperText: palette.black
    },
    label: {
      labelText: palette.americanblue,
      required: palette.mediumvermilion
    },
    dialogSuccess: {
      backgroundColor: palette.honeydew,
      border: palette.aeroblue
    },
    dialogError: {
      backgroundColor: palette.mistyrose,
      border: palette.lavanderblush
    },
    modernInput: {
      underline: palette.black42,
      underlineHover: palette.black42,
      underlineFocused: palette.lightsteelblue,
      disabled: palette.black20
    },
    history: {
      inputColor: palette.firstword_blue,
      highlighted: palette.firstword_blue,
      highlightedNightMode: palette.white,
      titleNightMode: palette.firstword_blue,
      icon: palette.firstword_blue,
      iconNightMode: palette.firstword_celadon_green,
      iconHover: palette.black,
      iconNightModeHover: palette.firstword_blue
    },
    invite: {
      text: palette.greyishbrown,
      messageInputBorder: palette.philippinesilver,
      buttonText: palette.white,
      buttonBackground: palette.honolulublue,
      buttonBackgroundHover: palette.mediumpersianblue
    },
    formDropdown: {
      selectIcon: palette.black
    },
    emailModal: {
      title: palette.americanblue
    },
    message: {
      answerText: palette.chineseblack,
      answerBackground: palette.white,
      answerBorder: palette.gainsboro,
      promptText: palette.eerieblack,
      promptBackground: palette.chat_blue,
      promptEditIcon: palette.spacecadet,
      titleText: palette.aurometalsaurus,
      mdTitleText: palette.darkbluegrey,
      highlight: palette.firstword_orange
    },
    mdMessage: {
      text: palette.americanblue,
      buttonText: palette.americanblue,
      buttonBorder: palette.toolbox,
      buttonHoverBorder: palette.bostonuniversityred
    },
    fwMessage: {
      text: palette.firstword_blue,
      buttonText: palette.firstword_orange,
      buttonBorder: palette.firstword_orange,
      buttonHoverBackground: palette.firstword_orange,
      buttonHoverText: palette.white,
      buttonHoverBorder: palette.firstword_celadon_orange
    },
    promptBar: {
      sendButtonBackground: palette.firstword_orange,
      newTopicBackground: palette.firstword_blue,
      newTopicText: palette.white
    },
    editProfile: {
      inputLabel: palette.black,
      inputBorder: palette.black42,
      fieldset: {
        divider: palette.grey,
        text: palette.darkpowderblue
      },
      submitBackground: palette.honolulublue,
      submitBackgroundHover: palette.mediumpersianblue
    },
    login: {
      button: palette.liberty
    },
    loginStandard: {
      submitBackground: palette.firstword_orange,
      submitBackgroundHover: palette.mediumpersianblue
    },
    loginWithEmail: {
      title: palette.darkjunglegreen,
      instruction: palette.americanblue,
      submitBackground: palette.firstword_orange,
      submitBackgroundHover: palette.mediumpersianblue
    },
    registration: {
      checkbox: palette.gainsboro,
      checkboxLabel: palette.americanblue,
      submitBackground: palette.firstword_orange,
      submitBackgroundHover: palette.mediumpersianblue
    },
    feedbackForm: {
      submitBackground: palette.firstword_orange,
      submitBackgroundHover: palette.mediumpersianblue,
      gradeBackground: palette.white,
      gradeBackgroundSelected: palette.americanblue,
      gradeBorder: palette.gainsboro
    },
    actionBar: {
      label: palette.americanblue,
      icon: palette.americanblue,
      buttonBorder: palette.americanblue,
      buttonBackground: palette.ghostwhite,
      buttonBackgroundActive: palette.lightsteelblue,
      buttonBackgroundHover: palette.toolbox,
      buttonLabelHover: palette.white,
      buttonIconHover: palette.white
    },
    feedbackButton: {
      color: palette.white,
      backgroundColor: palette.firstword_orange
    },
    interests: {
      headlineText: palette.darkbluegrey
    },
    leftBar: {
      buttonText: palette.soft_black
    },
    mobileButtonBar: {
      containerBorder: palette.gainsboro,
      buttonIcon: palette.liberty,
      buttonText: palette.liberty
    },
    popularPrompts: {
      promptText: palette.brilliantazure
    },
    textDivider: {
      text: palette.firstword_orange,
      dividers: palette.lightsilver
    }
  },
  typography: {
    fontFamily: fontFamily.fontPrimary
  },
  components: {
    ...master.components,
    //  Name of the component
    //  https://mui.com/material-ui/api/button/#theme-default-props
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          '&:focus': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.yankeesblue
          },
          '&:hover': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.yankeesblue
          },
          '&:active': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.celadonblue
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '60px',
          alignSelf: 'center',
          boxShadow: 'none',
          backgroundColor: palette.white,
          paddingLeft: '45px',
          paddingRight: '21px',
          '@media (max-width: 1023px)': {
            height: '52px'
          },
          '& .brandContainer': {
            justifyContent: 'center'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingLeft: '0 !important',
          paddingRight: '0 !important'
        }
      }
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundColor: palette.white } }
    },
    Menu: {
      styleOverrides: {
        container: {
          [`& .MuiDrawer-paper`]: {
            width: '240px',
            marginTop: '57px',
            boxShadow: '2px 2px 10px 2px hsla(240,1%,73%,.5)!important',
            border: `1px solid ${palette.lightsilver}`,
            borderBottom: '0',
            borderTop: '0',
            '@media (min-width: 821px) and (max-width: 1023px)': {
              marginTop: '72px'
            },
            '@media (min-width: 1024px)': {
              marginTop: '80px'
            }
          }
        },
        inputContainer: {
          margin: '20px 0px 0px 20px',
          paddingBottom: '5px'
        },
        searchInput: {
          margin: '0px 20px 0px 0px',
          borderRadius: '3px',
          height: '42px'
        },
        divider: {
          color: palette.lightgrey
        },
        listItem: {
          padding: '8px'
        }
      }
    },
    Header: {
      styleOverrides: {
        logo: {
          height: '40px'
        },
        title: {
          color: palette.firstword_green,
          fontFamily: 'Inter',
          fontSize: '20px',
          marginLeft: '19px',
          marginTop: '-20px'
        }
      }
    },
    Modal: {
      styleOverrides: {
        title: {
          fontSize: '28px',
          fontWeight: 'normal',
          color: palette.greyishbrown,
          padding: '20px 20px 5px 20px',
          fontFamily: fontFamily.fontPrimary
        },
        divider: {}
      }
    },
    Footer: {
      styleOverrides: {
        container: {
          marginTop: '0px',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: palette.white
        },
        row: {
          display: 'flex',
          alignItems: 'baseline',
          margin: '0px 5px 4px'
        },
        link: {
          '@media all and (max-width: 550px)': {
            margin: '5px 0px'
          }
        }
      }
    },
    TraditionalInput: {
      styleOverrides: {
        labelContainer: {
          fontFamily: fontFamily.fontPrimary,
          margin: '10px 0px 5px 0px',
          padding: '0 0 3px 0',
          fontSize: '13px'
        },
        inputContainer: {
          fontFamily: fontFamily.fontPrimary,
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '25px',
          input: {
            padding: '8px 13px'
          },
          '&.MuiInputBase-multiline': {
            padding: '8px 9px'
          }
        }
      },
      variants: [
        {
          props: { styleVariant: 'inset' },
          style: {
            input: {
              height: '40px',
              padding: '7px 9px'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px',
              boxShadow: `inset 0 2px 4px ${palette.black10}`
            }
          }
        }
      ]
    },
    ModernInput: {
      styleOverrides: {
        inputContainer: {
          fontFamily: fontFamily.fontPrimary,
          fontSize: '14px',
          lineHeight: '25px',
          padding: '0px',
          fontWeight: '400'
        }
      },
      variants: []
    },
    FormInputHelper: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          lineHeight: '15px',
          marginTop: '3px',
          marginRight: '0px',
          height: '15px',
          textAlign: 'right'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  zIndex: { appBar: 2, drawer: 4 }
}
export default dougallgpt
