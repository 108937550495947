import React, { useContext, useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { SessionContext } from 'src/modules/Session/context'
import { useDispatch } from 'react-redux'
import { actions as configActions } from 'src/modules/Config/reducer'

const LaunchDarklyContainer = ({ children }) => {
  const ldClient = useLDClient()
  const session = useContext(SessionContext)
  const dispatch = useDispatch()

  useEffect(() => {
    const validateSession = async () => {
      if (session.checked) {
        if (session.authenticated) {
          const domain = session.user.email.split('@')[1]
          const companyType = session.user?.derived_company?.company_type
          const companyName = session.user?.derived_company?.company_name
          const context = {
            key: `user-key-${session.user.profile_id}`,
            custom: {
              partyId: session.user?.party_id,
              domain,
              companyType,
              companyName
            }
          }

          await ldClient.identify(context)
        }

        dispatch(
          configActions.addConfigSuccess({
            key: 'feature_flag_ldk',
            data: { initialized: true }
          })
        )
      }
    }

    validateSession()
  }, [session])

  return children
}

export default LaunchDarklyContainer
