import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Tabs.module.sass'
import cx from 'classnames'

class Tabs extends Component {
  static displayName = 'Tabs'

  static propTypes = {
    /**
     * An additional custom className for the root element
     */
    className: PropTypes.string,
    /**
     * The index of the currently selected tab
     */
    value: PropTypes.any,
    /**
     * The function to run on tab change
     */
    tabChange: PropTypes.func,
    /**
     * Allow scrolling or wrapping of the tabs when exceeding page width
     */
    mode: PropTypes.oneOf(['default', 'scroll', 'wrap']),
    /**
     * Minimum width of tabs container (only used if tabs are in mode: scroll)
     */
    minWidth: PropTypes.number,
    /**
     * The component children (Contains the tabs)
     */
    children: PropTypes.node,
    /**
     * A CSS modules style object to override default theme
     */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }
  valueToIndex = {}
  render = () => {
    const {
      children: childrenProp,
      minWidth = 0,
      mode = 'default',
      value = 0,
      tabChange,
      className,
      altTheme
    } = this.props
    const themeStyles = { ...styles, ...altTheme }
    let childIndex = 0
    const children = React.Children.map(childrenProp, child => {
      if (!React.isValidElement(child)) {
        return null
      }
      const childValue = child.props.value || childIndex
      this.valueToIndex[childValue] = childIndex
      const selected = childValue === value

      childIndex += 1
      return React.cloneElement(child, {
        selected,
        tabChange,
        value: childValue
      })
    })
    return (
      <div
        className={cx(
          mode === 'scroll' ? themeStyles.scroll : themeStyles.container,
          className
        )}
        style={mode === 'wrap' ? { flexWrap: 'wrap' } : {}}
      >
        {mode === 'scroll' ? (
          <div className={themeStyles.container} style={{ minWidth: minWidth }}>
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    )
  }
}
export default Tabs
