import { createAction, handleActions } from 'redux-actions'

export const requestLandingContentData = createAction(
  'LANDING_CONTENT_DATA__REQUEST'
)
export const successLandingContentData = createAction(
  'LANDING_CONTENT_DATA__SUCCESS'
)
export const errorLandingContentData = createAction(
  'LANDING_CONTENT_DATA__ERROR'
)

export const requestLandingCompanyData = createAction(
  'LANDING_COMPANY_DATA__REQUEST'
)
export const successLandingCompanyData = createAction(
  'LANDING_COMPANY_DATA__SUCCESS'
)
export const errorLandingCompanyData = createAction(
  'LANDING_COMPANY_DATA__ERROR'
)
export const requestConfirmConsent = createAction('CONSENT_CONFIRM__REQUEST')
export const successConfirmConsent = createAction('CONSENT_CONFIRM__SUCCESS')
export const errorConfirmConsent = createAction('CONSENT_CONFIRM__ERROR')

export const actions = {
  requestLandingContentData,
  successLandingContentData,
  errorLandingContentData,

  requestLandingCompanyData,
  successLandingCompanyData,
  errorLandingCompanyData,

  requestConfirmConsent,
  successConfirmConsent,
  errorConfirmConsent
}

const defaultState = {
  content: {},
  error: null,
  loginInfo: {},
  registrationInfo: {},
  consentInfo: {},
  consent: false,
  loading: false
}

export default handleActions(
  {
    LANDING_CONTENT_DATA__REQUEST: (state, { payload }) => ({
      ...state,
      content: {},
      consent: false,
      loading: true,
      error: null
    }),
    LANDING_CONTENT_DATA__SUCCESS: (state, { payload: content }) => ({
      ...state,
      content,
      consent: true,
      loading: false,
      error: null
    }),
    LANDING_CONTENT_DATA__ERROR: (state, { payload: { error } }) => ({
      ...state,
      content: {},
      consent: false,
      loading: false,
      error
    }),

    LANDING_COMPANY_DATA__REQUEST: (state, { payload: { type } }) => ({
      ...state,
      [`${type}Info`]: {},
      loading: true,
      error: null
    }),
    LANDING_COMPANY_DATA__SUCCESS: (state, { payload: { type, data } }) => ({
      ...state,
      [`${type}Info`]: data,
      loading: false,
      error: null
    }),
    LANDING_COMPANY_DATA__ERROR: (state, { payload: { type, error } }) => ({
      ...state,
      [`${type}Info`]: {},
      loading: false,
      error
    }),
    CONSENT_CONFIRM__REQUEST: (state, { payload }) => ({
      ...state,
      loading: true,
      consent: false
    }),
    CONSENT_CONFIRM__SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      consent: true
    }),
    CONSENT_CONFIRM__ERROR: (state, { payload: { error } }) => ({
      ...state,
      loading: false,
      consent: false,
      error: error
    })
  },
  defaultState
)
