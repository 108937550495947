import { createAction, handleActions } from 'redux-actions'
import { checkIfArray } from '../../utils'

export const initialConfigRequest = createAction('INITIAL_CONFIG_REQUEST')
export const initialConfigSuccess = createAction('INITIAL_CONFIG_SUCCESS')
export const addConfigRequest = createAction('ADD_CONFIG_REQUEST')
export const addConfigSuccess = createAction('ADD_CONFIG_SUCCESS')
export const addConfigError = createAction('ADD_CONFIG_ERROR')

export const updateConfigRequest = createAction('UPDATE_CONFIG_REQUEST')
export const updateConfigSuccess = createAction('UPDATE_CONFIG_SUCCESS')
export const updateConfigError = createAction('UPDATE_CONFIG_ERROR')

export const getConfigRegisterModalValidation = createAction(
  'GET_CONFIG_REGISTER_MODAL_VALIDATION'
)

export const actions = {
  initialConfigRequest,
  initialConfigSuccess,

  addConfigRequest,
  addConfigSuccess,
  addConfigError,

  updateConfigRequest,
  updateConfigSuccess,
  updateConfigError,

  getConfigRegisterModalValidation
}

const defaultState = {
  bootstrapped: false
}

export default handleActions(
  {
    INITIAL_CONFIG_REQUEST: state => {
      return { ...state, bootstrapped: false }
    },
    INITIAL_CONFIG_SUCCESS: state => {
      return {
        ...state,
        bootstrapped: true
      }
    },
    ADD_CONFIG_REQUEST: (state, { payload: { key } }) => {
      return {
        ...state,
        [key]: {
          ready: false,
          error: null,
          data: null
        }
      }
    },
    ADD_CONFIG_SUCCESS: (state, { payload: { key, data } }) => {
      return {
        ...state,
        [key]: {
          ready: true,
          error: null,
          data
        }
      }
    },
    ADD_CONFIG_ERROR: (state, { payload: { key, error } }) => {
      return {
        ...state,
        [key]: {
          ready: true,
          error,
          data: null
        }
      }
    },

    UPDATE_CONFIG_REQUEST: (state, { payload: { key } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData) ? [...prevData] : { ...prevData }

      return {
        ...state,
        [key]: {
          ready: false,
          error: null,
          data
        }
      }
    },
    UPDATE_CONFIG_SUCCESS: (state, { payload: { key, updatedData } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData)
        ? [...prevData, ...updatedData]
        : { ...prevData, ...updatedData }

      return {
        ...state,
        [key]: {
          ready: true,
          error: null,
          data
        }
      }
    },
    UPDATE_CONFIG_ERROR: (state, { payload: { key, error } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData) ? [...prevData] : { ...prevData }

      return {
        ...state,
        [key]: {
          ready: true,
          error,
          data
        }
      }
    }
  },
  defaultState
)
