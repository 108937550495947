import FwSelect from 'src/components/FwSelect'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Tooltip, Box, Stack, Typography, styled } from '@mui/material'
import { Psychology } from '@mui/icons-material'
import useMediaSize from 'src/hooks/useMediaSize'
import usePiwik from 'src/hooks/usePiwik'

export const HayStackSelector = ({ onChange = () => {} }) => {
  const title = 'LLM Knowledge Base'
  const { handleTracking } = usePiwik()
  const [hayStackValue, setHayStackValue] = useState(
    localStorage.getItem('knowledge_setting') || 'fwHaystackOnly'
  )

  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)',
      customSmall: '(max-width: 1205px)'
    }
  })

  const ChatWarning = styled(Typography, {
    name: 'Chat',
    label: 'chatWarning',
    overridesResolver: (props, styles) => [styles.warning]
  })(() => ({
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#667085',
    padding: '4px 0 5px'
  }))
  const hayStackDataSource = [
    {
      id: 'fwHaystackOnly',
      name: 'FirstWord News Haystack Only'
    },
    {
      id: 'fwHaystackPlusBase',
      name: 'FirstWord News Haystack Plus Dougall Knowledge'
    }
  ]

  const handleHaystackChange = e => {
    setHayStackValue(e.target.value)
    localStorage.setItem('knowledge_setting', e.target.value)

    const trackingKey =
      e.target.value === 'fwHaystackPlusBase'
        ? 'fw-news-and-dougall'
        : 'fw-news-only'

    handleTracking('firstword-ai', 'change-haystack', trackingKey)

    if (onChange) {
      onChange(e)
    }
  }

  useEffect(() => {
    const currentValue = localStorage.getItem('knowledge_setting')
    if (!currentValue) {
      localStorage.setItem('knowledge_setting', hayStackValue)
    }
  }, [])

  const IconContainer = styled(Box)(() => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2d3e50',
    borderRadius: '50%',
    width: 26,
    height: 26,
    padding: 1,
    marginRight: 10
  }))

  return (
    <Stack
      sx={{
        padding: '8px'
      }}
      direction="row"
      alignItems={'center'}
      justifyContent={'center'}
      spacing={2}
    >
      {mediaSize !== 'small' && mediaSize !== 'customSmall' && (
        <Stack direction="row" alignItems={'center'} justifyContent={'center'}>
          <Tooltip describeChild title={title}>
            <IconContainer>
              <Psychology sx={{ color: '#fff', fontSize: 18 }} />
            </IconContainer>
          </Tooltip>
          <ChatWarning>LLM Knowledge Base: </ChatWarning>
        </Stack>
      )}
      <FwSelect
        id={'haystack_select_id'}
        label={(mediaSize === 'small' || mediaSize === 'customSmall') && title}
        name={'haystack_select'}
        dataSource={hayStackDataSource}
        value={hayStackValue}
        onChange={handleHaystackChange}
        formSx={{ width: '400px' }}
      />
    </Stack>
  )
}

HayStackSelector.propTypes = {
  onChange: PropTypes.func
}

export default HayStackSelector
