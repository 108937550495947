import { createReducer, createAction } from '@reduxjs/toolkit'

export const setFormState = createAction('CHAT_SET_FORM_STATE')
export const promptRequest = createAction('CHAT_PROMPT_REQUEST')
export const promptRequestStart = createAction('CHAT_PROMPT_REQUEST_START')
export const promptSuccess = createAction('CHAT_PROMPT_SUCCESS')
export const promptFail = createAction('CHAT_PROMPT_FAIL')
export const addMessage = createAction('CHAT_ADD_MESSAGE')
export const addMessages = createAction('CHAT_ADD_MESSAGES')
export const editLastMessage = createAction('CHAT_EDIT_LAST_MESSAGE')
export const resetMessages = createAction('CHAT_RESET_MESSAGE')
export const addTags = createAction('CHAT_ADD_TAGS')
export const showInterests = createAction('CHAT_SHOW_INTERESTS')
export const validatePromptAccess = createAction('CHAT_VALIDATE_PROMPT_ACCESS')
export const setAction = createAction('CHAT_SET_ACTION')
export const actionSubmit = createAction('CHAT_ACTION_SUBMIT')
export const setLinkedPromptId = createAction('CHAT_SET_LINKED_PROMPT')
export const resetLinkedPromptId = createAction('CHAT_RESET_LINKED_PROMPT')
export const setCurrentPrompt = createAction('CHAT_SET_CURRENT_PROMPT')
export const resetCurrentPrompt = createAction('CHAT_RESET_CURRENT_PROMPT')
export const shareEmail = createAction('CHAT_SHARE_EMAIL')
export const setPromptNum = createAction('CHAT_SET_PROMPT_NUM')
export const resetPromptNum = createAction('CHAT_RESET_PROMPT_NUM')
export const setUrlRequested = createAction('CHAT_SET_URL_REQUESTED')
export const setTyping = createAction('CHAT_SET_TYPING')
export const setActiveModal = createAction('CHAT_SET_ACTIVE_MODAL')

export const actions = {
  setFormState,
  promptRequest,
  promptRequestStart,
  promptSuccess,
  promptFail,
  addMessage,
  addMessages,
  editLastMessage,
  resetMessages,
  addTags,
  showInterests,
  validatePromptAccess,

  // Modal
  setActiveModal,

  setAction,
  actionSubmit,
  setLinkedPromptId,
  resetLinkedPromptId,
  setCurrentPrompt,
  resetCurrentPrompt,
  shareEmail,
  setPromptNum,
  resetPromptNum,
  setUrlRequested,
  setTyping
}

const chatReducer = createReducer(
  {
    formState: {
      prompt: ''
    },
    fetching: false,
    messages: [],
    tags: [],
    showInterests: false,
    activeModal: null,
    actionHandler: {
      action: '',
      prompt: '',
      promptId: '',
      answerId: ''
    },
    linkedPromptId: null,
    promptNum: null,
    current: {
      promptId: '',
      answerId: ''
    },
    urlRequested: false,
    isTyping: false
  },
  {
    [setFormState]: (state, { payload }) => {
      state.formState = { ...state.formState, ...payload }
    },
    [promptRequestStart]: state => {
      state.fetching = true
    },
    [promptSuccess]: state => {
      state.fetching = false
    },
    [promptFail]: state => {
      state.fetching = false
    },
    [addMessage]: (state, { payload }) => {
      state.messages = [...state.messages, { ...payload }]
    },
    [addMessages]: (state, { payload }) => {
      state.messages = payload
    },
    [editLastMessage]: (state, { payload }) => {
      state.messages.push({ ...state.messages.pop(), ...payload })
    },
    [resetMessages]: state => {
      state.messages = []
      state.tags = []
    },
    [addTags]: (state, { payload }) => {
      state.tags = payload.tags
    },
    [showInterests]: state => {
      state.showInterests = true
    },
    [setAction]: (state, { payload }) => {
      state.actionHandler = payload
    },
    [setLinkedPromptId]: (state, { payload }) => {
      state.linkedPromptId = payload
    },
    [resetLinkedPromptId]: state => {
      state.linkedPromptId = null
    },
    [setCurrentPrompt]: (state, { payload }) => {
      state.current.promptId = payload.promptId
      state.current.answerId = payload.answerId
    },
    [resetCurrentPrompt]: state => {
      state.current.promptId = ''
      state.current.answerId = ''
    },
    [setPromptNum]: (state, { payload }) => {
      state.promptNum = payload
    },
    [resetPromptNum]: state => {
      state.promptNum = null
    },
    [setUrlRequested]: (state, { payload }) => {
      state.urlRequested = payload
    },
    [setTyping]: (state, { payload }) => {
      state.isTyping = payload
    },
    [setActiveModal]: (state, { payload }) => {
      state.activeModal = payload
    }
  }
)

export default chatReducer
