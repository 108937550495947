import React from 'react'
import PropTypes from 'prop-types'
import styles from 'src/components/Modal/Modal.module.sass'
import cx from 'classnames'

const Modal = ({
  children,
  className = '',
  containerClassName = '',
  onClick = () => {}
}) => {
  return (
    <div className={cx(styles.modal, containerClassName)}>
      <div className={cx(styles.modalContent, className)} onClick={onClick}>
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onClick: PropTypes.func
}

export default Modal
