import React from 'react'
import PropTypes from 'prop-types'
import Typography from 'src/components/ui/Typography/index.js'
import styles from './SponsoredLines.module.sass'
import cx from 'classnames'

const SponsoredLines = ({ sponsor, className }) => {
  return (
    <Typography className={cx(className, styles.auxTag)} type="subheading">
      {sponsor}
    </Typography>
  )
}

SponsoredLines.propTypes = {
  sponsor: PropTypes.string,
  className: PropTypes.string
}

export default SponsoredLines
