import { takeEvery, all, call, select, put } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import { getPreviousId } from './selectors'
import { setLastArticleId } from 'src/modules/Benefits/reducer.js'

import { actions as paywallActions } from 'src/modules/Paywall/slice.js'

/**
 * Sync Piwik URL with current history URL
 */
function* updateUrl(services, action) {
  const Piwik = services('Piwik')
  const {
    payload: {
      location: { pathname }
    }
  } = action

  // Piwik pageview
  if (pathname === '/benefits') {
    const lastArticleId = yield select(getPreviousId)
    if (lastArticleId) {
      yield call([Piwik, 'updateCustomVariable'], 'article_id', lastArticleId)
    }
    yield call([Piwik, 'track'], 'page', 'view', 'benefits')
    yield put(setLastArticleId(null))
  }
}

/**
 * Handle paywall redirect logic
 */
function* handlePaywallRedirect(services, { payload }) {
  // Show register popup if the flow is defined
  yield put(
    paywallActions.updatePaywallVisible({
      visible: true,
      type: payload.type,
      text: payload?.text || null,
      subtype: payload?.subtype
    })
  )
}

export default function* watchTracking(services) {
  yield all([
    takeEvery(LOCATION_CHANGE, updateUrl, services),
    takeEvery('PAYWALL_REDIRECT_REQUEST', handlePaywallRedirect, services)
  ])
}
