import MainLayout from 'src/layouts/children/MainLayout'
import SingleColumnLayout from 'src/layouts/children/SingleColumnLayout'
import SingleFullColumnLayout from 'src/layouts/children/SingleFullColumnLayout'
import DoubleColumnLayout from 'src/layouts/children/DoubleColumnLayout'
import SingleStoryLayout from 'src/layouts/children/SingleStoryLayout'

// Lazy components mapping
export const componentsByLayout = {
  main_layout: MainLayout,
  single_column_layout: SingleColumnLayout,
  single_story_layout: SingleStoryLayout,
  single_full_column_layout: SingleFullColumnLayout,
  double_column_layout: DoubleColumnLayout
}
