import React from 'react'
import Spinner from '../ui/Spinner'
import styles from './SpinnerPage.module.sass'

const SpinnerPage = () => (
  <div
    className={styles.fixedPage}
    data-testid="spinner-page"
    role="progressbar"
    aria-busy="true"
    aria-label="Loading"
  >
    <div className={styles.logoutPage} data-testid="spinner-container">
      <Spinner
        fillColor="transparent"
        strokeColor="#fff"
        data-testid="spinner-element"
        aria-hidden="true"
      />
    </div>
  </div>
)

export default SpinnerPage
