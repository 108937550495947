import _get from 'lodash/get'
import {
  licenseTypes,
  audienceTypes
} from 'src/services/FW5ML/parsers/UserParser.js'

class NewslettersRepository {
  constructor(Fw5MLApi, NewsletterParser) {
    this.Fw5MLApi = Fw5MLApi
    this.NewsletterParser = NewsletterParser
  }

  async getNewsletters() {
    const req = await this.Fw5MLApi.fetch(`/newsletters`)
    const { data = [] } = req.data
    const nls = data.map(newsletter => this.NewsletterParser.mlToUi(newsletter))
    return this.NewsletterParser.filterTypes(nls)
  }

  async getNewslettersFilteredByRole(nls, role) {
    const map = {
      [licenseTypes.LICENSE_PLUS]: audienceTypes.PLUS,
      [licenseTypes.LICENSE_FREE]: audienceTypes.FREE
    }
    const audience = map[role]
    if (!audience) throw new Error(`Role ${role} is not valid.`)
    return nls.filter(
      nl => _get(nl, 'audience.subscription_types', []).indexOf(audience) > -1
    )
  }

  async getNewslettersByRole(role) {
    return await this.getNewsletters()
  }

  async getNewsletterById(nls, id) {
    return nls.find(newsletter => newsletter.id === id)
  }
}

export default NewslettersRepository
