import React from 'react'
import { Link } from 'react-router-dom'
import Page from '../../../../components/Page'
import Typography from '../../../../components/ui/Typography'
import styles from '../../../../modules/Static/Static.module.sass'
import cx from 'classnames'

const DeviceLimitPage = () => {
  return (
    <Page className={cx(styles.static, styles.centered)}>
      <Typography type="title" className={styles.title} paragraph>
        Device limit
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        You have reached the limit of devices that you can use to sign in.
        <br />
        Please{' '}
        <Link className={styles.link} to="/contact-us">
          contact support
        </Link>{' '}
        to solve this issue.
      </Typography>
    </Page>
  )
}

export default DeviceLimitPage
