import siteIds from '../constants/siteIds'

const healthTechCategoriesMap = {
  fw_mdCompanies: 'fw_company',
  fw_mdProducts: 'fw_product',
  fw_mdMarketSegments: 'fw_market_segment'
}

class InterestMapper {
  mapInterest(interest) {
    const processes = [this.healthTechExclusiveCategories]
    let processed = interest
    for (let process of processes) {
      processed = process(processed)
    }
    return processed
  }

  healthTechExclusiveCategories(interest) {
    const siteId = process.env.REACT_APP_SITE_ID
    if (siteId !== siteIds.SITE_ID_HEALTHTECH) return interest
    if (healthTechCategoriesMap[interest.category]) {
      return {
        ...interest,
        category: healthTechCategoriesMap[interest.category]
      }
    }
    return interest
  }
}

export default InterestMapper
