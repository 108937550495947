import React, { useState, useEffect, useRef, cloneElement } from 'react'
import { useLocation } from 'react-router-dom'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Slider from '@mui/material/Slider'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CloseIcon from '@mui/icons-material/Close'
import './TextToSpeech.css'

// Helper function to recursively extract plain text from React elements
const extractTextFromReactElement = element => {
  if (typeof element === 'string') {
    return element
  }
  if (Array.isArray(element)) {
    return element.map(extractTextFromReactElement).join('')
  }
  if (element.props && element.props.children) {
    return extractTextFromReactElement(element.props.children)
  }
  return ''
}

// List of allowed voices
const ALLOWED_VOICES = ['Martha', 'Nicky', 'Samantha', 'Aaron']

// Load user settings from localStorage if they exist
const loadSettingsFromLocalStorage = () => {
  const settings = localStorage.getItem('textToSpeechSettings')
  return settings ? JSON.parse(settings) : {}
}

// Save user settings to localStorage
const saveSettingsToLocalStorage = settings => {
  localStorage.setItem('textToSpeechSettings', JSON.stringify(settings))
}

const TextToSpeech = ({ children, config = {} }) => {
  const location = useLocation() // Hook to detect when the URL changes (i.e., navigating to a new article)
  const [isPlaying, setIsPlaying] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState(null)
  const [settingsOpen, setSettingsOpen] = useState(false) // Modal state for settings
  const speechInstanceRef = useRef(null)
  const synth = window.speechSynthesis

  // Load settings from localStorage or fallback to config/defaults
  const initialSettings = loadSettingsFromLocalStorage() || config
  const [pitch, setPitch] = useState(initialSettings.pitch || config.pitch || 1)
  const [rate, setRate] = useState(initialSettings.rate || config.rate || 1)
  const [volume, setVolume] = useState(
    initialSettings.volume || config.volume || 1
  )
  const [voice, setVoice] = useState(
    initialSettings.voice || config.voice || 'Martha'
  )

  // Cache voices globally to avoid re-fetching
  const availableVoicesRef = useRef([])

  // Extract plain text from the parsed React elements (children)
  const plainText = extractTextFromReactElement(children)

  const resetSpeech = () => {
    synth.cancel() // Cancel any ongoing speech
    if (speechInstanceRef.current) {
      setIsPlaying(false)
      setHighlightedIndex(null)
    }
  }

  // Load voices and ensure voice selection works properly
  const loadVoices = () => {
    const voices = synth.getVoices()
    if (voices.length > 0) {
      availableVoicesRef.current = voices.filter(v =>
        ALLOWED_VOICES.includes(v.name)
      ) // Only include allowed voices
    }
  }

  useEffect(() => {
    loadVoices()
    // Ensure voices are loaded asynchronously and available for use
    window.speechSynthesis.onvoiceschanged = () => loadVoices()

    return () => {
      window.speechSynthesis.onvoiceschanged = null
    }
  }, [])

  useEffect(() => {
    // Reset the speech synthesis system when navigating to a new article
    resetSpeech()

    // Create a new speech instance every time the article or location changes
    const speechInstance = new SpeechSynthesisUtterance(plainText)
    speechInstanceRef.current = speechInstance

    // Set selected voice if available
    const selectedVoice = availableVoicesRef.current.find(v => v.name === voice)
    if (selectedVoice) {
      speechInstance.voice = selectedVoice
    } else if (availableVoicesRef.current.length > 0) {
      speechInstance.voice = availableVoicesRef.current[0] // Fallback to the first allowed voice
    }

    // Set speech synthesis properties
    speechInstance.pitch = pitch
    speechInstance.rate = rate
    speechInstance.volume = volume

    // Listen for word boundaries to update the highlight
    speechInstance.onboundary = event => {
      if (event.charIndex !== undefined) {
        setHighlightedIndex(event.charIndex) // Update the highlighted index
      }
    }

    // When speech ends, reset the playing state and highlighted word
    speechInstance.onend = () => {
      setIsPlaying(false)
      setHighlightedIndex(null) // Reset highlighted word
    }

    return () => {
      resetSpeech() // Ensure speech is reset if component unmounts or changes
    }
  }, [plainText, pitch, rate, volume, voice, location])

  const handlePlay = () => {
    if (speechInstanceRef.current && !isPlaying) {
      synth.speak(speechInstanceRef.current) // Start speaking
      setIsPlaying(true)
    }
  }

  const handleStop = () => {
    if (isPlaying) {
      synth.cancel() // Stop the speech
      setIsPlaying(false)
      setHighlightedIndex(null) // Reset highlighted word
    }
  }

  // Function to recursively highlight words within nested elements only if playing
  const highlightTextInChildren = children => {
    let charIndex = 0

    const processElement = child => {
      if (typeof child === 'string') {
        return child.split(/(\s+)/).map(wordOrSpace => {
          const start = charIndex
          const end = charIndex + wordOrSpace.length
          charIndex = end

          const isHighlighted =
            isPlaying && start <= highlightedIndex && highlightedIndex < end

          return (
            <span
              key={`${start}-${wordOrSpace.trim()}`}
              className={isHighlighted ? 'text-highlight' : ''}
            >
              {wordOrSpace}
            </span>
          )
        })
      }

      // Ensure we always return an element, even if child has no children
      return child && child.props && child.props.children
        ? cloneElement(child, {
            children: React.Children.map(child.props.children, processElement)
          })
        : child
    }

    return React.Children.map(children, processElement)
  }

  // Handle opening/closing the settings modal
  const handleOpenSettings = () => setSettingsOpen(true)
  const handleCloseSettings = () => setSettingsOpen(false)

  // Handle apply settings
  const handleApplySettings = () => {
    // Save settings to localStorage
    const newSettings = { pitch, rate, volume, voice }
    saveSettingsToLocalStorage(newSettings)

    // Close settings modal
    handleCloseSettings()
  }

  return (
    <div>
      {!isPlaying && (
        <IconButton onClick={handlePlay} title="Start Reading Aloud">
          <PlayArrowIcon fontSize="small" />
        </IconButton>
      )}
      {isPlaying && (
        <IconButton onClick={handleStop} title="Stop Reading Aloud">
          <StopIcon fontSize="small" />
        </IconButton>
      )}

      {/* Settings Button */}
      <IconButton onClick={handleOpenSettings} title="Change Settings">
        <MoreVertIcon fontSize="small" />
      </IconButton>

      {/* Settings Modal */}
      <Modal open={settingsOpen} onClose={handleCloseSettings}>
        <div className="modal-container">
          <dialog className="settings-modal" aria-modal="true">
            <div className="modal-header">
              <h2>Speech Settings</h2>
              <IconButton
                onClick={handleCloseSettings}
                title="Close Settings"
                className="close-icon"
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div>
              <label htmlFor="pitch-slider">Pitch</label>
              <Slider
                id="pitch-slider"
                value={pitch}
                min={0.5}
                max={2}
                step={0.1}
                onChange={(e, val) => setPitch(val)}
                valueLabelDisplay="auto"
                sx={{ color: '#e27746' }}
              />
            </div>
            <div>
              <label htmlFor="rate-slider">Rate</label>
              <Slider
                id="rate-slider"
                value={rate}
                min={0.5}
                max={2}
                step={0.1}
                onChange={(e, val) => setRate(val)}
                valueLabelDisplay="auto"
                sx={{ color: '#e27746' }}
              />
            </div>
            <div>
              <label htmlFor="volume-slider">Volume</label>
              <Slider
                id="volume-slider"
                value={volume}
                min={0}
                max={1}
                step={0.1}
                onChange={(e, val) => setVolume(val)}
                valueLabelDisplay="auto"
                sx={{ color: '#e27746' }}
              />
            </div>
            <div>
              <label htmlFor="voice-select">Voice</label>
              <Select
                id="voice-select"
                value={voice}
                variant="standard"
                onChange={e => setVoice(e.target.value)}
                sx={{
                  ml: 2,
                  color: '#e27746',
                  '.MuiOutlinedInput-notchedOutline': { borderColor: '#e27746' }
                }}
              >
                {availableVoicesRef.current.map(v => (
                  <MenuItem key={v.name} value={v.name}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Button
              variant="contained"
              onClick={handleApplySettings}
              sx={{
                backgroundColor: '#e27746',
                '&:hover': { backgroundColor: '#d46b3b' }
              }}
            >
              Apply
            </Button>
          </dialog>
        </div>
      </Modal>

      {/* Render children with highlighted words only when playing */}
      <div>{highlightTextInChildren(children)}</div>
    </div>
  )
}

export default TextToSpeech
