export const getSiteName = () => {
  const appName = process.env.REACT_APP_SITE_NAME || 'PHARMA'
  const edgeRegex = /^edge$/i
  const healthtechRegex = /^healthtech$/i
  const reportsRegex = /^reports$/i
  const fwReportsRegex = /^fwreports$/i

  if (edgeRegex.test(appName)) {
    return 'Edge'
  }

  if (healthtechRegex.test(appName)) {
    return 'HealthTech'
  }
  if (reportsRegex.test(appName)) {
    return 'Reports'
  }

  if (fwReportsRegex.test(appName)) {
    return 'Reports'
  }

  return 'Pharma'
}

export const allSubscriptionTypes = {
  Edge: {
    PLUS: 'FirstWord Edge Oncology',
    FREE: 'FirstWord Edge Lite'
  },
  HealthTech: {
    PLUS: 'FirstWord HealthTech PLUS',
    FREE: 'FirstWord HealthTech'
  },
  Reports: {
    PLUS: 'FirstWord Reports Premium',
    FREE: 'FirstWord Reports'
  },
  Pharma: {
    PLUS: 'FirstWord Pharma PLUS',
    FREE: 'FirstWord Pharma'
  }
}

export const getSubscriptionTypes = () => {
  return allSubscriptionTypes[siteName]
}

export const getFreeSubscriptionTypes = () => {
  return Object.keys(allSubscriptionTypes).map(
    subscription => allSubscriptionTypes[subscription]?.FREE
  )
}

export const getPlusSubscriptionTypes = () => {
  return Object.keys(allSubscriptionTypes).map(
    subscription => subscriptions[subscription]?.PLUS
  )
}

export const getSiteDomain = () => {
  switch (siteName) {
    case 'Edge':
      return 'firstwordedge.com'

    case 'HealthTech':
      return 'firstwordhealthtech.com'

    default:
      return 'firstwordpharma.com'
  }
}

export const getOtherSitesDomains = () => {
  switch (siteName) {
    case 'Edge':
      return ['firstwordhealthtech.com', 'firstwordpharma.com']

    case 'HealthTech':
      return ['firstwordedge.com', 'firstwordpharma.com']

    default:
      return ['firstwordedge.com', 'firstwordhealthtech.com']
  }
}

const getSocialMedialInfo = () => {
  switch (siteName) {
    case 'Edge':
      return {
        twitter: '@fw_edge',
        linkedin: 'firstwordedge.com'
      }

    case 'HealthTech':
      return {
        twitter: '@fwhealthtech',
        linkedin: 'firstwordhealthtech.com'
      }

    default:
      return {
        twitter: '@fwpharma',
        linkedin: 'firstwordpharma.com'
      }
  }
}

const getSiteStoryViews = () => {
  if (siteName === 'Reports') {
    return {
      relatedNews: 'related news'
    }
  }

  return {
    recentNews: 'recent news',
    relatedAnalysis: 'related analysis',
    relatedNews: 'related news'
  }
}

export const siteName = getSiteName()
export const siteDomain = getSiteDomain()
export const socialMediaInfo = getSocialMedialInfo()
export const otherSitesDomains = getOtherSitesDomains()
export const siteStoryViews = getSiteStoryViews()
