const createFlags = (requestState, payload) => {
  const state = {
    busy: false,
    error: null
  }
  if (requestState === 'REQUEST') {
    state.busy = true
  } else if (requestState === 'ERROR') {
    state.error = payload
  } else {
    return undefined
  }
  return state
}

export default (state = {}, action) => {
  const { type, payload, meta = {} } = action
  if (!meta.asyncFlags) return state

  const matches = /(.*)__(REQUEST|SUCCESS|ERROR)$/.exec(type)

  // If it doesn't match our async pattern, ignore
  if (!matches) return state

  const [, requestName, requestStatus] = matches
  const requestState = createFlags(requestStatus, payload)

  // Build a dynamic branch in the state
  return {
    ...state,
    [requestName]: requestState
  }
}
