import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Chip
} from '@mui/material'

import styles from './aiAction.module.sass'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { removePaywall } from 'src/modules/Paywall/slice'
import firstWordAILogo from 'src/images/FirstWordAI.png'
import { useDispatch } from 'react-redux'
import Header from './header'

const AIPaywall = ({
  showRegistration,
  featureText,
  headerColor,
  showRegistrationButton,
  showCounter
}) => {
  const dispatch = useDispatch()

  const handleCloseModal = () => {
    dispatch(removePaywall())
  }

  return (
    <div className={styles.modalBackdrop}>
      <Card className={styles.pharmaSubscribeContainer}>
        <CardHeader
          className={styles.cardHeader}
          component={() => (
            <Header
              onClose={handleCloseModal}
              showRegistrationButton={showRegistrationButton}
              showCounter={showCounter}
              featureText={featureText}
              headerColor={headerColor}
            />
          )}
          title={
            <Typography
              variant="h6"
              component="h2"
              className={styles.cardHeaderTitle}
            >
              FirstWord AI is a feature exclusive to specific FirstWord
              licences.
            </Typography>
          }
        />

        <CardContent
          className={styles.cardContent}
          sx={{
            '@media (max-width: 680px)': {
              padding: '30px'
            }
          }}
        >
          <Box
            sx={{
              maxHeight: 'calc(100vh - 200px)',
              overflowY: 'auto',
              padding: '16px',
              '@media (max-width: 600px)': {
                maxHeight: 'calc(100vh - 250px)'
              }
            }}
          >
            <Grid container>
              <Grid sm={1} />
              <Grid item xs={12} sm={7}>
                <Box className={styles.chipAndTitleContainer}>
                  <img
                    src={firstWordAILogo}
                    className={styles.logo}
                    alt="FirstWord AI"
                  />

                  <Chip
                    variant="outlined"
                    label="Exclusive Content"
                    sx={{
                      borderColor: '#2d3e50ff',
                      color: '#2d3e50ff',
                      borderRadius: '8px',
                      '@media (max-width: 680px)': {
                        borderRadius: '2px',
                        height: '22px',
                        marginTop: '5px'
                      }
                    }}
                  />
                </Box>
                <Typography
                  className={styles.powered}
                  sx={{ fontSize: '.8rem', paddingTop: '8px' }}
                >
                  Powered by Dougall GPT
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  className={styles.pharmaInsightsTitle}
                  sx={{
                    fontSize: {
                      xs: '1rem',
                      sm: '1.25rem'
                    }
                  }}
                >
                  Experience the Efficiency of AI-Powered Assistance
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.pharmaInsightsListItem}
                  sx={{
                    '@media (max-width: 680px)': {
                      fontSize: '.9rem',
                      color: '#595959',
                      lineHeight: '1.1rem'
                    }
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: '#ec8756ff',
                      marginTop: '5px'
                    }}
                  />
                  Leverage timely pharma news and intelligence, surpassing
                  conventional AI models like ChatGPT and other LLMs.
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.pharmaInsightsListItem}
                  sx={{
                    '@media (max-width: 680px)': {
                      fontSize: '.9rem',
                      color: '#595959',
                      lineHeight: '1.1rem'
                    }
                  }}
                >
                  <CheckCircleIcon sx={{ marginTop: '5px' }} />
                  Streamline the process of finding information and insights,
                  saving valuable time by focusing on relevant industry content.
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.pharmaInsightsListItem}
                  sx={{
                    '@media (max-width: 680px)': {
                      fontSize: '.9rem',
                      color: '#595959',
                      lineHeight: '1.1rem'
                    }
                  }}
                >
                  <CheckCircleIcon sx={{ marginTop: '5px' }} />
                  Efficiently conduct research and assess critical data,
                  empowering you to make decisions with speed and confidence.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box className={styles.buttonsContainer}>
                  <Button
                    variant="contained"
                    size="xlarge"
                    style={{
                      backgroundColor: '#ec8756ff',
                      color: 'white',
                      alignItems: 'flex-end',
                      textTransform: 'capitalize'
                    }}
                    className={styles.requestButton}
                    sx={{
                      '@media (max-width: 680px)': {
                        width: '100%',
                        maxWidth: '100%'
                      }
                    }}
                    onClick={() =>
                      showRegistration({ trackingAction: 'contact-us' })
                    }
                  >
                    Contact Me
                  </Button>
                  <Typography
                    className={styles.contactText}
                    sx={{
                      '@media (max-width: 680px)': {
                        fontSize: '0.9rem',
                        lineHeight: '1.1rem'
                      }
                    }}
                  >
                    Request access from your FirstWord consultant or to be
                    notified of availability of this feature
                  </Typography>
                </Box>
              </Grid>

              <Grid item sm={1} />
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}

AIPaywall.propTypes = {
  showRegistration: PropTypes.func,
  featureText: PropTypes.string,
  headerColor: PropTypes.string,
  showRegistrationButton: PropTypes.bool,
  showCounter: PropTypes.bool
}

export default AIPaywall
