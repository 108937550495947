import ads from './constants/ads.json'
import { AD_TYPE_AIMATCH, AD_TYPE_HARDCODED } from './constants/adTypes'
class Ads {
  constructor(Session, AiMatch) {
    this.Session = Session
    this.AiMatch = AiMatch
  }

  async handleHardcodedAd(ad) {
    const currentAd = ads[ad.id]
    if (!currentAd)
      return {
        ...ad,
        src: `http://placehold.it/${parseInt(ad.width, 10)}x${parseInt(
          ad.height,
          10
        )}?text=aiMatch%20Ad`,
        link: '/'
      }
    return { ...ad, src: currentAd.image, link: currentAd.link }
  }

  async handleAiMatchAd(ad) {
    const profile = await this.Session.userLoad()
    const aimatchURL = await this.AiMatch.getRequestUrl({
      ...ad,
      profile
    })
    const adProps = await this.AiMatch.getAimatchAdProperties(
      ad,
      aimatchURL,
      profile
    )
    return { ...ad, ...adProps }
  }

  async generateAd(ad) {
    const handlers = {
      [AD_TYPE_HARDCODED]: ad => this.handleHardcodedAd(ad),
      [AD_TYPE_AIMATCH]: ad => this.handleAiMatchAd(ad)
    }
    const handler =
      handlers[ad.type || AD_TYPE_HARDCODED] || handlers[AD_TYPE_HARDCODED]

    const result = await handler(ad)
    return result
  }
}

export default Ads
