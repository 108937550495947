// Code shared from phoenix team for viewability
import { all, takeEvery, put, select } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { handleAxiosGetRequest } from 'src/utils/index.js'

function* adsViewTrackRequest(services, { payload: { area, id, src, url } }) {
  try {
    const trackRequestStatus = yield select(
      state => state.viewability.trackRequestStatus
    )
    if (!area || !id || !src || !url) return
    if (
      trackRequestStatus[area][id].length === 0 ||
      !trackRequestStatus[area][id][src]
    ) {
      handleAxiosGetRequest(url)
      yield put(actions.adsViewTrackSuccess({ area, id, src }))
    }
  } catch (e) {
    console.error(e)
    yield put(actions.adsViewTrackError({ area, id, src, error: e.message }))
  }
}

export default function* watchAds(services) {
  yield all([
    takeEvery('ADS_VIEW_TRACK_REQUEST', adsViewTrackRequest, services)
  ])
}
