import React from 'react'
import PropTypes from 'prop-types'
import BaseSkeleton from './styles'

const Skeleton = props => (
  <BaseSkeleton
    color={props.color}
    radius={props.radius}
    w={props.w}
    h={props.h}
    shineColor={props.shineColor}
  />
)

Skeleton.propTypes = {
  color: PropTypes.string,
  radius: PropTypes.number,
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shineColor: PropTypes.string
}

export default Skeleton
