import { all } from 'redux-saga/effects'
import { default as singleStorySaga } from './SingleStory/saga'
import { default as viewsSaga } from './Views/saga'
import { default as bootstrappingSaga } from './Bootstrapping/saga'
import { default as adsSaga } from './Ads/saga'
import { default as catalogsSaga } from './Catalogs/saga'
import { default as loginSaga } from './Login/saga'
import { default as contentRiverSaga } from './River/saga'
import { default as contactUsSaga } from './ContactUs/saga'
import { default as paymentSaga } from './Payment/saga'
import { default as trackingSaga } from './Tracking/saga'
import { default as registerSaga } from './Register/saga'
import { default as myInterestsSaga } from './MyInterests/saga'
import { default as myNewslettersSaga } from './MyNewsletters/saga'
import { default as myAccountSaga } from './MyAccount/saga'
import { default as mySavedSearchesSaga } from './MySavedSearches/saga'
import { default as mySettingsSaga } from './MySettings/saga'
import { default as myReportsSaga } from './MyReports/saga'
import { default as sessionSaga } from './Session/saga'
import { default as benefitsSaga } from './Benefits/saga'
import { default as captchaSaga } from './Captcha/saga'
import { default as noticesSaga } from './Notice/saga'
import { default as viewabilitySaga } from './Viewability/saga'
import { default as landingPageSaga } from './GatedLandingPage/saga'
import { default as configSaga } from './Config/saga'
import { default as dynamicRiverSaga } from './DynamicRiver/saga'
import { default as piwikSaga } from './Piwik/saga'
import { default as translationSaga } from 'src/modules/translation/TranslationSaga'
import { default as themeProviderSaga } from 'src/modules/themeProvider/ThemeProviderSaga'
import { default as chatSaga } from 'src/modules/DougallChat/ChatSaga'
import { default as historySaga } from 'src/modules/history/HistorySaga'
import { default as faqSaga } from 'src/modules/faq/FaqSaga'
import { default as liveFeedSaga } from 'src/modules/LiveFeed/LiveFeedSaga'
import { default as actionButtonsSaga } from 'src/components/ActionButtons/saga'

export default function* rootSaga(services) {
  yield all([
    contentRiverSaga(services),
    singleStorySaga(services),
    viewsSaga(services),
    bootstrappingSaga(services),
    adsSaga(services),
    catalogsSaga(services),
    loginSaga(services),
    contactUsSaga(services),
    paymentSaga(services),
    trackingSaga(services),
    registerSaga(services),
    myAccountSaga(services),
    myInterestsSaga(services),
    myNewslettersSaga(services),
    mySavedSearchesSaga(services),
    mySettingsSaga(services),
    myReportsSaga(services),
    sessionSaga(services),
    benefitsSaga(services),
    captchaSaga(services),
    noticesSaga(services),
    viewabilitySaga(services),
    landingPageSaga(services),
    configSaga(services),
    dynamicRiverSaga(services),
    piwikSaga(services),
    themeProviderSaga(services),
    translationSaga(services),
    chatSaga(services),
    historySaga(services),
    faqSaga(services),
    liveFeedSaga(services),
    actionButtonsSaga(services)
    // ...Add more sagas
  ])
}
