import { compose } from 'redux'
import { connect } from 'react-redux'
import DynamicRiverBody from './components/DynamicRiverBody'
import { actions } from 'src/modules/DynamicRiver/reducer.js'

const mapDispatchToProps = dispatch => ({
  getRiverContent: data => dispatch(actions.requestDynamicRiverContent(data)),
  loadMoreRiverContent: data => dispatch(actions.requestLoadMore(data))
})

const mapStateToProps = state => ({
  dynamicRivers: state.dynamicContentRiver
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DynamicRiverBody
)
