import React from 'react'
import { ProtectedSwitch, ShowFor, ShowIfNone } from './index'

const withProtection = (WrappedComponent, options) => props => {
  const { showFor, showForOthers } = options
  return (
    <ProtectedSwitch>
      <ShowFor {...showFor}>
        <WrappedComponent {...props} />
      </ShowFor>
      {showForOthers && <ShowIfNone>{showForOthers}</ShowIfNone>}
    </ProtectedSwitch>
  )
}

export default withProtection
