// React
import React from 'react'
import PropTypes from 'prop-types'

/**
 * The function `ComponentLoopItemMapping` retrieves the corresponding component
 * based on the item's component key, and renders that component with the props.
 */
const ComponentLoopItemMapping = ({ mapping = null, ...props }) => {
  const item = props?.item || null
  const Component = mapping ? mapping[item?.component] : null
  if (!Component) return null
  return <Component {...props} />
}

ComponentLoopItemMapping.propTypes = {
  mapping: PropTypes.object,
  item: PropTypes.object
}

export default ComponentLoopItemMapping
