import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Button from '../../../../components/ui/Buttons/Button'
import { Input } from '../../../../components/ui/Input'
import Typography from '../../../../components/ui/Typography'
import commonStyles from '../../../../styles/common.module.sass'
import styles from './ConsentForm.module.sass'

const ConsentForm = ({ handleSubmit, busy = false }) => {
  return (
    <form
      onSubmit={handleSubmit}
      className={`${commonStyles.centered} ${styles.form}`}
    >
      <Field
        component={Input}
        label="First Name"
        name="first_name"
        errorAlign="center"
        readOnly={true}
      />
      <Field
        component={Input}
        label="Last Name"
        name="last_name"
        errorAlign="center"
        readOnly={true}
      />
      <div>
        <Field
          component={Input}
          label="Work Email"
          name="email"
          errorAlign="center"
          type="email"
          readOnly={true}
        />
        <Typography type="body1" align="center" className={styles.info}>
          Use your corporate/work email to make sure you are taking advantage of
          any benefits your company may have.
        </Typography>
      </div>
      <Field
        component={Input}
        label="Company"
        name="company"
        errorAlign="center"
        readOnly={true}
      />
      <Field
        component={Input}
        label="Job Title"
        name="job_title"
        errorAlign="center"
        readOnly={true}
      />
      <Button
        type="submit"
        tier="secondary"
        submitting={busy}
        className={styles.button}
        hasSpinner
      >
        Submit
      </Button>
    </form>
  )
}

ConsentForm.propTypes = {
  handleSubmit: PropTypes.func,
  busy: PropTypes.bool
}

export default ConsentForm
