import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import ScrollToTop from './components/ScrollToTop'
import routes, { getDynamicRoutes } from './modules/routes'
import { createBrowserHistory } from 'history'
import MobileLeftMenu from './modules/MobileMenu/containers/LeftMenuContainer'
import MobileMenu from './modules/MobileMenu/containers/MenuContainer'
import PrestitialAd from './components/PrestitialAd/PrestitialAdContainer.js'
import LoginRegisterModalPage from './components/LoginRegisterModalPage/LoginRegisterModalPageContainer'
import { getConfig } from './modules/Config/selectors'
import PaywallFlowModal from './modules/Paywall'
import { siteName } from './utils/oneCodeBase'
import useLaunchDarkly from './modules/LaunchDarkly/useLaunchDarkly'
import Notifications from 'src/modules/Notifications/NotificationsContainer'

export const history = createBrowserHistory()

const Router = () => {
  // Config Selectors
  const configBootstrapped =
    useSelector(state => state?.config?.bootstrapped) || false

  const pagesConfig = useSelector(getConfig('pages')) || []

  // Routes set up
  const dynamicRoutes = getDynamicRoutes(pagesConfig)
  const finalRoutes = [...dynamicRoutes, ...routes]

  const { featureFlagConfig, flags } = useLaunchDarkly()

  // Feature flag validation
  const validFeatureFlag =
    siteName === 'Reports' && process.env.NODE_ENV !== 'development'
      ? flags?.newFwReportPlatform
      : true

  useEffect(() => {
    if (featureFlagConfig?.initialized && validFeatureFlag === false) {
      window.location.href = 'https://firstwordreports.com/'
    }
  }, [featureFlagConfig?.initialized])

  if (
    !featureFlagConfig?.initialized ||
    !configBootstrapped ||
    validFeatureFlag === false
  ) {
    return <></>
  }

  return (
    <ConnectedRouter history={history}>
      <Notifications>
        <ScrollToTop>
          <MobileMenu />
          <MobileLeftMenu />
          <PrestitialAd />
          <LoginRegisterModalPage />
          <PaywallFlowModal />
          <Switch>
            {finalRoutes.map((route, i) => (
              <Route key={`route-${route.path}-${i}`} {...route} />
            ))}
          </Switch>
        </ScrollToTop>
      </Notifications>
    </ConnectedRouter>
  )
}

export default Router
