import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Typography from '../../../../components/ui/Typography'
import AboutUs from '../../../../components/AboutUs'
import StorySpinner from '../../../../components/StorySpinner'
import FullImage from '../../../../components/FullImage'
import Page from '../../../../components/Page'
import landingCompanyStyles from '../../../../modules/LandingCompanyInfo/components/LandingCompanyInfo.module.sass'
import LandingCompanyInfo from '../../../../modules/LandingCompanyInfo/containers/LandingCompanyInfoContainer'
import ConsentForm from '../../containers/ConsentFormContainer'
import { ArticlesList } from '../Landing'
import commonStyles from '../../../../styles/common.module.sass'
import styles from './Consent.module.sass'
import cx from 'classnames'
import Contributors from '../../components/Contributors'
import ContributorsList from '../../components/ContributorsList'

const Consent = ({
  loadCompanyContent,
  company = null,
  consent = false,
  busy = false,
  requestConfirmConsent = () => {},
  ...props
}) => {
  const handleSubmit = values => {
    handleTracking('button', 'click', 'submit')
    requestConfirmConsent(values)
  }

  const handleTracking = (category, action, name) => {
    const { Piwik } = props.services
    Piwik.track(category, action, name)
  }

  useEffect(() => {
    loadCompanyContent({
      type: 'consent'
    })
  }, [])

  const asset = company?.asset?.type || {
    type: 'screenshot'
  }

  const {
    contributors = [],
    contributorsList = [],
    contributorsExtraItems = 0
  } = company ?? {}

  return (
    <Page>
      {busy ? (
        <StorySpinner />
      ) : (
        company &&
        !consent && (
          <>
            <div className={cx(commonStyles.content, styles.container)}>
              <LandingCompanyInfo
                data={company}
                parentServices={props.services}
                parentPage="Consent"
              />
              <section className={company && styles.landingSection}>
                <div className={company && styles.formBorders}>
                  <Typography type="headline" gutterBottom>
                    {company?.consentForm?.title ||
                      'Confirm Your Information Below'}
                  </Typography>
                  <ConsentForm onSubmit={handleSubmit} />
                  <Typography
                    type="body1"
                    align="center"
                    className={styles.info}
                  >
                    {company?.consentForm?.footer ||
                      `I acknowledge and agree to the use of my contact information to
              receive messages about offerings by FirstWord, its brands,
              affiliates and/or third-party partners, consistent with`}{' '}
                    <Link className={styles.link} to="/privacy">
                      FirstWord’s Privacy Policy
                    </Link>
                  </Typography>
                </div>
              </section>
              {company && asset && asset.type === 'screenshot' ? (
                <div className={landingCompanyStyles.largeViewImg}>
                  <FullImage
                    src={company.screenshot}
                    objectFit="cover"
                    width="100%"
                    height="100%"
                  />

                  {contributors && contributors.length > 0 ? (
                    <div className={styles.contributors}>
                      <Contributors contributors={contributors ?? []} />
                    </div>
                  ) : (
                    contributorsList &&
                    contributorsList.length > 0 && (
                      <div className={styles.contributors}>
                        <ContributorsList
                          contributors={contributorsList ?? []}
                          extraItems={contributorsExtraItems}
                        />
                      </div>
                    )
                  )}
                </div>
              ) : (
                company?.asset && (
                  <div className={landingCompanyStyles.largeViewImg}>
                    <ArticlesList
                      accesible={false}
                      articles={company.asset.articles}
                      articlesHtml={company.asset.articlesHtml}
                    />

                    {contributors && contributors.length > 0 ? (
                      <div className={styles.contributors}>
                        <Contributors contributors={contributors ?? []} />
                      </div>
                    ) : (
                      contributorsList &&
                      contributorsList.length > 0 && (
                        <div className={styles.contributors}>
                          <ContributorsList
                            contributors={contributorsList || []}
                            extraItems={contributorsExtraItems}
                          />
                        </div>
                      )
                    )}
                  </div>
                )
              )}
            </div>
            {company?.sponsor && <AboutUs sponsor={company.sponsor} />}
          </>
        )
      )}
    </Page>
  )
}

Consent.propTypes = {
  loadCompanyContent: PropTypes.func,
  requestConfirmConsent: PropTypes.func,
  company: PropTypes.object,
  consent: PropTypes.bool,
  busy: PropTypes.bool,
  services: PropTypes.object
}

export default Consent
