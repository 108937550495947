import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestHistory = createAction('HISTORY_REQUEST')
export const requestHistorySuccess = createAction('HISTORY_REQUEST_SUCCESS')
export const requestHistoryFail = createAction('HISTORY_REQUEST_FAIL')

export const requestPromptHistory = createAction('PROMPT_HISTORY_REQUEST')
export const requestTitleChange = createAction('HISTORY_REQUEST_TITLE_CHANGE')

export const deleteHistory = createAction('HISTORY_DELETE')
export const deleteHistorySuccess = createAction('HISTORY_DELETE_SUCCESS')
export const deleteHistoryFail = createAction('HISTORY_DELETE_FAIL')

export const setClearAllOpen = createAction('HISTORY_CLEAR_ALL_OPEN')
export const setSelectedThread = createAction('HISTORY_SET_SELECTED_THREAD')
export const resetSelectedThread = createAction('HISTORY_RESET_SELECTED_THREAD')

export const actions = {
  requestHistory,
  requestHistorySuccess,
  requestHistoryFail,
  deleteHistory,
  deleteHistorySuccess,
  deleteHistoryFail,
  setClearAllOpen,
  setSelectedThread,
  resetSelectedThread,
  requestPromptHistory,
  requestTitleChange
}

const initialState = {
  history: [],
  rawHistory: [],
  fetching: true,
  deleting: false,
  deletingId: null,
  clearAllOpen: false,
  selectedThreadId: null
}

const historyReducer = createReducer(initialState, {
  [requestHistory]: state => {
    state.fetching = true
  },
  [requestHistorySuccess]: (state, { payload: { history, rawHistory } }) => {
    state.fetching = false
    state.history = history
    state.rawHistory = rawHistory
  },
  [requestHistoryFail]: state => {
    state.fetching = false
    state.history = []
    state.rawHistory = []
  },
  [deleteHistory]: (state, { payload: { id } }) => {
    state.deleting = true
    state.deletingId = id
  },
  [deleteHistorySuccess]: state => {
    state.deleting = false
    state.deletingId = null
  },
  [deleteHistoryFail]: state => {
    state.deleting = false
    state.deletingId = null
  },
  [setClearAllOpen]: (state, { payload: { open } }) => {
    state.clearAllOpen = open
  },
  [setSelectedThread]: (state, { payload: { id } }) => {
    state.selectedThreadId = id
  },
  [resetSelectedThread]: state => {
    state.selectedThreadId = null
  }
})
export default historyReducer
