import React from 'react'
import PropTypes from 'prop-types'
import FullImage from 'src/components/FullImage/index.js'

const FullImageLinked = ({
  width,
  height,
  src = 'http://placehold.it/200x200',
  alt = '',
  objectFit,
  url = '#'
}) => {
  const newProps = {
    width,
    height,
    src,
    alt,
    objectFit
  }
  return (
    <a href={url}>
      <FullImage {...newProps} />
    </a>
  )
}

FullImageLinked.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  alt: PropTypes.string,
  objectFit: PropTypes.string,
  url: PropTypes.string
}

export default FullImageLinked
