import _get from 'lodash/get'
import { labelReplacementsConfigModule } from 'src/siteConfig/river.js'
const replacements = labelReplacementsConfigModule?.default || {}

class LabelReplacer {
  constructor() {
    this.replacements = replacements
    this.inverse = Object.keys(replacements).reduce((acc, category) => {
      const inverse = Object.keys(this.replacements[category]).reduce(
        (acc, key) => {
          return {
            ...acc,
            [this.replacements[category][key]]: key
          }
        },
        {}
      )
      return { ...acc, [category]: inverse }
    }, {})
  }
  getFrom(key, category = null, pool = this.replacements) {
    if (category) return _get(pool, `${category}.${key}`, key)
    for (let poolKey in pool) {
      const val = _get(pool[poolKey], key)
      if (val !== undefined) return val
    }
    return key
  }
  getForUI(key, category) {
    return this.getFrom(key, category, this.replacements)
  }
  getForML(key, category) {
    return this.getFrom(key, category, this.inverse)
  }
}

export default LabelReplacer
