import _get from 'lodash/get'
import _set from 'lodash/set'

// Alert Type
const BNA_TYPE = 'breaking_news'
const PMC_TYPE = 'promotional_campaign'

class NewsletterParser {
  constructor(getState) {
    this.getState = getState
  }
  mlToUi(newsletter) {
    const nl = {}
    const isRegionBased = _get(
      newsletter,
      'settings.delivery_time_settings.isRegionBased',
      false
    )
    const deliveryTimeSettings = _get(
      newsletter,
      'settings.delivery_time_settings.default'
    )

    _set(nl, 'dbID', _get(newsletter, 'id'))
    _set(nl, 'id', _get(newsletter, 'uuid'))
    _set(nl, 'name', _get(newsletter, 'title', ''))
    _set(nl, 'description', _get(newsletter, 'description', ''))
    _set(nl, 'frequency', _get(newsletter, 'recurrence'))
    _set(nl, 'audience', _get(newsletter, 'audience', {}))
    _set(nl, 'alertType', _get(newsletter, 'alert_type'))
    _set(nl, 'domain', _get(newsletter, 'domain'))
    _set(
      nl,
      'frequency_description',
      _get(newsletter, 'recurrence_description', '')
    )
    _set(nl, 'plusOnly', _get(newsletter, 'settings.plusOnly', false))

    _set(nl, 'newNewsletter', _get(newsletter, 'settings.newNewsletter', false))
    _set(nl, 'deliveryTimeSettings', deliveryTimeSettings)
    _set(nl, 'deliveryTimes', _get(deliveryTimeSettings, 'hours', []))
    _set(nl, 'isRegionBased', isRegionBased)
    _set(nl, 'settings', _get(newsletter, 'settings'))

    const regions = _get(
      newsletter,
      'settings.delivery_time_settings.regions',
      {}
    )
    _set(
      nl,
      'regions',
      Object.keys(regions).map(region => ({
        name: region,
        hour: regions[region].default
      }))
    )

    const deliveryTimes =
      (isRegionBased && regions?.length > 0) ||
      deliveryTimeSettings?.hours?.length > 0
    _set(nl, 'hasDeliveryTimes', deliveryTimes)
    return nl
  }
  filterTypes(newsletters) {
    const { config } = this.getState()
    const notifyMeNl =
      config?.notify_me_settings?.data?.newsletter_notify_me?.newsletter_uuid ??
      null

    return newsletters.filter(
      newsletter =>
        newsletter.alertType !== BNA_TYPE &&
        newsletter.alertType !== PMC_TYPE &&
        newsletter.id !== notifyMeNl
    )
  }
}

export default NewsletterParser
