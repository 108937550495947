import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { actions as historyActions } from 'src/modules/history/HistoryReducer'
import useChat from './useChat'

/**
 * Hook to handle new topic logic
 * @returns { handleNewTopic }
 */
const useTopic = () => {
  // Hooks
  const {
    addMessage,
    // currentPrompt,
    isFetching,
    resetCurrentPrompt,
    resetMessages,
    resetPromptNum,
    resetLinkedPromptId
  } = useChat()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Variables
  const newTopicAnswer = {
    isAnswer: true,
    showWarning: false,
    answer: t('newTopicAnswer'),
    showSocial: false
  }

  /**
   * Handle new topic function
   * This function cleans de chat state, fetches the history
   * and track the action
   */
  const handleNewTopic = () => {
    // Prevent click actions when answer is fetching
    if (!isFetching) {
      // Track
      // const { promptId, answerId } = currentPrompt

      // Refresh History
      dispatch(historyActions.resetSelectedThread())
      dispatch(historyActions.requestHistory())

      // Reset Chat
      resetMessages()
      resetCurrentPrompt()
      resetPromptNum()
      addMessage({
        date: moment().format('DD/MM/YYYY'),
        isDivider: true
      })
      addMessage(newTopicAnswer)

      // Reset Linked Prompt Id
      resetLinkedPromptId()
    }
  }

  return {
    handleNewTopic
  }
}
export default useTopic
