const getInitials = name => {
  const names = name.split(' ')
  let firstInitial
  if (names.length > 2) {
    firstInitial = names[1].charAt(0)
  }
  const lastInitial = names[names.length - 1].charAt(0)
  return `${firstInitial || ''}${lastInitial || ''}`
}

export default getInitials
