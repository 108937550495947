import { initializeTracking, setCustomVariable, trackEvent } from '@psl/piwik'
import { strToBool } from 'src/utils/index.js'

export const CUSTOM_VARIABLE_TYPE_VISIT = 'visit'
export const CUSTOM_VARIABLE_TYPE_PAGE = 'page'

const customVariables = {
  member_id: {
    id: 1,
    type: CUSTOM_VARIABLE_TYPE_VISIT
  },
  site_uid: {
    id: 13,
    type: CUSTOM_VARIABLE_TYPE_VISIT
  },
  event_id: {
    id: 12,
    type: CUSTOM_VARIABLE_TYPE_VISIT
  },
  project_id: {
    id: 5,
    type: CUSTOM_VARIABLE_TYPE_VISIT
  },
  psl_device_id: {
    id: 14,
    type: CUSTOM_VARIABLE_TYPE_VISIT
  },
  article_id: {
    id: 5,
    type: CUSTOM_VARIABLE_TYPE_PAGE
  }
}

class Piwik {
  constructor(config) {
    this.config = config
    this.initialized = false
    this.defered = []
  }

  initialize() {
    const siteId = this.config.PIWIK_SITEID
    const url = this.config.PIWIK_URL
    const secure = strToBool(this.config.PIWIK_SECURE)
    initializeTracking({ url, siteId, secure })
    this.initialized = true
    this.runDefered()
  }

  autoTrack(mapSelectorToTrack) {
    const body = document.getElementsByTagName('body')[0]
    const events = Object.keys(mapSelectorToTrack)
    events.forEach(event => {
      const selectorMap = mapSelectorToTrack[event]
      body.addEventListener(event, e => {
        if (e.target) {
          Object.keys(selectorMap)
            .filter(key => e.target.closest(key))
            .forEach(key => {
              const fn = selectorMap[key]
              const track = this.track.bind(this)
              fn(track, this, e)
            })
        }
      })
    })
  }

  pageview() {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['trackPageView'])
  }

  updateUrl(url) {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['setCustomUrl', url])
  }

  updateUser(user) {
    if (!this.initialized) throw new Error('Piwik not initialized')
    if (user) {
      window._paq.push(['setUserId', user?.party_id || user?.profile_id])
    }
  }

  updateTitle(title) {
    if (!title) return
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['setDocumentTitle', title])
  }

  updateCustomVariable(key, value, triggerUpdate = false) {
    if (!customVariables[key])
      throw new Error(`Unknown custom variable "${key}".`)
    if (!this.initialized) {
      return this.deferCustomVariable(key, value)
    } else {
      const cv = customVariables[key]
      setCustomVariable(cv.id, key, value, cv.type)
      // Trigger update
      if (triggerUpdate) {
        this.track('update', `update-${key}-in-custom-variable`)
      }
    }
  }

  deleteCustomVariable(key, triggerUpdate = false) {
    if (!this.initialized) throw new Error('Piwik not initialized')
    if (!customVariables[key])
      throw new Error(`Unknown custom variable "${key}".`)
    const cv = customVariables[key]
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['deleteCustomVariable', cv.id, cv.type])
    // Trigger update
    if (triggerUpdate) {
      this.track('update', `update-${key}-in-custom-variable`)
    }
  }

  deletePageCustomVariables() {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['deleteCustomVariables', 'page'])
  }

  forceNewVisit() {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://matomo.org/faq/how-to/faq_187/
    // window._paq.push(['resetUserId'])
    window._paq.push(['appendToTrackingUrl', 'new_visit=1'])
    // window._paq.push(['trackPageView'])
    window._paq.push(['deleteCookies'])
  }

  cleanForceNewVisit() {
    if (!this.initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://matomo.org/faq/how-to/faq_187/
    window._paq.push(['appendToTrackingUrl', ''])
  }

  cleanParam(param) {
    if (param) {
      param = param.trim().replace(/ /g, '-').toLowerCase()
    }
    return param
  }

  track(category, action, name, customData) {
    category = this.cleanParam(category)
    action = this.cleanParam(action)
    name = this.cleanParam(name)

    if (!this.initialized) {
      return this.deferTrack({ category, action, name, customData })
    }
    if (!customData) {
      return trackEvent(category, action, name)
    } else {
      // This is a direct usage of the Piwik API.
      // We are appending the user custom data
      window._paq.push([
        'appendToTrackingUrl',
        `user_data=${JSON.stringify(customData)}`
      ])
      const result = trackEvent(category, action, name)
      // This is a direct usage of the Piwik API.
      // Here we are cleaning up the custom data so it doesn't appear on further
      // events
      window._paq.push(['appendToTrackingUrl', ''])
      return result
    }
  }

  deferTrack(event) {
    return this.defered.push({ type: 'event', event })
  }

  deferCustomVariable(key, value) {
    return this.defered.push({ type: 'customVariable', key, value })
  }

  runDefered() {
    this.defered.forEach(defered => {
      const { type } = defered
      if (type === 'customVariable') {
        const { key, value } = defered
        const cv = customVariables[key]
        setCustomVariable(cv.id, key, value, cv.type)
      }
      if (type === 'event') {
        const {
          event: { category, action, name, customData }
        } = defered
        this.track(category, action, name, customData)
      }
    })
  }
}

export default Piwik
