import { createSelector } from '@reduxjs/toolkit'

export const singleStorySelector = state => state.singleStory

export const getStory = () =>
  createSelector(singleStorySelector, state => state.story)

export const getLoading = () =>
  createSelector(singleStorySelector, state => state.loading)

export const getIsLimited = () =>
  createSelector(singleStorySelector, state => state.isLimited)

export const getDisabled = () =>
  createSelector(singleStorySelector, state => state.disabledTags)

export const getRelatedUpdates = () =>
  createSelector(singleStorySelector, state => state.relatedUpdates)

export const getCombinedLoading = () =>
  createSelector(singleStorySelector, state => state.combinedLoading)

export const getLatestRelatedArticleDate = () =>
  createSelector(singleStorySelector, state =>
    state.relatedUpdates?.data?.criteria === 'nonBulletinRelatedReports'
      ? state.relatedUpdates.data.articles?.[0]?.publication_date_time
          ?.timestamp ?? null
      : null
  )
