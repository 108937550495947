import React from 'react'
import PropTypes from 'prop-types'

const PromotionalVideo = ({ className = '' }) => {
  return (
    <video
      className={className}
      id="aboutPromoVideo"
      preload="auto"
      controls
      playsInline
      controlsList="nodownload"
    >
      <source
        src="https://c.firstwordpharma.com/firstwordgroup/videos/fw_pharma_plus_promo_video_2022.mp4"
        type="video/mp4"
      ></source>
      Your browser does not support the video tag.
    </video>
  )
}

PromotionalVideo.propTypes = {
  /**
   * Component Styling class
   */
  className: PropTypes.string
}

export default PromotionalVideo
