import React from 'react'
import PropTypes from 'prop-types'
import styles from './SingleAsset.module.sass'
import GeneralContent from '../../components/GeneralContent'
import PromoButton from '../../components/PromoButton'
import { ArticlesList } from '../../pages/Landing'
import landingStyles from '../../pages/Landing/Landing.module.sass'
import Typography from '../../../../components/ui/Typography'
import cx from 'classnames'
import useMediaSize from '../../../../hooks/useMediaSize'
import Contributors from '../../components/Contributors'
import ContributorsList from '../../components/ContributorsList'

const SingleAsset = ({
  content,
  handleTracking = () => {},
  articlesHtml = []
}) => {
  const { articles = [] } = content
  const promoButtonUrl = articles[0].multimediaUrl || ''
  const name = articles[0].title || ''
  const promoButtonText = 'Download Now'

  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 600px)',
      medium: '(max-width: 959px)'
    }
  })

  return (
    <div className={styles.container}>
      <section className={styles.main}>
        {content.general && (
          <GeneralContent
            general={content.general}
            handleTracking={handleTracking}
          />
        )}

        {/* Article items */}
        {mediaSize === 'large' && articles && articles.length > 0 && (
          <ArticlesList
            articles={content.articles}
            handleTracking={handleTracking}
            articlesHtml={articlesHtml[0] ? articlesHtml[0] : {}}
          />
        )}
      </section>
      <aside className={cx(styles.sidebar, landingStyles.sidebar)}>
        {content.headingDownload && (
          <div className={styles.sidebarInfo}>
            <Typography type="title" className={landingStyles.title} paragraph>
              {content.headingDownload}
            </Typography>
            <div onClick={() => handleTracking('whitepaper', 'download', name)}>
              <PromoButton sponsor={{ promoButtonUrl, promoButtonText }} />
            </div>
          </div>
        )}

        {mediaSize !== 'large' && articles && articles.length > 0 && (
          <ArticlesList
            articles={content.articles}
            handleTracking={handleTracking}
            articlesHtml={articlesHtml[0] ? articlesHtml[0] : {}}
          />
        )}
      </aside>
      {content?.contributors && content?.contributors.length > 0 ? (
        <div className={styles.contributors}>
          <Contributors contributors={content?.contributors ?? []} />
        </div>
      ) : (
        content?.contributorsList &&
        content?.contributorsList.length > 0 && (
          <div className={styles.contributors}>
            <ContributorsList
              contributors={content?.contributorsList ?? []}
              extraItems={content?.contributorsExtraItems}
            />
          </div>
        )
      )}
    </div>
  )
}

SingleAsset.propTypes = {
  content: PropTypes.object,
  handleTracking: PropTypes.func,
  articlesHtml: PropTypes.array
}

export default SingleAsset
