import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PredeterminatedListFilters from './PredeterminatedListFilters'
import FreeInputText from './FreeInputText'
import useFilters from '../../../hooks/useFilters'
import styles from './FilterInput.module.sass'

const FilterInput = ({
  options = [],
  index = '',
  inputType = '',
  type = '',
  filterCategory = '',
  loading = false,
  open = false,
  orderFiltersByPosition = false,
  singleSelect = false
}) => {
  // Hooks
  const {
    onSelectFilter,
    onRemoveFilter,
    onTagSearch,
    addSelectedFilter,
    removeSelectedFilter,
    tagsForCategory
  } = useFilters()

  const [validOptions, setValidOptions] = useState([])

  useEffect(() => {
    const parsedOpts = parseOptions(options)

    const chosenOpts = checkSelectedOptns(parsedOpts)

    setValidOptions(chosenOpts)
  }, [options, tagsForCategory[index]?.selectedTags, open])

  // Handler functions
  const parseOptions = (options = []) => {
    const filterdByCategory = options?.filter(opt => opt.category === index)

    let result = filterdByCategory.map((opt, position) => {
      return {
        value: opt.tag,
        selected: false,
        field: index,
        position
      }
    })

    const chosen = getChosen(index)
    if (chosen.length > 0) {
      result = result.filter(item =>
        chosen.find(subitem => subitem.value !== item.value)
      )
      result = [...chosen, ...result]
    }

    if (orderFiltersByPosition) {
      // Order by position
      result = result.sort((a, b) => {
        if (a.position < b.position) return -1
        if (a.position > b.position) return 1
        return 0
      })
    } else {
      // Order alphabetically
      result = result.sort((a, b) => a.value.localeCompare(b.value))
    }

    return result
  }

  const checkSelectedOptns = validOptions => {
    let options = validOptions.map(opt => {
      const isOptionSelected = isOptionChosen(opt)

      return {
        ...opt,
        selected: isOptionSelected
      }
    })
    options = cleanOptions(options)
    return options
  }

  const cleanOptions = (optns = []) => {
    return optns
      .filter(
        (opt, index, self) =>
          index === self.findIndex(o => o.value === opt.value)
      )
      .filter(opt => !isValueAnURL(opt.value))
  }

  const isValueAnURL = url => {
    const regexHttp = /^(https?|ftp):\/\//
    const regex = /(Http:__www\.|Http:\/\/www\.)/
    return regexHttp.test(url) || regex.test(url)
  }

  const getChosen = index => tagsForCategory[index]?.selectedTags ?? []

  const isOptionChosen = option => {
    const selectedOptns = getChosen(index)
    const isOptionSelected = selectedOptns.findIndex(
      opt => opt.value === option.value
    )

    return isOptionSelected !== -1
  }

  const handleOptionSelect = async option => {
    const selectedOptionIndex = validOptions.findIndex(
      opt => opt.value === option.value
    )

    // If not found, it means this is a new search (plus icon)
    if (selectedOptionIndex === -1) {
      option.selected = true
      setValidOptions(validOptions => [...validOptions, option])
      onSelectFilter(index, option?.value, singleSelect)()
      return
    }

    const updatedOption = validOptions[selectedOptionIndex]

    if (!updatedOption?.selected) {
      onSelectFilter(index, option?.value, singleSelect)()
      addSelectedFilter(updatedOption, index, singleSelect)
    } else {
      onRemoveFilter(option)()
      removeSelectedFilter(updatedOption, index)
    }
  }

  return inputType === 'freeInputText' ? (
    <FreeInputText
      options={validOptions}
      index={index}
      handleOptionSelect={handleOptionSelect}
      type={type}
      filterCategory={filterCategory}
      onTagSearch={onTagSearch}
      isLoadingTags={loading}
    />
  ) : (
    <div
      className={type.toLowerCase() === 'fwreports' ? styles.floatingList : ''}
    >
      <PredeterminatedListFilters
        options={validOptions}
        index={index}
        handleOptionSelect={handleOptionSelect}
      />
    </div>
  )
}

FilterInput.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  index: PropTypes.string,
  inputType: PropTypes.string,
  type: PropTypes.string,
  filterCategory: PropTypes.string,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  orderFiltersByPosition: PropTypes.bool,
  singleSelect: PropTypes.bool
}

export default FilterInput
