import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ActionIcon from '../ActionIcon'
import { actions } from '../../reducer'
import RequestAccessModal from '../../../../modules/Paywall/Components/RequestModal'
import { addExtraData } from '../../../../modules/Paywall/slice'

const DownloadAccessButton = props => {
  const { iconText, active = false, reportSettings, articleId, ...rest } = props
  const dispatch = useDispatch()

  const handleDownloadClick = () => {
    dispatch(actions.reportDownloadRequest(reportSettings?.productCode))
  }

  return (
    <ActionIcon
      {...rest}
      action={handleDownloadClick}
      articleId={articleId}
      active={true}
      iconText={iconText}
      iconPath="svg/material-design-icons/content/download"
    />
  )
}

DownloadAccessButton.propTypes = {
  iconText: PropTypes.string,
  active: PropTypes.bool,
  iconPath: PropTypes.string,
  reportSettings: PropTypes.object,
  articleId: PropTypes.any
}

const DownloadNoAccessButton = props => {
  const {
    iconText,
    active = false,
    action = () => {},
    authenticated,
    reportSettings,
    ...rest
  } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const handleDownloadClick = () => {
    setIsModalOpen(true)
    dispatch(addExtraData({ extra: reportSettings }))
  }

  const handleConfirmDownload = () => {
    if (authenticated) {
      action()
    }
    setIsModalOpen(false)
  }

  return (
    <>
      <ActionIcon
        {...rest}
        action={handleDownloadClick}
        active={true}
        iconText={iconText}
        iconPath="svg/material-design-icons/content/download_grey"
      />

      <RequestAccessModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSuccess={handleConfirmDownload}
      />
    </>
  )
}

DownloadNoAccessButton.propTypes = {
  articleId: PropTypes.string.isRequired,
  iconText: PropTypes.string,
  active: PropTypes.bool,
  mediaUrl: PropTypes.string,
  iconPath: PropTypes.string,
  action: PropTypes.func,
  authenticated: PropTypes.bool,
  reportSettings: PropTypes.object
}

const DownloadActionButton = props => {
  if (props?.authenticated && props?.reportSettings?.hasAccess) {
    return <DownloadAccessButton {...props} />
  }
  return <DownloadNoAccessButton {...props} />
}

DownloadActionButton.propTypes = {
  reportSettings: PropTypes.object,
  authenticated: PropTypes.bool
}

export default DownloadActionButton
