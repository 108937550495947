import { createAction } from 'redux-actions'
import asyncMeta from '../AsyncFlags/metaCreator'

export const successUserRegistration = createAction(
  'USER_REGISTRATION__SUCCESS',
  null,
  asyncMeta
)
export const errorUserRegistration = createAction(
  'USER_REGISTRATION__ERROR',
  null,
  asyncMeta
)
export const requestUserRegistration = createAction(
  'USER_REGISTRATION__REQUEST',
  null,
  asyncMeta
)

export const actions = {
  requestUserRegistration,
  successUserRegistration,
  errorUserRegistration
}
