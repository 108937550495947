import { createAction, handleActions } from 'redux-actions'

export const requestViews = createAction('VIEWS__REQUEST')
export const successViews = createAction('VIEWS__SUCCESS')
export const errorViews = createAction('VIEWS__ERROR')
export const cleanViews = createAction('VIEW__CLEAN')
export const redirectRequest = createAction('REDIRECT_REQUEST')
export const redirectSuccess = createAction('REDIRECT_SUCCESS')

export const actions = {
  requestViews,
  successViews,
  errorViews,
  cleanViews,
  redirectRequest,
  redirectSuccess
}

const defaultState = {
  views: {},
  loading: false,
  error: '',
  fw5_story_id: null
}

export default handleActions(
  {
    [requestViews]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [successViews]: (state, action) => {
      return {
        ...state,
        views: { ...state.views, ...action.payload },
        loading: false
      }
    },
    [errorViews]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload
      }
    },
    [cleanViews]: (state, { payload }) => {
      // for backward compatibility, used for HOC WithViews
      return {
        ...state,
        loading: false,
        error: '',
        views: {}
      }
    },
    [redirectRequest]: (state, { payload }) => {
      return {
        ...state,
        loading: true,
        error: '',
        views: {},
        fw5_story_id: null
      }
    },
    [redirectSuccess]: (state, { payload: fw5_story_id }) => {
      return {
        ...state,
        loading: false,
        error: '',
        views: {},
        fw5_story_id: fw5_story_id
      }
    }
  },
  defaultState
)
