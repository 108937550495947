import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import useMediaSize from '../../../../../hooks/useMediaSize'
import useFilters from '../../../../../modules/River/hooks/useFilters'
import SearchFiltersBox from '../Filters'
import MyStoriesRiver from '../../../../../components/MyStoriesRiver'
import { SessionContext } from '../../../../../modules/Session/context'
import { licenseTypes } from '../../../../../services/FW5ML/parsers/UserParser'
import useViews from '../../../../../modules/Views/hooks/useViews'
import ShowForMediaSize from '../../../../../components/Responsive/ShowForMediaSize'
import Ad from '../../../../../modules/Ads/components/Ad'
import commonStyles from '../../../../../styles/common.module.sass'

const RecommendedForYouSidebar = props => {
  const {
    busy,
    toggleFilters,
    filtersOpen,
    isRiverPage,
    showMyFW = true,
    showFilters = true,
    showTitle = true,
    showForAll = false,
    flex = 'column',
    ads
  } = props

  const mediaSize = useMediaSize()
  const large = mediaSize === 'large'

  const { userHasLicense = () => false, getInterests } =
    useContext(SessionContext)

  const recommendedViews = showMyFW
    ? [
        {
          name: 'Recommended for you',
          tags: getInterests(),
          include_related_tags: true
        }
      ]
    : []

  const { availableFilters, onAddFilter } = useFilters()
  const { views, viewsError, viewsLoading } = useViews({
    views: recommendedViews
  })

  return (
    <Fragment>
      {showFilters && (
        <SearchFiltersBox
          busy={busy}
          large={large}
          filters={availableFilters}
          onFilterClick={onAddFilter}
          handleCloseFilters={toggleFilters}
          filtersOpen={filtersOpen}
          isPlus={userHasLicense(licenseTypes.LICENSE_PLUS)}
          showForAll={showForAll}
          showTitle={showTitle}
          flex={flex}
        />
      )}
      {isRiverPage && flex === 'column' && (
        <ShowForMediaSize showFor={['large', 'medium']}>
          <Ad
            className={commonStyles.newsletterSignUpAd}
            {...ads.riverMediumRectangle3}
          />
        </ShowForMediaSize>
      )}

      {/* MyFW+ / Recommended For you River */}
      {showMyFW && (
        <MyStoriesRiver
          view={views['Recommended for you']}
          viewsError={viewsError}
          viewsLoading={viewsLoading}
          imageSize={large ? 'small' : mediaSize} // Use 'small' teaser image when it is flexed in the sidebar
          getArticleLink={story => `/story/${story.id}`}
          flex={flex}
        />
      )}
    </Fragment>
  )
}

RecommendedForYouSidebar.propTypes = {
  busy: PropTypes.bool,
  toggleFilters: PropTypes.func,
  filtersOpen: PropTypes.bool,
  isRiverPage: PropTypes.bool,
  showMyFW: PropTypes.bool,
  showFilters: PropTypes.bool,
  showTitle: PropTypes.bool,
  flex: PropTypes.oneOf(['column', 'row']),
  ads: PropTypes.object,
  showForAll: PropTypes.bool
}

export default RecommendedForYouSidebar
