// Code shared from phoenix team for viewability
import { createAction, handleActions } from 'redux-actions'

export const adsViewTrackRequest = createAction('ADS_VIEW_TRACK_REQUEST')
export const adsViewTrackSuccess = createAction('ADS_VIEW_TRACK_SUCCESS')
export const adsViewTrackError = createAction('ADS_VIEW_TRACK_ERROR')

export const actions = {
  adsViewTrackRequest,
  adsViewTrackSuccess,
  adsViewTrackError
}

const defaultState = {}

const getDefaultValues = (state, area, id) => {
  const initialTrackRequestStatus =
    state.trackRequestStatus === undefined
      ? {}
      : { ...state.trackRequestStatus }

  const initialTrackRequestStatusArea =
    initialTrackRequestStatus[area] === undefined
      ? {}
      : { ...state.trackRequestStatus[area] }

  const initialTrackRequestStatusId =
    initialTrackRequestStatusArea[id] === undefined
      ? {}
      : { ...state.trackRequestStatus[area][id] }

  return {
    initialTrackRequestStatus,
    initialTrackRequestStatusArea,
    initialTrackRequestStatusId
  }
}

export default handleActions(
  {
    ADS_VIEW_TRACK_REQUEST: (state, { payload: { area, id, src } }) => {
      const {
        initialTrackRequestStatus,
        initialTrackRequestStatusArea,
        initialTrackRequestStatusId
      } = getDefaultValues(state, area, id)
      return {
        ...state,
        trackRequestStatus: {
          ...initialTrackRequestStatus,
          [area]: {
            ...initialTrackRequestStatusArea,
            [id]: {
              ...initialTrackRequestStatusId
            }
          }
        }
      }
    },
    ADS_VIEW_TRACK_SUCCESS: (state, { payload: { area, id, src } }) => {
      const {
        initialTrackRequestStatus,
        initialTrackRequestStatusArea,
        initialTrackRequestStatusId
      } = getDefaultValues(state, area, id)

      return {
        ...state,
        trackRequestStatus: {
          ...initialTrackRequestStatus,
          [area]: {
            ...initialTrackRequestStatusArea,
            [id]: {
              ...initialTrackRequestStatusId,
              [src]: true
            }
          }
        }
      }
    },
    ADS_VIEW_TRACK_ERROR: (state, { payload: { area, id, src } }) => {
      const {
        initialTrackRequestStatus,
        initialTrackRequestStatusArea,
        initialTrackRequestStatusId
      } = getDefaultValues(state, area, id)

      return {
        ...state,
        trackRequestStatus: {
          ...initialTrackRequestStatus,
          [area]: {
            ...initialTrackRequestStatusArea,
            [id]: {
              ...initialTrackRequestStatusId,
              [src]: false
            }
          }
        }
      }
    }
  },
  defaultState
)
