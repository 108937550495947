import { createSelector } from 'reselect'

const getNotices = state => state.notices

export const getCurrentNotices = createSelector(
  [getNotices],
  currentNotices => currentNotices.notices
)

export const getNoticesError = createSelector(
  [getNotices],
  currentNotices => currentNotices.error
)

export const getNoticesLoading = createSelector(
  [getNotices],
  currentNotices => currentNotices.loading
)
