import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import ContactUsForm from './components/ContactUsForm'
import validate from './components/ContactUsForm/validate'
import { connect } from 'react-redux'
import { siteDomain } from '../../utils/oneCodeBase'

const mapStateToProps = (
  state,
  {
    match: {
      params: { why }
    }
  }
) => {
  let team, message, grade
  switch (why) {
    case 'request':
      team = `subscriptions@${siteDomain}`
      message = 'Request trial'
      break
    case 'subscriber':
      team = `subscriptions@${siteDomain}`
      break
    case 'feedback':
      team = `feedback@${siteDomain}`
      break
    default:
      team = 'Please select'
  }

  const name = `${
    state.session.user.firstName ? state.session.user.firstName : ''
  } ${state.session.user.lastName ? state.session.user.lastName : ''}`.trim()
  return {
    initialValues: {
      name,
      email: state.session.user.email,
      message,
      team,
      grade
    }
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'contact-us',
    validate,
    touchOnBlur: false
  })
)(ContactUsForm)
