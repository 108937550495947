import PropTypes from 'prop-types'
import useMediaSize from '../../hooks/useMediaSize'

const ShowForMediaSize = ({ children, showFor = 'large', ...props }) => {
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)'
    }
  })
  const testSize = () => {
    let matchAgainst = []
    if (Array.isArray(showFor)) {
      matchAgainst = showFor
    } else if (typeof showFor === 'string') {
      matchAgainst = [showFor]
    }
    return matchAgainst.includes(mediaSize)
  }
  return testSize() ? children : null
}

ShowForMediaSize.propTypes = {
  children: PropTypes.node,
  showFor: PropTypes.any
}

export default ShowForMediaSize
