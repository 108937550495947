import React from 'react'
import PropTypes from 'prop-types'

// Components
import styles from './FeaturedArticle.module.sass'
import Article from './components/Article'

const FeaturedArticle = ({ content = [] }) => {
  return (
    <div className={styles.content}>
      {content.map((item, index) => (
        <Article item={item} key={`featured-article-${index}`} />
      ))}
    </div>
  )
}

FeaturedArticle.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object)
}

export default FeaturedArticle
