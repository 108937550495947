import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Logo from 'src/components/Logo/index.js'
import ReactHtmlParser from 'react-html-parser'
import Tag from 'src/components/Tag/index.js'
import SaveButton from 'src/components/SaveButton/index.js'
import FullImage from 'src/components/FullImage/index.js'
import AddInterests from 'src/components/AddInterests/index.js'
import Interests from 'src/components/Interests/index.js'
import NotifyMe from 'src/components/NotifyMe/index.js'
import SocialButtons from 'src/components/SocialButtons/index.js'
import AdInjector from 'src/services/FW5ML/parsers/AdInjector.js'
import dayjs from 'dayjs'
import Typography from 'src/components/ui/Typography/index.js'
import styles from './SingleStory.module.sass'
import isEmpty from 'lodash/isEmpty'
import copyToClipboard from 'src/utils/copyToClipboard.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { piwikRequest } from 'src/modules/Piwik/reducer.js'
import LoginRegisterModal from 'src/components/LoginRegisterModalPage/components/LoginRegisterModal/index.js'
import ReportArticleSections from './reports/ReportArticleSections'
import {
  getConfig,
  getConfigFilteredTags,
  getTextToSpeechConfig
} from 'src/modules/Config/selectors'
import classNames from 'classnames/bind'
import ActionButtons from 'src/components/ActionButtons'
import ReportButtons from './reports/ReportButtons'

import {
  siteName,
  socialMediaInfo,
  otherSitesDomains
} from 'src/utils/oneCodeBase.js'
import TextToSpeech from 'src/components/TextToSpeech'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { addExtraData } from '../../modules/Paywall/slice'
import { getLatestRelatedArticleDate } from 'src/modules/SingleStory/selector'

const adInjector = new AdInjector()
const getUrl = () => window.location
const cx = classNames.bind(styles)

const SingleStory = ({
  congress,
  title: titleSource = '',
  source = 'Source',
  published = 0,
  author = 'Author name',
  body = 'Body',
  tags = [],
  allTags = [],
  image,
  type,
  typeTo,
  ad,
  isLimited = false,
  disabledTags = [],
  simplePage = false,
  interests = { visible: true, clickable: true, inline: false },
  storyTags = { visible: true, clickable: true },
  showRef = true,
  showSocialLinks = true,
  showDividers = true,
  showAd = true,
  fullImage = false,
  id = '',
  user = {},
  reportSettings = null,
  showActionBar = false,
  headerFlex = 'column',
  showUpdated = false,
  likes = 0,
  likedByProfile = false,
  authenticated = false,
  isPaid = false,
  onTypeClick = () => {
    /* NOOP */
  },
  setStoryTitle = () => {
    /* NOOP */
  }
}) => {
  const internalToken = useSelector(getConfig('internal_token')) || null
  const [notified, setNotified] = useState(false)
  const [liked, setLiked] = useState(likedByProfile)
  const [likeCount, setLikeCount] = useState(likes)

  const [modalVisible, setModalVisible] = useState(false)
  const dispatch = useDispatch()
  const title = `${titleSource}${
    congress ? `: Presented at ${congress.join(', ')}` : ''
  }`
  const latestArticleUpdateDate = useSelector(getLatestRelatedArticleDate())
  const publishedDate = published
    ? dayjs.unix(published).format('MMMM DD, YYYY')
    : null
  const updatedDate =
    latestArticleUpdateDate &&
    dayjs.unix(latestArticleUpdateDate).format('MMMM DD, YYYY')
  const displayDate =
    updatedDate || (latestArticleUpdateDate === null && publishedDate)
  const bodyWithAd = adInjector.injectAd(body)

  const filteredTags =
    useSelector(
      getConfigFilteredTags({
        interests: tags
      })
    ) || tags

  const textToSpeechConfig = useSelector(getTextToSpeechConfig())

  /**Remove this once text to speech is integrated */
  const flags = useFlags()
  const readArticleEnabled = flags['readArticle']

  const transform = node => {
    if (node.type === 'tag') {
      // Render advertorial
      if (showAd && node.name === 'ad-placeholder') {
        return ad
      }
      // Remove Notify Me button
      if (
        node.name === 'a' &&
        (node?.attribs?.href?.includes(
          'info.firstwordreports.com/physician_view_alert/'
        ) ||
          node.children.find(item =>
            item?.data?.toLowerCase()?.includes('notify me of the results')
          ))
      ) {
        return <></>
      }

      // Check if there is a link to other FW sites
      // Add internal token to autologin to other FW sites
      if (
        node.name === 'a' &&
        node?.attribs?.href &&
        internalToken &&
        otherSitesDomains.find(item => node?.attribs?.href.includes(item))
      ) {
        const separator = node?.attribs?.href.includes('?') ? '&' : '?'
        node.attribs.href = `${node?.attribs?.href}${separator}it=${internalToken}`
      }
    }
  }

  useEffect(() => {
    setStoryTitle(title)
    dispatch(addExtraData({ extra: reportSettings }))
  }, [])

  const hndSocialIconClick = socialNetwork => e => {
    e.preventDefault()

    // Piwik tracking
    dispatch(
      piwikRequest({
        customVarUpdate: {
          key: 'article_id',
          value: id
        },
        tracking: {
          category: 'article',
          action: 'share-click',
          name: socialNetwork === 'link' ? 'copy-url' : socialNetwork
        }
      })
    )

    if (socialNetwork === 'link') {
      copyToClipboard(getUrl())
      dispatch(
        notificationsEnqueue({
          message: 'Article URL was copied to your clipboard'
        })
      )
      return
    }
    const linkBuilders = {
      twitter: () => {
        const message = encodeURIComponent(
          `${title} ${getUrl()} via ${socialMediaInfo?.twitter}`
        )
        return `https://twitter.com/intent/tweet?text=${message}`
      },
      linkedin: () => {
        const summary = body.length > 50 ? body.slice(0, 50) + '...' : body
        return `http://www.linkedin.com/shareArticle?mini=true&url=${getUrl()}&title=${encodeURIComponent(
          title
        )}&summary=${encodeURIComponent(summary)}&source=${
          socialMediaInfo?.linkedin
        }`
      }
    }
    if (!linkBuilders[socialNetwork]) return
    const link = linkBuilders[socialNetwork]()
    window.open(link, 'pop', 'width=600, height=400, scrollbars=no')
  }

  const renderArticle = () => {
    const options = simplePage ? {} : { transform }

    return ReactHtmlParser(bodyWithAd, options)
  }

  const onNotifyUpdate = () => {
    setNotified(notified => !notified)
  }

  const onLikeUpdate = () => {
    const likeUpdated = !liked
    setLiked(likeUpdated)

    if (likeUpdated) {
      //liked
      setLikeCount(item => item + 1)
    } else {
      //unliked
      setLikeCount(item => item - 1)
    }
  }

  const imageClassName = fullImage
    ? styles.fullImageContainer
    : styles.imageContainer

  //For Reports, Last Updated is shown instead of Published
  const publishedLabel = showUpdated ? 'Last Updated : ' : 'Published : '

  return (
    <div data-clarity-mask={isPaid}>
      {modalVisible && (
        <LoginRegisterModal
          loginConfig={{ header: 'Sign In to be notified of the results' }}
          registerConfig={{
            header: 'Register to be notified of the results'
          }}
          modalClassName={styles.registerModal}
          showCloseBtn={true}
          setModalVisible={setModalVisible}
        />
      )}
      <article
        className={cx(
          styles.SingleStory,
          showDividers && styles.borderBottom,
          isLimited && styles.hideOverflow
        )}
      >
        <header>
          {simplePage && (
            <div className={styles.logoContainer}>
              <Logo className={styles.logo} />
            </div>
          )}
          <div
            className={headerFlex === 'row' ? styles.headerRowContainer : ''}
          >
            <div>
              <div className={styles.topActions}>
                {storyTags?.visible && type && (
                  <Tag
                    label={type}
                    to={storyTags.clickable ? typeTo : null}
                    onClick={e => onTypeClick(type)}
                  />
                )}
                <SaveButton />
              </div>
              <Typography type="headline" gutterBottom className={styles.title}>
                {title}
              </Typography>
              <div
                className={
                  headerFlex === 'row' ? styles.articleHeaderInline : ''
                }
              >
                {author && <div className={styles.author}>{author}</div>}
                {displayDate && (
                  <div className={styles.published}>
                    {publishedLabel}
                    {displayDate}
                  </div>
                )}
                {showRef && (
                  <div className={styles.source}>
                    {source && `Ref: ${source}`}
                  </div>
                )}
              </div>
              {!simplePage && showActionBar && (
                <div
                  className={styles.actionButtonsContainer}
                  style={{ marginBottom: '40px' }}
                >
                  <ActionButtons
                    learnMoreProps={{ active: false }}
                    reportSettings={reportSettings}
                    articleId={id}
                    articleBody={body}
                    articleTitle={title}
                    singleStory={true}
                    likeProps={{
                      liked,
                      likeCount,
                      action: onLikeUpdate
                    }}
                    notifyProps={{
                      notified,
                      action: onNotifyUpdate,
                      setNotified: setNotified
                    }}
                  />
                </div>
              )}
            </div>
            {image && (
              <div
                className={cx(
                  headerFlex === 'row'
                    ? styles.imageContainerSmall
                    : imageClassName
                )}
              >
                <FullImage
                  src={image}
                  width="100%"
                  height="100%"
                  objectFit="contain"
                />
              </div>
            )}
          </div>
        </header>
        <main className={styles.body}>
          {readArticleEnabled ? (
            <TextToSpeech config={textToSpeechConfig}>
              {renderArticle()}
            </TextToSpeech>
          ) : (
            renderArticle()
          )}
        </main>

        {!simplePage && (
          <NotifyMe
            article={{
              id,
              type,
              title,
              tags: allTags,
              publication_date: published
            }}
            setModalVisible={setModalVisible}
            onParentClick={() => setModalVisible(!user.profile_id)}
          />
        )}

        {/* Firstword Report specific content */}
        {siteName === 'Reports' && reportSettings && (
          <>
            <ReportButtons
              authenticated={authenticated}
              user={user}
              articleId={id}
              upcomingAuthenticatedSettings={{
                notifyAction: onNotifyUpdate,
                setNotified,
                notified
              }}
              reportSettings={reportSettings}
            />
            <ReportArticleSections {...reportSettings} />
          </>
        )}

        {!simplePage && showActionBar && (
          <div
            className={styles.actionButtonsContainer}
            style={{ marginTop: '40px' }}
          >
            <ActionButtons
              learnMoreProps={{ active: false }}
              reportSettings={reportSettings}
              articleId={id}
              articleBody={body}
              articleTitle={title}
              singleStory={true}
              likeProps={{
                liked,
                likeCount,
                action: onLikeUpdate
              }}
              notifyProps={{
                notified,
                action: onNotifyUpdate,
                setNotified: setNotified
              }}
            />
          </div>
        )}

        {!simplePage && (
          <footer
            className={cx(styles.footer, showDividers && styles.borderTop)}
          >
            {interests?.visible && !isEmpty(filteredTags) && (
              <>
                {interests.inline ? (
                  <Interests
                    tags={filteredTags}
                    clickable={interests.clickable}
                    parentStyle={{ fontSize: '15px', marginTop: '30px' }}
                  />
                ) : (
                  <AddInterests
                    interests={filteredTags}
                    {...interests}
                    disabledTags={disabledTags}
                  />
                )}
              </>
            )}

            {showSocialLinks && <SocialButtons onClick={hndSocialIconClick} />}
          </footer>
        )}
      </article>
    </div>
  )
}

SingleStory.propTypes = {
  congress: PropTypes.array,
  title: PropTypes.string,
  source: PropTypes.string,
  published: PropTypes.number,
  updated: PropTypes.number,
  author: PropTypes.string,
  body: PropTypes.string,
  teaser: PropTypes.string,
  tags: PropTypes.array,
  allTags: PropTypes.array,
  image: PropTypes.string,
  type: PropTypes.string,
  typeTo: PropTypes.string,
  ad: PropTypes.node,
  isLimited: PropTypes.bool,
  disabledTags: PropTypes.array,
  simplePage: PropTypes.bool,
  interests: PropTypes.object,
  storyTags: PropTypes.object,
  showRef: PropTypes.bool,
  showSocialLinks: PropTypes.bool,
  showDividers: PropTypes.bool,
  showAd: PropTypes.bool,
  fullImage: PropTypes.bool,
  id: PropTypes.string,
  user: PropTypes.object,
  reportSettings: PropTypes.object,
  showActionBar: PropTypes.bool,
  headerFlex: PropTypes.string,
  showUpdated: PropTypes.bool,
  onTypeClick: PropTypes.func,
  setStoryTitle: PropTypes.func,
  likes: PropTypes.number,
  likedByProfile: PropTypes.bool,
  authenticated: PropTypes.bool,
  isPaid: PropTypes.bool
}

export default SingleStory
