import { createAction, handleActions } from 'redux-actions'

export const toggleRightMenu = createAction('TOGGLE_RIGHT_MENU')
export const toggleLeftMenu = createAction('TOGGLE_LEFT_MENU')
export const closeLeftMenu = createAction('CLOSE_LEFT_MENU')
export const actions = {
  toggleRightMenu,
  toggleLeftMenu,
  closeLeftMenu
}

const defaultState = {
  rightMenuOpen: false,
  leftMenuOpen: false
}

export default handleActions(
  {
    [toggleRightMenu]: state => ({
      ...state,
      rightMenuOpen: !state.rightMenuOpen,
      leftMenuOpen: false
    }),
    [toggleLeftMenu]: state => ({
      ...state,
      leftMenuOpen: !state.leftMenuOpen,
      rightMenuOpen: false
    }),
    [closeLeftMenu]: state => ({
      ...state,
      leftMenuOpen: false
    })
  },
  defaultState
)
