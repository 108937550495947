import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import { propWarning } from '../utils'
import modernStyles from './FieldModern.module.sass'
import traditionalStyles from './FieldTraditional.module.sass'
import classNames from 'classnames/bind'

const Field = ({
  altTheme,
  textarea,
  hasError,
  isFocused,
  readOnly,
  disabled,
  input = {},
  id,
  value,
  autosize,
  hasPasswordToggle,
  login,
  placeholder,
  leftElements,
  rightElements,
  button,
  traditional,
  minHeight,
  maxHeight,
  resize,
  fieldRef,
  className,
  type,
  pattern,
  onEnterKeyDown,
  onKeyDown,
  onFocus,
  onBlur,
  onChange,
  inputClassName,
  ...rest
}) => {
  const styles =
    textarea || traditional || login ? traditionalStyles : modernStyles
  const themeStyles = { ...styles, ...altTheme }
  const cx = classNames.bind(themeStyles)
  const determineTag = (textarea, autosize) => {
    if (textarea) {
      return autosize ? TextareaAutosize : 'textarea'
    }
    return 'input'
  }

  const Tag = determineTag(textarea, autosize)
  const [showPassword, setShowPassword] = useState(false)
  const togglePassword = () => {
    setShowPassword(!showPassword)
  }
  const handleFocus = event => {
    if (input.onFocus !== undefined) {
      input.onFocus(event)
    }
    if (onFocus !== undefined) {
      onFocus(event)
    }
  }
  const handleBlur = event => {
    if (input.onBlur !== undefined) {
      input.onBlur(event)
    }
    if (onBlur !== undefined) {
      onBlur(event)
    }
  }

  const handleChange = event => {
    if (input.onChange !== undefined) {
      input.onChange(event)
    }
    if (onChange !== undefined) {
      onChange(event)
    }
  }
  const handleKeyPress = event => {
    const { which } = event
    if (onEnterKeyDown && which === 13) {
      onEnterKeyDown(event)
    }
  }
  const handleKeyDown = event => {
    if (onKeyDown) {
      onKeyDown(event)
    }
  }
  return (
    <div
      className={cx(
        {
          inputContainer: !textarea,
          textareaContainer: textarea,
          hasError,
          isFocused,
          readOnly,
          disabled,
          login
        },
        className
      )}
    >
      {!hasPasswordToggle && leftElements}
      <Tag
        {...input}
        {...rest}
        id={id}
        {...(autosize ? { inputRef: fieldRef } : { ref: fieldRef })}
        type={
          !textarea ? (hasPasswordToggle && showPassword ? 'text' : type) : null
        }
        disabled={disabled}
        value={value}
        name={id}
        placeholder={placeholder}
        className={cx(
          {
            textarea,
            input: !textarea,
            error: hasError
          },
          inputClassName
        )}
        onFocus={!readOnly ? handleFocus : null}
        onBlur={!readOnly ? handleBlur : null}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        pattern={pattern}
        readOnly={readOnly}
        style={
          textarea && {
            minHeight,
            maxHeight,
            resize
          }
        }
      />
      {(!hasPasswordToggle && (rightElements || button) && (
        <div
          onClick={e => {
            // TRIGGER FOCUS TO MAIN INPUT
            document.getElementById(id).focus()
          }}
          className={themeStyles.rightElements}
        >
          {rightElements}
          {button}
        </div>
      )) ||
        button}
      {type === 'password' && hasPasswordToggle && (
        <div
          className={showPassword ? styles.toggleHide : styles.toggleShow}
          onClick={togglePassword}
        >
          Toggle password
        </div>
      )}
    </div>
  )
}

Field.displayName = 'Field'
Field.propTypes = {
  /**
   * A CSS modules style object to override default theme
   */
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  /**
   * If `true`, the field will render as a textarea
   */
  textarea: PropTypes.bool,
  /**
   * If `true`, the field is read-only
   */
  readOnly: PropTypes.bool,
  /**
   * If `true`, the field has styling indicating an error
   */
  hasError: PropTypes.bool,
  /**
   * If `true`, the field has styling indicating focus
   */
  isFocused: PropTypes.bool,
  /**
   * If `true`, the field has styling indicating that it is disabled
   */
  disabled: PropTypes.bool,
  /**
   * The connected input props (if using redux-form)
   */
  input: PropTypes.object,
  /**
   * The string used for both field "id" and "name" attributes
   */
  id: PropTypes.string,
  /**
   * Called when field is focused
   */
  onFocus: PropTypes.func,
  /**
   * Called when field is blurred
   */
  onBlur: PropTypes.func,
  /**
   * Called when field is changed
   */
  onChange: PropTypes.func,
  /**
   * Called when enter key is pressed
   */
  onEnterKeyDown: PropTypes.func,
  /**
   * Called when key is down
   */
  onKeyDown: PropTypes.func,
  /**
   * Controlled value of the field
   */
  value: PropTypes.string,
  /**
   * If `true`. the field has a password visibility toggle button (only available for type="password")
   */
  hasPasswordToggle: PropTypes.bool,
  /**
   * The type of field (not used for textarea)
   */
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'tel',
    'url',
    'email',
    'search'
  ]),
  /**
   * The pattern regex that the field's value is checked against on form submission
   */
  pattern: PropTypes.string,
  /**
   * If `true`, this is a login style field
   */
  login: PropTypes.bool,
  /**
   * The placeholder text shown inside the field before text is entered (not used with "labelFloat")
   */
  placeholder: PropTypes.string,
  /**
   * If `true`, this field requires a value to submit the form
   */
  required: PropTypes.bool,
  /**
   * An optional node of elements to be contained within the Field component
   */
  leftElements: PropTypes.node,
  /**
   * An optional node of elements to be contained within the Field component
   */
  rightElements: PropTypes.node,
  /**
   * @deprecated in version 2.7
   *
   * An optional button element to be contained within the component
   * This is deprecated in favor of `rightElements` prop which can contain multiple elements
   */
  button: propWarning(
    'Deprecated prop: This prop will be removed in version 3. Use `rightElements` prop instead.'
  ),
  /**
   * If `true`, the field uses the traditional style
   */
  traditional: PropTypes.bool,
  /**
   * A ref to the field element
   */
  fieldRef: PropTypes.object,
  /**
   * An additional custom className
   */
  className: PropTypes.string,
  /**
   * An additional custom className for the field element
   */
  inputClassName: PropTypes.string,
  /**
   * @ignore
   */
  minHeight: PropTypes.number,
  /**
   * @ignore
   */
  maxHeight: PropTypes.number,
  /**
   * @ignore
   */
  autosize: PropTypes.bool,
  /**
   * @ignore
   */
  resize: PropTypes.oneOf([
    '',
    'both',
    'horizontal',
    'vertical',
    'block',
    'inline'
  ])
}
export default Field
