import { takeEvery, all, call } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import getPathTrackingAction from './pageviewActions'

/**
 * Saga wrapper for Piwik tracking
 */
function* doTracking(services, action) {
  const Piwik = services('Piwik')
  const {
    payload: { category, action: trackAction, name, customData }
  } = action
  yield call([Piwik, 'track'], category, trackAction, name, customData)
}

/**
 * Sync Piwik URL with current history URL
 */
function* updateUrl(services, action) {
  const Piwik = services('Piwik')

  const {
    payload: {
      location: { pathname }
    }
  } = action

  // Piwik pageview
  yield call([Piwik, 'deletePageCustomVariables'])
  yield call([Piwik, 'updateUrl'], pathname)
  const trackingAction = getPathTrackingAction(pathname)
  if (trackingAction) {
    yield call([Piwik, 'track'], ...trackingAction)
  }
}

export default function* watchTracking(services) {
  yield all([
    takeEvery('TRACK', doTracking, services),
    takeEvery(LOCATION_CHANGE, updateUrl, services)
  ])
}
