import { createAction, handleActions } from 'redux-actions'
import asyncMeta from '../AsyncFlags/metaCreator'

export const requestApplyCoupon = createAction(
  'PAYMENT__APPLY_COUPON__REQUEST',
  null,
  asyncMeta
)

export const successApplyCoupon = createAction(
  'PAYMENT__APPLY_COUPON__SUCCESS',
  null,
  asyncMeta
)

export const errorApplyCoupon = createAction(
  'PAYMENT__APPLY_COUPON__ERROR',
  null,
  asyncMeta
)

export const requestGetPrice = createAction(
  'PAYMENT__GET_PRICE__REQUEST',
  null,
  asyncMeta
)

export const successGetPrice = createAction(
  'PAYMENT__GET_PRICE__SUCCESS',
  null,
  asyncMeta
)

export const errorGetPrice = createAction(
  'PAYMENT__GET_PRICE__ERROR',
  null,
  asyncMeta
)

export const requestProcessPayment = createAction(
  'PAYMENT__REQUEST',
  null,
  asyncMeta
)

export const successProcessPayment = createAction(
  'PAYMENT__SUCCESS',
  null,
  asyncMeta
)
export const errorProcessPayment = createAction(
  'PAYMENT__ERROR',
  null,
  asyncMeta
)

export const logPush = (module, message) =>
  createAction('PAYMENT_LOG_PUSH')({ module, message })

export const logClear = createAction('PAYMENT_LOG_CLEAR')
export const clearCoupon = createAction('PAYMENT_CLEAR_COUPON', null, asyncMeta)
export const setCoupon = createAction('PAYMENT_SET_COUPON', null, asyncMeta)

export const actions = {
  requestProcessPayment,
  successProcessPayment,
  errorProcessPayment,
  logPush,
  logClear,
  clearCoupon,
  setCoupon,
  requestApplyCoupon,
  successApplyCoupon,
  errorApplyCoupon,
  requestGetPrice,
  errorGetPrice,
  successGetPrice
}

const defaultState = {
  log: [],
  loading: false,
  success: false,
  coupon: {
    name: '',
    isLoading: false,
    isApplied: false,
    error: null
  },
  price: 0,
  priceWithDiscount: 0,
  currency: 'usd'
}

export default handleActions(
  {
    PAYMENT_LOG_PUSH: (state, { payload: { module, message } }) => ({
      ...state,
      log: [...state.log, { module, message }]
    }),
    PAYMENT_LOG_CLEAR: state => ({
      ...state,
      log: []
    }),
    PAYMENT__REQUEST: (state, { payload: { module, message } }) => ({
      ...state,
      loading: true,
      success: false
    }),
    PAYMENT__GET_PRICE__REQUEST: (state, { payload }) => ({
      ...state,
      loading: true,
      success: false
    }),
    PAYMENT__GET_PRICE__SUCCESS: (
      state,
      {
        payload: {
          data: { currency, price }
        }
      }
    ) => {
      return {
        ...state,
        loading: false,
        currency,
        price
      }
    },
    PAYMENT__APPLY_COUPON__REQUEST: (state, { payload: { coupon } }) => {
      return {
        ...state,
        coupon: {
          name: coupon,
          isLoading: true,
          isApplied: false
        }
      }
    },
    PAYMENT__APPLY_COUPON__SUCCESS: (
      state,
      {
        payload: {
          data: { newPrice, currency }
        }
      }
    ) => {
      return {
        ...state,
        coupon: {
          ...state.coupon,
          isLoading: false,
          isApplied: true
        },
        priceWithDiscount: newPrice,
        currency
      }
    },
    PAYMENT__APPLY_COUPON__ERROR: (state, { payload }) => {
      return {
        ...state,
        coupon: {
          ...state.coupon,
          isLoading: false,
          isApplied: false,
          error: payload,
          name: '',
          priceWithDiscount: 0
        }
      }
    },
    PAYMENT__SUCCESS: (state, { payload }) => ({
      ...state,
      loading: false,
      success: true
    }),
    PAYMENT__ERROR: (state, { payload }) => ({
      ...state,
      loading: false,
      success: false
    }),
    PAYMENT_CLEAR_COUPON: state => ({
      ...state,
      coupon: {
        name: '',
        isLoading: false,
        isApplied: false,
        error: ''
      },
      priceWithDiscount: 0
    }),
    PAYMENT_SET_COUPON: (state, { payload: { coupon } }) => {
      return {
        ...state,
        coupon
      }
    }
  },
  defaultState
)
