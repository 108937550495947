import localforage from 'localforage'

const Storage = () => ({
  async setItem(key, value) {
    const item = await localforage.setItem(key, value)
    return item
  },

  async getItem(key, defaultValue = undefined) {
    const item = await localforage.getItem(key)
    return item || defaultValue
  },

  async removeItem(key) {
    await localforage.removeItem(key)
  }
})

export default Storage
