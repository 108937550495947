const baseUrl = window.location.href.includes(
  process.env.REACT_APP_BLUE_GREEN_PORT
)
  ? process.env.REACT_APP_ML_URL.replace(
      '.com',
      `.com:${process.env.REACT_APP_BLUE_GREEN_PORT}`
    )
  : process.env.REACT_APP_ML_URL

const phoenixURL = process.env.REACT_APP_PHOENIX_API_URL
export default [
  `${baseUrl}/profile/auth`,
  `${baseUrl}/registration/confirm`,
  `${baseUrl}/auth`,
  `${phoenixURL}/auth/token`,
  `${phoenixURL}/auth/token/verify`
]
