import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '../../../../../components/ui/Typography'
import styles from './NoResults.module.sass'

function NoResults() {
  return (
    <div className={styles.suggestions}>
      <p className={styles.none}>
        Your search returned <b>0</b> results:
      </p>
      <Typography type="body2">
        Please review your search for any typos or spelling errors - or try a
        different search term. If you need any assistance, please{' '}
        <Link to="/contact-us" className={styles.link}>
          contact us
        </Link>
        .
      </Typography>
    </div>
  )
}

export default NoResults
