const pharmaConfig = {
  label: 'Show FirstWord Pharma Articles',
  searchConfig: {
    all_destinations: true,
    base_query: {
      replace_all: false,
      filter: {
        must_not: []
      }
    }
  }
}

// export default [{ ...pharmaConfig }]
export default []
