import { takeEvery, call, all, put } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'

function* requestAccountSettingsLoad(services) {
  const UserRepository = services('UserRepository')
  const Session = services('Session')

  try {
    const user = yield call([Session, 'userLoad'])
    if (!user) return
    const { data } = yield call([UserRepository, 'getSettings'])
    const { data: settings } = data
    yield put(actions.successAccountSettingsLoad(settings))
    yield call(accountSettingsSaveToCache, services, settings)
  } catch (e) {
    console.log(e)
    yield put(
      logPush(
        'Account Settings',
        `Error while loading user settings: ${e.message}`
      )
    )
  }
}

function* requestAccountSettingsUpdate(services, action) {
  const UserRepository = services('UserRepository')
  const Session = services('Session')
  const { payload: settings } = action

  try {
    const user = yield call([Session, 'userLoad'])
    if (!user) throw new Error('Please sign in and try again')
    yield call([UserRepository, 'saveSetting'], settings)
    yield put(actions.successAccountSettingsUpdate(settings))
    yield call(accountSettingsSaveToCache, services, settings)
  } catch (e) {
    console.log(e)
    yield put(
      notificationsEnqueue({ message: 'We were unable to save your settings.' })
    )
    yield put(
      logPush(
        'Account Settings',
        `Error while saving user settings: ${e.message}`
      )
    )
  }
}

function* accountSettingsSaveToCache(services, settings) {
  const Session = services('Session')
  yield call([Session, 'setCookie'], 'SETTINGS_CACHE', settings)
}

export function* requestAccountSettingsLoadFromCache(services) {
  const Session = services('Session')
  const cachedSettings = yield call([Session, 'getCookie'], 'SETTINGS_CACHE')
  if (cachedSettings) {
    yield put(actions.successAccountSettingsLoad(cachedSettings))
  }
}

export default function* watchUpdate(services) {
  yield all([
    takeEvery(
      'ACCOUNT_SETTINGS_LOAD__REQUEST',
      requestAccountSettingsLoad,
      services
    ),
    takeEvery(
      'ACCOUNT_SETTINGS_UPDATE__REQUEST',
      requestAccountSettingsUpdate,
      services
    )
  ])
}
