import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Checkbox from '../../../../components/ui/Checkbox'
import { getSetting } from '../../../../modules/MySettings/selectors'
import { INCLUDE_MEDICAL_ABSTRACT } from '../../../../services/FW5ML/constants/settings'
import styles from './MedicalAbstractsToggle.module.sass'
import usePiwik from '../../../../hooks/usePiwik'

const MedicalAbstractsToggle = ({ loading, onToggle, query }) => {
  const { handleTracking } = usePiwik()
  const defaultValue = useSelector(getSetting(INCLUDE_MEDICAL_ABSTRACT))
  const [medicalAbstractsEnabled, setMedicalAbstractsEnabled] =
    useState(defaultValue)
  const hndToggle = () => {
    setMedicalAbstractsEnabled(!medicalAbstractsEnabled)
    onToggle(!medicalAbstractsEnabled)

    handleTracking(
      'search',
      'click',
      !medicalAbstractsEnabled
        ? 'show-medical-abstracts'
        : 'hide-medical-abstracts'
    )
  }

  // Set default setting value when doing a new search
  useEffect(() => {
    setMedicalAbstractsEnabled(defaultValue)
  }, [query])
  return (
    !loading && (
      <Checkbox
        altTheme={styles}
        label="Show Medical Abstracts"
        name="settings_show_medical_abstracts_in_river"
        hasDivider={false}
        checked={medicalAbstractsEnabled}
        disabled={loading}
        onChange={hndToggle}
      />
    )
  )
}

MedicalAbstractsToggle.propTypes = {
  onToggle: PropTypes.func,
  loading: PropTypes.bool,
  query: PropTypes.string
}

export default MedicalAbstractsToggle
