import { createAction, handleActions } from 'redux-actions'
import asyncMeta from '../AsyncFlags/metaCreator'

const STACK_SIZE = 1

export const requestSavedSearchList = createAction(
  'SAVED_SEARCH_LIST__REQUEST',
  null,
  asyncMeta
)
export const successSavedSearchList = createAction(
  'SAVED_SEARCH_LIST__SUCCESS',
  null,
  asyncMeta
)
export const errorSavedSearchList = createAction(
  'SAVED_SEARCH_LIST__ERROR',
  null,
  asyncMeta
)

export const requestSavedSearchSave = createAction('SAVED_SEARCH_SAVE__REQUEST')
export const successSavedSearchSave = createAction('SAVED_SEARCH_SAVE__SUCCESS')
export const errorSavedSearchSave = createAction('SAVED_SEARCH_SAVE__ERROR')

export const requestSavedSearchDelete = createAction(
  'SAVED_SEARCH_DELETE__REQUEST',
  null,
  asyncMeta
)
export const successSavedSearchDelete = createAction(
  'SAVED_SEARCH_DELETE__SUCCESS',
  null,
  asyncMeta
)
export const errorSavedSearchDelete = createAction(
  'SAVED_SEARCH_DELETE__ERROR',
  null,
  asyncMeta
)

export const requestSaveSearchAlert = createAction(
  'SAVE_SEARCH_ALERT__REQUEST',
  null,
  asyncMeta
)

export const successSaveSearchAlert = createAction(
  'SAVE_SEARCH_ALERT__SUCCESS',
  null,
  asyncMeta
)

export const errorSaveSearchAlert = createAction(
  'SAVE_SEARCH_ALERT__ERROR',
  null,
  asyncMeta
)

export const actions = {
  requestSavedSearchList,
  successSavedSearchList,
  errorSavedSearchList,

  requestSavedSearchSave,
  successSavedSearchSave,
  errorSavedSearchSave,

  requestSavedSearchDelete,
  successSavedSearchDelete,
  errorSavedSearchDelete,

  requestSaveSearchAlert,
  successSaveSearchAlert,
  errorSaveSearchAlert
}

const defaultState = {
  savedSearches: [],
  deleting: [],
  saving: []
}

export default handleActions(
  {
    SAVED_SEARCH_LIST__REQUEST: state => ({
      ...state,
      savedSearches: []
    }),
    SAVED_SEARCH_LIST__SUCCESS: (state, { payload: savedSearches }) => ({
      ...state,
      savedSearches: savedSearches
    }),

    SAVED_SEARCH_SAVE__REQUEST: (state, { payload: { name, search } }) => ({
      ...state,
      saving: [...state.saving, { name, search }].slice(-1 * STACK_SIZE)
    }),
    SAVED_SEARCH_SAVE__SUCCESS: (state, { payload: name }) => ({
      ...state,
      saving: state.saving.filter(search => search.name !== name)
    }),
    SAVED_SEARCH_SAVE__ERROR: (state, { payload: name }) => ({
      ...state,
      saving: state.saving.filter(search => search.name !== name)
    }),

    SAVED_SEARCH_DELETE__REQUEST: (state, { payload: { search_id: id } }) => ({
      ...state,
      deleting: [...state.deleting, id]
    }),
    SAVED_SEARCH_DELETE__SUCCESS: (state, { payload: id }) => ({
      ...state,
      savedSearches: state.savedSearches.filter(
        ({ search_id: savedId }) => savedId !== id
      ),
      deleting: state.deleting.filter(search => search !== id)
    }),
    SAVED_SEARCH_DELETE__ERROR: (state, { payload: { id } }) => ({
      ...state,
      deleting: state.deleting.filter(search => search !== id)
    })
  },
  defaultState
)
