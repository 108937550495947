export default function validate(values) {
  let errors = {}
  const maxLength = 50
  if (!values.first_name) {
    errors.first_name = 'Your first name is required'
  } else if (values.first_name.trim() === '') {
    errors.first_name = 'Your first name is required'
  } else if (values.first_name.length > maxLength) {
    errors.first_name = `Your first name must contain no more than ${maxLength} characters.`
  }

  if (!values.last_name) {
    errors.last_name = 'Your last name is required'
  } else if (values.last_name.trim() === '') {
    errors.last_name = 'Your last name is required'
  } else if (values.last_name.length > maxLength) {
    errors.last_name = `Your last name must contain no more than ${maxLength} characters.`
  }

  if (!values.email) {
    errors.email = 'Your email address is required'
  } else if (!/^[^@]+@.+\.[a-z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.company) {
    errors.company = 'Company is required'
  } else if (values.company.trim() === '') {
    errors.company = 'Company is required'
  } else if (values.company.length > maxLength) {
    errors.company = `Your company must contain no more than ${maxLength} characters.`
  }

  return errors
}
