import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from '../Input'
import { mutuallyExclusiveProps } from 'airbnb-prop-types'
import { propError } from '../utils'

const TextArea = ({ button, rightElements, ...rest }) => (
  <Input {...rest} textarea />
)
TextArea.displayName = 'TextArea'
TextArea.propTypes = {
  /**
   * If `true` the textarea will resize to fit entered text
   */
  autosize: PropTypes.bool,
  /**
   * If and how the textarea is resizable by the user
   */
  resize: PropTypes.oneOf([
    '',
    'both',
    'horizontal',
    'vertical',
    'block',
    'inline'
  ]),
  /**
   * If `true`, the label is floating, like material design text fields (not used with "placeholder")
   */
  labelFloat: mutuallyExclusiveProps(
    PropTypes.bool,
    'placeholder',
    'labelFloat'
  ),
  /**
   * Not supported
   */
  button: propError('This prop is not supported'),
  /**
   * Not supported
   */
  rightElements: propError('This prop is not supported'),
  /**
   * A minimum height for the field
   */
  minHeight: PropTypes.number,
  /**
   * A maximum height for the field
   */
  maxHeight: PropTypes.number
}

export default TextArea
