import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { newsletter } from '../../../../utils/propTypes'
import { NewsletterSection } from '../NewsletterSection'
import Typography from '../../../../components/ui/Typography'
import { Divider } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
const BrowseNewsletters = ({
  newsletters,
  subscribed = [],
  pendingForInsertion = [],
  pendingForDeletion = [],
  requestDeleteNewsletter = () => {},
  requestInsertNewsletter = () => {},
  configMyNewsletters = {},
  requestRegion = () => {}
}) => {
  const { sections = [], layout } = configMyNewsletters
  const flagName = 'industryNewsletter'
  const flags = useFlags()

  useEffect(() => {
    requestRegion && requestRegion()
  }, [])

  const hndNewsletterButtonClicked = useCallback(
    (newsletter, isSubscribed) => {
      const handler = isSubscribed
        ? requestDeleteNewsletter
        : requestInsertNewsletter
      return e => handler(newsletter)
    },
    [newsletter]
  )

  const memoizedData = useMemo(() => {
    const groupedData = _.mapValues(_.groupBy(sections, 'domain'), items =>
      _.groupBy(items, 'section')
    )

    const sectionsWithData = Object.entries(groupedData).reduce(
      (acc, [domain, sectionData]) => {
        const sectionsWithMatchingItems = Object.entries(sectionData).reduce(
          (sections, [section, items]) => {
            const matchingItems = items
              .map(item => _.find(newsletters, { id: item.uuid }))
              .filter(Boolean)
            if (matchingItems.length > 0) {
              sections[section] = matchingItems
            }
            return sections
          },
          {}
        )

        if (Object.keys(sectionsWithMatchingItems).length > 0) {
          acc[domain] = sectionsWithMatchingItems
        }

        return acc
      },
      {}
    )

    return sectionsWithData
  }, [newsletters])

  /**
   * Displays the newsletters with or without Feature Flags based on specific conditions
   * @param {Array} newsletters - Array of newsletter objects to filter
   * @param {String} flagName - The feature flag name to check against
   * @param {Object} flags - Flags specified in Launch Darkly
   * @returns {Array} Filtered array of newsletters
   */
  const shouldDisplayNewsletters = (newsletters, flagName, flags) => {
    if (!newsletters) {
      return []
    }

    return newsletters.filter(
      newsletter =>
        // Include the newsletter if it does not have the 'behindFeatureFlag' prop
        // or if the 'behindFeatureFlag' property is set and the flag with the same name is true
        !newsletter?.settings?.behindFeatureFlag ||
        (newsletter?.settings?.behindFeatureFlag === flagName &&
          flags[flagName])
    )
  }

  const DomainComponent = React.memo(({ domain, layout, newsletters = [] }) => {
    const { sections = [] } = layout

    return (
      <div>
        <Typography
          type="title"
          style={{ marginTop: '16px', marginBottom: '8px' }}
        >
          {domain.title}
        </Typography>
        <Divider sx={{ color: '#d8d8d8' }} />
        {sections.map((section, index) => {
          const newsletterData = shouldDisplayNewsletters(
            newsletters[section.dataKey],
            flagName,
            flags
          )

          if (!newsletterData || newsletterData.length === 0) {
            console.warn(
              `Newsletter data missing for section: ${section.title}`
            )
            return null
          }

          return (
            <NewsletterSection
              key={`${section.dataKey}-${index}`}
              pendingForDeletion={pendingForDeletion}
              pendingForInsertion={pendingForInsertion}
              subscribed={subscribed}
              title={section.dataKey === 'all' ? null : section.title}
              style={'container'}
              newslettersSection={newsletterData}
              hndNewsletterButtonClicked={hndNewsletterButtonClicked}
            />
          )
        })}
      </div>
    )
  })

  return (
    <>
      {layout?.domain.map((domain, index) => (
        <DomainComponent
          key={`${domain.dataKey}-${index}`}
          domain={domain}
          layout={layout}
          newsletters={memoizedData[domain.dataKey]}
        />
      ))}{' '}
    </>
  )
}

BrowseNewsletters.propTypes = {
  /**
   * The newsletters matching the currently selected filter
   */
  newsletters: PropTypes.array,
  /**
   * The newsletters the user is subscribed to
   */
  subscribed: PropTypes.array,
  /**
   * The newsletters pending insertion
   */
  pendingForInsertion: PropTypes.array,
  /**
   * The newsletters pending deletion
   */
  pendingForDeletion: PropTypes.array,
  /**
   * Called when a newsletter is deleted for the user
   */
  requestDeleteNewsletter: PropTypes.func,
  /**
   * Called when a newsletter is inserted for the user
   */
  requestInsertNewsletter: PropTypes.func,
  /**
   * The object that specifies from the config the sections for the newsletters
   */
  configMyNewsletters: PropTypes.object,
  /**
   * Called when rendering the component to get the user's region
   */
  requestRegion: PropTypes.func
}

export default BrowseNewsletters
