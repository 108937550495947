import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from './reducer'
import { compose } from 'redux'
import { history } from '../../Router'
import { historyHandler } from '../../utils'

class ComponentRedirectFw4ToFw5 extends Component {
  componentDidMount() {
    const {
      match: {
        params: { fw4_story_id: fw4Id }
      }
    } = this.props
    this.props.redirect(fw4Id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.fw5_story_id) {
      historyHandler(`/story/${this.props.fw5_story_id}`, '', history, 'push')
    }
  }

  render() {
    return <p>Redirecting ...</p>
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.views.loading,
    fw5_story_id: state.views.fw5_story_id
  }
}

const mapDispatchToProps = dispatch => ({
  redirect: fw4_story_id => dispatch(actions.redirectRequest(fw4_story_id))
})

ComponentRedirectFw4ToFw5.propTypes = {
  match: PropTypes.object,
  redirect: PropTypes.func,
  fw5_story_id: PropTypes.string
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ComponentRedirectFw4ToFw5
)
