/*
- trackingDetails: It is a property that can be an array of objects or a single object, and it's plan is to define the tracking details for each case
The object or array of objects, can have the following properties, example:
  {
    customVarUpdate: {
      key: 'article_id',
      value: id
    },
    tracking: {
      category: 'article',
      action: 'share-click',
      name: socialNetwork === 'link' ? 'copy-url' : socialNetwork
    }
  }

  - customVarUpdate: Optional and used when a variables has to be updated in Piwik, like an article id
  - tracking: Optional and used to set the details for the action tracking
*/
export default {
  logo: {
    validation: (location, newletterFrom) => {
      return location.pathname === '/' && newletterFrom === 'logo'
    },
    trackingDetails: {
      tracking: {
        category: 'newsletter',
        action: 'logo-click',
        name: 'home'
      }
    }
  },
  'footer-subscriptions': {
    validation: (location, newletterFrom) => {
      return (
        location.pathname === '/my-account/newsletters' &&
        newletterFrom === 'footer-subscriptions'
      )
    },
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'footer-click',
          name: 'my-subscriptions'
        }
      }
    ]
  },
  'footer-contact': {
    validation: (location, newletterFrom) => {
      return (
        location.pathname === '/contact-us' &&
        newletterFrom === 'footer-contact'
      )
    },
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'footer-click',
          name: 'contact-us'
        }
      }
    ]
  },
  'footer-terms': {
    validation: (location, newletterFrom) => {
      return location.pathname === '/terms' && newletterFrom === 'footer-terms'
    },
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'footer-click',
          name: 'terms'
        }
      }
    ]
  },
  'footer-privacy': {
    validation: (location, newletterFrom) => {
      return (
        location.pathname === '/privacy' && newletterFrom === 'footer-privacy'
      )
    },
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'footer-click',
          name: 'privacy'
        }
      }
    ]
  },
  'footer-advertise': {
    validation: (location, newletterFrom) => {
      return (
        location.pathname === '/advertise' &&
        newletterFrom === 'footer-advertise'
      )
    },
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'footer-click',
          name: 'advertise'
        }
      }
    ]
  }
}
