import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'

import {
  TraditionalInput,
  TraditionalLabel,
  ModernInput,
  ModernLabel
} from 'src/components/ui/Input/index.js'
import FormInputHelper from 'src/components/FormInputHelper'

/**
 * process react-hook-form error array
 * @param error
 * @returns {*|undefined}
 */
const handleErrorMsg = error => {
  if (error)
    return Array.isArray(error)
      ? error.map(error => error.message).join(' ')
      : error?.message
  return undefined
}

/**
 * react-hook-form MUI integration
 * @param type
 * @param name
 * @param control
 * @param label
 * @param placeholder
 * @param helper
 * @param rest
 * @returns {JSX.Element}
 */
const FormInput = ({
  type = 'text',
  helperText = '',
  name,
  control,
  label,
  placeholder,
  variant,
  className,
  ...rest
}) => {
  const renderInput = ({
    field: { onChange, onBlur, value },
    fieldState: { error }
  }) => {
    const { disabled, required } = rest
    const errorText = handleErrorMsg(error)
    switch (variant) {
      case 'modern':
        return (
          <FormControl className={className} fullWidth>
            {label && (
              <ModernLabel
                htmlFor={name}
                disabled={disabled}
                required={required}
              >
                {label}
              </ModernLabel>
            )}
            <ModernInput
              id={name}
              inputProps={{
                'data-testid': 'form-input'
              }}
              fullWidth
              type={type}
              error={!!error}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder={placeholder}
              {...rest}
            />
            {(error || helperText) && (
              <FormInputHelper error={!!errorText}>
                {errorText || helperText}
              </FormInputHelper>
            )}
          </FormControl>
        )
      case 'traditional':
      default:
        return (
          <FormControl className={className} fullWidth>
            {label && (
              <TraditionalLabel
                htmlFor={name}
                disabled={disabled}
                required={required}
              >
                {label}
              </TraditionalLabel>
            )}
            <TraditionalInput
              id={name}
              inputProps={{
                'data-testid': 'form-input'
              }}
              fullWidth
              type={type}
              error={!!error}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder={placeholder}
              {...rest}
            />
            {(error || helperText) && (
              <FormInputHelper error={!!errorText}>
                {errorText || helperText}
              </FormInputHelper>
            )}
          </FormControl>
        )
    }
  }
  return (
    <Controller
      name={name}
      control={control}
      render={renderProps => renderInput(renderProps)}
    />
  )
}
FormInput.propTypes = {
  /**
   * Class name to be passed to the root element (FormControl)
   */
  className: PropTypes.string,
  /**
   * react-hook-form useForm control
   */
  control: PropTypes.any,
  /**
   * input type
   */
  type: PropTypes.string,
  /**
   * input name
   */
  name: PropTypes.string,
  /**
   * top label text
   */
  label: PropTypes.string,
  /**
   * non error input helper text
   */
  helperText: PropTypes.string,
  /**
   * input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Variant of the input to be used, default 'traditional'
   */
  variant: PropTypes.oneOf(['traditional', 'modern']),
  /**
   * Style variant of the input to be used, default none
   */
  styleVariant: PropTypes.oneOf(['inset'])
}
export default FormInput
