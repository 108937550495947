import React from 'react'
import Spinner from '../ui/Spinner'

const StorySpinner = () => (
  <Spinner
    style={{ marginTop: 10, width: '100%' }}
    fillColor="transparent"
    strokeColor="rgba(213,213,213,1)"
  />
)

export default StorySpinner
