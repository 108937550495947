import { takeEvery, put, all, call } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { logout } from 'src/modules/Session/actions'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import { ACCESS_DENIED } from 'src/services/FW5ML/errorCodes.js'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { history } from 'src/Router.js'
import { historyHandler, checkIfArray } from 'src/utils/index.js'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions as configActions } from 'src/modules/Config/reducer.js'

function* requestView(services, { payload = [] }) {
  const views = payload

  const StoriesRepository = services('StoriesRepository')
  const StoryParser = services('StoryParser')

  if (!views || views.length === 0 || !checkIfArray(views)) return

  try {
    const { data: articles } = yield call([StoriesRepository, 'listArticles'], {
      content_views: views.map(({ key, ...rest }) => ({ ...rest })) // Ignore key for request
    })

    let normalizedArticles = yield StoryParser.normalizeViewSet(articles)
    normalizedArticles = normalizedArticles
      .map(({ name, data }, index) => {
        const originalView = views[index]
        const { key } = originalView
        const returnKey = key || name
        return {
          [returnKey]: { stories: data }
        }
      })
      .reduce((prev, curr) => {
        const curKey = Object.keys(curr)[0]
        const curValue = Object.values(curr)[0]
        let key = curKey
        // This provides a way to query multiple instances of the same content
        // view with different parameters in the same request.
        while (prev[key] !== undefined) {
          key += '(copy)'
        }
        return {
          ...prev,
          [key]: curValue
        }
      }, {})

    yield put(actions.successViews(normalizedArticles))
  } catch (e) {
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: true }))
        return
      }
    }
    yield put(actions.errorViews(e.message))
    throw e
  }
}

function* resetTemplateViews(services, { payload = {} }) {
  try {
    yield put(
      configActions.addConfigRequest({
        key: 'templateViews',
        data: {
          desktop: [],
          mobile: []
        }
      })
    )
  } catch (e) {
    console.log(e)
  }
}

function* redirect(services, { payload = null }) {
  const StoriesRepository = services('StoriesRepository')
  const fw5_story_id = yield call(
    [StoriesRepository, 'resolveLegacyLinks'],
    payload
  )
  if (fw5_story_id) {
    yield put(actions.redirectSuccess(fw5_story_id))
  } else {
    yield notificationsEnqueue({
      message: 'FW Story not found'
    })
    historyHandler(`/contact-us`, '', history, 'push')
  }
}

export default function* watchViews(services) {
  yield all([
    takeEvery(actions.requestViews.toString(), requestView, services),
    takeEvery(actions.redirectRequest.toString(), redirect, services),
    takeEvery(LOCATION_CHANGE, resetTemplateViews, services)
  ])
}
