import PropTypes from 'prop-types'

export const interestCategory = PropTypes.arrayOf(PropTypes.string)
export const interestTree = PropTypes.objectOf(interestCategory)
export const fullInterestTree = PropTypes.objectOf(interestTree)

export const tagWithCategory = PropTypes.shape({
  category: PropTypes.string,
  tag: PropTypes.string
})

export const article = PropTypes.shape({
  author: PropTypes.string,
  body: PropTypes.string,
  categorizedTags: PropTypes.objectOf(PropTypes.array),
  flags: PropTypes.shape({
    isPaid: PropTypes.bool
  }),
  id: PropTypes.string,
  image: PropTypes.string,
  published: PropTypes.number,
  source: PropTypes.string,
  tags: PropTypes.arrayOf(tagWithCategory),
  title: PropTypes.string,
  type: PropTypes.string
})

export const savedSearch = PropTypes.shape({
  search_id: PropTypes.number,
  name: PropTypes.string,
  search_request: PropTypes.object
})

export const newsletter = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string
})
