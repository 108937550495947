export default {
  'section-header': {
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'heading-click',
          name: '' // Name will be passed directly in the river saga function
        }
      }
    ]
  },
  'read-more-link': {
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'button-click',
          name: '' // Name will be passed directly in the river saga function
        }
      }
    ]
  },
  'other-storywatches': {
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'button-click',
          name: '' // Name will be passed directly in the river saga function
        }
      }
    ]
  },
  'read-more-btn': {
    trackingDetails: [
      {
        tracking: {
          category: 'newsletter',
          action: 'button-click',
          name: '' // Name will be passed directly in the river saga function
        }
      }
    ]
  }
}
