import styles from 'src/styles/themeswitch.scss'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import cssVars from 'css-vars-ponyfill'
import { siteName } from 'src/utils/oneCodeBase.js'

const themeOptions = [
  // PLUS Pharma theme
  {
    '--main-color': styles.greenish,
    '--footer-color': styles.footerColorGreenish,
    '--featured-color': styles.featuredColorGreenish,
    '--plus-color': styles.inherit,
    '--fw-color': styles.greenish,
    '--footer-logo-width': styles.auto,
    name: `${licenseTypes.LICENSE_PLUS}_${siteName}`
  },
  // FREE Pharma theme
  {
    '--main-color': styles.navy,
    '--footer-color': styles.footerColorNavy,
    '--featured-color': styles.featuredColorNavy,
    '--plus-color': styles.transparent,
    '--fw-color': styles.blue,
    '--footer-logo-width': styles.footerLogoWidth,
    name: `${licenseTypes.LICENSE_FREE}_${siteName}`
  },
  //PLUS Edge theme
  {
    '--main-color': styles.edgeMain,
    '--footer-color': styles.edgeMain,
    '--featured-color': styles.featuredColorGreenish,
    '--plus-color': styles.transparent,
    '--fw-color': styles.edgeMain,
    '--footer-logo-width': styles.auto,
    name: 'Edge'
  },
  {
    '--main-color': styles.reportsMain,
    '--footer-color': styles.reportsMain,
    '--featured-color': styles.featuredColorGreenish,
    '--plus-color': styles.transparent,
    '--fw-color': styles.reportsMain,
    '--footer-logo-width': styles.auto,
    name: 'Reports'
  }
]

export function themeSwitcher(themeName) {
  const variables =
    themeOptions.find(t => t.name.toLowerCase() === themeName.toLowerCase()) ||
    {}
  cssVars({ variables })
  // When IE11 is no longer supported, we can remove cssVars "ponyfill" and instead directly use `setProperty` as below
  /*
  const html = document.getElementsByTagName('html')[0]
  Object.keys(variables).forEach((property, i) => {
    if (property === 'name') {
      return
    }
    html.style.setProperty(property, variables[property])
  })
  */
}
