import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { actions as viewsActions } from 'src/modules/Views/reducer'
import GeneralLayoutChild from './children/GeneralLayoutChild'

const getViewsRequest = (viewsData, interests) => {
  // REDUCE is slow for foreach map reduce
  //
  return Object.keys(viewsData).reduce(function (acc, curr) {
    const sectionData = viewsData[curr]
    const views = sectionData
      .filter(item => item?.view_request === true)
      .map(item => {
        const viewBody = { name: item?.name }

        if (item?.include_likes) {
          viewBody.include_likes = item.include_likes
        }

        if (item?.include_related_tags) {
          viewBody.include_related_tags = item.include_related_tags
        }

        if (item?.skip_exclusion) {
          viewBody.skip_exclusion = item.skip_exclusion
        }

        if (item?.interests_request && interests) {
          viewBody.tags = interests
        }

        if (item?.tags) {
          viewBody.tags = { ...viewBody?.tags, ...item.tags }
        }

        if (item?.size) {
          viewBody.size = item.size
        }

        return viewBody
      })
    return [...acc, ...views]
  }, [])
}

const LayoutChild = ({
  screen,
  content_layout = [],
  sessionContext,
  ...props
}) => {
  const dispatch = useDispatch()
  const content = screen ? content_layout[screen] : {}
  const interests = sessionContext?.getInterests() || null

  const requestViews = () => {
    const viewsRequest = getViewsRequest(content, interests)

    if (viewsRequest && viewsRequest.length > 0) {
      dispatch(viewsActions.requestViews(viewsRequest))
    }
  }

  useEffect(() => {
    // Avoid duplicate requests for mobile, take desktop requests as default
    if (screen !== 'mobile') {
      requestViews()
    }
  }, [])

  return (
    <GeneralLayoutChild
      {...props}
      screen={screen}
      content_layout={content_layout}
    />
  )
}

LayoutChild.propTypes = {
  screen: PropTypes.string,
  content_layout: PropTypes.object,
  sessionContext: PropTypes.object,
  component: PropTypes.elementType
}

export default LayoutChild
