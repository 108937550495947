export default function validate(values) {
  let errors = {}

  if (!values.name) {
    errors.name = 'Your name is required'
  } else if (values.name.trim() === '') {
    errors.name = 'Your name is required'
  } else if (values.name.length > 64) {
    errors.name = 'Full name must not exceed 64 characters'
  }
  if (!values.team || values.team === 'Please select') {
    errors.team = 'A team is required'
  }
  if (
    !values.message ||
    values.message.trim() === '' ||
    values.message.trim().length < 5
  ) {
    errors.message = 'Message is required'
  }

  if (!values.email) {
    errors.email = 'Your email address is required'
  } else if (!/^[^@]+@.+\.[a-z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.grade && values.grade !== 0) {
    errors.grade = 'Please select a grade'
  }
  return errors
}
