import React, { useState, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SessionContext } from 'src/modules/Session/context'
import AIPaywall from './Components/AIAction'
import UpgradeUser from './Components/UpgradeUser'
import RequestModal from './Components/RequestModal'
import AnonPaywall from './Components/AnonAction'
import {
  getPaywallVisible,
  getType,
  getFeatureText,
  removePaywall,
  getSubtype
} from 'src/modules/Paywall/slice'
import { actions as moduleActions } from 'src/modules/MobileMenu/reducer'
import EmailConfirmation from './Components/EmailConfirmation'
import PlusActionPaywall from './Components/PlusAction'
import usePiwik from 'src/hooks/usePiwik'
import mapping from './mapping'

const PaywallFlowModal = () => {
  const { handleTrackingWithArticleId } = usePiwik()
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [showRegistration, setShowRegistration] = useState(false)
  const [fromTrackingAction, setFromTrackingAction] = useState(
    'request-a-free-trial'
  )
  const [isAnonymous, setIsAnonymous] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [thankYouText, setThankYouText] = useState('')
  const [popTitle, setPopTitle] = useState('')
  const [trackingKey, setTrackingKey] = useState(null)
  const [trackingType, setTrackingType] = useState(null)
  const location = useLocation()
  const dispatch = useDispatch()

  const session = useContext(SessionContext)
  const paywallType = useSelector(getType)
  const paywallSubtype = useSelector(getSubtype)
  const paywallVisible = useSelector(getPaywallVisible)
  const featureTextSelector = useSelector(getFeatureText)

  const mappedData = useMemo(() => {
    const mappingKey = paywallSubtype || paywallType || null
    return mapping[mappingKey]
  }, [paywallSubtype, paywallType])

  const featureText = featureTextSelector || mappedData?.text || ''
  const headerColor = mappedData?.headerColor
  const showCounter = mappedData?.showCounter
  const showRegistrationButton = mappedData?.showRegistrationButton

  useMemo(() => {
    if (mappedData?.tracking) {
      const tracking = mappedData?.tracking
      const trackingMappedKey =
        session?.checked && session?.authenticated
          ? tracking?.free
          : tracking?.anon

      setTrackingKey(trackingMappedKey || null)
      setTrackingType(tracking?.type || 'paywall')
    }
  }, [mappedData?.tracking, session])

  useEffect(() => {
    /*
      featureTextSelector is also passed in the dependencies because it needs to track the view
      when the same paywall appears and is already opened, but with different content.
      For example in the PLUS newsletters, the paywall is the same for all PLUS newsletters,
      but what changes is the featureText/title of each newsletter
    */

    if (trackingType && trackingKey && paywallVisible) {
      handleTrackingWithArticleId(trackingType, 'view', trackingKey)
    }
  }, [trackingType, trackingKey, paywallVisible, featureTextSelector])

  useEffect(() => {
    if (session?.checked && session?.authenticated) {
      setThankYouText(`A member of our team will reach out to you shortly, `)
      setIsAnonymous(false)
    } else {
      setThankYouText(
        "We've sent you an email. Please click the link within the email to verify your email address. If you haven’t received the email, please check your spam and promotions folders or "
      )
      setIsAnonymous(true)
    }
  }, [session])

  useEffect(() => {
    if (showUpgrade) {
      setPopTitle(
        'Complete the below form and a member of our team will reach out to you shortly.'
      )
    } else {
      setPopTitle(
        'Complete the below form and a member of our team will reach out to you shortly to discuss a free trial.'
      )
    }
  }, [showUpgrade])

  useEffect(() => {
    dispatch(moduleActions.closeLeftMenu())
    if (paywallType === 'ai_paywall') {
      setPopTitle(
        'Complete the below form and a member of our team will reach out to you shortly.'
      )
    }
  }, [paywallType, paywallVisible])

  const PaywallByType = {
    ai_paywall: AIPaywall,
    free_only: AnonPaywall,
    free_feature: AnonPaywall,
    plus_only: PlusActionPaywall,
    plus_feature: PlusActionPaywall
  }
  const closeConfirmation = () => {
    setShowUpgrade(false)
    setShowRegistration(false)
    setShowSuccess(false)
    dispatch(removePaywall())
    if (
      location.pathname.includes('story') &&
      (paywallType === 'plus_only' || paywallType === 'free_only')
    ) {
      window.location.replace('/')
    }
  }

  const PaywallPopup = PaywallByType[paywallType]
  return (
    <>
      {paywallVisible && (
        <PaywallPopup
          paywallType={paywallType}
          featureText={featureText}
          popTitle={popTitle}
          isAnonymous={isAnonymous}
          headerColor={headerColor}
          showRegistrationButton={showRegistrationButton}
          paywallSubtype={paywallSubtype}
          showCounter={showCounter}
          showRegistration={props => {
            const trackingAction =
              props?.trackingAction || 'request-a-free-trial'

            setFromTrackingAction(trackingAction)
            handleTrackingWithArticleId(
              trackingType,
              'button-click',
              `${trackingKey}_${trackingAction}`
            )
            setShowRegistration(true)
            handleTrackingWithArticleId(
              'pop-up',
              'view',
              `paywall_${trackingKey}_${trackingAction}-form`
            )
          }}
          onClose={() => closeConfirmation()}
          setShowUpgrade={() => {
            setFromTrackingAction('upgrade-now')
            handleTrackingWithArticleId(
              'paywall',
              'button-click',
              `${trackingKey}_upgrade-now`
            )
            setShowUpgrade(true)
            handleTrackingWithArticleId(
              'paywall',
              'view',
              `${trackingKey}_upgrade-now`
            )
          }}
          trackingKey={trackingKey}
          trackingType={trackingType}
        />
      )}

      {showUpgrade && (
        <UpgradeUser
          featureText={featureText}
          popTitle={popTitle}
          headerColor={headerColor}
          showRegistration={props => {
            const trackingAction =
              props?.trackingAction || 'upgrade-now_contact-us'
            setFromTrackingAction(trackingAction)
            handleTrackingWithArticleId(
              'paywall',
              'button-click',
              `${trackingKey}_${trackingAction}`
            )
            setShowRegistration(true)
            handleTrackingWithArticleId(
              'pop-up',
              'view',
              `paywall_${trackingKey}_${trackingAction}-form`
            )
          }}
          onClose={() => {
            setShowUpgrade(false)
          }}
          trackingKey={trackingKey}
        />
      )}

      {showRegistration && (
        <RequestModal
          open={showRegistration}
          popTitle={popTitle}
          headerColor={headerColor}
          onCancel={() => setShowRegistration(false)}
          onSuccess={() => {
            setShowRegistration(false)
            setShowSuccess(true)
            handleTrackingWithArticleId(
              'pop-up',
              'button-click',
              `paywall_${trackingKey}_${fromTrackingAction}-form_submit`
            )
          }}
          trackingKey={trackingKey}
        />
      )}
      {showSuccess && (
        <EmailConfirmation
          open={showSuccess}
          onCancel={() => closeConfirmation()}
          thankYouText={thankYouText}
          inlineContact={isAnonymous}
          trackingKey={trackingKey}
        />
      )}
    </>
  )
}

PaywallFlowModal.propTypes = {
  trackingAction: PropTypes.string
}

export default PaywallFlowModal
