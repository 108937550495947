import _get from 'lodash/get'

const queryString = getState => ({
  get: (key, defaultValue, fromState = false) => {
    let qs = window?.location?.search
    if (fromState) {
      const state = getState()
      qs = state?.router?.location?.search
    }

    const objs = qs
      .replace(/^\?/, '')
      .split('&')
      .reduce((accu, cur) => {
        const KEYED_ARRAY_RE = /^(\w+)\[([^\]]*)\]$/
        const parts = cur.split('=')
        const key = parts.shift()
        let val = parts.length === 0 ? true : decodeURIComponent(parts.join(''))
        if (key.length === 0) return accu
        if (KEYED_ARRAY_RE.test(key)) {
          const match = KEYED_ARRAY_RE.exec(key)
          const mainkey = match[1]
          const subkey = decodeURIComponent(match[2])
          return {
            ...accu,
            [mainkey]: {
              ...accu[mainkey],
              [subkey]: val
            }
          }
        }
        return { ...accu, [key]: val }
      }, {})
    if (key === undefined) return objs
    return _get(objs, key, defaultValue)
  },

  removeKey: (removeKey, query) => {
    let i = 0
    if (!query) return ''
    const qs = query
    return qs
      .replace(/^\?/, '')
      .split('&')
      .reduce((accu, cur) => {
        const parts = cur.split('=')
        const key = parts.shift()
        const val = parts.length === 0 ? '' : '=' + parts.join('')
        if (key === removeKey) return accu
        const separator = i++ === 0 ? '?' : '&'
        return `${accu}${separator}${key}${val}`
      }, '')
  },

  build: objs => {
    return (
      '?' +
      Object.keys(objs)
        .reduce((acc, key) => {
          const val = objs[key]
          if (typeof val === 'object') {
            const newItems = Object.keys(val)
              .reduce((acc, k) => {
                const v = encodeURIComponent(val[k])
                return [
                  ...acc,
                  `${encodeURIComponent(key)}[${encodeURIComponent(k)}]=${v}`
                ]
              }, [])
              .join('&')
            return [...acc, newItems]
          }
          return [
            ...acc,
            `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
          ]
        }, [])
        .join('&')
    )
  }
})

export default queryString
