import React from 'react'
import PropTypes from 'prop-types'

import styles from '../../SingleStory.module.sass'
import Button from '@mui/material/Button'
import usePiwik from 'src/hooks/usePiwik'

const AnonymousAndNotUpcoming = ({ openModal, articleId }) => {
  const { handleTrackingWithArticleIdProp } = usePiwik()
  const handleContactButtonClick = () => {
    handleTrackingWithArticleIdProp(
      'article',
      'button-click',
      'contact-me-about-this-report',
      articleId
    )
    openModal('contact-me-about-this-report')
  }
  return (
    <Button
      className={styles.contactButton}
      onClick={handleContactButtonClick}
      variant="contained"
    >
      Contact Us to Access this Report
    </Button>
  )
}

AnonymousAndNotUpcoming.propTypes = {
  openModal: PropTypes.func,
  articleId: PropTypes.string
}

export default AnonymousAndNotUpcoming
