import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../Typography'
import Divider from '../Divider'
import styles from './Footnote.module.sass'

const Footnote = ({ children, className, textClassName }) => (
  <div className={className}>
    <Divider className={styles.divider} />
    <Typography type="footnote" className={textClassName}>
      {children}
    </Typography>
  </div>
)

Footnote.propTypes = {
  /**
   * Text content inside footnote
   */
  children: PropTypes.node,
  /**
   * An additional custom className
   */
  className: PropTypes.string,
  /**
   * An additional custom className for children
   */
  textClassName: PropTypes.string
}

export default Footnote
