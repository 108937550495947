import PropTypes from 'prop-types'
import {
  TextField,
  Typography,
  styled,
  FormControl,
  MenuItem
} from '@mui/material'
import React, { useState } from 'react'

export const FwSelect = ({
  id = 'select_id',
  name = 'select_name',
  dataSource = [],
  fullWidth = true,
  formSx = null,
  label = null,
  variant = 'standard',
  value = null,
  valueForData = 'id',
  labelForData = 'name',
  size = 'small',
  showEmptyValue = false,
  emptyValueLabel = 'None',
  onChange = () => {}
}) => {
  const [selectedValue, setSelectedValue] = useState(value)

  const handleOnChange = e => {
    setSelectedValue(e.target.value)
    if (onChange) {
      onChange(e)
    }
  }
  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      size={size}
      sx={formSx}
    >
      <TextField
        labelId={id}
        id={`fw_${id}`}
        name={name}
        value={selectedValue}
        onChange={handleOnChange}
        variant="standard"
        select
        label={label}
        InputLabelProps={{
          shrink: !!label
        }}
        sx={{
          padding: 0,
          '.MuiInputBase-root': {
            marginTop: label ? undefined : '0px'
          }
        }}
      >
        {showEmptyValue && (
          <MenuItem value="">
            <em>{emptyValueLabel}</em>
          </MenuItem>
        )}
        {dataSource.map(item => (
          // eslint-disable-next-line react/jsx-key
          <MenuItem key={item[valueForData]} value={item[valueForData]}>
            <ChatWarning>{item[labelForData]}</ChatWarning>
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}

FwSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  dataSource: PropTypes.array,
  fullWidth: PropTypes.bool,
  formSx: PropTypes.object,
  label: PropTypes.any,
  variant: PropTypes.string,
  value: PropTypes.any,
  valueForData: PropTypes.string,
  labelForData: PropTypes.string,
  size: PropTypes.string,
  showEmptyValue: PropTypes.bool,
  emptyValueLabel: PropTypes.string,
  onChange: PropTypes.func
}

export default FwSelect

const ChatWarning = styled(Typography, {
  name: 'Chat',
  label: 'chatWarning',
  overridesResolver: (props, styles) => [styles.warning]
})(() => ({
  fontSize: '14px',
  fontWeight: 'bold',
  padding: 0,
  color: '#667085'
}))
