import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalContainer from 'react-overlays/Modal'
import styles from './Modal.module.sass'
import Icon from '../Icon'
import Typography from '../Typography'

const modalStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}

const Modal = ({
  overlayColor = 'rgba(0, 0, 0, 0.1)',
  maxWidth,
  height,
  width,
  modalOpen,
  modalType,
  children,
  onDismiss,
  altTheme,
  closeLabel,
  bottomText,
  hasBackdropBlur
}) => {
  const [showModal, setShowModal] = useState(modalOpen)
  const [internalUpdate, setInternalUpdate] = useState(false)

  useEffect(() => {
    if (!internalUpdate && modalOpen !== showModal) {
      setShowModal(modalOpen)
    }
    setInternalUpdate(false)
  }, [modalOpen, showModal, internalUpdate])

  const close = () => {
    setShowModal(false)
    setInternalUpdate(true)
    if (typeof onDismiss === 'function') {
      onDismiss()
    }
  }

  const decideModal = (
    modalStyle,
    backdropStyle,
    showModal,
    type,
    themeStyles,
    maxWidth,
    width,
    height,
    other,
    children,
    closeLabel,
    bottomText
  ) => {
    switch (type) {
      // Interstitial
      case 1:
        return (
          <div
            aria-labelledby="modal-label"
            style={modalStyle}
            backdropStyle={backdropStyle}
            show={showModal}
            className={themeStyles.interstitialContainer}
            containerClassName={styles.modalBody}
          >
            <div
              className={themeStyles.interstitial}
              style={{
                ...(width && { width: width }),
                ...(height && { height: height })
              }}
              {...other}
            >
              <Typography
                type="thumb-label"
                className={themeStyles.closeLabel}
                onClick={close}
              >
                {closeLabel}
              </Typography>
              <div className={themeStyles.interstitialContent}>{children}</div>
              <Typography type="greeting" className={themeStyles.bottomText}>
                {bottomText}
              </Typography>
            </div>
          </div>
        )
      // Regular Modal
      default:
        return (
          <ModalContainer
            aria-labelledby="modal-label"
            style={modalStyle}
            backdropStyle={backdropStyle}
            show={showModal}
            onHide={close}
            className={themeStyles.container}
            containerClassName={styles.modalBody}
          >
            <div
              className={themeStyles.modal}
              style={{ ...(maxWidth && { maxWidth: `${maxWidth}px` }) }}
              {...other}
            >
              <Icon
                className={themeStyles.button}
                onClick={close}
                icon="svg/material-design-icons/navigation/close"
                button
              />
              <div className={themeStyles.content}>{children}</div>
            </div>
          </ModalContainer>
        )
    }
  }

  const themeStyles = { ...styles, ...altTheme }
  const backdropStyle = {
    ...modalStyle,
    zIndex: 'auto',
    backgroundColor: overlayColor,
    backdropFilter: hasBackdropBlur ? 'blur(5px)' : ''
  }

  return (
    <>
      {decideModal(
        modalStyle,
        backdropStyle,
        showModal,
        modalType,
        themeStyles,
        maxWidth,
        width,
        height,
        {}, // `other` props should be handled appropriately if they are used
        children,
        closeLabel,
        bottomText
      )}
    </>
  )
}

Modal.propTypes = {
  overlayColor: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
  modalOpen: PropTypes.bool,
  modalType: PropTypes.number,
  children: PropTypes.node,
  onDismiss: PropTypes.func,
  altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  closeLabel: PropTypes.string,
  bottomText: PropTypes.string,
  hasBackdropBlur: PropTypes.bool
}

export default Modal
