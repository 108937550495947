import React from 'react'
import PropTypes from 'prop-types'
import styles from './Uncap.module.sass'

const Uncap = ({ children }) => <span className={styles.uncap}>{children}</span>

Uncap.propTypes = {
  children: PropTypes.node
}

export default Uncap
