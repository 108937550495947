import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { actions } from 'src/components/ActionButtons/reducer'
import styles from '../../SingleStory.module.sass'
import Button from '@mui/material/Button'
import usePiwik from 'src/hooks/usePiwik'

const HasAccess = ({ reportSettings, articleId }) => {
  const dispatch = useDispatch()
  const { handleTrackingWithArticleIdProp } = usePiwik()

  const handleDownloadClick = () => {
    dispatch(actions.reportDownloadRequest(reportSettings?.productCode))
    handleTrackingWithArticleIdProp(
      `article`,
      'button-click',
      'download-now',
      articleId
    )
  }

  return (
    <Button
      className={styles.contactButton}
      variant="contained"
      onClick={handleDownloadClick}
    >
      Download now
    </Button>
  )
}

HasAccess.propTypes = {
  reportSettings: PropTypes.object,
  articleId: PropTypes.any
}

export default HasAccess
