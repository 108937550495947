import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { tagWithCategory } from '../../utils/propTypes'
import classNames from 'classnames'
import styles from './Interests.module.sass'
import { Link } from 'react-router-dom'

const Interests = ({ tags = [], clickable = true, parentStyle = {} }) => {
  return tags.length ? (
    <p className={styles.tags} style={parentStyle}>
      <span className={styles.interests}>Interests: </span>
      {tags.map((interest, index) => {
        const category =
          interest.category === 'fw_therapeutic_category'
            ? 'drug_classes'
            : interest.category
        return (
          <Fragment key={`${interest.category}-${interest.tag}`}>
            {clickable ? (
              <Link
                to={`/river/tag/${encodeURIComponent(
                  category
                )}/${encodeURIComponent(interest.tag)}`}
                className={classNames(styles.link, 'story-excerpt-interest')}
              >
                {interest.tag}
              </Link>
            ) : (
              <span className="story-excerpt-interest">{interest.tag}</span>
            )}
            {index < tags.length - 1 && ', '}
          </Fragment>
        )
      })}
    </p>
  ) : null
}

Interests.propTypes = {
  /**
   * Array of tags
   */
  tags: PropTypes.arrayOf(tagWithCategory),
  /**
   * Indicates whether the interests should be clickable
   */
  clickable: PropTypes.bool,
  /**
   * Additional styles for the parent element
   */
  parentStyle: PropTypes.object
}

export default Interests
