import siteConfig from 'src/site.config.json'

const {
  configuration: {
    tracking: { clarity }
  }
} = siteConfig

/**
 * ClarityService, handles Microsoft Clarity tracking and analytics functionality.
 * It provides methods for initializing tracking variables
 *
 * @param {SessionService} SessionService - The session service used for caching values.
 * @returns {Object} - An object containing the TrackingService methods.
 */
const ClarityService = SessionService => {
  const isProduction = process.env.NODE_ENV === 'production'
  const isStaging = process.env.NODE_ENV === 'staging'

  // Only enable Clarity in production or staging environments
  if (!isProduction && !isStaging) {
    console.warn('Clarity is disabled in non-production environments')
    return {
      setCustomIds: () => {},
      resetCustomIds: () => {}
    }
  }

  return {
    /**
     * Sets custom IDs for Clarity if identifiers are defined in site.config.json.
     * Extracts the values from cache and calls the Clarity 'identify' API with customId and customSessionId.
     *
     * Docs: https://learn.microsoft.com/en-us/clarity/setup-and-installation/identify-api#customizing-custom-id
     *
     * @returns {boolean|Promise<*>}
     */
    setCustomIds(partyId, subscriptionType) {
      if (window.clarity === undefined) {
        console.warn('clarity not found')
        return
      }

      const customId = partyId ? partyId.toString() : undefined
      const customSessionId = subscriptionType
        ? subscriptionType.toString()
        : undefined

      if (customId) {
        window.clarity('set', 'partyId', customId)
      }
      if (customSessionId) {
        window.clarity('set', 'subscription', customSessionId)
      }
      return window.clarity('identify', customId, customSessionId)
    },

    /**
     * Resets custom-id and custom-session-id if clarity is found and identifiers are defined.
     *
     * @returns {boolean|Promise<*>}
     */
    resetCustomIds() {
      if (window.clarity === undefined) {
        console.warn('clarity not found')
        return
      }

      if (clarity?.identifiers) return window.clarity('identify', '', '')
    }
  }
}

export default ClarityService
