import React from 'react'
import PropTypes from 'prop-types'
import FullImage from '../FullImage'
import styles from './AboutUs.module.sass'
import cx from 'classnames'
import ReactHtmlParser from 'react-html-parser'

const AboutUs = ({ sponsor }) => (
  <div className={styles.sponsorFooterContainer}>
    <div className={styles.footerContainer}>
      {sponsor.largeLogoUrl && sponsor.url && (
        <a
          href={sponsor.url}
          className={styles.sponsorLogo}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FullImage src={sponsor.largeLogoUrl} alt={`${sponsor.name} Logo`} />
        </a>
      )}
      <div className={cx(styles.sponsorText, styles.singleStory)}>
        <h2>About {sponsor.name ? sponsor.name : 'Us'}</h2>
        <p>{ReactHtmlParser(sponsor.description)}</p>
      </div>
    </div>
  </div>
)

AboutUs.propTypes = {
  sponsor: PropTypes.object
}

export default AboutUs
