import axios from 'axios'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { delay } from 'src/utils/index.js'
import FW5MLError from './FW5MLError'

const MAX_RETRIES = 3

// Delay function: tries^2 + 1
const delayFn = tries => tries * tries + 1

const shouldRetry = error =>
  // Request was made and server responded with error 502 or 503
  (error.response && [502, 503].indexOf(error.response.status) > -1) ||
  // Request was made, but no response was received
  (!error.response && error.request)

const FW5MLInterceptors = (Session, config, dispatch) => {
  const appConfig = config
  return {
    invalidateCache: async config => {
      config.headers['Cache-Control'] =
        'no-cache, no-store, must-revalidate, max-age=0'
      return config
    },
    setToken: async config => {
      // Do not override a previously set token
      if (config.headers.Authorization) return config
      const isAuthPath = /\/auth\/?$/i
      if (isAuthPath.test(config.url)) {
        const authToken = appConfig.ML_AUTH_TOKEN
        config.headers['Authorization'] = `Bearer ${authToken}`
      } else {
        const token = await Session.tokenLoad()
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`
        }
      }
      return config
    },
    catchNetworkErrors: async error => {
      if (!error.response) {
        console.error('The MiddleLayer is unreachable.', error)
        dispatch(
          notificationsEnqueue({
            message:
              'Something went wrong! Please refresh the page and try again.'
          })
        )
        return
      }
      throw error
    },
    retryIfUnavailable: async error => {
      for (let tries = 1; tries <= MAX_RETRIES; tries++) {
        if (shouldRetry(error)) {
          console.warn(`Request failed. Retrying in ${delayFn(tries)}s.`)
          await delay(delayFn(tries) * 1000)()
          try {
            const res = await axios(error.config)
            return res
          } catch (e) {
            continue
          }
        }
        // Something else happened; bubble up the exception
        throw error
      }
      throw new Error(
        'Something went wrong! Please refresh the page and try again.'
      )
    },
    detectMLfailuresOnSuccess: async response => {
      const {
        config: { url }
      } = response

      if (url !== '/profile/auth/email') {
        if (response.data && response.data.success === false) {
          const { error } = response.data
          const err = new FW5MLError(error.msg, error.code)
          throw err
        }
      }
      return response
    },
    detectMLfailuresOnFail: async error => {
      if (error?.response?.data && error.response.data.success === false) {
        const { error: responseError } = error.response.data
        const err = new FW5MLError(responseError.msg, responseError.code)
        throw err
      }
      throw error
    }
  }
}

export default FW5MLInterceptors
