export default function validate(values) {
  let errors = {}

  const minLength = 2
  const maxLength = 600
  if (values.query && values.query.length < minLength) {
    errors.query = `Must be ${minLength} characters or more`
  }
  if (values.query && values.query.length > maxLength) {
    errors.query = `Must be ${maxLength} characters or less (${values.query.length}/${maxLength})`
  }
  return errors
}
