class LiveFeedRepository {
  constructor(Fw5MLApi) {
    this.Fw5MLApi = Fw5MLApi
  }

  async getLiveFeed(liveFeedTags) {
    if (liveFeedTags?.length > 0) {
      const req = await this.Fw5MLApi.fetch(`/liveFeed`, 'POST', {
        isPromptLinked: true,
        query_string: null,
        type: [],
        sort_by: 'publication_date',
        from: 0,
        size: 40,
        publication_date: null,
        tags: {
          _all: liveFeedTags
        },
        tags_category_operator: 'OR'
      })
      return req
    } else {
      const req = await this.Fw5MLApi.fetch(`/liveFeed`, 'POST', {
        isPromptLinked: false,
        sort_by: 'publication_date',
        content_views: [
          {
            name: 'lead article'
          },
          {
            name: 'top stories'
          },
          {
            name: 'insight analysis and views'
          },
          {
            name: 'conference news'
          }
        ],
        tags_category_operator: 'OR'
      })
      return req
    }
  }
}

export default LiveFeedRepository
