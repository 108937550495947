import { createAction, handleActions } from 'redux-actions'
import { stages } from './constants'

export const requestBootstrap = createAction('BOOTSTRAP__REQUEST')
export const successBootstrap = createAction('BOOTSTRAP__SUCCESS')
export const errorBootstrap = createAction('BOOTSTRAP__ERROR')
export const setNoticeVisibility = createAction(
  'BOOTSTRAP_SET_NOTICE_VISIBILITY'
)

export const actions = {
  requestBootstrap,
  successBootstrap,
  errorBootstrap,
  setNoticeVisibility
}

const defaultState = {
  stage: stages.UNINITIALIZATED,
  notice: true
}

export default handleActions(
  {
    BOOTSTRAP__REQUEST: state => ({
      ...state,
      stage: stages.BOOTSTRAPPING
    }),
    BOOTSTRAP__SUCCESS: state => ({
      ...state,
      stage: stages.INITIALIZED
    }),
    BOOTSTRAP__ERROR: state => ({
      ...state,
      stage: stages.FAILED
    }),
    BOOTSTRAP_SET_NOTICE_VISIBILITY: (state, { payload }) => ({
      ...state,
      notice: payload
    })
  },
  defaultState
)
