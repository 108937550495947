import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import withCatalogs from '../../Catalogs/withCatalogs'
import RegisterForm from '../components/RegisterForm'
import validate from '../components/RegisterForm/validate'
import { audienceTypes } from '../../../services/FW5ML/parsers/UserParser'

export default compose(
  reduxForm({
    form: 'register',
    initialValues: {
      license: audienceTypes.FREE,
      register_pharma: false,
      register_healthtech: false,
      register_edge: false,
      register_reports: false
    },
    validate,
    touchOnBlur: false
  }),
  withCatalogs({
    jobFunctions: 'jobFunction',
    country: 'country',
    heardAbout: 'heardAboutOptions'
  })
)(RegisterForm)
