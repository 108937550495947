import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Typography from 'src/components/ui/Typography/index.js'
import styles from './ReferenceArticleExcerpt.module.sass'
import useComponentSize from '@rehooks/component-size'
import throttle from 'lodash/throttle'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const ReferenceArticleExcerpt = ({
  name = 'Name',
  title = 'Title',
  to = '/'
}) => {
  const ref = useRef(null)
  const throttledComponentSize = throttle(useComponentSize, 250)
  const size = throttledComponentSize(ref)
  const { width } = size
  const sidebar = width < 420
  return (
    <article ref={ref} className={styles.container}>
      <div className={styles.content}>
        <header>
          <a
            href={to}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              type="title"
              className={cx(styles.title, { sidebar })}
              gutterBottom
            >
              {title} [{name}]
            </Typography>
          </a>
        </header>
      </div>
    </article>
  )
}
ReferenceArticleExcerpt.propTypes = {
  /**
   * The excerpt name
   */
  name: PropTypes.string,
  /**
   * The excerpt title
   */
  title: PropTypes.string,
  /**
   * The excerpt link to full story
   */
  to: PropTypes.string
}

export default ReferenceArticleExcerpt
