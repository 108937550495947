import { createReducer, createAction } from '@reduxjs/toolkit'

export const displayContainer = createAction('DISPLAY_CONTAINER')

export const actions = {
  displayContainer
}

const initialState = {
  display: 'chat'
}

const chatPageReducer = createReducer(initialState, {
  [displayContainer]: (state, { payload: { container } }) => {
    // Make sure only popular, chat, history or faqs are available options
    // This only applies to small screens
    const display = ['popular', 'chat', 'history', 'faqs'].includes(container)
      ? container
      : 'chat'
    state.display = display
  }
})
export default chatPageReducer
