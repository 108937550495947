import axios from 'axios'
import { authRoles, limitedRoles, defaultSpecialty } from '../constants'
import { addSiteIdToPayload, isEmpty } from 'src/utils'

const PhoenixAuthService = () => ({
  /**
   * Executes user verify and token refresh
   * @param verifyEndpoint
   * @param refreshEndpoint
   * @param accessToken
   * @param full - if true: will try refresh
   * @param refreshToken
   */
  async userVerify(
    verifyEndpoint,
    refreshEndpoint,
    accessToken,
    full = false,
    refreshToken
  ) {
    if (isEmpty(accessToken)) {
      throw new Error('no token')
    }

    const userVerify = (endpoint, token) => ({
      url: endpoint,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const tokenRefresh = (endpoint, token) => ({
      url: endpoint,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: addSiteIdToPayload({ token })
    })

    return await axios(userVerify(verifyEndpoint, accessToken))
      .then(r => {
        return this.parseAuthResponse(r, 'verify')
      })
      .catch(async e => {
        // IF VERIFY FAILS, TRY TO REFRESH
        if (full) {
          // IF FULL VALIDATION IS REQUESTED, RUN REFRESH
          if (
            [400, 401].includes(e.response?.status) &&
            !isEmpty(refreshToken)
          ) {
            // AUTH RETURNS 400 WHEN TOKEN INVALID
            const response = await axios(
              tokenRefresh(refreshEndpoint, refreshToken)
            ).catch(e => {
              console.warn(e)
              throw new Error('REFRESH TOKEN ERROR 1')
            })
            return this.parseAuthResponse(response, 'refresh')
          } else {
            // IF NOT 400 SOMETHING'S WRONG WITH AUTH - ABORT
            console.warn(e)
            throw new Error('REFRESH TOKEN ERROR 2')
          }
        } else {
          // SOMETHING'S WRONG WITH AUTH - ABORT
          console.warn(e)
          throw new Error('VERIFY TOKEN ERROR')
        }
      })
  },
  /**
   * Executes token verify and token refresh
   * @param verifyEndpoint
   * @param refreshEndpoint
   * @param accessToken
   * @param full - if true: will try refresh
   * @param refreshToken
   */
  async tokenVerify(
    verifyEndpoint,
    refreshEndpoint,
    accessToken,
    full = false,
    refreshToken
  ) {
    if (isEmpty(accessToken)) {
      throw new Error('no token')
    }

    const tokenCheck = (endpoint, token) => ({
      url: endpoint,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: addSiteIdToPayload({ token })
    })

    return await axios(tokenCheck(verifyEndpoint, accessToken))
      .then(r => {
        return this.parseAuthResponse(r, 'verify')
      })
      .catch(async e => {
        // IF VERIFY FAILS, TRY TO REFRESH
        if (full) {
          // IF FULL VALIDATION IS REQUESTED, RUN REFRESH
          if (
            [400, 401].includes(e.response?.status) &&
            !isEmpty(refreshToken)
          ) {
            // AUTH RETURNS 400 WHEN TOKEN INVALID
            const response = await axios(
              tokenCheck(refreshEndpoint, refreshToken)
            ).catch(e => {
              console.warn(e)
              throw new Error('REFRESH TOKEN ERROR')
            })
            return this.parseAuthResponse(response, 'refresh')
          } else {
            // IF NOT 400 SOMETHING'S WRONG WITH AUTH - ABORT
            console.warn(e)
            throw new Error('REFRESH TOKEN ERROR')
          }
        } else {
          // SOMETHING'S WRONG WITH AUTH - ABORT
          console.warn(e)
          throw new Error('VERIFY TOKEN ERROR')
        }
      })
  },
  // /**
  //  * Executes OTP AUTH  and verify
  //  * @returns {Promise<{valid: boolean, access: *, valType: *, refresh: *}|{valid: boolean, access: *, valType: *, refresh}>}
  //  * @param endpoint
  //  * @param payload
  //  * @param token
  //  */
  // async otpRequest(endpoint, payload, token) {
  //   return await axios({
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     },
  //     url: endpoint,
  //     method: 'POST',
  //     data: addSiteIdToPayload(payload)
  //   }).then(r => {
  //     return this.parseAuthResponse(r, 'verify')
  //   })
  // },
  /**
   * Validate both verify and refresh response and return processed auth obj
   * @param response
   * @param type - 'refresh', 'verify'
   * @returns {{valid: boolean, access: *, valType: *, refresh: *}|{valid: boolean, userInfo: ({}), isAuth: boolean, access: *, role: {alias: string, description: string}, valType: *, refresh: *}}
   */
  parseAuthResponse(response, type) {
    if (type === 'verify') {
      if (isEmpty(response?.data)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.info)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.tokens)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.tokens?.access)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (response?.data?.info?.role?.name !== 'limited')
        if (isEmpty(response?.data?.tokens?.refresh)) {
          throw new Error('TOKEN INTEGRITY ERROR')
        }
      if (isEmpty(response?.data?.info?.role)) {
        throw new Error('ROLE ERROR')
      }
      const idhProfileId = isEmpty(response?.data?.info?.user?.idhProfileId)
        ? ''
        : response.data.info.user.idhProfileId
      const countryCode = isEmpty(response?.data?.info?.user?.countryCode)
        ? ''
        : response.data.info.user.countryCode
      const professionId = isEmpty(response?.data?.info?.user?.professionId)
        ? ''
        : response.data.info.user.professionId
      const userInfo = isEmpty(response?.data?.info?.user)
        ? {}
        : {
            ...response.data.info.user,
            country: countryCode,
            profession_id: professionId
          }

      return {
        valid: true,
        isAuth: authRoles.includes(response.data.info.role.name),
        isLimited: limitedRoles.includes(response.data.info.role.name),
        valType: type,
        access: response.data.tokens.access,
        refresh: response.data.tokens.refresh,
        name: response.data.info.role.name,
        role: response.data.info.role.permissions.routes,
        roleLimits: response.data.info.role.permissions.roleLimits,
        features: response.data.info.role.permissions?.features || {},
        specialty: this.getSpecialty(response),
        profession: this.getProfession(response),
        userInfo: {
          ...userInfo
        },
        idhProfileId
      }
    } else if (type === 'refresh') {
      if (isEmpty(response?.data)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.access)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      if (isEmpty(response?.data?.refresh)) {
        throw new Error('TOKEN INTEGRITY ERROR')
      }
      return {
        valid: true,
        valType: type,
        access: response.data.access,
        refresh: response.data.refresh
      }
    }
  },
  /**
   * Validate that session token has all required fields, and does full validation
   * @param verifyEndpoint
   * @param refreshEndpoint
   * @param state
   * @returns {Promise<*>}
   */
  validateCacheToken(verifyEndpoint, refreshEndpoint, state) {
    const goFull = state.name !== 'limited'
    if (isEmpty(state?.access)) throw new Error('INVALID SESSION TOKEN')
    if (goFull)
      if (isEmpty(state?.refresh)) throw new Error('INVALID SESSION TOKEN')
    return this.tokenVerify(
      verifyEndpoint,
      refreshEndpoint,
      state.access,
      goFull,
      state.refresh
    ).then(authResponse => authResponse)
  },
  /**
   * Validate that session token has valid user
   * @param verifyEndpoint
   * @param refreshEndpoint
   * @param state
   * @returns {Promise<*>}
   */
  validateCacheUser(verifyEndpoint, refreshEndpoint, state) {
    if (!isEmpty(state?.access) && !isEmpty(state?.refresh)) {
      return this.userVerify(
        verifyEndpoint,
        refreshEndpoint,
        state.access,
        true,
        state.refresh
      ).then(authResponse => authResponse)
    } else {
      throw new Error('INVALID SESSION TOKEN')
    }
  },
  /**
   * Validate that session token has all required fields
   * @param cacheToken
   * @returns {boolean}
   */
  validateCacheIntegrity(cacheToken) {
    if (isEmpty(cacheToken?.access)) throw new Error('cache integrity error')
    if (cacheToken?.name !== 'limited')
      if (isEmpty(cacheToken?.refresh)) throw new Error('cache integrity error')
  },
  /**
   * @param endpoint
   * @param payload
   * @returns {Promise<{valid: boolean, userInfo: {}, isAuth: boolean, access: *, role: {}, refresh: *}>}
   */
  async getPhoenixToken(endpoint, payload) {
    const axiosOptions = {
      url: endpoint,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: addSiteIdToPayload(payload)
    }
    return await axios(axiosOptions)
      .then(response => {
        if (isEmpty(response?.data)) {
          throw new Error('TOKEN INTEGRITY ERROR')
        }
        if (isEmpty(response?.data?.access)) {
          throw new Error('access error')
        }
        return {
          isAuth: false,
          isLimited: false,
          valid: true,
          access: response.data.access,
          refresh: response.data.refresh,
          role: {},
          userInfo: {}
        }
      })
      .catch(e => {
        throw new Error('Error fetching Phoenix Token' + e)
      })
  },
  /**
   * Verify if requested path is safe to access
   * @param reqAuth - if the path requires auth, will trigger lookup
   * @param reqPathName - path identifier, will do lookup in role table
   * @param role - AUTH role table containing path names
   * @returns {boolean}
   */
  pathCheck({ reqAuth, reqPathName, role }) {
    let allowed = false
    const roleList = Object.keys(role)
    if (reqAuth === true) {
      for (let i = 0; i < roleList.length; i++) {
        if (new RegExp('(?:^|\\s)' + reqPathName, 'i').test(roleList[i])) {
          allowed = true
          break
        }
      }
    } else {
      allowed = true
    }
    return allowed
  },
  /**
   * Attempt to recover Specialty from Verify token if none fallback to default Specialty
   * @param response
   * @returns {string}
   */
  getSpecialty(response) {
    const specialty = response?.data?.info?.user?.specialty
    if (!isEmpty(specialty)) {
      return specialty
    } else {
      const specialtyId = response?.data?.info?.user?.specialtyId
      const specialtyIdLima = response?.data?.info?.user?.specialtyIdLima
      const specialtyLima = response?.data?.info?.user?.specialtyLima

      if (!isEmpty(specialtyId)) {
        const specObj = specialties.find(item => item.id === specialtyId)
        return isEmpty(specObj) ? defaultSpecialty : specObj.key
      } else if (!isEmpty(specialtyIdLima)) {
        return this.findInSpecialtyLima(
          specialtyIdLima,
          'specialtyLimaID',
          'specialtyContent',
          defaultSpecialty
        )
      } else if (!isEmpty(specialtyLima)) {
        return this.findInSpecialtyLima(
          specialtyLima,
          'specialtyLima',
          'specialtyContent',
          defaultSpecialty
        )
      } else {
        return defaultSpecialty
      }
    }
  },
  /**
   * Attempt to recover specialtyId from Verify token if none fallback to empty string
   * @param response
   * @returns {string}
   */
  getSpecialtyId(response) {
    const specialtyId = response?.data?.info?.user?.specialtyId
    if (!isEmpty(specialtyId)) {
      return specialtyId
    } else {
      const specialtyIdLima = response?.data?.info?.user?.specialtyIdLima
      const specialty = response?.data?.info?.user?.specialty
      const specialtyLima = response?.data?.info?.user?.specialtyLima

      const defaultSpecObj = specialties.find(
        item => item.value === defaultSpecialty
      )

      if (!isEmpty(specialtyIdLima)) {
        return this.findInSpecialtyLima(
          specialtyIdLima,
          'specialtyLimaID',
          'specialtyContentId',
          defaultSpecObj.id
        )
      } else if (!isEmpty(specialty)) {
        return this.findInSpecialtyLima(
          specialty,
          'specialtyContent',
          'specialtyContentId',
          defaultSpecObj.id
        )
      } else if (!isEmpty(specialtyLima)) {
        return this.findInSpecialtyLima(
          specialtyLima,
          'specialtyLima',
          'specialtyContentId',
          defaultSpecObj.id
        )
      } else {
        return defaultSpecObj.id
      }
    }
  },
  extractCacheStateValues({
    name = '',
    access = '',
    refresh = '',
    role = '',
    roleLimits = '',
    specialty = '',
    profession = '',
    idhProfileId = '',
    version = '',
    isAuth = false,
    isLimited = false,
    userInfo,
    expirationDate,
    features = {}
  }) {
    return {
      name,
      access,
      refresh,
      role,
      roleLimits,
      specialty,
      profession,
      idhProfileId,
      version,
      isAuth,
      isLimited,
      userInfo,
      expirationDate,
      features
    }
  }
})

export default PhoenixAuthService
