import { compose } from 'redux'
import Layout from 'src/modules/Layout'
import { withServices } from 'src/services/ServiceContext.js'

export default compose(
  withServices(resolve => ({
    ContentRiverBuilder: resolve('ContentRiverBuilder'),
    Piwik: resolve('Piwik')
  }))
)(Layout)
