import {
  takeEvery,
  put,
  call,
  all,
  select,
  takeLatest
} from 'redux-saga/effects'
import { push, LOCATION_CHANGE } from 'connected-react-router'
import { reset } from 'redux-form'
import { reauthAndRetryCall } from 'src/utils/sagaHelpers.js'
import { actions } from './reducer.js'
import { paywallRedirectFlow } from 'src/modules/Benefits/reducer.js'
import * as selectors from './selectors'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import _get from 'lodash/get'
import { logout } from 'src/modules/Session/actions'
import {
  NOT_ALLOWED,
  SERVER_ERROR,
  ACCESS_DENIED
} from 'src/services/FW5ML/errorCodes.js'
import { getURLSortProps } from 'src/utils/index.js'
import { actions as piwikActions } from 'src/modules/Piwik/reducer.js'
import newsletterTracking from 'src/modules/River/newsletterTracking.js'

const SPONSORED_HEADLINES_INTERVAL = 10
// const REDIRECT_TO_TAGS_PAGE_STATUS_CODE = 9000

function* fetchRiverData(services) {
  const riverId = yield select(selectors.getRiverId)
  const riverKey = yield select(selectors.getRiverKey)
  const StoriesRepository = services('StoriesRepository')
  const SponsoredHeadlinesRepository = services('SponsoredHeadlinesRepository')
  const Piwik = services('Piwik')

  const searchConfig = yield select(selectors.getSearchConfig)
  const searchQueryConfig = yield select(selectors.getSearchQuery)

  try {
    let data
    if (riverKey === 'your-reports') {
      const response = yield reauthAndRetryCall(
        services,
        [StoriesRepository, 'getProfileReports'],
        { config: searchConfig, customPayload: null }
      )
      data = response.data
    } else {
      const response = yield reauthAndRetryCall(
        services,
        [StoriesRepository, 'search'],
        searchConfig,
        searchQueryConfig
      )
      data = response.data
    }

    const requiredSponsoredHeadlines = Math.floor(
      data.results.length / SPONSORED_HEADLINES_INTERVAL + 1
    )
    if (requiredSponsoredHeadlines) {
      const sponsoredHeadlines = yield call(
        [SponsoredHeadlinesRepository, 'getHeadlines'],
        requiredSponsoredHeadlines
      )
      yield put(
        actions.appendSponsoredHeadlines({ riverId, sponsoredHeadlines })
      )
    }

    return data
  } catch (e) {
    yield put(actions.errorSearch({ riverId, message: e.message }))
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      // ML is returning error code 0 for query_string length error
      // @TODO define if this is the final agreement for this error code
      if ([0].indexOf(code) > -1) {
        yield put(logPush('Search', e.message))
        yield put(
          notificationsEnqueue({
            message:
              'Query string value should be more than 3 characters and no more than 100'
          })
        )
        yield call(
          [Piwik, 'track'],
          'notification',
          'error',
          'search-query-string-was-invalid'
        )
        yield put(push('/search'))
      }
      if (code === NOT_ALLOWED) {
        yield put(paywallRedirectFlow('push'))
      }
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: false }))
        return
      }
      if ([SERVER_ERROR].indexOf(code) > -1) {
        yield put(logPush('Search', e.message))
        yield put(
          notificationsEnqueue({
            message:
              'Unable to conduct search. Please refresh the page and try again.'
          })
        )
        yield call(
          [Piwik, 'track'],
          'notification',
          'error',
          'search-server-error'
        )
        yield put(push('/contact-us'))
      }
    } else {
      console.log(e)
      yield put(
        notificationsEnqueue({
          message:
            'Unable to conduct search. Please refresh the page and try again.'
        })
      )
      yield call(
        [Piwik, 'track'],
        'notification',
        'error',
        'search-general-error'
      )
    }
  }
}

function* loadRiver(services, action) {
  const {
    withFeaturedContent = false,
    withSingleFeaturedContent = false,
    getFeaturedContentTag = false,
    skipAggs = false
  } = action.payload
  const StoriesRepository = services('StoriesRepository')
  const riverId = yield select(selectors.getRiverId)
  yield put(actions.setLoading({ riverId, loading: true }))
  if (withSingleFeaturedContent) {
    yield put(actions.requestFeaturedContent({ riverId }))
    // Get most recent article
    const data = yield fetchRiverData(services)
    const featuredArticle = data?.results?.length ? data.results[0] : null

    // Exclude article & fetch new articles
    let excludeIds = []
    if (featuredArticle) excludeIds = [featuredArticle.id]
    yield put(
      actions.mergeSearch({
        riverId,
        searchBody: { exclude_ids: excludeIds }
      })
    )
    yield put(
      actions.successFeaturedContent({
        riverId,
        data: { results: featuredArticle ? [featuredArticle] : [] }
      })
    )
  } else if (withFeaturedContent) {
    let excludeIds = []
    let tagFound = false

    let searchConfig = yield select(selectors.getSearchConfig)
    const searchQueryConfig = yield select(selectors.getSearchQuery)

    if (getFeaturedContentTag) {
      const { data } = yield reauthAndRetryCall(
        services,
        [StoriesRepository, 'tagMatch'],
        searchConfig,
        searchQueryConfig
      )
      tagFound = data.tagFound
      const tag = data.tagMatch

      if (tagFound && tag) {
        searchConfig = {
          filters: {
            filters: {
              [tag.category]: [searchConfig.filters.query]
            },
            type: 'tags',
            operator: 'AND',
            include_related_tags: true
          }
        }
      }
    }

    if (tagFound) {
      yield put(
        actions.requestFeaturedContent({
          riverId
        })
      )
      const { data: featuredData } = yield call(
        [StoriesRepository, 'getFeaturedContent'],
        searchConfig
      )
      if (featuredData && featuredData.results.length > 2) {
        // Get array of ids to exclude & add to filter
        excludeIds = featuredData.results.map(r => r.id)
        yield put(
          actions.successFeaturedContent({ riverId, data: featuredData })
        )
      }
    }
    yield put(actions.requestFeaturedContent({ riverId, loading: false }))

    yield put(
      actions.mergeSearch({
        riverId,
        searchBody: { exclude_ids: excludeIds }
      })
    )
  } else {
    const data = yield fetchRiverData(services)
    if (data) {
      yield put(actions.successSearch({ riverId, data, skipAggs }))
    }
  }
}

function* loadMore(services) {
  const data = yield fetchRiverData(services)
  if (data) {
    const riverId = yield select(selectors.getRiverId)
    yield put(actions.successLoadMore({ riverId, data }))
  }
}

function* loadContentRiver(services, action) {
  const {
    withFeaturedContent = false,
    withSingleFeaturedContent = false,
    getFeaturedContentTag = false
  } = action.payload
  const ContentRiverBuilder = services('ContentRiverBuilder')
  const queryString = services('queryString')
  const nlCampaignId = queryString.get('nlc')
  const nlCampaignFrom = queryString.get('from')
  const riverId = yield select(selectors.getRiverId)
  const riverKey = yield select(selectors.getRiverKey)
  const riverArgs = yield select(selectors.getRiverArgs)
  const previousSearchBody = yield select(selectors.getSearchBody)

  // Decode GET params
  const args = queryString.get('f', '')
  const queryStringArgs = yield call([ContentRiverBuilder, 'decodeArgs'], args)
  let decodedPath = decodeURIComponent(riverArgs)

  // Clean search form
  yield put(reset('search'))

  try {
    const searchBody = yield call(
      [ContentRiverBuilder, 'build'],
      riverKey,
      decodedPath,
      queryStringArgs
    )

    // Set search sorting for search results
    if (riverKey === 'search') {
      const { sort } = getURLSortProps(
        'relevancy',
        '_score',
        'date',
        'publication_date'
      )
      searchBody.sort = sort
    }

    const router = yield select(selectors.getRouter)
    const isSavedSearch = yield select(selectors.getIsSavedSearch)
    // Refresh river content if its the first time content is loaded OR its either
    // initial load, history.(go|goBack|goForward), or navigation via browser's forward/back buttons.
    if (
      !isSavedSearch &&
      (Object.keys(previousSearchBody).length === 0 ||
        (router.action && router.action !== 'POP'))
    ) {
      yield put(
        actions.setSearch({
          riverId,
          searchBody,
          withFeaturedContent,
          withSingleFeaturedContent,
          getFeaturedContentTag
        })
      )
    }

    // PIWIK TRACKING
    let trackingName =
      decodedPath && decodedPath !== 'undefined' ? decodedPath : riverKey

    // Specific tracking validations
    if (riverKey === 'events') {
      if (nlCampaignFrom && nlCampaignFrom === 'read-more-btn') {
        trackingName = 'view-events-calendar'
      }
    }

    // --- NEWSLETTER TRACKING ---
    if (nlCampaignId) {
      if (nlCampaignFrom && newsletterTracking[nlCampaignFrom]) {
        // Pass params to default newsletterTracking config
        let name = ''
        if (riverKey === 'story-watch') {
          name = `storywatch_${decodedPath}`
        } else {
          name = trackingName
        }

        newsletterTracking[nlCampaignFrom].trackingDetails[0].tracking.name =
          name
        yield put(piwikActions.newsletterPiwikRequest(newsletterTracking))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(push(`/search/${riverKey}`))
  }
}

function* startRiverRequest(services, action) {
  const { payload = {} } = action
  const { searchBody = {} } = payload || {}
  const { filters = {} } = searchBody || {}
  // Perform search
  if (filters?.drug_classes && filters?.drug_classes.length > 0) {
    action.payload.searchBody.filters.fw_therapeutic_category = [
      ...(filters.fw_therapeutic_category || []),
      ...filters.drug_classes
    ]
    delete action.payload.searchBody.filters.drug_classes
  }
  yield put(actions.requestSearch(action.payload))
}
function* riverRequest(services, action) {
  const { payload = {} } = action
  const { searchBody = {} } = payload || {}
  const { filters = {} } = searchBody || {}
  // Perform search
  if (filters?.drug_classes && filters?.drug_classes.length > 0) {
    action.payload.searchBody.filters.fw_therapeutic_category = [
      ...(filters.fw_therapeutic_category || []),
      ...filters.drug_classes
    ]
    delete action.payload.searchBody.filters.drug_classes
  }
  action.payload.skipAggs = true
  yield put(actions.requestSearch(action.payload))
}

function* addFilter(services, action) {
  const Piwik = services('Piwik')
  const { key, filter, defaultFilters = {} } = action.payload
  const fullKeyValue = `${key}_${filter}`
  const { filters: defaults = {} } = defaultFilters
  const riverId = yield select(selectors.getRiverId)
  let newFilter = { [key]: [filter] }
  // Updated is not an array
  if (key === 'publication_date') newFilter[key] = filter
  newFilter = { filters: newFilter }

  let replaceFiltersCategory = ''
  if (Object.keys(defaults)?.includes(key)) {
    // this is a filter that, when added, will replace any filter value of the same category
    replaceFiltersCategory = key
  }

  yield call([Piwik, 'track'], 'search', 'add-filter', fullKeyValue)
  yield put(
    actions.mergeSearch({
      riverId,
      searchBody: newFilter,
      replaceFiltersCategory
    })
  )
}

function* selectFilter(services, action) {
  const Piwik = services('Piwik')
  const { key, filter, defaultFilters = {}, singleSelect } = action.payload
  const fullKeyValue = `${key}_${filter}`
  const { filters: defaults = {} } = defaultFilters
  const riverId = yield select(selectors.getRiverId)
  let newFilter = { [key]: [filter] }
  // Updated is not an array
  if (key === 'publication_date') newFilter[key] = filter
  newFilter = { filters: newFilter }

  let replaceFiltersCategory = ''
  if (Object.keys(defaults)?.includes(key) || singleSelect) {
    // this is a filter that, when added, will replace any filter value of the same category
    replaceFiltersCategory = key
  }

  yield call([Piwik, 'track'], 'search', 'add-filter', fullKeyValue)
  yield put(
    actions.mergeSearch({
      riverId,
      searchBody: newFilter,
      replaceFiltersCategory,
      skipAggs: true
    })
  )
}

function* removeFilter(services, action) {
  const Piwik = services('Piwik')
  const { filter } = action.payload
  yield call(
    [Piwik, 'track'],
    'search',
    'remove-filter',
    `${filter?.field}_${filter?.value}`
  )
  yield call(riverRequest, services, action)
}

function* fetchTags(services, action) {
  const InterestsRepository = services('InterestsRepository')
  const SearchParser = services('SearchParser')

  const riverId = yield select(selectors.getRiverId)

  const { query_string = '', category = '' } = action?.payload ?? {}
  try {
    yield put(actions.setTagsLoading({ riverId, loading: true, category }))
    let data
    if (query_string.length >= 3) {
      data = yield call(
        [InterestsRepository, 'getFilterSuggestion'],
        query_string,
        category
      )
    }
    const { tags = [] } = data
    const parsedTags = yield call(
      [SearchParser, 'decodeTagSearchResponse'],
      tags
    )
    yield put(
      actions.successTagsSearch({ riverId, data: parsedTags, category })
    )
    yield put(actions.setTagsLoading({ riverId, loading: false, category }))
  } catch (e) {
    put(actions.errorTagsSearch())
    yield put(actions.setTagsLoading({ riverId, loading: true }))
    console.log(e)
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
    }
    yield put(
      notificationsEnqueue({
        message: `Error while requesting tag suggestions. Please refresh the page and try again.`
      })
    )
  }
}

function* replaySearch(services, action) {
  const SearchParser = services('SearchParser')
  const { payload: search } = action
  const decodedSearchBody = yield call([SearchParser, 'decodeFilters'], search)
  const qs = _get(search, 'query_string', '')
  const encodedQueryString = encodeURIComponent(qs)

  // Set search sorting for search results
  const { sortUrlParam, sort } = getURLSortProps(
    'relevancy',
    '_score',
    'date',
    'publication_date'
  )
  decodedSearchBody.sort = sort

  yield put(
    actions.setSearch({
      riverId: `search;;${qs}`,
      searchBody: decodedSearchBody,
      isSavedSearch: true
    })
  )

  yield put(push(`/search/${encodedQueryString}?rst=1&sort=${sortUrlParam}`))
}

function* redirectSearch(services, action) {
  const Piwik = services('Piwik')
  const { payload: query } = action
  const encodedQueryString = encodeURIComponent(query)
  yield call([Piwik, 'track'], 'search', 'search', query)
  yield put(push(`/search/${encodedQueryString}?rst=1&sort=relevancy`))
}

function* trackRiverPageView(services, action) {
  const {
    payload: {
      location: { pathname }
    }
  } = action

  if (pathname.match(/^\/(river|stories)\/([^/]+)/)) {
    // Add here more pages with no prefix if necessary
    const PAGES_WITH_NO_PREFIX = ['/river/your-reports']
    try {
      const Piwik = services('Piwik')

      const riverKey = yield select(selectors.getRiverKey)
      const riverArgs = yield select(selectors.getRiverArgs)

      let decodedPath = decodeURIComponent(riverArgs)

      // PIWIK TRACKING
      let trackingName =
        decodedPath && decodedPath !== 'undefined' ? decodedPath : riverKey
      const prefix = !PAGES_WITH_NO_PREFIX.includes(pathname)
        ? 'content-river_'
        : ''
      let pageViewName = `${prefix}${trackingName}`

      // Specific tracking validations
      if (riverKey === 'events') {
        pageViewName = riverKey
      }

      yield call([Piwik, 'track'], 'page', 'view', pageViewName)
    } catch (error) {
      console.log(error)
    }
  }
}

export default function* watchContentRiver(services) {
  yield all([
    takeEvery(actions.addFilter, addFilter, services),
    takeEvery(actions.selectFilter, selectFilter, services),
    takeEvery(actions.requestSearch, loadRiver, services),
    takeEvery(actions.requestLoadMore, loadMore, services),
    takeEvery(actions.loadContentRiver, loadContentRiver, services),
    takeEvery(actions.replaySearch, replaySearch, services),
    takeEvery(actions.redirectSearch, redirectSearch, services),
    // TODO: should seperate this where you add search and then call the request
    // like done below
    takeEvery(
      [
        actions.setSearch,
        actions.mergeSearch,
        actions.removeSearch,
        actions.setSorting,
        actions.setMedicalAbstracts
      ],
      startRiverRequest,
      services
    ),
    takeEvery(actions.removeFilter, removeFilter, services),
    takeEvery(LOCATION_CHANGE, trackRiverPageView, services),
    takeLatest(actions.requestTagsSearch, fetchTags, services)
  ])
}
