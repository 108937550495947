import { createAction, handleActions } from 'redux-actions'
import asyncMeta from '../AsyncFlags/metaCreator'

export const requestSendMessage = createAction(
  'CONTACTUS__REQUEST',
  null,
  asyncMeta
)
export const successSendMessage = createAction(
  'CONTACTUS__SUCCESS',
  null,
  asyncMeta
)
export const errorSendMessage = createAction(
  'CONTACTUS__ERROR',
  null,
  asyncMeta
)

export const requestConsultantContact = createAction(
  'CONSULTANT_CONTACT_REQUEST',
  null,
  asyncMeta
)

export const logPush = (module, message) =>
  createAction('CONTACTUS_LOG_PUSH')({ module, message })
export const logClear = createAction('CONTACTUS_LOG_CLEAR')

export const actions = {
  requestSendMessage,
  successSendMessage,
  errorSendMessage,
  logPush,
  logClear,
  requestConsultantContact
}

const defaultState = {
  log: []
}

export default handleActions(
  {
    CONTACTUS_LOG_PUSH: (state, { payload: { module, message } }) => ({
      ...state,
      log: [...state.log, { module, message }]
    }),
    CONTACTUS_LOG_CLEAR: state => ({
      ...state,
      log: []
    })
  },
  defaultState
)
