import React from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'

const Svg = ({
  className = '',
  src,
  width,
  height,
  fill,
  onClick = () => {},
  fontFamily = 'Arial',
  fontSize = '34px'
}) => {
  const parseClassString = classString => {
    if (!classString) return []
    return classString.split(' ')
  }

  const parsedClasses = parseClassString(className)

  return (
    <ReactSVG
      src={src}
      beforeInjection={svg => {
        if (width) svg.setAttribute('width', width)
        if (height) svg.setAttribute('height', height)
        if (className) svg.classList.add(...parsedClasses)
        if (fill) svg.setAttribute('style', `fill: ${fill}`)

        // Set text styles directly on text elements
        const textElements = svg.querySelectorAll('text')
        textElements.forEach(text => {
          text.setAttribute('font-family', fontFamily)
          text.setAttribute('font-size', fontSize)
        })
      }}
      onClick={onClick}
    />
  )
}

Svg.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  onClick: PropTypes.func,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string
}

export default Svg
