import { connect } from 'react-redux'
import { actions } from 'src/modules/MobileMenu/reducer.js'
import { actions as configActions } from 'src/modules/Config/reducer.js'
import MobileMenu from 'src/modules/MobileMenu/components/MobileMenu'

const mapDispatchToProps = dispatch => ({
  handlePageScroll: data => dispatch(configActions.addConfigRequest(data)),
  handleSlideMenu: () => dispatch(actions.toggleRightMenu())
})

const mapStateToProps = state => ({
  open: state.mobileMenu.rightMenuOpen,
  position: 'right'
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
