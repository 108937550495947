import { sponsoredHeadlines } from 'src/siteConfig/assets.js'

const headlines = sponsoredHeadlines?.default ?? []
class SponsoredHeadlinesRepository {
  constructor() {
    this.current = 0
  }

  async getHeadline() {
    if (this.current >= headlines.length) {
      this.current = 0
    }
    return headlines[this.current++]
  }

  getHeadlines(amount) {
    const headlines = []
    for (let i = 0; i < amount; i++) {
      headlines.push(this.getHeadline())
    }
    return Promise.all(headlines)
  }
}

export default SponsoredHeadlinesRepository
