import React from 'react'
import { ProtectedArea, ShowFor } from './LicenseProtected'
import { licenseTypes } from '../services/FW5ML/parsers/UserParser'
import Uncap from './Uncap'

const Title = () => (
  <ProtectedArea>
    <ShowFor license={licenseTypes.LICENSE_PLUS}>
      <Uncap>MyFW+</Uncap> Stories
    </ShowFor>
    <ShowFor license={licenseTypes.LICENSE_FREE} anonymous>
      Recommended for You
    </ShowFor>
  </ProtectedArea>
)
export default Title
