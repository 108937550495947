/**
 * Autotrack function returns an object mapping DOM selectors to functions
 * designed to track events in Piwik.
 * It can be used to handle very simple click trackings without the overhead
 * of connecting sagas, services, passing down `onClick` events, etc.
 *
 * Only one listener per event is added to the main body, so no performance
 * issues should exist.
 *
 * This function is called only once during Bootstrapping.
 *
 * API:
 * const autoTrack = () => ({
 *   '<event>': {
 *     '<DOM Selector>': (
 *       track:function,
 *       PiwikServiceInstance:Piwik,
 *       clickEvent:event
 *     ) => {
 *       // ... Tracking handling
 *       // track('Category', 'Action', 'Name', { custom: 'data' })
 *     }
 *   }
 * })
 */
const autoTrack = () => ({
  click: {
    /**
     * Track main header logo click
     */
    '#HeaderLogo': track => track('logo', 'click'),
    /**
     * Track Paywall subscribe button click
     */
    '.paywall-cta-button': (track, Piwik, event) => {
      const cta = event?.target?.innerText
      track('paywall', 'button-click', cta)
    },
    /**
     * Track Story Exceprpts interest click
     */
    '.story-excerpt-interest': (track, Piwik, event) => {
      const tag = event?.target?.innerText
      track('article', 'interest-click', tag)
    },
    /**
     * Track menu items click
     */
    '[data-piwik-class="menu-item"]': (track, Piwik, event) => {
      const item = event?.target?.innerText
      track('menu', 'item-click', item)
    },
    /**
     * Track View more links click
     */
    '[data-piwik-class="view-more-link"]': (track, Piwik, event) => {
      const text = event?.target?.innerText
      const actionName = text.replace(/^View More\s+/i, '').toLowerCase()
      track('article', 'view-more-click', actionName)
    },
    /**
     * Track View more links click
     */
    '[data-piwik-class="sort-button"]': (track, Piwik, event) => {
      const item = event?.target?.innerText
      track('search', 'sort', item)
    },
    /**
     * Track new search button click
     */
    '[data-piwik-class="new-search"]': (track, Piwik, event) => {
      track('search', 'click', 'new')
    },
    /**
     * Track View more links click
     */
    '[data-piwik-class="view-more-tag"]': (track, Piwik, event) => {
      const dataset = event?.target?.dataset
      if (dataset?.piwikName) {
        track('article', 'read-more-tag-click', dataset.piwikName)
      }
    },
    /**
     * Track Tab selection in My Account
     */
    '[data-piwik-class="my-account-tab"]': (track, Piwik, event) => {
      const text = event?.target?.innerText
      track('account', 'tab-click', text)
    },
    /**
     * Track Footer links
     */
    '[data-piwik-class="footer-link"]': (track, Piwik, event) => {
      const text = event?.target?.innerText
      track('footer', 'link-click', text)
    },
    /**
     * Track Footer social links
     */
    '[data-piwik-class="footer-social-linkedin"]': (track, Piwik, event) => {
      track('footer', 'social-media-click', 'linkedIn')
    },
    '[data-piwik-class="footer-social-twitter"]': (track, Piwik, event) => {
      track('footer', 'social-media-click', 'twitter')
    },
    /**
     * Track Footer links
     */
    '[data-piwik-class="load-more"]': (track, Piwik, event) => {
      track('search', 'load-more-click')
    },
    /**
     * Track Story Excerpt tags
     */
    '[data-piwik-class="story-excerpt-type-tag"]': (track, Piwik, event) => {
      const type = event?.target?.innerText
      track('article', 'type-click', type)
    }
  }
})

export default autoTrack
