import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from './Tag.module.sass'

const Tag = ({ label = '', to = null, className, onClick = () => {} }) => {
  const Component = to === null ? 'span' : Link
  return (
    <Component
      to={to}
      onClick={onClick}
      data-piwik-class="story-excerpt-type-tag"
      className={styles.Tag + ` ${className || ''}`}
    >
      {label}
    </Component>
  )
}

Tag.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default Tag
