import { all, call, put, takeEvery, select } from 'redux-saga/effects'

import { actions as translationActions } from 'src/modules/translation/TranslationReducer'
import { getTranslationList } from 'src/modules/translation/TranslationSelector'
import { isEmpty, supportBCP } from '../../utils'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { endpointVersions } from 'src/constants'
import SessionService from 'src/services/SessionService'
import siteConfig from 'src/site.config.json'

const { tokenGetEndpoint } = endpointVersions.V2
const { configuration } = siteConfig

/**
 * @param services
 * function that sets page language hierarchy for authenticated users
 * 1. check for supported language set at profile level
 * 2. if it set at the browser and supported
 * 3. if user profile country is set to accepted languageCode
 * 4. set to fallbackLng
 */
function* translationRequest(services) {
  const {
    localization: { fallbackLng, countryLang, debug = false }
  } = configuration
  try {
    const browserLang = navigator.language
    const state = new SessionService().getFromCache('state', '')
    const profileCountryCode = state?.userInfo?.countryCode
    const profileLanguageCode = supportBCP(state?.userInfo?.languageCode)
    const TranslationService = services('TranslationService')

    // RETRIEVE RESOURCES
    let resources = yield select(getTranslationList())
    if (isEmpty(resources)) {
      try {
        resources = yield call([TranslationService, 'fetchTranslation'])
      } catch (e) {
        console.error(e)
      }
    }

    const languageCode =
      !isEmpty(profileLanguageCode) &&
      Object.keys(resources).includes(profileLanguageCode)
        ? profileLanguageCode
        : !isEmpty(browserLang) && Object.keys(resources).includes(browserLang)
        ? browserLang
        : !isEmpty(profileCountryCode) &&
          Object.keys(countryLang).includes(profileCountryCode.toLowerCase())
        ? countryLang[profileCountryCode.toLowerCase()]
        : fallbackLng
    if (!i18next?.isInitialized) {
      i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .use(Backend)
        .init({
          debug,
          resources,
          react: {
            bindI18n: 'loaded languageChanged',
            bindI18nStore: 'added',
            useSuspense: true
          },
          detection: {
            order: ['queryString', 'cookie'],
            cache: ['cookie']
          },
          interpolation: {
            escapeValue: false
          },
          missingKeyHandler: (lng, ns, key, fallbackValue) => {
            console.warn(lng, ns, key, fallbackValue)
          }
        })
    }

    i18next?.changeLanguage(languageCode)

    yield put(
      translationActions.translationRequestSuccess({ languageCode, resources })
    )
  } catch (e) {
    console.error(e)
    yield put(translationActions.translationRequestFail())

    // yield put(
    //   notificationsEnqueue({
    //     message: i18next.t('translationSagaErrorMessage'),
    //     type: 'error'
    //   })
    // )
  }
}

export default function* watchTranslationRequest(services) {
  yield all([takeEvery('BOOTSTRAP__SUCCESS', translationRequest, services)])
}
