import React, { Fragment, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  ProtectedArea,
  ShowFor
} from 'src/components/LicenseProtected/index.js'
import LinkedSubMenu from 'src/modules/MobileMenu/components/LinkedSubMenu'
import MobileMenuTopItems from 'src/modules/MobileMenu/components/MobileMenuTopItems'
import { useHistory, useLocation } from 'react-router-dom'
import { historyHandler } from 'src/utils/index.js'
import { getConfig } from 'src/modules/Config/selectors'
import { SessionContext } from 'src/modules/Session/context'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import useMediaSize from 'src/hooks/useMediaSize.js'
import Typography from 'src/components/ui/Typography/index.js'
import MenuItem from 'src/components/ui/Menu/MenuItem.js'
import styles from './MobileMenu.module.sass'
import { siteName } from 'src/utils/oneCodeBase.js'
import Link from 'src/components/Link/index.js'
import Tag from 'src/components/Tag/index.js'
import cx from 'classnames'
import useLaunchDarkly from 'src/modules/LaunchDarkly/useLaunchDarkly'

const SingleItem = ({ item = {}, position, location, history }) => (
  <div
    className={cx(
      styles.singleItemContainer,
      item.separators && styles.separators
    )}
  >
    {item.external ? (
      <Link
        to={item.to}
        className={cx(
          styles.singleItem,
          position === 'right' && styles.singleItemRight
        )}
      >
        {item.label}
      </Link>
    ) : (
      <>
        <Typography
          onClick={() => historyHandler(item.to, location, history, 'push')}
          className={cx(
            styles.singleItem,
            position === 'right' && styles.singleItemRight
          )}
        >
          <span>{item.label}</span>
        </Typography>
        {item.tag && <Tag className={styles.auxTag} label={item.tag} />}
      </>
    )}
  </div>
)

SingleItem.propTypes = {
  item: PropTypes.object,
  position: PropTypes.oneOf(['left', 'right']),
  location: PropTypes.object,
  history: PropTypes.object
}

export const MobileMenu = ({
  open: menuOpen,
  position = 'left',
  handleSlideMenu,
  doSearchCall
}) => {
  const { userHasLicense = () => false } = useContext(SessionContext)
  const menuConfig = useSelector(getConfig('menus')) || {}
  const menuItems = menuConfig[position] || []
  const { getFeatureFlagMenuItem } = useLaunchDarkly()

  const history = useHistory()
  const location = useLocation()
  let extraScroll = 0
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 680px)',
      medium: '(max-width: 960px)'
    }
  })

  let normalTopPH

  if (mediaSize === 'large') {
    normalTopPH = 131
  } else if (mediaSize === 'medium') {
    normalTopPH = 86
  } else {
    normalTopPH = 56
  }

  let normalTopEdge

  if (mediaSize === 'large') {
    normalTopEdge = 121
  } else if (mediaSize === 'medium') {
    normalTopEdge = 91
  } else {
    normalTopEdge = 61
  }

  const normalTop = siteName === 'Pharma' ? normalTopPH : normalTopEdge

  const headerSettings = useSelector(getConfig('headerSettings'))
  const { adHeight = 0, fixed = true } = headerSettings || {}

  extraScroll = fixed ? normalTop : normalTop + adHeight

  useEffect(() => {
    if (menuOpen) handleSlideMenu()
  }, [location.pathname])

  useEffect(() => {
    if (menuOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'visible'
  }, [menuOpen])

  const validateLicenseItem = item => {
    const { show_for_licenses = [] } = item
    let showForValidLicense = true
    if (show_for_licenses && show_for_licenses.length > 0) {
      showForValidLicense = show_for_licenses.find(license =>
        userHasLicense(licenseTypes[license])
      )
    }
    return showForValidLicense
  }

  const getSidebarStyle = () => {
    if (menuOpen) {
      return position === 'left' ? styles.visibleLeft : styles.visibleRight
    } else {
      return position === 'left' ? styles.hiddenLeft : styles.hiddenRight
    }
  }

  return (
    <Fragment>
      {menuItems && menuItems.length > 0 && (
        <nav
          style={{
            top: extraScroll,
            height: `calc(100vh - ${extraScroll}px)`
          }}
          className={cx(styles.sidebar, getSidebarStyle())}
        >
          <MobileMenuTopItems
            position={position}
            doSearchCall={doSearchCall}
            location={location}
            historyHandler={historyHandler}
            history={history}
          />
          <div className={styles.listContainer}>
            <ProtectedArea>
              {menuItems.map((menuItem, index) => {
                const item = getFeatureFlagMenuItem(menuItem)

                return (
                  item && (
                    <ShowFor {...item} key={index}>
                      {item.group ? (
                        <LinkedSubMenu title={item.group} to={item.to}>
                          {item.content.map((subitem, subindex) =>
                            !subitem.external ? (
                              <Fragment key={`${index}-${subindex}`}>
                                {!!validateLicenseItem(subitem) &&
                                  (subitem.mobileOnly === true ? (
                                    mediaSize === 'small' && (
                                      <MenuItem
                                        icon=""
                                        onClick={() =>
                                          historyHandler(
                                            subitem.to,
                                            location,
                                            history,
                                            'push'
                                          )
                                        }
                                        className={cx(
                                          styles.subItem,
                                          location.pathname === subitem.to &&
                                            styles.subItemActive
                                        )}
                                        data-piwik-class="menu-item"
                                      >
                                        {subitem.label}
                                        {subitem.tag && (
                                          <Tag
                                            className={styles.auxTag}
                                            label={subitem.tag}
                                          />
                                        )}
                                      </MenuItem>
                                    )
                                  ) : (
                                    <MenuItem
                                      icon=""
                                      onClick={() =>
                                        historyHandler(
                                          subitem.to,
                                          location,
                                          history,
                                          'push'
                                        )
                                      }
                                      className={cx(
                                        styles.subItem,
                                        location.pathname === subitem.to &&
                                          styles.subItemActive
                                      )}
                                      data-piwik-class="menu-item"
                                    >
                                      {subitem.label}
                                      {subitem.tag && (
                                        <Tag
                                          className={styles.auxTag}
                                          label={subitem.tag}
                                        />
                                      )}
                                    </MenuItem>
                                  ))}
                              </Fragment>
                            ) : (
                              <Fragment key={`${index}-${subindex}`}>
                                {!!validateLicenseItem(subitem) &&
                                  (subitem.mobileOnly === true ? (
                                    mediaSize === 'small' && (
                                      <MenuItem
                                        icon=""
                                        to={subitem.to}
                                        component={Link}
                                        className={styles.subItem}
                                        data-piwik-class="menu-item"
                                      >
                                        {subitem.label}
                                      </MenuItem>
                                    )
                                  ) : (
                                    <MenuItem
                                      icon=""
                                      to={subitem.to}
                                      component={Link}
                                      className={styles.subItem}
                                      data-piwik-class="menu-item"
                                    >
                                      {subitem.label}
                                    </MenuItem>
                                  ))}
                              </Fragment>
                            )
                          )}
                        </LinkedSubMenu>
                      ) : (
                        <Fragment>
                          {!!validateLicenseItem(item) &&
                            (item.mobileOnly === true ? (
                              mediaSize === 'small' && (
                                <SingleItem
                                  item={item}
                                  position={position}
                                  location={location}
                                  history={history}
                                />
                              )
                            ) : (
                              <SingleItem
                                item={item}
                                position={position}
                                location={location}
                                history={history}
                              />
                            ))}
                        </Fragment>
                      )}
                    </ShowFor>
                  )
                )
              })}
            </ProtectedArea>
          </div>
        </nav>
      )}
      {menuOpen && (
        <div className={styles.dimScreen} onClick={handleSlideMenu} />
      )}
    </Fragment>
  )
}

MobileMenu.propTypes = {
  open: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
  handleSlideMenu: PropTypes.func,
  doSearchCall: PropTypes.func
}

export default MobileMenu
