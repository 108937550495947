import { connect } from 'react-redux'
import { actions } from './reducer.js'
import Notifications from './components/Notifications'

const mapStateToProps = state => ({
  notifications: state.notifications.notifications
})

const mapDispatchToProps = dispatch => ({
  dismissSnackbar: uid =>
    setTimeout(() => {
      // Allow snackbar dismissal animation to finish before removal
      dispatch(actions.notificationsDequeue(uid))
    }, 250)
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
