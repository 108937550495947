import React from 'react'
import PropTypes from 'prop-types'
import StorySpinner from '../../../../components/StorySpinner'
import MediaEventWrapper from '../../containers/MediaEventWrapperContainer'

const RiverMediaEventBody = props => {
  const { loading, results, config } = props

  if (loading) {
    return <StorySpinner />
  }

  if (results) return <MediaEventWrapper events={results} config={config} />

  return null
}

RiverMediaEventBody.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.array,
  config: PropTypes.object
}

export default RiverMediaEventBody
