import React from 'react'
import PropTypes from 'prop-types'
import styles from './Tooltip.module.sass'
import ReactTooltip from 'react-tooltip'
import Icon from '../Icon'
import cx from 'classnames'

const Tooltip = ({ tip, className, id = 'tooltip', placement, ...rest }) => {
  return (
    <div className={styles.container}>
      <div className={className} data-tip={tip} data-for={id}>
        <Icon
          className={styles.icon}
          icon="svg/material-design-icons/action/help"
          iconSize={16}
          iconColor="#333333"
        />
      </div>
      <ReactTooltip
        id={id}
        className={styles.tooltip}
        delayShow={500}
        effect="solid"
        place={placement}
        multiline
        html
        offset={{ top: 10 }}
        {...rest}
      />
    </div>
  )
}
Tooltip.displayName = 'Tooltip'
Tooltip.propTypes = {
  /**
   * An additional custom className
   */
  className: PropTypes.string,
  /**
   * The text to show inside the tooltip
   */
  tip: PropTypes.string,
  /**
   * ID of the tooltip
   */
  id: PropTypes.string,
  /**
   * Where to display the tooltip, can be top, right, bottom, left
   */
  placement: PropTypes.string
}
export default Tooltip
