import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import useProtectedRender from './useProtectedRender'

const ProtectedArea = ({ children = [] }) => {
  const shouldElementBeRendered = useProtectedRender()
  const elements = React.Children.toArray(children)
  return (
    <Fragment>
      {elements.filter(el => !!el).map(shouldElementBeRendered)}
    </Fragment>
  )
}

ProtectedArea.propTypes = {
  children: PropTypes.node
}

export default ProtectedArea
