import _get from 'lodash/get'

class Device {
  constructor(Fw5MLApi, config) {
    this.config = config

    this.Fw5MLApi = Fw5MLApi
    this.initialized = false
  }

  initialize() {
    return new Promise(resolve => {
      const initializationTimeout = _get(
        this.config,
        'DEVICE_INITIALIZATION_TIMEOUT',
        3000
      )
      this.attachRelevantIDScript()
      this.initialized = true
      resolve(true)
      setTimeout(() => {
        if (!this.initialized) resolve(false)
      }, initializationTimeout)
    })
  }
  trace(msg) {
    if (process.env.REACT_APP_IO_DEBUG) {
      console.log('[iOvation]', msg)
    }
  }

  attachRelevantIDScript() {
    const s = document.createElement('script')
    s.src = process.env.REACT_APP_RELEVANT_ID_SRC
    document.getElementsByTagName('body')[0].appendChild(s)
  }

  async trackInfo(user) {
    if (!this.initialized) return undefined
    try {
      let relaventId = new Promise(resolve => {
        window.relevantID.callService(
          {
            ClientID: process.env.REACT_APP_RELEVANT_ID_CLIENT,
            SurveyID: process.env.REACT_APP_RELEVANT_ID_SURVEY_ID,
            PanelistID: user.profile_id
          },
          response => resolve(response)
        )
      })

      const res = await relaventId
      this.Fw5MLApi.fetch(`/device/upsert`, 'POST', {
        uid: user.profile_id,
        uid_type: 'fw',
        device_id: res.RVid,
        device_type: 'rvid',
        metadata: { relevant_id_response: res }
      })
      return res.RVid
    } catch (error) {
      return undefined
    }
  }
}

export default Device
