import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../reducer.js'
import * as selectors from '../selector'

function useViews({ views = [], multiple = false, chunk = false }) {
  const dispatch = useDispatch()
  const currentViews = useSelector(selectors.getCurrentViews)
  const viewsError = useSelector(selectors.getViewsError)
  const viewsLoading = useSelector(selectors.getViewsLoading)

  const refreshViews = () => {
    if (multiple) {
      refreshMultipleViews()
    } else if (chunk) {
      refreshChunkViews()
    } else {
      refreshSingleViews()
    }
  }

  const refreshSingleViews = () => {
    dispatch(actions.requestViews(views))
  }

  const refreshMultipleViews = () => {
    for (let view of views) {
      dispatch(actions.requestViews([view]))
    }
  }

  const refreshChunkViews = () => {
    for (let view of views) {
      dispatch(actions.requestViews(view))
    }
  }

  const appendNewView = newViews => {
    for (let view of newViews) {
      dispatch(actions.requestViews(view))
    }
  }

  useEffect(() => {
    if (views.length > 0) {
      refreshViews()
    }
  }, [])

  return {
    views: currentViews,
    viewsError,
    viewsLoading,
    refreshViews,
    appendNewView
  }
}

export default useViews
