import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '../../../../components/ui/Typography'
import Page from '../../../../components/Page'
import styles from '../../../../modules/Static/Static.module.sass'
import cx from 'classnames'
import { getConfig } from '../../../../modules/Config/selectors'
import SpinnerPage from '../../../../components/SpinnerPage'

const NotFoundPage = () => {
  // Get dynamic pages config
  const pagesConfig = useSelector(getConfig('pages'))

  return (
    <>
      {/* Validation to avoid showing 404 when dynamic pages are loading */}
      {pagesConfig ? (
        <Page className={cx(styles.static, styles.centered)}>
          <Typography type="headline" gutterBottom>
            Not found
          </Typography>
          <Typography type="subheading">
            We can't seem to find the page you are looking for.
          </Typography>
        </Page>
      ) : (
        <SpinnerPage />
      )}
    </>
  )
}

export default NotFoundPage
