class FW5MLError extends Error {
  constructor(message, code) {
    super(message)
    this.code = code
    // For IE11 badness...
    this.constructor = FW5MLError
    Object.setPrototypeOf(this, FW5MLError.prototype)
  }

  getCode() {
    return this.code
  }
}

export default FW5MLError
