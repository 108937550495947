/*
 * Color Palette
 * A palette structure wich only includes colors identified by its name
 *
 * Color name convention from: https://www.color-name.com/hex/
 */
const palette = {
  black: '#000',
  white: '#fff',
  grey: '#cececd',
  blue: '#1055cc',
  navyblue: '#293056',
  paleblue: '#e5e6e8',
  darkGrey: '#333'
}

/**
 * MASTER THEME
 * This theme is the structure representation of our themes
 *
 * It includes the possible components which styles can be overriden from the theme
 */
const master = {
  /**
   * Main Palette
   * In this palette the colors are assigned to the components that use them
   * The components have their own definition to access these color assignments
   *
   * There should not be color definitions in components
   */
  palette: {
    menu: {
      main: palette.black,
      topText: palette.white,
      divider: palette.blue
    }
  },
  /**
   * Components Styles Override
   * Components have their own definition of styles, which can be overriden from here
   *
   * Here are the possible custom components and their sub-components styles which can be overriden
   */
  components: {
    ActionBar: {
      styleOverrides: {
        container: {},
        buttonContainer: {},
        button: {},
        shareButton: {},
        label: {}
      }
    },
    Chat: {
      styleOverrides: {
        formContainer: {},
        container: {},
        streamContainer: {},
        mobileFooterContainer: {},
        bottomContainer: {},
        scrollFade: {},
        warning: {}
      }
    },
    DougallMDMessage: {
      styleOverrides: {
        container: {},
        tagsContainer: {},
        tagButton: {}
      }
    },
    EmailModal: {
      styleOverrides: {
        emailModalTitle: {},
        emailModalInput: {}
      }
    },
    Faq: {
      styleOverrides: {
        answer: {}
      }
    },
    FeedbackButton: {
      styleOverrides: {
        root: {}
      }
    },
    FollowUpPrompts: {
      styleOverrides: {
        container: {},
        promptContainer: {}
      }
    },
    FormCheckBox: {
      styleOverrides: {
        checkboxContainer: {}
      }
    },
    FormDropDown: {
      styleOverrides: {
        inputLabel: {},
        inputContainer: {}
      }
    },
    FormInputHelper: {
      styleOverrides: {}
    },
    History: {
      styleOverrides: {
        itemContainer: {},
        itemFormContainer: {},
        titleElement: {},
        inputContainer: {},
        iconButton: {},
        customIcon: {},
        spinnerContainer: {}
      }
    },
    Home: {
      styleOverrides: {
        middleContainer: {}
      }
    },
    Interests: {
      styleOverrides: {
        headline: {}
      }
    },
    Invite: {
      styleOverrides: {
        container: {},
        title: {},
        emailInputContainer: {},
        messageInputContainer: {},
        submitButton: {}
      }
    },
    LeftBar: {
      styleOverrides: {
        container: {},
        button: {}
      }
    },
    Login: {
      styleOverrides: {
        buttonContainer: {}
      }
    },
    LoginStandard: {
      styleOverrides: {
        container: {},
        title: {},
        inputContainer: {},
        submitButton: {}
      }
    },
    LoginWithEmail: {
      styleOverrides: {
        title: {},
        inputContainer: {},
        submitButton: {},
        instruction: {}
      }
    },
    Message: {
      styleOverrides: {
        contaienr: {},
        titleContainer: {},
        contentContainer: {},
        highlightTag: {}
      }
    },
    MessagesStream: {
      styleOverrides: {
        container: {}
      }
    },
    MobileButtonBar: {
      styleOverrides: {
        container: {},
        button: {}
      }
    },
    ModernInput: {
      styleOverrides: {
        labelContainer: {},
        inputContainer: {}
      }
    },
    PopularPrompts: {
      styleOverrides: {
        prompt: {}
      }
    },
    Registration: {
      styleOverrides: {
        container: {},
        title: {},
        inputContainer: {},
        dropdownContainer: {},
        checkboxContainer: {},
        submitButton: {}
      }
    },
    TextDivider: {
      styleOverrides: {
        container: {}
      }
    },
    TraditionalInput: {
      styleOverrides: {
        labelContainer: {},
        inputContainer: {}
      }
    }
  }
}

export default master
