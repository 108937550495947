import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Page from 'src/components/Page/index.js'
import commonStyles from 'src/styles/common.module.sass'
import { historyHandler } from 'src/utils/index.js'
import { useHistory } from 'react-router-dom'
import { getConfig } from 'src/modules/Config/selectors'
import styles from './OnboardingFlow.module.sass'
import Typography from 'src/components/ui/Typography/index.js'
import OnboardingNewsletters from 'src/modules/OnboardingFlow/components/OnboardingNewsletters/index.js'
import { actions as configActions } from 'src/modules/Config/reducer.js'
import usePiwik from 'src/hooks/usePiwik'
import cx from 'classnames'

const OnboardingFlow = () => {
  const { handleTracking } = usePiwik()
  const history = useHistory()
  const dispatch = useDispatch()
  const router = useSelector(state => state.router)
  const newsletter_promo_visibility =
    useSelector(getConfig('newsletter_promo_visibility')) || {}

  const {
    disclaimer,
    title,
    description,
    content = []
  } = useSelector(getConfig('newsletter_promo'))

  useEffect(() => {
    // Redirect to home if user does not come from registration flow
    // or if there are no newsletters from config
    if (
      !newsletter_promo_visibility?.visible ||
      !content ||
      content.length === 0
    ) {
      if (process.env.NODE_ENV === 'development') {
        return
      }
      historyHandler('/', '', history, 'replace')
    } else {
      handleTracking('page', 'view', 'newsletter-onboarding')
    }
  }, [])

  useEffect(() => {
    // After component unmounts
    return () => {
      if (newsletter_promo_visibility?.visible) {
        dispatch(
          configActions.updateConfigRequest({
            key: 'newsletter_promo_visibility',
            data: { visible: false }
          })
        )
      }
    }
  }, [router?.location?.pathname])

  return (
    <Page className={commonStyles.content}>
      <div
        className={cx(
          commonStyles.content,
          commonStyles.centered,
          styles.nlContainer
        )}
      >
        <h2 className={styles.title}> {title} </h2>
        <Typography className={styles.description}>{description}</Typography>
        <OnboardingNewsletters content={content} />
        <Typography className={styles.disclaimer}>{disclaimer}</Typography>
      </div>
    </Page>
  )
}

export default OnboardingFlow
