import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styles from '../../../../components/SingleStory/SingleStory.module.sass'
import Button from '@mui/material/Button'
import RequestAccessModal from '../../../../modules/Paywall/Components/RequestModal.js'
import { getConfig } from '../../../../modules/Config/selectors'
import { actions } from '../../../../modules/SingleStory/reducer'
import { SessionContext } from '../../../../modules/Session/context'
import usePiwik from '../../../../hooks/usePiwik'
import EmailConfirmation from '../../../../modules/Paywall/Components/EmailConfirmation'

export const NotifyUpdatesAndValid = ({ user, title, story }) => {
  const dispatch = useDispatch()
  const [notified, setNotified] = useState(null)
  const { handleTrackingWithArticleIdProp } = usePiwik()
  const notifyMeSettings =
    useSelector(getConfig('notify_me_settings'), shallowEqual) || {}
  const newsletterUuid =
    notifyMeSettings?.newsletter_notify_me_updates?.newsletter_uuid
  const productCode = story?.reportSettings?.productCode
  const parentProductCode = story?.reportSettings?.parentProductCode

  const validProductCode =
    parentProductCode && parentProductCode !== 'null' ? parentProductCode : null

  // Check if parent product code is not currently in the product code
  const notifyMeId =
    validProductCode && !productCode.includes(validProductCode)
      ? `${parentProductCode}-${productCode}`
      : productCode

  useEffect(() => {
    if (productCode) {
      const userIsNotified = user?.notifyMe?.find(
        item =>
          item?.preview_article_ids?.includes(notifyMeId) &&
          item?.newsletter_uuid === newsletterUuid
      )
      setNotified(!!userIsNotified)
    }
  }, [productCode])

  const notifyUpdate = () => {
    setNotified(notified => !notified)
    dispatch(
      actions.requestSimpleNotifyMeSubscribe({
        newsletterUuid,
        articleId: notifyMeId
      })
    )
    handleTrackingWithArticleIdProp(
      'article',
      'button-click',
      notified
        ? 'notify-me-of-future-updates'
        : 'cancel-notify-me-of-future-updates',
      story.id
    )
  }

  if (notified === null) return

  return (
    <Button
      className={
        notified ? styles.notifyActiveSideButton : styles.notifySideButton
      }
      variant="contained"
      onClick={notifyUpdate}
    >
      {notified ? '✓ You will be notified of future updates' : title}
    </Button>
  )
}

NotifyUpdatesAndValid.propTypes = {
  user: PropTypes.object,
  story: PropTypes.object,
  productCode: PropTypes.string,
  title: PropTypes.string
}

export const NotifyUpdatesAndInvalid = ({ title, story }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const { handleTrackingWithArticleIdProp } = usePiwik()

  const handleOpenModal = () => {
    handleTrackingWithArticleIdProp(
      'pop-up',
      'view',
      'notify-me-anon-form',
      story.id
    )
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    handleTrackingWithArticleIdProp('pop-up', 'click', 'close-window', story.id)
    setModalOpen(false)
  }

  const withSubmitTracking = () => {
    handleTrackingWithArticleIdProp(
      'pop-up',
      'button-click',
      'notify-me-anon-form-submit',
      story.id
    )
  }

  const handleSubmitForm = () => {
    setModalOpen(false)
    setIsConfirmationModalOpen(true)
  }

  return (
    <>
      <button onClick={handleOpenModal} className={styles.notifySideButton}>
        {title}
      </button>
      <RequestAccessModal
        open={modalOpen}
        onCancel={handleCloseModal}
        onSuccess={handleSubmitForm}
        withSubmitTracking={withSubmitTracking}
      />
      <EmailConfirmation
        open={isConfirmationModalOpen}
        onCancel={() => setIsConfirmationModalOpen(false)}
      />
    </>
  )
}

NotifyUpdatesAndInvalid.propTypes = {
  title: PropTypes.string,
  story: PropTypes.object
}

const NotifyUpdates = props => {
  const {
    authenticated,
    user,
    checked: sessionReady
  } = useContext(SessionContext)

  const title = props?.item?.title

  if (props?.story?.reportSettings?.upcoming || !sessionReady) return
  if (!authenticated)
    return <NotifyUpdatesAndInvalid {...props} user={user} title={title} />

  return <NotifyUpdatesAndValid {...props} user={user} title={title} />
}

NotifyUpdates.propTypes = {
  story: PropTypes.object,
  item: PropTypes.object
}

export default NotifyUpdates
