import React from 'react'
import { Redirect } from 'react-router-dom'
import withProtection from './withProtection'

const withProtectionAndRedirection = (WrappedComponent, options) => {
  const { redirectForOthers = '', ...otherOptions } = options
  return withProtection(WrappedComponent, {
    ...otherOptions,
    showForOthers: <Redirect to={redirectForOthers} />
  })
}

export default withProtectionAndRedirection
