import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions as landingPageActions } from '../../GatedLandingPage/reducer'
import RegisterLandingPage from '../components/RegisterLandingPage'

const mapStateToProps = state => ({
  landingPageCompany: state.landingPage.registrationInfo.config,
  busyLandingPage: state.landingPage.loading
})

const mapDispatchToProps = dispatch => ({
  loadCompanyContent: data =>
    dispatch(landingPageActions.requestLandingCompanyData(data))
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RegisterLandingPage
)
