/**
 * The function `mappedComponents` takes a list of components and returns a new object with keys
 * derived from the component names by removing 'LoopItem_'.
 */
export const mappedComponents = componentsList => {
  return Object.keys(componentsList).reduce((prev, curr) => {
    const component = curr.replace('LoopItem_', '')
    return {
      ...prev,
      [component]: componentsList[curr]
    }
  }, {})
}
