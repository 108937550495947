const healthtechConfig = {
  label: 'Show FirstWord HealthTech Articles',
  searchConfig: {
    all_destinations: true,
    base_query: {
      replace_all: false,
      filter: {
        must_not: []
      }
    }
  }
}

// export default [{ ...healthtechConfig }]
export default []
