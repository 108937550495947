import { createAction } from 'redux-actions'
import asyncMeta from '../AsyncFlags/metaCreator'

export const successPasswordlessLogin = createAction(
  'PASSWORDLESS_LOGIN__SUCCESS',
  null,
  asyncMeta
)
export const errorPasswordlessLogin = createAction(
  'PASSWORDLESS_LOGIN__ERROR',
  null,
  asyncMeta
)
export const requestPasswordlessLogin = createAction(
  'PASSWORDLESS_LOGIN__REQUEST',
  null,
  asyncMeta
)

export const actions = {
  requestPasswordlessLogin,
  successPasswordlessLogin,
  errorPasswordlessLogin
}
