import { Button, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
/**
 * Custom FormButton styles
 * @type {{minHeight: string, border: string, boxShadow: string, paddingBottom: string, paddingRight: string, fontSize: number, lineHeight: string, paddingTop: string, paddingLeft: string, fontWeight: string, textTransform: string}}
 */
const button = {
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
  textTransform: 'uppercase',
  fontSize: 18,
  paddingLeft: '10.66667px',
  paddingRight: '10.66667px',
  paddingTop: '11px',
  paddingBottom: '11px',
  border: 'none',
  lineHeight: '1.06',
  fontWeight: 'bold',
  height: '40px'
}
/**
 * Inherits from MUI Button:
 * See Props and CSS on: https://mui.com/material-ui/api/button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormButton = props => {
  const {
    text,
    loading,
    disabled,
    variant,
    children,
    type,
    altIconTheme,
    altButtonTheme,
    ...other
  } = props

  const CButton = styled(Button)(({ theme }) => {
    // Example to visualize default theme before overriding
    return {
      ...button,
      color: theme.palette.primary.contrastText,
      ...altButtonTheme
    }
  })

  /**
   * CircularProgress icon override: https://mui.com/material-ui/api/circular-progress/#theme-default-props
   */
  const CSpinner = styled(CircularProgress)(({ theme }) => {
    // Example to visualize default theme before overriding
    return {
      color: theme.palette.primary.contrastText,
      marginTop: '-12px',
      marginBottom: '-12px',
      ...altIconTheme
    }
  })

  return (
    <CButton
      {...other}
      disabled={disabled}
      type={type || 'button'}
      variant={variant || 'contained'}
      endIcon={loading && <CSpinner size={24} />}
    >
      {children || text}
    </CButton>
  )
}
FormButton.propTypes = {
  /**
   * Button text
   */
  text: PropTypes.string,
  /**
   * show/hide the loading spinner
   */
  loading: PropTypes.bool,
  /**
   * disable the button
   */
  disabled: PropTypes.bool,
  /**
   * Button variant
   * https://mui.com/material-ui/react-button-group/#button-variants
   */
  variant: PropTypes.string,
  /**
   * support for child component
   */
  children: PropTypes.string,
  /**
   * Button type, defaults to "button"
   */
  type: PropTypes.string,
  /**
   * Custom style for the loader Icon
   */
  altIconTheme: PropTypes.object,
  /**
   * Custom style for the Button component
   * https://mui.com/material-ui/api/button/#css
   */
  altButtonTheme: PropTypes.object
}

export default FormButton
