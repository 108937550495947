import React, { useContext } from 'react'
import { SessionContext } from 'src/modules/Session/context'
import AnonReportsView from './children/AnonReportsView'
import ProfileReportsView from './children/ProfileReportsView'

const MyReportsView = props => {
  const { authenticated, checked: sessionReady } = useContext(SessionContext)

  // Wait for session to be ready before evaluating
  if (!sessionReady) return

  if (!authenticated) {
    return <AnonReportsView {...props} />
  }

  return <ProfileReportsView {...props} />
}

export default MyReportsView
