import { compose } from 'redux'
import { connect } from 'react-redux'
import { withServices } from 'src/services/ServiceContext.js'
import PrestitialAd from 'src/components/PrestitialAd/index.js'
import { actions as adActions } from 'src/modules/Ads/reducer'

const mapDispatchToProps = dispatch => ({
  resetPrestitialAd: () => dispatch(adActions.prestitialAdReset())
})

const mapStateToProps = state => ({
  prestitialAd: state?.ads?.prestitialAd
})

export default compose(
  withServices(resolve => ({
    Session: resolve('Session')
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(PrestitialAd)
