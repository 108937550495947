const Driver = () => ({
  indexedDBSupport() {
    return new Promise(resolve => {
      let db = window.indexedDB.open('testDriver')
      db.onsuccess = () => resolve(true)

      db.onerror = () => resolve(false)
    })
  },

  localStorageSupport() {
    try {
      window.localStorage.setItem('testKey', '1')
      window.localStorage.removeItem('testKey')
      return true
    } catch (error) {
      return false
    }
  }
})

export default Driver
