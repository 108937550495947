import React from 'react'
import PropTypes from 'prop-types'
import BaseSkeleton from '../Skeleton'
import styles from './RiverLoader.module.sass'

const StoryExcerptPlaceholder = ({ ...props }) => (
  <div className={styles.storyContainer} {...props}>
    <BaseSkeleton w={80} h={18} />
    <BaseSkeleton w={'80%'} h={20} />
    <BaseSkeleton w={150} h={15} />
    <BaseSkeleton w={250} h={12} />
    <BaseSkeleton w={100} h={20} />
  </div>
)

const RiverLoader = ({ size = 10 }) => {
  const rows = []
  for (let i = 0; i < size; i++) {
    rows.push(<StoryExcerptPlaceholder key={i} />)
  }
  return <div>{rows}</div>
}

RiverLoader.propTypes = {
  size: PropTypes.number
}

export default RiverLoader
