import _get from 'lodash/get'
import _set from 'lodash/set'
class LandingPageRepository {
  constructor(Fw5MLApi) {
    this.Fw5MLApi = Fw5MLApi
  }

  async getLandingContentData(uri_path) {
    const req = await this.Fw5MLApi.fetch(`/cms/content/${uri_path}`)
    const { data } = req.data
    return data
  }

  async getLandingCompanyData({ type, uri_path }) {
    const req = await this.Fw5MLApi.fetch(`/cms/content/${type}/${uri_path}`)
    const { data } = req.data
    return data
  }

  async validateCompanyRoute({ landingPages, uri_path }) {
    const { options } = landingPages
    const { pages } = options
    if (landingPages && options && pages) {
      const companyFound = pages.find(company => company.uri_path === uri_path)
      return companyFound
    }
    return null
  }

  async confirmConsent({ user, uri_path }) {
    const body = {}
    _set(body, 'first_name', _get(user, 'firstName'))
    _set(body, 'last_name', _get(user, 'lastName'))
    _set(body, 'email', _get(user, 'email'))
    _set(body, 'company', _get(user, 'company'))
    _set(body, 'job_title', _get(user, 'job_title'))

    const req = await this.Fw5MLApi.fetch(`/consent/${uri_path}`, 'PUT', body)
    return req.data
  }
}

export default LandingPageRepository
