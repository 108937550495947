import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { themeSwitcher } from 'src/utils/themeSwitcher.js'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import { siteName } from 'src/utils/oneCodeBase.js'

class ThemeContainer extends Component {
  setTheme(license, checked, authenticated) {
    const { LICENSE_PLUS, LICENSE_FREE } = licenseTypes
    if (siteName === 'Edge' || siteName === 'Reports') {
      themeSwitcher(siteName)
    } else if (
      license === licenseTypes.LICENSE_PLUS &&
      checked &&
      authenticated
    ) {
      themeSwitcher(`${LICENSE_PLUS}_${siteName}`)
    } else {
      themeSwitcher(`${LICENSE_FREE}_${siteName}`)
    }
  }
  componentDidUpdate(prevProps) {
    const {
      session: {
        checked: prevChecked,
        authenticated: prevAuth,
        user: { license: prevLicense }
      }
    } = prevProps
    const {
      session: {
        checked,
        authenticated,
        user: { license }
      }
    } = this.props
    if (
      checked !== prevChecked ||
      authenticated !== prevAuth ||
      license !== prevLicense
    ) {
      this.setTheme(license, checked, authenticated)
    }
  }
  componentDidMount() {
    const {
      session: {
        checked,
        authenticated,
        user: { license }
      }
    } = this.props
    this.setTheme(license, checked, authenticated)
  }
  render() {
    return this.props.children
  }
}

const mapStateToProps = state => ({
  session: state.session
})

ThemeContainer.propTypes = {
  children: PropTypes.node,
  session: PropTypes.object
}

export default connect(mapStateToProps)(ThemeContainer)
