const baseAssetsUrl = 'assets/perspectives/conference-rooms-to-zooms'

export const article = {
  title: 'Conference Rooms to Zooms',
  subtitle: `Over the course of three episodes, experienced pharma marketing experts discuss the impact of COVID-19,
    from managing client relationships to diving headfirst into non-personal promotional planning, to changing HCP
    expectations, here’s what experts envision lies ahead for the industry.`,
  type: 'podcast',
  linkedInDescription: `Over the course of three episodes, experienced pharma marketing experts discuss the impact of COVID-19, 
            from managing client relationships to diving headfirst into non-personal promotional planning, to changing HCP
            expectations, here’s what experts envision lies ahead for the industry.`,
  twitterShare: {
    text: 'Check out the latest podcast series from FirstWord Perspectives: ',
    accounts: ' @fwpharma @PDQCommunicatio'
  }
}

export const sponsor = {
  name: 'PDQ',
  description: `PDQ Communications, Inc.’s mission is to provide exceptional, multi-channeled healthcare
    communications to the pharmaceutical industry in a timely, cost efficient manner. PDQ is a women-owned (WBENC
    certified), diversity company founded nearly 20-years ago by two women with a combined 60 years of healthcare
    communications experience. Our targeted direct mail and e-mail programs reach healthcare providers in all
    practice settings (MD, RN, RPh, NP, PA, etc.) where they are most engaged.`,
  smallLogoUrl: `${baseAssetsUrl}/logos/PDQ-Logo.png`,
  largeLogoUrl: `${baseAssetsUrl}/logos/PDQ-transparent.png`,
  url: 'https://www.pdqcom.com/',
  promoButtonText: 'Download PDQ’s COVID Poll Results',
  promoButtonUrl: 'https://firstword.wufoo.com/forms/zvdywzm1428t31/'
}

export const articles = [
  {
    title: 'The Initial Marketing Impact',
    description: `Experienced marketers explore how COVID-19 has impacted the pharma industry and what initial steps
    pharma took as they were trying to navigate the first half of 2020.`,
    imageUrl: `${baseAssetsUrl}/posters/Conference-Rooms-to-Zooms-Episode01.jpg`,
    multimediaType: 'audio',
    multimediaFiles: [
      {
        type: 'audio/mp3',
        url: 'https://c.firstwordpharma.com/podcasts/pdq/Nicole_Episode_1.mp3'
      },
      {
        type: 'audio/ogg',
        url: 'https://c.firstwordpharma.com/podcasts/pdq/Nicole_Episode_1.ogg'
      }
    ]
  },
  {
    title: 'Reshaping the Role of the Pharma Marketer',
    description: `Industry experts discuss how over the course of the pandemic the expectations of HCPs have changed
    and as a result the role of the pharma marketer also continues to evolve.`,
    imageUrl: `${baseAssetsUrl}/posters/Conference-Rooms-to-Zooms-Episode02.jpg`,
    multimediaType: 'audio',
    multimediaFiles: [
      {
        type: 'audio/mp3',
        url: 'https://c.firstwordpharma.com/podcasts/pdq/Mary_Episode_2.mp3'
      },
      {
        type: 'audio/ogg',
        url: 'https://c.firstwordpharma.com/podcasts/pdq/Mary_Episode_2.ogg'
      }
    ]
  },
  {
    title: 'Looking Ahead to a Post-COVID World',
    description: `Non-personal promotion experts discuss the challenges the industry has faced during the pandemic
    and what key learnings have influenced the way pharma marketing will look going forward.`,
    imageUrl: `${baseAssetsUrl}/posters/Conference-Rooms-to-Zooms-Episode03.jpg`,
    multimediaType: 'audio',
    multimediaFiles: [
      {
        type: 'audio/mp3',
        url: 'https://c.firstwordpharma.com/podcasts/pdq/Wes_Episode_3.mp3'
      },
      {
        type: 'audio/ogg',
        url: 'https://c.firstwordpharma.com/podcasts/pdq/Wes_Episode_3.ogg'
      }
    ]
  },
  {
    imageUrl: `${baseAssetsUrl}/posters/Conference-Rooms-to-Zooms-Article.jpg`
  }
]

export const contributors = [
  {
    name: 'Nicole Woodland - De Van',
    imageUrl: `${baseAssetsUrl}/collaborators/NICOLE.jpg`,
    credentials: [
      'Chief Organizational Effectiveness Officer',
      'CMI Media Group and Compas'
    ]
  },
  {
    name: 'Mary Andrews',
    imageUrl: `${baseAssetsUrl}/collaborators/MARY.jpg`,
    credentials: ['Global Head of Channels and Operations', 'ViiV Healthcare']
  },
  {
    name: 'Wesley van den Heuvel',
    imageUrl: `${baseAssetsUrl}/collaborators/WESLEY.jpg`,
    credentials: ['Senior Director, Omnichannel Engagement', 'Novo Nordisk']
  },
  {
    name: 'Kevin Kingree',
    imageUrl: `${baseAssetsUrl}/collaborators/KEVIN.jpg`,
    credentials: [
      'Podcast Moderator, VP, Marketing/New Business Development',
      'PDQ Communications, Inc.'
    ]
  },
  {
    name: 'Ashley Rapp',
    imageUrl: `${baseAssetsUrl}/collaborators/ASHLEY.jpg`,
    credentials: ['Podcast Host, Editor', 'FirstWord']
  }
]
