import { createAction, handleActions } from 'redux-actions'
import asyncMeta from '../AsyncFlags/metaCreator'

export const requestDynamicRiverContent = createAction(
  'DYNAMIC_RIVER_CONTENT__REQUEST',
  null,
  asyncMeta
)
export const successDynamicRiverContent = createAction(
  'DYNAMIC_RIVER_CONTENT__SUCCESS',
  null,
  asyncMeta
)
export const errorDynamicRiverContent = createAction(
  'DYNAMIC_RIVER_CONTENT__ERROR',
  null,
  asyncMeta
)

export const requestLoadMore = createAction(
  'DYNAMIC_RIVER_CONTENT_LOAD_MORE__REQUEST',
  null,
  asyncMeta
)
export const successLoadMore = createAction(
  'DYNAMIC_RIVER_CONTENT_LOAD_MORE__SUCCESS',
  null,
  asyncMeta
)
export const errorLoadMore = createAction(
  'DYNAMIC_RIVER_CONTENT_LOAD_MORE__ERROR',
  null,
  asyncMeta
)

export const actions = {
  requestDynamicRiverContent,
  successDynamicRiverContent,
  errorDynamicRiverContent,

  requestLoadMore,
  successLoadMore,
  errorLoadMore
}

const defaultState = {}

export default handleActions(
  {
    [requestDynamicRiverContent]: (state, { payload }) => {
      const { riverId } = payload
      return {
        ...state,
        [riverId]: {
          loading: true
        }
      }
    },
    [successDynamicRiverContent]: (state, { payload }) => {
      const { riverId, data } = payload
      const { results = [], count = 0, aggs = {}, from = 0 } = data
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loading: false,
          loadingMore: false,
          results,
          count,
          aggs,
          page: 1,
          filter: { from }
        }
      }
    },
    [errorDynamicRiverContent]: (state, { payload }) => {
      return state
    },
    [requestLoadMore]: (state, { payload }) => {
      const { riverId } = payload
      const filter = {
        ...state[riverId].filter,
        from: state[riverId].page * 10
      }
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loadingMore: true,
          filter
        }
      }
    },
    [successLoadMore]: (state, { payload }) => {
      const { riverId, data } = payload
      return {
        ...state,
        [riverId]: {
          ...state[riverId],
          loadingMore: false,
          results: [...state[riverId].results, ...data.results],
          page: state[riverId].page + 1
        }
      }
    }
  },
  defaultState
)
