class InterestsRepository {
  constructor(Fw5MLApi, ConfigRepository, InterestMapper) {
    this.Fw5MLApi = Fw5MLApi
    this.configRepository = ConfigRepository
    this.interestMapper = InterestMapper
  }

  async getSuggestion(query) {
    const req = await this.Fw5MLApi.fetch(`/interests`, 'POST', {
      query_string: query,
      size: 10
    })
    const { data } = req.data
    const contentSettings =
      this.configRepository.getStateConfig('content_settings')
    const validTagCategories = contentSettings?.valid_tag_categories ?? []
    const validTagsAndCategories = data.tags
      ? data.tags
          .map(interest => this.interestMapper.mapInterest(interest))
          .filter(
            ({ tag, category }) => validTagCategories.indexOf(category) > -1
          )
      : []
    return {
      ...data,
      tags: validTagsAndCategories
    }
  }

  async getFilterSuggestion(query, category = '') {
    const req = await this.Fw5MLApi.fetch(`/tag_search`, 'POST', {
      query_string: query,
      size: 1000,
      category
    })
    const { data } = req.data
    const contentSettings =
      this.configRepository.getStateConfig('content_settings')
    const validTagCategories = contentSettings?.valid_tag_categories ?? []
    const validTagsAndCategories = data.tags
      ? data.tags
          .map(interest => this.interestMapper.mapInterest(interest))
          .filter(
            ({ tag, category }) => validTagCategories.indexOf(category) > -1
          )
      : []
    return {
      ...data,
      tags: validTagsAndCategories
    }
  }
}

export default InterestsRepository
