import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
const ContributorListItem = ({ contributors = [] }) => {
  return (
    <ul>
      {contributors.length > 0 &&
        contributors.map((contributor, index) => {
          const { name = '', credentials = [], company = '' } = contributor
          const fullCredentials = credentials.join(', ')
          const hasContent = name || fullCredentials || company
          let contributorName = ''
          if (hasContent) {
            contributorName = ReactHtmlParser(
              `${
                name && (fullCredentials || company) ? name + ',' : name
              } ${fullCredentials}${
                company && fullCredentials ? ', ' + company : company
              }`
            )
          }
          return (
            contributorName && (
              <li key={`${contributor?.name} - ${index + 1}`}>
                {contributorName}
              </li>
            )
          )
        })}
    </ul>
  )
}

ContributorListItem.propTypes = {
  contributors: PropTypes.array
}

export default ContributorListItem
