import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { stages } from 'src/modules/Bootstrapping/constants'
import { actions } from 'src/modules/Bootstrapping/reducer'
import SpinnerPage from 'src/components/SpinnerPage/index.js'

const BootstrappingPage = props => {
  const dispatch = useDispatch()
  const stage = useSelector(state => state?.bootstrapping?.stage)
  const configBootstrapped = useSelector(state => state?.config?.bootstrapped)

  useEffect(() => {
    dispatch(actions.requestBootstrap())
  }, [])

  if (
    stage === stages.UNINITIALIZATED ||
    stage === stages.BOOTSTRAPPING ||
    !configBootstrapped
  ) {
    return <SpinnerPage />
  }

  if (stage === stages.INITIALIZED || stage === stages.FAILED) {
    return props.children
  }

  return <SpinnerPage />
}

BootstrappingPage.propTypes = {
  children: PropTypes.node
}

export default React.memo(BootstrappingPage)
