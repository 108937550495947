import { styled } from '@mui/material/styles'
import OutlinedInput, {
  outlinedInputClasses
} from '@mui/material/OutlinedInput'
import FormLabel, { formLabelClasses } from '@mui/material/FormLabel'

/**
 * Custom Label, based on theme's FormInput
 * theme path: TraditionalInput.styleOverrides.labelContainer
 */
export const TraditionalLabel = styled(FormLabel, {
  name: 'TraditionalInput',
  label: 'traditionalLabel',
  overridesResolver: (props, styles) => [styles.labelContainer]
})(({ theme }) => ({
  height: '18px',
  lineHeight: '24px',
  color: theme.palette.label.labelText,
  [`&.${formLabelClasses.disabled}`]: {
    color: theme.palette.traditionalInput?.disabled
  },
  [`&.${formLabelClasses.required} .${formLabelClasses.asterisk}`]: {
    color: theme.palette.label.required
  }
}))

/**
 * Custom MUI OutlinedInput interface, based on theme's FormInput
 * theme path: TraditionalInput.styleOverrides.inputContainer
 */
const TraditionalInput = styled(OutlinedInput, {
  name: 'TraditionalInput',
  label: 'traditionalInput',
  overridesResolver: (props, styles) => [styles.inputContainer],
  shouldForwardProp: prop => !['styleVariant'].includes(prop)
})(({ theme }) => {
  const borderColor = theme.palette?.traditionalInput?.inputBorder
  const hoverBorderColor = theme.palette?.traditionalInput?.inputBorderHover
  const focusedBorderColor = theme.palette?.traditionalInput?.inputBorderFocused
  const errorBorder = theme.palette.error.main
  return {
    // Root/Container styles
    padding: '0px',
    lineHeight: '24px',

    // Input styles
    [`& .${outlinedInputClasses.input}`]: {
      color: theme.palette?.traditionalInput?.inputText,
      lineHeight: '1.5',
      height: 'unset',
      // boxSizing: 'border-box',
      '&::placeholder': {
        color: theme.palette?.traditionalInput?.inputPlaceholder,
        opacity: 1
      }
    },
    // TextArea styles
    [`& .${outlinedInputClasses.inputMultiline}`]: {
      lineHeight: '1.5',
      /* Scrollbar Hidden for IE and Edge */
      msOverflowStyle: 'none',
      /* Scrollbar Hidden for Firefox */
      scrollbarWidth: 'none',
      /* Scrollbar Hidden for Chrome, Safari and Opera */
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    // Outline styles
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: borderColor,
      padding: '0px',
      boxShadow: `0 1px 3px 0 ${theme.palette.traditionalInput?.inputBoxShadow1}, 0 1px 2px 0 ${theme.palette.traditionalInput?.inputBoxShadow2}`,
      borderWidth: '1px',
      borderRadius: '7px'
    },
    // Outline & hover styles
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: hoverBorderColor,
      borderWidth: '2px'
    },
    // Outline & focused styles
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: `${focusedBorderColor} !important`
      },
    // Outline hover & focused styles
    [`&:hover.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor: focusedBorderColor
      },
    // Error styles
    [`&.Mui-error`]: {
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor
      },
      // Outline & hover styles
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: hoverBorderColor
      },
      // Outline & focused styles
      [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: `${errorBorder} !important`
        },
      // Outline hover & focused styles
      [`&:hover.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: `${errorBorder} !important`
        }
    }
  }
})

export default TraditionalInput
