import PropTypes from 'prop-types'

const ShowFor = ({ children }) => children

ShowFor.propTypes = {
  notReady: PropTypes.bool,
  anonymous: PropTypes.bool,
  authenticated: PropTypes.bool,
  notPlus: PropTypes.bool,
  license: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  requireAll: PropTypes.bool,
  paywall: PropTypes.string,
  paywallFeature: PropTypes.string,
  featureFlag: PropTypes.string,
  subtype: PropTypes.string
}

export default ShowFor
