import FWPerspectiveLive from '../../../images/media-event/fw-perspectives-live.png'
import SponsorEvent from '../../../images/media-event/sponsor-event.png'
import IAVLive from '../../../images/media-event/iav-live.png'
import FWDefault from '../../../images/featured/firstword.png'

export default {
  'FW Perspectives Live': FWPerspectiveLive,
  'Sponsor Event': SponsorEvent,
  'IAV Live': IAVLive,
  'FW Default': FWDefault
}
