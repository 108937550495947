import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getPageAndTemplateConfig } from 'src/modules/Config/selectors'
import LayoutChild from './LayoutChild'
import { SessionContext } from 'src/modules/Session/context'
import useSingleStory from 'src/hooks/useSingleStory'
import GeneralLayout from './children/GeneralLayout'

const Layout = ({ ...props }) => {
  const storyId = props?.match?.params?.id
  const sessionContext = useContext(SessionContext)
  const pageTemplateConfig = useSelector(getPageAndTemplateConfig()) || {}
  const { requestStoryContent, cleanSingleStory } = useSingleStory()

  // Request single story content if layout is specified,
  // This is done in this parent component to avoid having 2 calls for each child component (desktop and mobile)
  useEffect(() => {
    if (pageTemplateConfig?.page_layout === 'single_story_layout' && storyId) {
      requestStoryContent(storyId)
    }
    return () => {
      cleanSingleStory()
    }
  }, [storyId])

  return (
    <GeneralLayout
      pageTemplateConfig={pageTemplateConfig}
      component={LayoutChild}
      sessionContext={sessionContext}
      {...props}
    />
  )
}

Layout.propTypes = {
  match: PropTypes.object
}

export default Layout
