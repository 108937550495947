import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { actions as chatActions } from 'src/modules/DougallChat/ChatReducer'
import { actions as chatPageActions } from 'src/modules/ChatPage/ChatPageReducer'

import PromptList from 'src/components/PromptList/PromptList'
import { Box, Typography, styled } from '@mui/material'
import { Star } from 'src/static/icons'
import { getPrompts } from 'src/modules/Config/selectors'

const PromptContainer = styled(Box, {
  name: 'PopularPrompts',
  label: 'popularPromptsPrompt',
  overridesResolver: (props, styles) => [styles.prompt]
})(({ theme }) => ({
  cursor: 'pointer',
  margin: '4px 0px',
  '> p': { color: theme.palette.popularPrompts.promptText, fontSize: '11px' }
}))

const PopularPrompt = ({ item, onClick }) => {
  const { caption, popularPromptValue } = item
  return (
    <PromptContainer onClick={() => onClick(popularPromptValue)}>
      <Typography>{caption ?? ''}</Typography>
    </PromptContainer>
  )
}

PopularPrompt.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}

const transformData = data => {
  const grouped = {}

  data.forEach(item => {
    // Group by headingName
    if (!grouped[item.headingName]) {
      grouped[item.headingName] = {}
    }

    // Group by subHeadingName within headingName
    if (!grouped[item.headingName][item.subHeadingName]) {
      grouped[item.headingName][item.subHeadingName] = []
    }

    // Add the item under the appropriate subheading
    grouped[item.headingName][item.subHeadingName].push(item)
  })
  // Convert the grouped object into the array structure expected by ListChildren
  const result = Object.keys(grouped).map(headingName => {
    const subHeadings = Object.keys(grouped[headingName]).map(
      subHeadingName => ({
        caption: subHeadingName,
        children: grouped[headingName][subHeadingName].map(prompt => ({
          ...prompt,
          caption: prompt.popularPromptName // Use the popularPromptName as caption for display
        }))
      })
    )

    return {
      caption: headingName,
      children: subHeadings
    }
  })

  return result
}

const PopularPrompts = ({ nightMode = false, openCallback, isOpen }) => {
  const dispatch = useDispatch()
  const popularPrompts = useSelector(getPrompts())

  const formattedPrompts = transformData(popularPrompts)

  const { t } = useTranslation()

  const handlePromptClick = prompt => {
    dispatch(
      chatActions.setFormState({
        prompt
      })
    )
    dispatch(chatPageActions.displayContainer({ container: 'chat' }))
  }

  return (
    <div>
      <PromptList
        id="popular"
        title={t('popularPrompt')}
        icon={Star}
        items={formattedPrompts}
        customChildrenRender={item => (
          <PopularPrompt item={item} onClick={handlePromptClick} />
        )}
        isFetching={false}
        collapsibleContent
        nightMode={nightMode}
        wrapText
        manualOpenCallback={openCallback}
        isOpen={isOpen}
        dividers={false}
        autoClose
        autoCloseLevel={0}
      />
    </div>
  )
}

PopularPrompts.propTypes = {
  /**
   * Styles for dark or light background
   */
  nightMode: PropTypes.bool,
  /**
   * Callback when manually controlled opening
   */
  openCallback: PropTypes.func,
  /**
   * Variable for manual opening
   */
  isOpen: PropTypes.bool
}

export default PopularPrompts
