import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getPaywallRedirectUrl } from '../../modules/Benefits/selectors'
import Typography from '../ui/Typography'
import Tag from '../Tag'
import styles from './MediaEventExcerpt.module.sass'
import { SessionContext } from '../../modules/Session/context'
import { licenseTypes } from '../../services/FW5ML/parsers/UserParser'
import imagery from '../../modules/River/config/MediaEventImageMapping'

const MediaEventExcerpt = ({ event, isActive = false, isMobile }) => {
  const { title, type, mediaEvent } = event
  const {
    teaser,
    thumbnail_url,
    cta,
    cta_url,
    featured_content,
    plus,
    // location, // Temporary remove location since events will be only virtual for now
    sponsors,
    speakers,
    event_start_timestamp,
    start_date // For future Resources type
    // end_date // Temporary removed
  } = mediaEvent
  const { user } = useContext(SessionContext)
  const [buttonUrl, setButtonUrl] = useState('')
  // Pick certain date depending on the article data (Resources doesnt have event_start_timestamp, use normal date)
  const startDate = event_start_timestamp || start_date

  // Set paywall redirect url
  const paywallRedirectUrl = useSelector(getPaywallRedirectUrl()) || '/benefits'

  useEffect(() => {
    const isPlusUser = user.license === licenseTypes.LICENSE_PLUS
    const isPaidArticle = plus === '1'
    if (isPlusUser) {
      setButtonUrl(cta_url)
      return
    } else if (isPaidArticle) {
      setButtonUrl(paywallRedirectUrl)
      return
    }
    setButtonUrl(cta_url)
  }, [])

  const getImage = type => {
    if (imagery[type]) return imagery[type]
    return imagery['FW Default']
  }

  const getDateTime = startDate => {
    const dt = new Date(startDate * 1000)
    const d = dt.toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'America/Toronto'
    })
    const t = dt.toLocaleTimeString('en-us', {
      timeZone: 'America/Toronto',
      hour: '2-digit',
      minute: '2-digit'
    })

    return isActive ? `${d} ${t}` : d
  }

  return (
    <article className={styles.article}>
      <div className={styles.body}>
        <div>
          {featured_content === '1' && isActive && (
            <Tag className={styles.featured} label="FEATURED" />
          )}
          <Tag className={styles.typeLabel} label={type} />
          {plus === '1' && <Tag className={styles.plus} label="PLUS" />}
          <Typography type="subheading" gutterBottom className={styles.title}>
            {title}
          </Typography>
          {startDate && (
            <Typography type="body1" gutterBottom>
              {getDateTime(startDate)}
              {isActive ? ` EDT` : ''}
            </Typography>
          )}
          {isMobile && (
            <div className={styles.imageContainer}>
              <img
                className={styles.image}
                alt="thumbnail"
                src={thumbnail_url || ''}
                onError={event => {
                  event.currentTarget.src = getImage(type)
                }}
              />
            </div>
          )}
          {teaser && (
            <Typography type="body1" gutterBottom className={styles.teaser}>
              {teaser}
            </Typography>
          )}
          {/* Temporary remove location since events will be only virtual for now */}
          {speakers && (
            <Typography type="body2">Speakers: {speakers}</Typography>
          )}
          {sponsors && (
            <Typography type="body2" gutterBottom>
              Sponsored by: {sponsors}
            </Typography>
          )}
        </div>
        {cta && cta_url && (
          <div className={styles.buttonContainer}>
            {buttonUrl !== paywallRedirectUrl ? (
              <a
                href={buttonUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.actionButton}
              >
                {cta}
              </a>
            ) : (
              <Link to={buttonUrl} className={styles.actionButton}>
                {cta}
              </Link>
            )}
          </div>
        )}
      </div>
      {!isMobile && (
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            alt="thumbnail"
            src={thumbnail_url || ''}
            onError={event => {
              event.currentTarget.src = getImage(type)
            }}
          />
        </div>
      )}
      {/* Removed modal until a new approach is taken */}
      {/* <RegisterModalForm open={open} onDismiss={toggleModal} /> */}
    </article>
  )
}

MediaEventExcerpt.propTypes = {
  event: PropTypes.object,
  isActive: PropTypes.bool,
  isMobile: PropTypes.bool
}

export default MediaEventExcerpt
