import React, { useContext } from 'react'
import styles from '../../Header.module.sass'
import { SessionContext } from 'src/modules/Session/context'
import Logo from 'src/components/Logo/index.js'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import cx from 'classnames'
import { siteName } from 'src/utils/oneCodeBase.js'

const SimpleHeader = () => {
  const { userHasLicense = () => false } = useContext(SessionContext)

  return (
    <header
      className={cx(styles.header, styles.headerFixed, styles.simpleHeader)}
    >
      <div className={styles.headerTop}>
        <div className={styles.container}>
          <div className={styles.link}>
            <Logo className={styles.logo} />
            <h1 className={styles.title}>
              {`FirstWord ${siteName}${
                userHasLicense(licenseTypes.LICENSE_PLUS) ? ' +' : ''
              }`}
            </h1>
          </div>
        </div>
      </div>
    </header>
  )
}

export default SimpleHeader
