import Cache from 'src/utils/Cache.js'

class LegacyLinkResolver {
  constructor(Fw5MLApi) {
    this.Fw5MLApi = Fw5MLApi
    this.cache = new Cache()
  }

  async resolve(legacyIds) {
    const cached = legacyIds
      .filter(id => this.cache.has(id))
      .reduce((acc, id) => ({ [id]: this.cache.get(id) }), {})
    const nonCached = legacyIds.filter(id => !this.cache.has(id))
    let requested = {}
    if (nonCached.length > 0) {
      try {
        const req = await this.Fw5MLApi.fetch(`/articles/legacy_link`, 'POST', {
          legacy_ids: legacyIds.filter(id => !this.cache.has(id))
        })
        const { data } = req.data
        for (let legacyId in data) {
          this.cache.set(legacyId, data[legacyId])
        }
        requested = data
      } catch (e) {
        console.warn('Unable to resolve legacy links.', e)
      }
    }
    return {
      ...cached,
      ...requested
    }
  }
}

export default LegacyLinkResolver
