export default function validate(values) {
  let errors = {}

  if (!values.name || values.name.trim() === '') {
    errors.name = 'Your name is required'
  } else if (values.name.length > 64) {
    errors.name = 'Full name must not exceed 64 characters'
  }
  if (!values.number || values.number.trim() === '') {
    errors.number = 'Number is required'
  }
  if (!values.exp_month || values.exp_month === 'Please select') {
    errors.exp_month = 'Exp Month is required'
  }
  if (!values.exp_year || values.exp_year === 'Please select') {
    errors.exp_year = 'Exp Year is required'
  }
  if (!values.country || values.country === 'Please select') {
    errors.country = 'Country is required'
  }
  if (!values.postal_code || values.postal_code.trim() === '') {
    errors.postal_code = 'Postal Code is required'
  }
  if (!values.city || values.city.trim() === '') {
    errors.city = 'City is required'
  }
  if (!values.cvc || values.cvc.trim() === '') {
    errors.cvc = 'CVC is required'
  }
  if (!values.email || values.email.trim() === '') {
    errors.email = 'E-mail is required'
  }
  if (!values.full_address || values.full_address.trim() === '') {
    errors.full_address = 'Address is required'
  }

  return errors
}
