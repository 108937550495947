export const getPageFound = pages => {
  if (!pages || pages.length === 0) return false
  const pageFound = pages.find(
    page =>
      page === window.location.pathname ||
      (page !== '/' && window.location.pathname.startsWith(page))
  )

  return pageFound
}

export const getPageNotFound = pages => {
  if (!pages || pages.length === 0) return true
  const pageFound = pages.find(
    page =>
      page === window.location.pathname ||
      (page !== '/' && window.location.pathname.startsWith(page))
  )

  return !pageFound
}
