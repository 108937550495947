export const orderedFilters = [
  'type',
  'publication_date',
  'fw_product',
  'fw_therapeutic_category',
  'fw_company',
  'conditions',
  'fw_conference',
  'fw_regulatory_agency',
  'fw_regulatory_market',
  'fw_job_position'
]

export const filterLabels = {
  type: 'Story Type',
  publication_date: 'Date Range',
  fw_product: 'Products',
  fw_therapeutic_category: 'Drug Classes',
  fw_company: 'Companies',
  fw_companies: 'Companies',
  fw_regulatory_market: 'Regulatory Markets',
  fw_regulatory_agency: 'Regulatory Agencies',
  fw_regulatory_qualifier: 'Regulatory Agencies',
  conditions: 'Conditions',
  fw_job_position: 'Business Areas',
  fw_conference: 'Conferences',
  fw_category: 'Categories',
  fw_job_title: 'Job Title',
  fw_story_watch: 'StoryWatch',
  fw_mdCompanies: 'MedDevCompany(mdcompany)',
  fw_mdProducts: 'MedDevProduct(mdproduct)',
  fw_mdMarketSegments: 'MedDevMarketSegments(mdMarketSegment)'
}
export const excludedCategories = [
  'type',
  'publication_date',
  'fw_category',
  'fw_companies',
  'fw_job_title',
  'fw_regulatory_agency',
  'fw_story_watch',
  'fw_mdCompanies',
  'fw_mdProducts',
  'fw_mdMarketSegments'
]
const overrides = {
  '1_day': '1 Day',
  '1_week': '1 Week',
  '1_month': '1 Month',
  '2_month': '2 Months',
  '3_month': '3 Months',
  '4_month': '4 Months',
  '5_month': '5 Months',
  '6_month': '6 Months'
}
const filterTags = {
  products: {
    filters: {},
    fieldsExist: ['tags.fw_product'],
    exclude_article_types: ['Conference Daily Digest']
  },
  companies: {
    filters: {},
    fieldsExist: ['tags.fw_company', 'tags.fw_companies'],
    exclude_article_types: ['Conference Daily Digest']
  },
  'regulatory-markets-and-agencies': {
    filters: {},
    fieldsExist: [
      'tags.fw_regulatory_market',
      'tags.fw_regulatory_agency',
      'tags.fw_regulatory_qualifier'
    ],
    exclude_article_types: ['Conference Daily Digest']
  },
  conditions: {
    filters: {},
    fieldsExist: ['tags.conditions'],
    exclude_article_types: ['Conference Daily Digest']
  },
  business: {
    filters: {},
    fieldsExist: ['tags.fw_job_position'],
    exclude_article_types: ['Conference Daily Digest']
  }
}

export const extraFilters = {
  'KOL Views': ['KOL Views', 'KOL Views Results'],
  'Physician Views': [
    'Physician Views',
    'Physician Views Poll Results',
    'Physician Views In depth',
    'Physician Views In depth results'
  ]
}

export const getFilterLabel = filter => overrides[filter] || filter
export const getKey = key => filterLabels[key] || key
export const getExtraFilters = filter => extraFilters[filter] || null
export const getFilterTags = key => filterTags[key] || key
