import React from 'react'
import PropTypes from 'prop-types'
import ComponentLoop from 'src/layouts/components/ComponentLoop.js'
import styles from './SingleFullColumnLayout.module.sass'

function SingleFullColumnLayout({ content = {}, ...props }) {
  return (
    <div>
      <div className={styles.topFullSection}>
        <ComponentLoop section="top" items={content?.top} {...props} />
      </div>
      <div className={styles.mainFullSection}>
        <ComponentLoop
          section="main"
          className={styles.main}
          items={content?.main}
          {...props}
        />
      </div>
    </div>
  )
}

SingleFullColumnLayout.propTypes = {
  content: PropTypes.object
}

export default SingleFullColumnLayout
