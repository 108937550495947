export const SERVER_ERROR = 1001 // An internal system error / Unexpected error
export const INVALID_REQUEST = 2001 // Invalid Request: Business rule violation in request parameters provided
export const NOT_ALLOWED = 2002 // UnAuthorized Action: User's access roles do not permit this action
export const ACCESS_DENIED = 3001 // Access denied  / Security-related errors (auth, Invalid Token, invalid pwd etc)
export const DEVICE_MISMATCH = 3010 // DeviceId on login email request doesn't match DeviceId on login
export const DEVICE_UNKNOWN_CONFIRMATION_REQUIRED = 3015 // DeviceId was not found in our records - confirmation required
export const DEVICE_UNKNOWN = 3020 // DeviceId was not found in our records
export const TOKEN_EXPIRED = 3200 // Token Expired
export const DEVICE_LIMIT_REACHED = 3005 // User has exceeded the =maximum allowable devices for this app
export const USER_ALREADY_EXISTS = 5003 // User already exists while trying to register
export const USER_SUBSCRIPTION_INACTIVE = 5050 // User exists  but it doesnt have an active subscription
export const USER_DOES_NOT_EXIST = 3500 // User does not exists while trying to login
export const SAVED_SEARCHES_LIMIT_REACHED = 4001 // User has reached the limit of saved searches in his profile
export const CAPTCHA_VERIFICATION_REQUIRED = 3050 // Device contains a high botnet score value captcha token required
export const CAPTCHA_VERIFICATION_FAILED = 3051 // Authorization failed with given captcha token
export const SSO_REDIRECT = 3900 // Frontend needs to redirect the user to the SP
export const FWREPORTS_NO_USER_MATCH = 3600 // Fronted user does not match with any FW Report account, keep them on page to display error
export const PROFILE_ALREADY_SUBSCRIBED = 7002 // A profile is already subscribed to the newsletter
export const CONSENT_REQUIRED = 7500 // A user needs to accept consent to landing page
export const REGISTRATION_ALREADY_CONFIRMED = 5006
export const ARTICLE_NOT_PUBLISHED = 6002 //A user tries to access an unpublished article via its direct url

export default {
  SERVER_ERROR,
  INVALID_REQUEST,
  NOT_ALLOWED,
  ACCESS_DENIED,
  DEVICE_MISMATCH,
  DEVICE_UNKNOWN,
  TOKEN_EXPIRED,
  USER_ALREADY_EXISTS,
  USER_DOES_NOT_EXIST,
  USER_SUBSCRIPTION_INACTIVE,
  SAVED_SEARCHES_LIMIT_REACHED,
  CAPTCHA_VERIFICATION_REQUIRED,
  CAPTCHA_VERIFICATION_FAILED,
  DEVICE_UNKNOWN_CONFIRMATION_REQUIRED,
  SSO_REDIRECT,
  FWREPORTS_NO_USER_MATCH,
  PROFILE_ALREADY_SUBSCRIBED,
  CONSENT_REQUIRED,
  ARTICLE_NOT_PUBLISHED
}
