import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../reducer.js'
import * as selectors from '../selector'

function useNotices() {
  const dispatch = useDispatch()
  const notices = useSelector(selectors.getCurrentNotices)
  const noticesError = useSelector(selectors.getNoticesError)
  const noticesLoading = useSelector(selectors.getNoticesLoading)

  const dismissNotice = id => {
    dispatch(actions.dismissNotice({ id }))
  }

  useEffect(() => {
    dispatch(actions.requestNotices())
  }, [])

  return {
    unreadNotices: notices,
    noticesLoading,
    noticesError,
    dismissNotice
  }
}

export default useNotices
