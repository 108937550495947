import axios from 'axios'
import { SSE } from 'sse.js'

const DougallML = (DougallMLInterceptors, Session, SessionService) => ({
  createClient() {
    const instance = axios.create({})
    instance.interceptors.request.use(
      null,
      DougallMLInterceptors.catchNetworkErrors
    )
    instance.interceptors.response.use(
      null,
      DougallMLInterceptors.detectMLfailuresOnFail
    )
    return instance
  },

  async fetch(endpoint, method = 'GET', data = {}, extra = {}, token = null) {
    const { headers = {}, ...restExtra } = extra
    if (!token) {
      token = await Session.phoenixTokenLoad()
    }
    const interactionId = decodeURIComponent(
      SessionService.getFromCache('interactionId', '')
    )
    const conf = {
      url: endpoint,
      method,
      data: { ...data, interactionId },
      ...restExtra,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...headers
      }
    }

    const client = this.createClient()
    return client.request(conf)
  },

  async sseFetch(endpoint, method = 'GET', data = {}, extra = {}) {
    const { headers = {}, ...restExtra } = extra

    const token = await Session.phoenixTokenLoad()
    const interactionId = decodeURIComponent(
      SessionService.getFromCache('interactionId', '')
    )

    const options = {
      method,
      payload: JSON.stringify({ ...data, interactionId }),
      ...restExtra,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...headers
      }
    }

    const response = new SSE(endpoint, { ...options })
    return response
  }
})

export default DougallML
