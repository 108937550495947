import master from './master'

/**
 * Color palette
 */
const palette = {
  aeroblue: '#d1fadf',
  americanblue: '#363f72',
  americanred: '#be1e2d',
  black: '#000000',
  black10: '#0000001a',
  black38: '#00000061',
  black42: '#0000006b',
  browngrey3: '#a0a0a0',
  brownishgrey2: '#666666',
  cadetgrey: '#98a2b3',
  celadonblue: '#0778AD',
  darkjunglegreen: '#101828',
  darkJunglegreen10: '#1018281a',
  darkJunglegreen6: '#1018280f',
  darkpowderblue: '#003399',
  gainsboro: '#d5d9eb',
  gray: '#b9b9bb',
  grey: '#cececd',
  greyishbrown: '#595959',
  honeydew: '#ecfdf3',
  honolulublue: '#0164b0',
  lavenderblush: '#fef3f2',
  lightgrey: '#ebebeb',
  lightsilver: '#d6d6d6',
  lightblack: '#333333',
  lightgreyblue: '#8cb8e4',
  mdred: '#cc0202',
  mediumpersianblue: '#015ea6',
  mediumvermilion: '#cd5532',
  mistyrose: '#fee4e2',
  silverlakeblue: '#597fcb',
  sonicsilver: '#757475',
  spacecadet: '#293056',
  firstword_orange: '#e27746',
  verylightpink50: 'rgba(239, 239, 239, 0.5)',
  white: '#ffffff',
  whiteish: '#f4f4f4'
}

/**
 * Font family overrides for MD
 */
const fontFamily = {
  fontPrimary: ['"Helvetica"', 'Arial', 'sans-serif'].join(','),
  fontSecondary: ['Imperial', 'Georgia', 'serif'].join(','),
  fontTertiary: 'arial'
}
/**
 * MUI Default theme overrides for MD brand
 */
const dougallmd = {
  active: 'dougallmd',
  palette: {
    primary: {
      main: palette.honolulublue
    },
    error: { main: palette.americanred },
    header: {
      main: palette.white,
      menuIcon: palette.browngrey3
    },
    menu: {
      main: palette.black,
      topText: palette.skyblue,
      divider: palette.lightgrey,
      selected: palette.lightblue,
      searchInput: palette.whitegrey,
      searchIcon: palette.brownishgrey2,
      searchText: palette.brownishgrey2,
      background: palette.white,
      textHover: palette.honolulublue
    },
    divider: { main: palette.grey },
    filter: {
      backgroundColor: palette.white,
      loadingBackgroundColor: palette.white,
      selectedBackgroundColor: palette.celadonblue,
      borderColor: palette.greyishbrown,
      loadingBorderColor: palette.grey,
      fontColor: palette.greyishbrown,
      loadingFontColor: palette.gray,
      selectedFontColor: palette.white,
      hoverBackgroundColor: palette.white
    },
    traditionalInput: {
      inputPlaceholder: palette.cadetgrey,
      inputBorder: palette.gainsboro,
      inputBorderHover: palette.black42,
      inputBorderFocused: palette.silverlakeblue,
      inputText: palette.lightblack,
      placeholderSecondary: palette.verylightgrey,
      disabled: palette.black20,
      inputBoxShadow1: palette.darkJunglegreen10,
      inputBoxShadow2: palette.darkJunglegreen6
    },
    followUpPrompts: {
      border: palette.americanblue,
      text: palette.brownishgrey2
    },
    formInputHelper: {
      helperText: palette.black
    },
    label: {
      labelText: palette.americanblue,
      required: palette.mediumvermilion
    },
    dialogSuccess: {
      backgroundColor: palette.honeydew,
      border: palette.aeroblue
    },
    dialogError: {
      backgroundColor: palette.mistyrose,
      border: palette.lavenderblush
    },
    editProfile: {
      inputLabel: palette.black,
      inputBorder: palette.black42,
      fieldset: {
        divider: palette.grey,
        text: palette.darkpowderblue
      },
      submitBackground: palette.honolulublue,
      submitBackgroundHover: palette.mediumpersianblue
    },
    emailShare: {
      inputLabel: palette.black,
      inputBorder: palette.black42,
      messageInput: palette.sonicsilver
    },
    login: {
      button: palette.liberty
    },
    loginStandard: {
      submitBackground: palette.firstword_orange,
      submitBackgroundHover: palette.mediumpersianblue
    },
    loginWithEmail: {
      title: palette.darkjunglegreen,
      instruction: palette.americanblue,
      submitBackground: palette.firstword_orange,
      submitBackgroundHover: palette.mediumpersianblue
    },
    searchForm: {
      inputLabel: palette.black,
      inputBorder: palette.black42,
      inputPlaceholder: palette.black38,
      searchIcon: palette.celadonblue
    },
    registration: {
      checkbox: palette.gainsboro,
      checkboxLabel: palette.americanblue,
      submitBackground: palette.firstword_orange,
      submitBackgroundHover: palette.mediumpersianblue
    },
    search: {
      inputLabel: palette.black,
      inputBorder: palette.black42,
      inputPlaceholder: palette.black38,
      searchIcon: palette.celadonblue
    },
    mdFeedback: {
      inputLabel: palette.black,
      inputBorder: palette.black42
    },
    interests: {
      headlineText: palette.cadetgrey
    },
    textDivider: {
      text: palette.firstword_orange,
      dividers: palette.lightsilver
    }
  },
  typography: {
    fontFamily: fontFamily.fontPrimary
  },
  components: {
    ...master.components,
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          '&:focus': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.mediumpersianblue
          },
          '&:hover': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.mediumpersianblue
          },
          '&:active': {
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.24), 0 -2px 2px rgba(0, 0, 0, 0.05)',
            backgroundColor: palette.lightgreyblue
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '74.5px',
          alignSelf: 'center',
          boxShadow: 'none',
          backgroundColor: palette.white,
          '@media (max-width: 1023px)': {
            height: '66.5px'
          },
          '@media (max-width: 520px)': {
            paddingLeft: '20px'
          },
          '& .MuiToolbar-root': {
            maxWidth: '520px',
            width: '100%'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundColor: palette.white } }
    },
    Menu: {
      styleOverrides: {
        container: {
          [`& .MuiDrawer-paper`]: {
            width: '240px',
            marginTop: '66px',
            boxShadow: '2px 2px 10px 2px hsla(240,1%,73%,.5)!important',
            border: `1px solid ${palette.lightsilver}`,
            borderBottom: '0',
            borderTop: '0',
            '@media (min-width: 1024px)': {
              marginTop: '74px'
            }
          }
        },
        inputContainer: {
          margin: '20px 0px 0px 20px',
          paddingBottom: '5px'
        },
        searchInput: {
          margin: '0px 20px 0px 0px',
          borderRadius: '3px',
          height: '42px'
        },
        divider: {
          color: palette.lightgrey
        },
        listItem: {
          padding: '8px'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.lightsilver,
          borderBottomWidth: '1px'
        }
      }
    },
    Footer: {
      styleOverrides: {
        container: {
          marginTop: '17px',
          flexWrap: 'wrap'
        },
        row: {
          display: 'inline-flex',
          margin: '0px 5px 7px'
        }
      }
    },
    TraditionalInput: {
      styleOverrides: {
        labelContainer: {
          fontFamily: fontFamily.fontPrimary,
          margin: '10px 0px 5px 0px',
          padding: '0 0 3px 0',
          fontSize: '13px'
        },
        inputContainer: {
          fontFamily: fontFamily.fontPrimary,
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '25px',
          input: {
            padding: '8px 13px'
          },
          '&.MuiInputBase-multiline': {
            padding: '8px 9px'
          }
        }
      },
      variants: [
        {
          props: { styleVariant: 'inset' },
          style: {
            input: {
              height: '40px',
              padding: '7px 9px'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px',
              boxShadow: `inset 0 2px 4px ${palette.black10}`
            }
          }
        }
      ]
    },
    FormInputHelper: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          lineHeight: '15px',
          marginTop: '3px',
          marginLeft: '0px',
          height: '15px',
          textAlign: 'left'
        }
      }
    }
  }
}
export default dougallmd
