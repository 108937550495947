import { useEffect } from 'react'

import useChat from './useChat'

/**
 * Hook to hanlde user prompt validation
 */
const usePromptValidation = () => {
  // Hooks
  const { urlRequested } = useChat()

  useEffect(() => {}, [urlRequested])
}
export default usePromptValidation
