import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import styles from './Snackbar.module.sass'
import cx from 'classnames'

class Snackbar extends Component {
  state = {
    delta: 0,
    deltaY: 0,
    showSnackbar: false,
    mouse: 0,
    mouseY: 0,
    offset: 0,
    isPressed: false,
    internalUpdate: false
  }
  static defaultProps = {
    thresholdX: 100,
    thresholdY: 50,
    relative: false,
    position: 'floating',
    closeIcon: 'svg/material-design-icons/navigation/close',
    iconSize: 24
  }
  static displayName = 'Snackbar'
  static propTypes = {
    /**
     * The drag/swipe distance in X required to dismiss the snackbar
     */
    thresholdX: PropTypes.number,
    /**
     * The drag/swipe distance in Y required to dismiss the snackbar
     */
    thresholdY: PropTypes.number,
    /**
     * Is the snackbar visible?
     */
    showSnackbar: PropTypes.bool,
    /**
     * Callback when the snackbar is dismissed
     */
    onDismiss: PropTypes.func,
    /**
     * The component children
     */
    children: PropTypes.node,
    /**
     * A CSS modules style object to override default theme
     */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /**
     * Is the snackbar positioned relative to its container ?
     */
    relative: PropTypes.bool,
    /**
     * Color mode
     */
    mode: PropTypes.oneOf([
      '',
      'error'
    ]) /* @TODO: For v3, change this to colorMode. Use `mode` in place of `position` prop
    /**
     * Time (in milliseconds) when the snackbar will be hidden
     */,
    dismissal: PropTypes.number,
    /**
     * The position of the snackbar
     */
    position: PropTypes.oneOf(['top', 'floating', 'bottom']),
    /**
     * An additional custom className for the root element
     */
    className: PropTypes.string,
    /**
     * The icon to use for the close button
     */
    closeIcon: PropTypes.string,
    /**
     * The icon size
     */
    iconSize: PropTypes.number
  }
  static getDerivedStateFromProps(
    { showSnackbar },
    { showSnackbar: stateShowSnackbar, internalUpdate }
  ) {
    if (internalUpdate) {
      // Prop should not prevent internal state changes
      return { internalUpdate: !internalUpdate }
    } else if (stateShowSnackbar !== showSnackbar) {
      return {
        showSnackbar,
        isPressed: false
      }
    }
    return null
  }
  dismissalTime = null

  componentDidMount() {
    const { dismissal, showSnackbar } = this.props
    if (dismissal) {
      this.dismissalTime = setTimeout(() => {
        this.dismiss()
      }, dismissal)
    }
    window.addEventListener('touchend', this.handleMouseUp)
    window.addEventListener('mouseup', this.handleMouseUp)
    this.setState({
      offset: Math.abs(this.snackbar.offsetHeight + 100) * -1,
      internalUpdate: true,
      // If `showSnackbar` prop is provided, we use it (`false` can be used to delay or prevent display)
      // Default behavior is to animate display on initial render
      showSnackbar: showSnackbar === undefined ? true : showSnackbar
    })
  }

  componentWillUnmount() {
    if (this.dismissalTime) {
      clearTimeout(this.dismissalTime)
    }
    window.removeEventListener('touchend', this.handleMouseUp)
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseDown = ({ pageX, pageY }) => {
    this.setState({
      delta: pageX,
      deltaY: pageY,
      isPressed: true,
      internalUpdate: true
    })
  }
  handleTouchStart = evt => {
    this.handleMouseDown(evt.touches[0])
  }
  handleMouseMove = evt => {
    const { isPressed, delta, deltaY } = this.state
    // Don't drag iOS body with snackbar
    evt.preventDefault()

    if (isPressed) {
      let mouse = (evt.pageX || evt.touches[0].pageX) - delta
      if (Math.abs(mouse) >= this.props.thresholdX) {
        this.dismiss()
        return
      }

      let mouseY = (evt.pageY || evt.touches[0].pageY) - deltaY
      if (Math.abs(mouseY) >= this.props.thresholdY) {
        this.dismiss()
        return
      }

      if (Math.abs(mouse) > Math.abs(mouseY)) {
        mouseY = 0
      } else {
        mouse = 0
      }
      this.setState({
        mouse: mouse,
        mouseY: mouseY,
        isPressed: true,
        internalUpdate: true
      })
    }
  }
  handleMouseUp = () => {
    this.setState({
      isPressed: false,
      delta: 0,
      deltaY: 0,
      mouse: 0,
      mouseY: 0,
      internalUpdate: true
    })
  }
  dismiss = () => {
    this.setState({ showSnackbar: false, internalUpdate: true })
    typeof this.props.onDismiss === 'function' && this.props.onDismiss()
  }
  storeSnackbarReference = snackbar => {
    if (snackbar !== null) {
      this.snackbar = snackbar
    }
  }
  render() {
    const {
      children,
      altTheme,
      thresholdX,
      thresholdY,
      showSnackbar: ignore,
      onDismiss: ignore2,
      relative,
      mode,
      position,
      className,
      closeIcon,
      iconSize,
      ...other
    } = this.props
    const themeStyles = { ...styles, ...altTheme }
    const { mouse, mouseY, showSnackbar, isPressed, offset } = this.state
    const threshold = mouseY === 0 ? thresholdX : thresholdY
    const distance = mouseY === 0 ? mouse : mouseY
    const easing = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)'
    let transitionStyle
    if (isPressed) {
      transitionStyle = ''
    } else if (showSnackbar) {
      transitionStyle = `transform 250ms ${easing}, opacity 250ms ${easing}`
    } else {
      transitionStyle = `transform 250ms ${easing}, opacity 250ms ${easing}, visibility 1ms 250ms`
    }
    const transformStyle = `translate(${mouse}px, ${
      mouseY || (showSnackbar ? 0 : offset)
    }px)`

    const animationStyle =
      position === 'floating'
        ? {
            transform: transformStyle,
            WebkitTransform: transformStyle,
            transition: transitionStyle,
            WebkitTransition: transitionStyle
          }
        : {
            transition: `opacity 250ms ${easing}, visibility 1ms 250ms`,
            WebkitTransition: `opacity 250ms ${easing}, visibility 1ms 250ms`
          }
    const style = {
      ...animationStyle,
      opacity: showSnackbar ? (threshold - Math.abs(distance)) / threshold : 0,
      visibility: !showSnackbar ? 'hidden' : 'visible',
      ...(relative && { position: 'relative' })
    }
    return (
      <div
        {...other}
        ref={this.storeSnackbarReference}
        onMouseDown={position === 'floating' ? this.handleMouseDown : undefined}
        onTouchStart={
          position === 'floating' ? this.handleTouchStart : undefined
        }
        onMouseMove={position === 'floating' ? this.handleMouseMove : undefined}
        onTouchMove={position === 'floating' ? this.handleMouseMove : undefined}
        style={style}
        // unselectable="on"
        className={cx(
          themeStyles[mode],
          themeStyles[position],
          themeStyles.snackbar,
          className
        )}
      >
        <Icon
          className={themeStyles.button}
          colorTier={!mode ? 'knockout' : 'default'}
          onClick={this.dismiss}
          onTouchEnd={this.dismiss}
          icon={closeIcon}
          iconSize={iconSize}
          button
        />
        {children}
      </div>
    )
  }
}

export default Snackbar
