import React from 'react'
import PropTypes from 'prop-types'
import ShowForMediaSize from 'src/components/Responsive/ShowForMediaSize'
import { screenSizesMapping } from 'src/constants'
import { getObjectByProperty } from 'src/utils'

const GeneralLayoutChild = ({
  screen,
  content_layout = [],
  sessionContext,
  component = <></>,
  ...props
}) => {
  const ChildComponent = component || <></>
  const content = screen ? content_layout[screen] : {}
  const showFor = getObjectByProperty(screenSizesMapping, screen, 'shared')

  const childProps = {
    ...props,
    screen,
    content
  }

  // Same content for desktop and mobile (no mediaSize required)
  if (showFor === 'shared') {
    return <ChildComponent {...childProps} />
  }

  // Different content for mobile and desktop
  return (
    <ShowForMediaSize showFor={showFor}>
      <ChildComponent {...childProps} />
    </ShowForMediaSize>
  )
}

GeneralLayoutChild.propTypes = {
  screen: PropTypes.string,
  content_layout: PropTypes.object,
  sessionContext: PropTypes.object,
  component: PropTypes.elementType
}

export default GeneralLayoutChild
