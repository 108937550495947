import React from 'react'
import PropTypes from 'prop-types'
import commonStyles from 'src/styles/common.module.sass'
import ComponentLoopItem from 'src/layouts/components/ComponentLoopItem.js'

const ComponentLoop = ({
  section,
  items,
  className = null,
  customStyles = {},
  ...props
}) => {
  if (!items || !items?.length > 0) {
    return <></>
  }

  return (
    <div
      className={!className ? section && commonStyles[section] : className}
      style={customStyles}
    >
      {items.map((item, index) => {
        const key = `${item.component}-${index}`
        return (
          <ComponentLoopItem key={key} parentKey={key} item={item} {...props} />
        )
      })}
    </div>
  )
}

ComponentLoop.propTypes = {
  section: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  customStyles: PropTypes.object
}

export default ComponentLoop
