import PropTypes from 'prop-types'
import FormButton from '../FormButton'
import styles from './Modal.module.sass'
import ModalUI from './ui/modal'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

/**
 * Close Button
 * forwardProps: customStyles
 */
const CloseButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'customStyles'
})(({ customStyles }) => ({
  margin: 0,
  padding: 0,
  ...customStyles
}))

/**
 * Modal Submit Button
 * theme path: Modal.styleOverrides.submitButton
 */
const ModalSubmitButton = styled(FormButton, {
  name: 'Modal',
  label: 'modalSubmitButton',
  overridesResolver: (props, styles) => [styles.submitButton]
})(({ theme }) => ({
  marginTop: '20px',
  textTransform: 'none',
  width: '100%',
  fontSize: '12px',
  borderRadius: '7px',
  backgroundColor: theme.palette.modal?.submitBackground,
  '&:hover': {
    backgroundColor: theme.palette.modal?.submitBackgroundHover
  }
}))

const Modal = ({
  open,
  onCancel,
  onConfirm,
  actionCaption = '',
  submitting,
  customActions,
  children,
  closeButtonStyles,
  closeButtonIconSize = 24,
  altButtonTheme: propButtonTheme
}) => {
  const altButtonTheme = {
    ...propButtonTheme
  }
  return (
    <ModalUI
      modalOpen={open}
      hasBackdropBlur
      modalType={1}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.closeContainer}>
            <CloseButton
              data-testid="modalCancelButton"
              customStyles={closeButtonStyles}
              onClick={onCancel}
            >
              <CloseIcon
                data-testid="close-modal"
                sx={{
                  fontSize: closeButtonIconSize,
                  marginRight: 0,
                  transform: 'none',
                  color: '#00000080'
                }}
              />
            </CloseButton>
          </div>
          {children}
          {customActions ? (
            <div className={styles.buttonContainer}>{customActions}</div>
          ) : (
            <ModalSubmitButton
              data-testid="modalSubmitButton"
              type="submit"
              loading={submitting}
              disabled={submitting}
              sx={altButtonTheme}
              onClick={onConfirm}
              onMouseDown={event => {
                event.preventDefault()
              }}
            >
              {actionCaption}
            </ModalSubmitButton>
          )}
        </div>
      </div>
    </ModalUI>
  )
}
Modal.propTypes = {
  /**
   * Display modal
   */
  open: PropTypes.bool,
  /**
   * cancel callback
   */
  onCancel: PropTypes.func,
  /**
   * Confirm callback
   */
  onConfirm: PropTypes.func,
  /**
   * Action caption
   */
  actionCaption: PropTypes.string,
  submitting: PropTypes.bool,
  children: PropTypes.node,
  customActions: PropTypes.node,
  /**
   * Custom styles to be passed to the closeButton container
   */
  closeButtonStyles: PropTypes.object,
  closeButtonIconSize: PropTypes.number,
  altButtonTheme: PropTypes.object
}
export default Modal
