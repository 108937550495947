import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Button from '../../../../components/ui/Buttons/Button'
import { Input } from '../../../../components/ui/Input'

const LoginForm = ({
  handleSubmit,
  busy,
  buttonLabel = 'Sign in',
  traditional = false,
  ...props
}) => {
  return (
    <div data-private>
      <form onSubmit={handleSubmit}>
        <Field
          component={Input}
          label="Email Address"
          name="email"
          errorAlign="center"
          type="email"
          traditional={traditional}
        />
        <Button
          minWidth="100%"
          type="submit"
          tier="secondary"
          submitting={busy}
          hasSpinner
        >
          {buttonLabel}
        </Button>
      </form>
    </div>
  )
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  busy: PropTypes.bool,
  buttonLabel: PropTypes.string,
  traditional: PropTypes.bool
}

export default LoginForm
