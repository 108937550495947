import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../../components/ui/Icon'
import Typography from '../../../../components/ui/Typography'
import styles from './InterestGroup.module.sass'
import classNames from 'classnames/bind'
import { filterLabelsConfigModule } from '../../../../siteConfig/river'

const { getKey } = filterLabelsConfigModule

const cx = classNames.bind(styles)

const InterestGroup = ({
  category: categoryDisplay, // @TODO: When ML no longer sends duplicate categories, we can revert this to `category`
  interests = [],
  pendingForDeletion = [],
  partial = false,
  render
}) => {
  const [open, setOpen] = useState(true)
  const handleClick = () => setOpen(!open)
  const pendingForDeletionStr = pendingForDeletion?.map(
    ({ tag: interest, category }) => `${category}-${interest}`
  ) // @TODO: When ML no longer sends duplicate categories, we can use `category` from props
  const mappedInterests = interests
    .filter(
      ({ interest, category }) =>
        pendingForDeletionStr.indexOf(`${category}-${interest}`) === -1 // @TODO: When ML no longer sends duplicate categories, we can use `category` from props
    )
    .sort(({ interest: a }, { interest: b }) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    )
    .map(render)
  return partial ? (
    mappedInterests
  ) : (
    <Fragment>
      <div className={styles.groupLabel} onClick={handleClick}>
        {getKey(categoryDisplay)}
        {/* @TODO: When ML no longer sends duplicate categories, we can use `category` */}
        <Icon
          className={styles.groupIcon}
          icon={open ? 'svg/custom/carat-d' : 'svg/custom/carat-r'}
          colorTier="secondary"
          button
        />
      </div>
      <div className={cx(styles.group, { open })}>
        {mappedInterests.length ? (
          mappedInterests
        ) : (
          <Typography className={styles.message}>
            There are no interests in this category.
          </Typography>
        )}
      </div>
    </Fragment>
  )
}

InterestGroup.propTypes = {
  /**
   * Interest category label
   */
  category: PropTypes.string,
  /**
   * Saved interests held in this category group
   */
  interests: PropTypes.arrayOf(PropTypes.object),
  /**
   * The interest(s) pending deletion
   */
  pendingForDeletion: PropTypes.array,
  /**
   * If `true`, shows only a snippet of full interests
   */
  partial: PropTypes.bool,
  /**
   * Maps the interests with a parent's component
   */
  render: PropTypes.func
}

export default InterestGroup
