import { styled } from '@mui/material/styles'
import Input, { inputClasses } from '@mui/material/Input'
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel'

/**
 * Custom Label, based on theme's FormInput
 */
export const ModernLabel = styled(InputLabel, {
  name: 'ModernInput',
  label: 'modernLabel',
  overridesResolver: (props, styles) => [styles.labelContainer]
})(({ theme }) => ({
  color: theme.palette.label.labelText,
  height: '19px',
  transform: 'translate(0px, 16px) scale(1)', // Remove left margin
  [`&.${inputLabelClasses.shrink}`]: {
    transform: 'translate(0px, -9px) scale(1)' // Remove left margin & avoid resizing
  },
  [`&.${inputLabelClasses.disabled}`]: {
    color: theme.palette.modernInput?.disabled
  },
  [`&.${inputLabelClasses.required} .${inputLabelClasses.asterisk}`]: {
    color: theme.palette.label.required
  }
}))

/**
 * Custom MUI OutlinedInput interface, based on theme's FormInput
 */
const ModernInput = styled(Input, {
  name: 'ModernInput',
  label: 'modernInput',
  overridesResolver: (props, styles) => [styles.inputContainer]
})(({ theme, error }) => {
  const borderColor = error
    ? theme.palette.error
    : theme.palette.modernInput?.underline
  const hoverBorderColor = error
    ? theme.palette.error
    : theme.palette.modernInput?.underlineHover
  const focusedBorderColor = error
    ? theme.palette.error
    : theme.palette.modernInput?.underlineFocused
  return {
    [`&.${inputClasses.underline}:before`]: {
      borderBottomColor: borderColor
    },
    '&:hover': {
      [`&.${inputClasses.underline}:before`]: {
        borderBottomColor: hoverBorderColor
      }
    },
    [`&.${inputClasses.underline}:after`]: {
      borderBottomColor: focusedBorderColor
    },
    [`&.Mui-disabled`]: {
      [`&.${inputClasses.underline}:before`]: {
        borderBottomColor: theme.palette.modernInput?.disabled,
        borderBottomStyle: 'solid'
      }
    }
  }
})

export default ModernInput
