import React from 'react'
import styles from '../../../../components/SingleStory/SingleStory.module.sass'
import SectionHeading from '../../../SectionHeading'

const NoTherapyTrendsFound = () => {
  return (
    <div>
      <SectionHeading title="Related Updates" />

      <p className={styles.body} style={{ marginBottom: '30px' }}>
        There are currently no related updates for this report. To be notified
        when new updates are available, please click the "Notify Me" button
        below.
      </p>
    </div>
  )
}

export default NoTherapyTrendsFound
