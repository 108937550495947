import { compose } from 'redux'
import { connect } from 'react-redux'
import LoginRegisterModalPage from 'src/components/LoginRegisterModalPage/index.js'
import { withServices } from 'src/services/ServiceContext.js'
import { actions as configActions } from 'src/modules/Config/reducer.js'

const mapDispatchToProps = dispatch => ({
  registerModalValidation: data =>
    dispatch(configActions.getConfigRegisterModalValidation(data))
})

const mapStateToProps = state => ({
  location: state.router.location || {}
})

export default compose(
  withServices(resolve => ({
    Session: resolve('Session')
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(LoginRegisterModalPage)
