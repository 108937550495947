import { createReducer, createAction } from '@reduxjs/toolkit'

export const themeRequest = createAction('THEME_REQUEST')
export const themeRequestSuccess = createAction('THEME_REQUEST_SUCCESS')
export const themeRequestFail = createAction('THEME_REQUEST_FAIL')
export const themeRequestChange = createAction('THEME_REQUEST_CHANGE')

export const actions = {
  themeRequest,
  themeRequestSuccess,
  themeRequestFail,
  themeRequestChange
}

const initialState = {
  fetching: false,
  active: '',
  list: []
}

const ThemeProviderReducer = createReducer(initialState, {
  [themeRequest]: state => {
    state.fetching = true
  },
  [themeRequestSuccess]: (state, { payload: { active = '', list = [] } }) => {
    state.fetching = false
    state.list = list
    state.active = active
  },
  [themeRequestChange]: (state, { payload: { active = '' } }) => {
    state.fetching = false
    state.active = active
  },
  [themeRequestFail]: state => {
    state.fetching = false
    state.active = undefined
  }
})

export default ThemeProviderReducer
