// React
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Typography from 'src/components/ui/Typography/index.js'
import styles from '../../FeaturedArticle.module.sass'
import FullImageLinked from 'src/components/FullImageLinked/index.js'
import SponsoredLines from 'src/components/SponsoredLines/index.js'

const Article = ({ item }) => {
  const { thumbnail, title, sponsoredBy, url } = item
  const imageProps = {
    width: '100%',
    height: '100%',
    src: thumbnail,
    url,
    objectFit: 'cover'
  }

  return (
    <div className={styles.article}>
      <div>
        <FullImageLinked {...imageProps} />
      </div>
      <div>
        {title && (
          <a href={url} className={styles.link}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              {title}
            </Typography>
          </a>
        )}
        {sponsoredBy && <SponsoredLines sponsor={sponsoredBy}></SponsoredLines>}
      </div>
    </div>
  )
}
Article.propTypes = {
  item: PropTypes.object
}

export default Article
