import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    cursor: 'pointer',
    marginLeft: '4%',
    marginBottom: '8%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  topRightText: {
    fontSize: '.8rem',
    textAlign: 'right',
    marginLeft: 'auto'
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  avatar: {
    marginRight: 8
  },
  boldText: {
    fontWeight: 'bold'
  }
}))

function AdsLiveFeed(props) {
  const classes = useStyles()
  const data = props.data || {}
  const {
    headline = '',
    teaserText = '',
    ctaText = '',
    publicationDoctor = '',
    topRightText = '',
    logo = null
  } = data.html || {}
  const { clickUrl } = data || {}
  if (
    !headline &&
    !teaserText &&
    !ctaText &&
    !publicationDoctor &&
    !topRightText &&
    clickUrl === '#' &&
    !logo
  ) {
    return null
  }

  const avatarContent = logo ? (
    <Avatar className={classes.avatar} src={logo} />
  ) : (
    ''
  )

  const handleReadMoreClick = () => {
    window.open(clickUrl, '_blank')
  }

  return (
    <>
      <Box className={classes.root} onClick={handleReadMoreClick}>
        <Box className={classes.header}>
          {avatarContent}
          <Typography className={`${classes.boldText} ${classes.avatar}`}>
            {publicationDoctor}
          </Typography>
          <Typography className={classes.topRightText}>
            {topRightText}
          </Typography>
        </Box>
        <Box className={classes.details}>
          <Typography component="subtitle1" variant="subtitle1">
            {headline}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {teaserText}
            <Link onClick={handleReadMoreClick} className={classes.clickable}>
              …read more
            </Link>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop="2px">
          <Typography variant="body2" color="text.secondary">
            {ctaText}
          </Typography>
        </Box>
      </Box>
      <Divider
        variant="middle"
        sx={{
          width: '50%',
          margin: 'auto',
          color: '#d6d6d6'
        }}
      />
    </>
  )
}

AdsLiveFeed.propTypes = {
  data: PropTypes.object
}

export default AdsLiveFeed
