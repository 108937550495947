import { createReducer, createAction } from '@reduxjs/toolkit'

export const translationRequest = createAction('TRANSLATION_REQUEST')
export const translationRequestSuccess = createAction(
  'TRANSLATION_REQUEST_SUCCESS'
)
export const translationRequestFail = createAction('TRANSLATION_REQUEST_FAIL')

export const actions = {
  translationRequest,
  translationRequestSuccess,
  translationRequestFail
}

const initialState = {
  fetching: false,
  status: false,
  languageCode: 'en'
}

const translationReducer = createReducer(initialState, {
  [translationRequest]: state => {
    state.fetching = true
  },
  [translationRequestSuccess]: (
    state,
    { payload: { languageCode, resources } }
  ) => {
    state.languageCode = languageCode
    state.resources = resources
    state.status = true
    state.fetching = false
  },
  [translationRequestFail]: state => {
    state.status = true
    state.fetching = false
  }
})

export default translationReducer
