/**
 * Polyfills and feature detection
 * Functions check feature support
 * Conditionally push polyfills to the polyfills array if support does not exist
 * loadPolyfills is called before render() in src/index.js
 */
const supportsNativeSmoothScroll =
  'scrollBehavior' in document.documentElement.style

const loadProxyPolyfill = async () => {
  const ProxyPolyfill = require('proxy-polyfill/src/proxy')
  global.Proxy = ProxyPolyfill
}

const supportsResizeObserver = () => {
  return (
    'ResizeObserver' in global &&
    'ResizeObserverEntry' in global &&
    'contentRect' in ResizeObserverEntry.prototype
  )
}

const loadPolyfills = () => {
  // Additional polyfill imports can be added to the promise array under conditionals
  const polyfills = []
  if (typeof global.Proxy === 'undefined') {
    polyfills.push(loadProxyPolyfill())
  }
  if (!supportsNativeSmoothScroll) {
    polyfills.push(
      import('smoothscroll-polyfill').then(smoothscroll =>
        smoothscroll.polyfill()
      )
    )
  }
  if (!supportsResizeObserver()) {
    polyfills.push(
      import('resize-observer-polyfill/dist/ResizeObserver.global')
    )
  }
  return Promise.all(polyfills)
}

export default loadPolyfills
