import React from 'react'
import PropTypes from 'prop-types'

const FullImage = ({
  width,
  height,
  src = 'http://placehold.it/200x200',
  alt = '',
  objectFit,
  ...props
}) => (
  <img
    style={{
      width,
      height,
      minWidth: width,
      minHeight: height,
      objectFit,
      verticalAlign: 'middle'
    }}
    src={src}
    alt={alt}
    {...props}
  />
)

FullImage.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  alt: PropTypes.string,
  objectFit: PropTypes.string
}

export default FullImage
