import React from 'react'
import Perspectives from '../../../../components/Perspectives'
import { article, sponsor, articles, contributors } from './data'

const ConferenceRoomsToZoomsPage = () => {
  return (
    <Perspectives
      article={article}
      sponsor={sponsor}
      articles={articles}
      contributors={contributors}
    ></Perspectives>
  )
}

export default ConferenceRoomsToZoomsPage
