import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import Typography from '../../../../components/ui/Typography'
import { licenseTypes } from '../../../../services/FW5ML/parsers/UserParser'
import { SessionContext } from '../../../../modules/Session/context'
import Page from '../../../../components/Page'
import styles from './PayWallPage.module.sass'
import PayWallSection from './PayWallSection'
import usePayment from '../../../../modules/Payment/usePayment'
import { getConfig } from '../../../../modules/Config/selectors'

const PayWallPage = () => {
  const { userHasLicense = () => false } = useContext(SessionContext)
  const isFree = userHasLicense(licenseTypes.LICENSE_FREE)
  const { priceWithCurrency = null } = usePayment()

  const webpageContentConfig = useSelector(getConfig('webpage_content')) ?? {}
  const benefitsConfig = webpageContentConfig?.benefits ?? {}
  const headerConfig = benefitsConfig?.header
  const firstColumnConfig = benefitsConfig?.first_column
  const secondColumnConfig = benefitsConfig?.second_column
  const thirdColumnConfig = benefitsConfig?.third_column

  return (
    <Page className={styles.content}>
      {headerConfig && (
        <PayWallSection
          subheading={headerConfig?.subheading}
          cta={headerConfig?.cta}
          to={headerConfig?.to}
          className={styles.register}
          isFree={isFree}
        >
          <Typography type="subheading" paragraph>
            {headerConfig?.title}
          </Typography>
          <Typography type="body1">{headerConfig?.body}</Typography>
        </PayWallSection>
      )}
      {firstColumnConfig && (
        <PayWallSection
          plus
          subheading={firstColumnConfig?.subheading}
          cta={firstColumnConfig?.cta}
          to={firstColumnConfig?.to}
        >
          {firstColumnConfig?.title && (
            <Typography
              type="subheading"
              paragraph
              align="left"
              className={styles.intro}
            >
              {firstColumnConfig?.title}
            </Typography>
          )}
          <ul className={styles.list}>
            {firstColumnConfig.list.map((item, index) => (
              <li
                className={styles.item}
                key={`benefits-first-desc-item-${index}`}
              >
                {item}
              </li>
            ))}
          </ul>
        </PayWallSection>
      )}
      {secondColumnConfig && (
        <PayWallSection
          plus
          subheading={
            secondColumnConfig?.subheading ?? `${priceWithCurrency} / Year`
          }
          cta={secondColumnConfig?.cta}
          to={secondColumnConfig?.to}
        >
          {secondColumnConfig?.title && (
            <Typography
              type="subheading"
              paragraph
              align="left"
              className={styles.intro}
            >
              {secondColumnConfig?.title}
            </Typography>
          )}
          <ul className={styles.list}>
            {secondColumnConfig.list.map((item, index) => (
              <li
                className={styles.item}
                key={`benefits-second-desc-item-${index}`}
              >
                {item}
              </li>
            ))}
          </ul>
        </PayWallSection>
      )}
      {thirdColumnConfig && (
        <PayWallSection
          plus
          subheading={thirdColumnConfig?.subheading}
          cta={thirdColumnConfig?.cta}
          to={thirdColumnConfig?.to}
        >
          {thirdColumnConfig?.title && (
            <Typography
              type="subheading"
              paragraph
              align="left"
              className={styles.intro}
            >
              {thirdColumnConfig?.title}
            </Typography>
          )}
          <ul className={styles.list}>
            {thirdColumnConfig.list.map((item, index) => (
              <li
                className={styles.item}
                key={`benefits-third-desc-item-${index}`}
              >
                {item}
              </li>
            ))}
          </ul>
        </PayWallSection>
      )}
    </Page>
  )
}

export default PayWallPage
