import { createSelector } from 'reselect'

const getViews = state => state.views

export const getCurrentViews = createSelector(
  [getViews],
  currentViews => currentViews.views || {}
)

export const getViewsError = createSelector(
  [getViews],
  currentViews => currentViews.error || ''
)

export const getViewsLoading = createSelector(
  [getViews],
  currentViews => currentViews.loading || false
)
