'use strict'
import { useEffect } from 'react'
import PropTypes from 'prop-types'

const ScrollToTop = ({ children, shouldScroll }) => {
  useEffect(() => {
    if (shouldScroll) {
      window.scrollTo(0, 0)
    }
  }, [shouldScroll])
  return children
}
ScrollToTop.propTypes = {
  /**
   * Children wrapped with ScrollToTop functionality
   */
  children: PropTypes.node,
  /**
   * If `true`, activates ScrollToTop
   */
  shouldScroll: PropTypes.bool
}
export default ScrollToTop
