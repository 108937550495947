import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions as landingPageActions } from '../reducer'
import Consent from '../pages/Consent'
import { withServices } from '../../../services/ServiceContext'

const mapStateToProps = state => {
  return {
    company: state.landingPage.consentInfo.config,
    consent: state.landingPage.consent,
    busy: state.landingPage.loading
  }
}

const mapDispatchToProps = dispatch => ({
  loadCompanyContent: data =>
    dispatch(landingPageActions.requestLandingCompanyData(data)),
  requestConfirmConsent: data =>
    dispatch(landingPageActions.requestConfirmConsent(data))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withServices(resolve => ({
    Piwik: resolve('Piwik')
  }))
)(Consent)
