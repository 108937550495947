import { createSelector } from '@reduxjs/toolkit'
export const liveFeedSelector = state => state.liveFeedReducer

export const getLiveFeedTagsData = () =>
  createSelector(liveFeedSelector, liveFeed => liveFeed?.liveFeedTags)

export const getLiveFeedFetching = () =>
  createSelector(liveFeedSelector, liveFeed => liveFeed.fetching)

export const getLiveFeedResponseData = () =>
  createSelector(liveFeedSelector, livefeed => livefeed?.liveFeed)

export const getLiveFeedError = () =>
  createSelector(liveFeedSelector, livefeed => livefeed.error)
