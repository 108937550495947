export const ANON_ARTICLE_LIMIT_REACHED = 8000 // An error for when anonymous users reach the maximum weekly limit for viewing articles

class FW5Error extends Error {
  constructor(message, code) {
    super(message)
    this.code = code
    // For IE11 badness...
    this.constructor = FW5Error
    Object.setPrototypeOf(this, FW5Error.prototype)
  }

  getCode() {
    return this.code
  }
}

export default FW5Error
