import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ShowIfNone } from './index'
import useProtectedRender from './useProtectedRender'

const ProtectedSwitch = ({ children = [] }) => {
  const shouldElementBeRendered = useProtectedRender()

  const elements = !Array.isArray(children) ? [children] : children

  const filteredElements = elements
    .filter(el => !!el)
    .filter(el => el.type !== ShowIfNone)
    .map(shouldElementBeRendered)
    .filter(el => !!el)

  const showIfNoneChildren = elements
    .filter(el => !!el)
    .filter(el => el.type === ShowIfNone)

  if (filteredElements.length > 0) return filteredElements[0]

  return <Fragment>{showIfNoneChildren.filter(el => !!el) || null}</Fragment>
}

ProtectedSwitch.propTypes = {
  children: PropTypes.array
}

export default ProtectedSwitch
