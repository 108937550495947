import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from '../../../../components/SectionHeading'
import styles from './ContributorsList.module.sass'
import ContributorListItem from './ContributorListItem'
import cx from 'classnames'

const ContributorsList = ({ contributors = [], extraItems = 0 }) => {
  const half = Math.ceil(contributors.length / 2 + extraItems)
  const firstHalf = contributors.slice(0, half)
  const secondHalf = contributors.slice(half)
  return (
    <section className={cx(styles.singleStory)}>
      <SectionHeading title="Contributors" />

      <div className={styles.contributorsList}>
        {firstHalf && firstHalf.length > 0 && (
          <ContributorListItem contributors={firstHalf} />
        )}

        {secondHalf && secondHalf.length > 0 && (
          <ContributorListItem contributors={secondHalf} />
        )}
      </div>
    </section>
  )
}

ContributorsList.propTypes = {
  contributors: PropTypes.array,
  extraItems: PropTypes.number
}

export default ContributorsList
