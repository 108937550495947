import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestFaq = createAction('FAQ_REQUEST')
export const requestSuccess = createAction('FAQ_SUCCESS')
export const requestFail = createAction('FAQ_FAIL')

export const actions = {
  requestFaq,
  requestSuccess,
  requestFail
}

const faqReducer = createReducer(
  { fetching: true, faqs: [] },
  {
    [requestFaq]: state => {
      state.fetching = true
    },
    [requestSuccess]: (state, { payload }) => {
      state.fetching = false
      state.faqs = payload
    },
    [requestFail]: state => {
      state.fetching = false
      state.faqs = []
    }
  }
)

export default faqReducer
