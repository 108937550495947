import { createSelector } from 'reselect'
import { isBusy } from '../AsyncFlags/selectors'
import { matchPath } from 'react-router-dom'
import {
  forceHideUsedFilter,
  forceDisplayFilterSection
} from './config/riverConfig'
import { contentRiversConfigModule } from '../../siteConfig/river'

const { DEFAULT_RIVER } = contentRiversConfigModule?.rivers || {}

export const getRouter = state => state.router || {}

export const getRiverId = createSelector(getRouter, router => {
  let riverKey, args
  if (router?.location) {
    const { pathname } = router.location
    const match = matchPath(pathname, {
      path: '/(river|stories)/:riverKey/:args*'
    })
    if (match?.params) {
      riverKey = match.params.riverKey
      args = decodeURIComponent(match.params.args)
    }
    if (!riverKey) {
      const matchSearch = matchPath(pathname, {
        path: '/search/:searchTerm*'
      })
      if (matchSearch?.params) {
        riverKey = 'search'
        args = matchSearch.params.searchTerm
        if (args) {
          args = decodeURIComponent(args)
        }
        if (!args) {
          return ''
        }
      }
    }
  }
  return args ? `${riverKey};;${args}` : riverKey
})

export const getRiverKey = createSelector(getRiverId, (id = DEFAULT_RIVER) => {
  return id.split(';;')[0]
})

export const getRiverArgs = createSelector(getRiverId, (id = DEFAULT_RIVER) => {
  const splitId = id.split(';;')
  return splitId.length === 2 ? splitId[1] : ''
})

export const getRiver = state => state.contentRiver || {}

export const getContentRiver = createSelector(
  [getRiverId, getRiver],
  (riverId, river) => {
    return river[riverId] || {}
  }
)

export const getSearchBody = createSelector(
  getContentRiver,
  river => river.filter || {}
)

export const getSearchConfig = createSelector(getContentRiver, river => {
  const filters = river.filter || {}
  const showMedicalAbstracts = river.showMedicalAbstracts
  return {
    filters,
    showMedicalAbstracts
  }
})

export const getUsedRiverFilters = createSelector(
  [getRiverId, getSearchBody],
  (riverId, searchBody) => {
    const { filters = {} } = searchBody
    return Object.keys(filters).reduce((acc, key) => {
      const val = filters[key]
      if (forceHideUsedFilter(riverId, key, val)) {
        return acc
      } else if (Array.isArray(val)) {
        return [
          ...acc,
          ...val.map(value => ({
            field: key,
            value: value
          }))
        ]
      } else {
        return [...acc, { field: key, value: val }]
      }
    }, [])
  }
)

export const getAggs = createSelector(getContentRiver, river => river.aggs)
export const getFoundTags = createSelector(
  getContentRiver,
  river => river?.tagForCategory ?? {}
)
export const getFiltersByCategory = createSelector(
  getContentRiver,
  river => river?.tagForCategory ?? []
)

export const getUsedFiltersFormatted = createSelector(
  getUsedRiverFilters,
  usedFilters => {
    const validUsedFilters = usedFilters ?? []
    const result = {}

    validUsedFilters.forEach(item => {
      const { field, value } = item
      if (!result[field]) {
        result[field] = []
      }
      result[field].push(value)
    })

    return result
  }
)

export const getAvailableRiverFilters = createSelector(
  [getRiverId, getSearchBody, getAggs, getUsedFiltersFormatted],
  (riverId, searchBody, aggs = {}, validUsedFilters) => {
    const { filters = {} } = searchBody

    const availableFilters = Object.keys(aggs)
      .filter(key => key !== 'publication_date')
      .reduce((acc, key) => {
        const value = aggs[key]
        if (
          !filters[key] ||
          (Object.keys(value).length > 1 &&
            forceDisplayFilterSection(riverId, key))
        ) {
          return { ...acc, [key]: value }
        }

        const newValues = Object.keys(value)
          .filter(k => {
            return validUsedFilters[key] &&
              validUsedFilters[key].indexOf(k) !== -1
              ? filters[key]
              : filters[key].indexOf(k) === -1
          })
          .reduce(
            (acc, k) => ({
              ...acc,
              [k]: value[k]
            }),
            {}
          )
        return { ...acc, [key]: newValues }
      }, {})
    return availableFilters
  }
)

export const getCount = createSelector(
  getContentRiver,
  river => river.count || 0
)

export const getPage = createSelector(getContentRiver, river => river.page || 0)

export const getResults = createSelector(
  getContentRiver,
  river => river.results || []
)

export const getResultCount = createSelector(
  getResults,
  results => results.length || 0
)

export const infiniteScrollerEnabled = createSelector(
  [
    getCount,
    getResultCount,
    isBusy('CONTENT_RIVER'),
    isBusy('CONTENT_RIVER_LOAD_MORE')
  ],
  (count, resultCount, searchIsBusy, searchLoadMoreIsBusy) => {
    return (
      count > 10 &&
      count > resultCount &&
      !(searchIsBusy || searchLoadMoreIsBusy)
    )
  }
)

export const getViews = state => state.views

export const getRequestedViews = createSelector(getViews, views => {
  return Object.keys(views).reduce(
    (acc, key) => ({
      ...acc,
      [key]: views[key]
    }),
    {}
  )
})

export const getSearchQuery = createSelector(getRouter, router => {
  let query = ''
  if (router?.location?.search) {
    query = router.location.search
  }
  return query
})

export const getLoading = createSelector(getContentRiver, river => {
  return river.loading !== false
})

export const getLoadingMore = createSelector(getContentRiver, river => {
  return river.loadingMore !== false
})
export const getFeaturedLoading = createSelector(getContentRiver, river => {
  return river.loadingFeaturedContent
})

export const getSorting = createSelector(getSearchBody, searchBody => {
  return searchBody.sort
})

export const getIsSavedSearch = createSelector(getRiver, river => {
  return river.isSavedSearch
})
