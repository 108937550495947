import { createSelector } from 'reselect'
import { getAnonArticleLimitSettings } from '../Config/selectors'

export const getPreviousId = state => state.benefits.lastArticleId

// Set paywall redirect url
export const getPaywallRedirectUrl = () =>
  createSelector(getAnonArticleLimitSettings(), anonArticleLimitSettings => {
    return anonArticleLimitSettings?.registerPopUpFlow
      ? '/register'
      : '/benefits'
  })
