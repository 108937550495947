const PaymentRepository = Fw5MLApi => ({
  async processPayment(form) {
    const req = await Fw5MLApi.fetch('profile/payment/process', 'POST', {
      ...form
    })
    return req.data
  },
  async processGuestPayment(form) {
    const req = await Fw5MLApi.fetch('profile/payment/process/guest', 'POST', {
      ...form
    })
    return req.data
  },
  async applyCoupon({ coupon }) {
    const req = await Fw5MLApi.fetch(
      'profile/payment/validate_coupon',
      'POST',
      {
        coupon
      }
    )
    return req.data
  },
  async getPrice() {
    const req = await Fw5MLApi.fetch('profile/payment/price', 'GET')
    return req.data
  }
})

export default PaymentRepository
