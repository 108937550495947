export const orderedFilters = [
  'type',
  'publication_date',
  'fw_market_segment',
  'fw_product',
  'fw_company',
  'conditions',
  'fw_conference',
  'fw_regulatory_agency',
  'fw_regulatory_market',
  'fw_job_position',
  'fw_therapeutic_category'
]

export const filterLabels = {
  type: 'Story Type',
  publication_date: 'Date Range',
  fw_market_segment: 'Market Segments',
  fw_product: 'Products',
  fw_company: 'Companies',
  fw_companies: 'Companies',
  fw_regulatory_market: 'Regulatory Markets',
  fw_regulatory_agency: 'Regulatory Agencies',
  fw_regulatory_qualifier: 'Regulatory Agencies',
  conditions: 'Conditions',
  fw_therapeutic_category: 'Therapeutic Categories',
  fw_job_position: 'Business Areas',
  fw_conference: 'Conferences',
  fw_category: 'Categories',
  fw_job_title: 'Job Title',
  fw_story_watch: 'StoryWatch'
}
export const excludedCategories = [
  'type',
  'publication_date',
  'fw_category',
  'fw_companies',
  'fw_job_title',
  'fw_job_position',
  'fw_regulatory_agency',
  'fw_story_watch'
]
const overrides = {
  '1_day': '1 Day',
  '1_week': '1 Week',
  '1_month': '1 Month',
  '2_month': '2 Months',
  '3_month': '3 Months',
  '4_month': '4 Months',
  '5_month': '5 Months',
  '6_month': '6 Months'
}

export const extraFilters = {
  'KOL Views': ['KOL Views', 'KOL Views Results'],
  'Physician Views': ['Physician Views', 'Physician Views Poll Results']
}

export const getFilterLabel = filter => overrides[filter] || filter
export const getKey = key => filterLabels[key] || key
export const getExtraFilters = filter => extraFilters[filter] || null
